// React Core
import { useEffect, useContext } from 'react';

// React Router Dom
import { useHistory } from 'react-router-dom';

// Global State
import BlendCreatorContext from '../../context/BlendCreator/blendCreatorContext';
import UserCartContext from '../../context/UserCart/userCartContext';

// Ionic Components
import {
  IonButton,
  IonItem,
  IonLabel,
  IonLoading,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';

// Helper Functions
import currencyFormatter from '../../helpers/currencyFormatter';
import {
  getGrindDisplayName,
  getRoastDisplayName,
} from '../../helpers/getDisplayNames';

// Styling
import './CreatedBlendSummary.css';

interface Props {
  setBlendingStep: (x: number) => void;
}

const CreatedBlendSummary: React.FC<Props> = ({ setBlendingStep }) => {
  const history = useHistory();

  const {
    blend_recipes,
    blend_name,
    blend_description,
    blend_roastType,
    blend_grindType,
    blend_bagQuantity,
    blend_bagTypeId,
    blend_bagSizeId,
    loading,
    dynamically_priced_blends,
    setBlendBagSizeId,
    setBlendBagQuantity,
    fetchDynamicallyPricedBlend,
    resetBlendCreator,
    setShowAddToCartToast,
  } = useContext(BlendCreatorContext);

  const { addToCart, loading: cart_loading } = useContext(UserCartContext);

  useEffect(() => {
    if (blend_recipes.length === 0) {
      history.push('/blend');
    }

    fetchDynamicallyPricedBlend();

    //eslint-disable-next-line
  }, [blend_recipes, blend_bagSizeId, blend_bagQuantity]);

  const onAddToCartHandler = async () => {
    let blendRecipes = blend_recipes.map((bean: any) => {
      return {
        beanId: bean.id,
        percentage: bean.percentage,
      };
    });

    let data = {
      bagSizeId: blend_bagSizeId,
      bagTypeId: blend_bagTypeId,
      blendRecipes,
      certificationId: 1,
      description: blend_description,
      name: blend_name,
      grindId: blend_grindType,
      roastId: blend_roastType,
      quantity: blend_bagQuantity,
      lableDesignId: 58,
    };

    await addToCart(data);
    resetBlendCreator();
    setShowAddToCartToast(true);
    history.push('/blend');
  };

  const onGoCartBlendingBoardHandler = async () => {
    if (blend_recipes.length === 0 && !blend_name && !blend_description) {
      history.push('/cart');
      return;
    }

    let blendRecipes = blend_recipes.map((bean: any) => {
      return {
        beanId: bean.id,
        percentage: bean.percentage,
      };
    });

    let data = {
      bagSizeId: blend_bagSizeId,
      bagTypeId: blend_bagTypeId,
      blendRecipes,
      certificationId: 1,
      description: blend_description,
      name: blend_name,
      grindId: blend_grindType,
      roastId: blend_roastType,
      quantity: blend_bagQuantity,
      lableDesignId: 58,
    };

    await addToCart(data);
    resetBlendCreator();
    history.push('/cart');
  };

  const onBackBlendStepHandler = () => {
    setBlendingStep(3);
  };

  const onBagSizeChangeHandler = (e: any) => {
    setBlendBagSizeId(e.detail.value);
  };

  const onBagQuantityChangeHandler = (e: any) => {
    setBlendBagQuantity(e.detail.value);
  };

  return (
    <div className="createdBlendSummary">
      <IonLoading isOpen={cart_loading} />
      <p className="blendingBoard__text">Blend Summary</p>
      <p className="blendingBoard__subtext">
        Here you can find a summary of your very own blend.
      </p>

      <div className="createdBlendSummary__maincontent">
        <div className="createdBlendSummary__info">
          <div className="createdBlendSummary__bagInfo">
            <IonItem>
              <IonLabel>Select Bag Size</IonLabel>
              <IonSelect
                value={blend_bagSizeId}
                onIonChange={onBagSizeChangeHandler}
              >
                <IonSelectOption value={3}>250 gram</IonSelectOption>
                <IonSelectOption value={5}>500 gram</IonSelectOption>
                <IonSelectOption value={7}>1 kg</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>Bag Quantity</IonLabel>
              <IonSelect
                value={blend_bagQuantity}
                onIonChange={onBagQuantityChangeHandler}
              >
                {[...new Array(100)].map((_, idx: number) => (
                  <IonSelectOption key={idx} value={idx + 1}>
                    {idx + 1}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th className="createdBlendSummary__name">
                    <span>Coffee Name</span>
                  </th>
                  <th className="createdBlendSummary__desc">Description</th>
                  <th className="createdBlendSummary__percentage">
                    Blend Percentage
                  </th>
                  <th className="createdBlendSummary__roast">Roast</th>
                  <th className="createdBlendSummary__grind">Grind</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="createdBlendSummary__name">{blend_name}</td>
                  <td className="createdBlendSummary__desc">
                    {blend_description}
                  </td>
                  <td className="createdBlendSummary__percentage">
                    {blend_recipes.map((bean: any, idx: number) => (
                      <p key={idx}>
                        {bean.percentage}% {bean.name}
                      </p>
                    ))}
                  </td>
                  <td className="createdBlendSummary__roast">
                    {getRoastDisplayName(blend_roastType)}
                  </td>
                  <td className="createdBlendSummary__grind">
                    {getGrindDisplayName(blend_grindType)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="createdBlendSummary__pricing">
          <div className="createdBlendSummary__stats">
            <h4 className="createdBlendSummary__stats-heading">Stats</h4>
            <div className="createdBlendSummary__stats-text flex-col">
              <div>
                <span>Price Per Kilo</span>{' '}
                <span>
                  {currencyFormatter(dynamically_priced_blends.price_per_kilo)}
                </span>
              </div>

              <div>
                <span>Volume Discount Per Kilo</span>{' '}
                <span>
                  {currencyFormatter(
                    dynamically_priced_blends.volume_discount_per_kilo
                  )}
                </span>
              </div>
            </div>

            <div className="createdBlendSummary__stats-text">
              <span>Quantity</span>
              <span>{dynamically_priced_blends.quantity}</span>
            </div>

            <div className="createdBlendSummary__stats-text">
              <span>Price per Bag</span>
              <span>
                {currencyFormatter(dynamically_priced_blends.unit_price)}
              </span>
            </div>
          </div>

          <div className="createdBlendSummary__totals">
            <h4 className="createdBlendSummary__stats-heading">Totals</h4>
            <div className="createdBlendSummary__stats-text">
              <span>Sub Total</span>
              <span>
                {currencyFormatter(dynamically_priced_blends.subtotal)}
              </span>
            </div>

            <div className="createdBlendSummary__stats-text">
              <span>Volume Discount</span>
              <span>
                {currencyFormatter(dynamically_priced_blends.volume_discount)}
              </span>
            </div>

            <div className="createdBlendSummary__stats-text grand-total">
              <span>Grand Total</span>
              <span>
                {currencyFormatter(dynamically_priced_blends.total_price)}
              </span>
            </div>
          </div>

          <div className="createdBlendSummary__checkoutBtns">
            <IonButton
              onClick={onAddToCartHandler}
              className="secondary"
              disabled={loading}
            >
              Add To Cart
            </IonButton>
            <IonButton
              onClick={onGoCartBlendingBoardHandler}
              className="warning"
              disabled={loading}
            >
              Checkout
            </IonButton>
          </div>
        </div>
      </div>

      <div className="blendpage__actionBtns">
        <div className="blendpage__continueBtns">
          <IonButton
            onClick={onBackBlendStepHandler}
            className="blendpage__nextBtn secondary"
          >
            Back
          </IonButton>
        </div>
      </div>
    </div>
  );
};

export default CreatedBlendSummary;
