// Ionic Components
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
} from '@ionic/react';

// Core React
import React, { useEffect, useState } from 'react';

// React Router Dom
import { useHistory, useLocation } from 'react-router';

// Axios Modules
import axios from 'axios';
import config from '../../config';

// Styling & Assets
import './LoginPage.css';

// Custom Components
import { Header } from '../../components/Layout/Header';
import { Footer } from '../../components/Layout/Footer';
import { Helmet } from 'react-helmet';

const loginpageHeroBg = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/barista-making-chemex-coffee.png')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

interface Params {
  emailVar: string;
  passwordVar: string;
}

const LoginPage: React.FC<Params> = () => {
  const location = useLocation();
  const history = useHistory();

  // Local State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Component Variables
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingLogin, setLoadingLogin] = useState(false);

  // Check to see if user has already logged in
  const blendlyAuthToken = localStorage.getItem('blendlyAuthToken');

  useEffect(() => {
    // Check if Token found in localstorage
    if (blendlyAuthToken) {
      // If already logged in redirect back to previous page
      history.push('/');
    }

    // eslint-disable-next-line
  }, []);

  // Fire on Input change of the form fields
  const onChangeField = (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }

    // Remove errors messages on change input
    setErrorMessage('');
  };

  const onFormSubmit = async (e: any) => {
    // Stop default form submit
    e.preventDefault();

    // Extract CartID from localstorage
    let cartId = localStorage.getItem('blendlyCartId');

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // SearchParams constructor to extraxt the url search params
    let searchParams = new URLSearchParams(location.search);

    try {
      setLoadingLogin(true);
      // Login User to get token
      const res = await axios.post(
        `${config.apiURL}/authenticate/blendly`,
        {
          cartId: cartId,
          email: email,
          password: password,
        },
        axiosConfig
      );

      // Save the token received to localstorage
      localStorage.setItem('blendlyAuthToken', res.data.token);

      // Extract possible redirect params value
      let redirectParam = searchParams.get('redirect');

      // Once done login will redirect to this url
      if (redirectParam) {
        localStorage.setItem('blendlyAuthToken', res.data.token);
        history.push(`/${redirectParam}`);
      } else {
        localStorage.setItem('blendlyAuthToken', res.data.token);
        // This will default redirect to the profile page once login completed
        history.push('/profile');
      }

      setLoadingLogin(false);
    } catch (error: any) {
      console.log(error.response.data);
      setErrorMessage(error.response.data.message);
      setLoadingLogin(false);
    }
  };

  return (
    <IonPage className="loginpage">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      <IonContent>
        <Header />

        <IonToast
          isOpen={errorMessage !== ''}
          onDidDismiss={() => setErrorMessage('')}
          message={errorMessage}
          duration={5000}
          color="danger"
        />

        <div className="loginpage__hero" style={loginpageHeroBg}>
          <form className="loginpage__form" onSubmit={onFormSubmit}>
            <h2>Members' Area</h2>

            <IonItem>
              <IonLabel position="floating">Email</IonLabel>
              <IonInput
                name="email"
                type="email"
                required
                onIonChange={onChangeField}
              />
            </IonItem>

            <IonItem>
              <IonLabel position="floating">Password</IonLabel>
              <IonInput
                name="password"
                type="password"
                required
                onIonChange={onChangeField}
              />
            </IonItem>

            <IonButton
              className="warning loginpage__form__button"
              expand="block"
              type="submit"
              disabled={loadingLogin}
            >
              {!loadingLogin ? 'Log In' : 'Loading...'}
            </IonButton>

            <IonGrid className="password-username">
              <IonRow>
                <IonCol>
                  <IonRouterLink
                    className="password-username__links"
                    routerLink="/passwordreset"
                  >
                    Forgot Password
                  </IonRouterLink>
                </IonCol>
              </IonRow>
            </IonGrid>

            <hr className="loginpage__hr" />

            <p className="loginpage__logincaption">Don't Have an account?</p>
            <p className="loginpage__logincaption">Sign up today</p>

            <IonRouterLink routerLink="/signup">
              <IonButton
                className="warning loginpage__form__button"
                expand="block"
              >
                Sign up
              </IonButton>
            </IonRouterLink>
          </form>
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
