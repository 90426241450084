import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.css';

// GLobal State using CONTEXT API
import CuppingNotesState from './context/CuppingNotes/CuppingNotesState';
import UserProfileState from './context/UserProfile/UserProfileState';
import BlendCreatorState from './context/BlendCreator/BlendCreatorState';
import UserCartState from './context/UserCart/UserCartState';
import OrderDetailsState from './context/OrderDetails/OrderDetailsState';
import CheckoutState from './context/Checkout/CheckoutState';
import MobileMenuState from './context/MobileMenu/MobileMenuState';
import ShopState from './context/Shop/ShopState';

ReactDOM.render(
  <MobileMenuState>
    <UserProfileState>
      <OrderDetailsState>
        <CheckoutState>
          <BlendCreatorState>
            <UserCartState>
              <CuppingNotesState>
                <ShopState>
                  <App />
                </ShopState>
              </CuppingNotesState>
            </UserCartState>
          </BlendCreatorState>
        </CheckoutState>
      </OrderDetailsState>
    </UserProfileState>
  </MobileMenuState>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
