import React from 'react';
import { IonRow, IonCol, IonText } from '@ionic/react';

import './Banner.css';

type Props = {
  options: {
    h1: string;
    h2: string;
    bgImg?: string;
    size: string;
  };
};

const Banner: React.FC<Props> = ({ children, options }) => (
  <div
    className={`banner ${options.size}`}
    style={options.bgImg ? { backgroundImage: `url('${options.bgImg}')` } : {}}
  >
    <div className={`ctaWrapper ${options.bgImg && 'shadowOverlay'}`}>
      <IonText color="secondary">
        {children}
        <h2 className="darkCTA">
          {options.h1}
          <span>{options.h2}</span>
        </h2>
      </IonText>
    </div>
  </div>
);

export default Banner;
