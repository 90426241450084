import { IonButton } from '@ionic/react';
import { useContext } from 'react';

// Global State
import CuppingNotesContext from '../../context/CuppingNotes/cuppingNotesContext';

// Custom Components
import BeansIcons from '../BeansIcons/BeansIcons';

// Styling
import './NoteInformationSummary.css';

const QRCode = require('qrcode.react');

const NoteInformationSummary = () => {
  const { selectedNote } = useContext(CuppingNotesContext);

  return (
    <ul className="noteInformationSummary">
      <li>
        <div className="noteInformationSummary__keyvalue">
          <span className="noteInformationSummary__key">CTAPS ID</span>
          <span className="noteInformationSummary__value">
            {selectedNote.ctaps_id}
          </span>
        </div>
      </li>
      <li>
        <div className="noteInformationSummary__keyvalue">
          <span className="noteInformationSummary__key">Name</span>
          <span className="noteInformationSummary__value">
            {selectedNote.name}
          </span>
        </div>
      </li>
      <li>
        <div className="noteInformationSummary__keyvalue">
          <span className="noteInformationSummary__key">Description</span>
          <span className="noteInformationSummary__value">
            {selectedNote.description}
          </span>
        </div>
      </li>
      <li>
        <div className="noteInformationSummary__keyvalue">
          <span className="noteInformationSummary__key">Roast</span>
          <div className="noteInformationSummary__value">
            <span>{selectedNote.roast_display_name}</span>

            <BeansIcons roastId={selectedNote.roast_id} />
          </div>
        </div>
      </li>
      <li>
        <div className="noteInformationSummary__keyvalue">
          <span className="noteInformationSummary__key">Grind</span>
          <span className="noteInformationSummary__value">
            {selectedNote.grind_display_name}
          </span>
        </div>
      </li>
      <li>
        <div className="noteInformationSummary__keyvalue">
          <span className="noteInformationSummary__key">Origins</span>
          <span className="noteInformationSummary__value">
            {selectedNote.originsStrings.join(', ')}
          </span>
        </div>
      </li>
      <li>
        <div className="noteInformationSummary__keyvalue">
          <span className="noteInformationSummary__key">Brand</span>
          <span className="noteInformationSummary__value">
            {selectedNote.brand}
          </span>
        </div>
      </li>
      <li>
        <div className="noteInformationSummary__keyvalue">
          <span className="noteInformationSummary__key noteInformationSummary__qrtext">
            QR Code
          </span>
          <span className="noteInformationSummary__value">
            <QRCode value={window.location.href} />
          </span>
        </div>
      </li>
      <li>
        <div className="noteInformationSummary__keyvalue">
          <span className="noteInformationSummary__key">Invite everyone</span>
          <div className="noteInformationSummary__value">
            <IonButton
              className="secondary"
              onClick={() => {
                navigator.clipboard
                  .writeText(window.location.href)
                  .then(() => {
                    console.log('Text copied to clipboard...');
                  })
                  .catch((err) => {
                    console.log('Something went wrong', err);
                  });
              }}
            >
              Copy Link
            </IonButton>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default NoteInformationSummary;
