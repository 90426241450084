import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonPage,
  IonContent,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../FormStyles.css';
import config from '../../../../config';
import { Header } from '../../../../components/Layout/Header';
import { Footer } from '../../../../components/Layout/Footer';

const EditBasicInfo: React.FC = () => {
  // User Data state
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [userCity, setUserCity] = useState('');
  const [userProvice, setUserProvice] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [countryId, setCountryId] = useState('');
  const [userPhone, setUserPhone] = useState('');
  //   Countries State
  const [countries, setCountries] = useState([
    { id: '', display_name: '', value: '' },
  ]);
  //   Error state
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  //   Check to see if still logged in
  const blendlyAuthToken = window.localStorage.getItem('blendlyAuthToken');
  if (blendlyAuthToken === null) {
    window.location.href = '/login';
  }

  useEffect(() => {
    getUserData();

    //eslint-disable-next-line
  }, []);

  const getUserData = async () => {
    //   Get User Data
    const responseUser = await axios(
      `${config.apiURL}/authenticate/blendly/user?token=${blendlyAuthToken}`
    );
    // Get all countries
    const responseCountries = await axios(`${config.apiURL}/countries`);
    // Destructuring data from user object
    const {
      address,
      city,
      country_id,
      first_name,
      last_name,
      province,
      zip_code,
      company_name,
      phone,
    } = responseUser.data;
    // Updating state with data
    setFirstName(first_name);
    setLastName(last_name);
    setCompanyName(company_name);
    setUserAddress(address);
    setUserCity(city);
    setUserProvice(province);
    setZipCode(zip_code);
    setCountryId(country_id);
    setUserPhone(phone);
    setCountries(responseCountries.data);
  };

  const countriesOption = () => {
    countries.sort((a, b) => {
      let fa = a.value.toLowerCase(),
        fb = b.value.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    return (
      <>
        {countries.map((item: any) => {
          return (
            <IonSelectOption key={item.id} value={item.id}>
              {item.value}
            </IonSelectOption>
          );
        })}
      </>
    );
  };

  const onInputChange = (e: any) => {
    const { value, name } = e.target;

    switch (name) {
      case 'firstname':
        setFirstName(value);
        break;
      case 'surname':
        setLastName(value);
        break;
      case 'companyName':
        setCompanyName(value);
        break;
      case 'address':
        setUserAddress(value);
        break;
      case 'city':
        setUserCity(value);
        break;
      case 'province':
        setUserProvice(value);
        break;
      case 'zipCode':
        setZipCode(value);
        break;
      case 'country':
        setCountryId(value);
        break;
      case 'phone':
        setUserPhone(value);
        break;
      default:
        break;
    }

    // Remove errors on change input
    setIsError(false);
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    axios({
      method: 'put',
      url: `${config.apiURL}/customers/update?token=${blendlyAuthToken}`,
      data: {
        lastName: lastName,
        firstName: firstName,
        companyName: companyName,
        address: userAddress,
        city: userCity,
        province: userProvice,
        zipCode: zipCode,
        countryId: countryId,
        phone: userPhone,
      },
    })
      .then((res) => {
        window.location.href = '/profile';
      })
      .catch((err) => {
        setIsError(true);
        setErrorMessage(err.response.data.message);
      });
  };

  //   Go Back to the previous page
  const goBack = () => {
    window.history.back();
  };

  return (
    <IonPage className="edit-forms">
      <IonContent>
        <Header />
        <div className="edit-forms__container">
          <h4 className="form-title">Update Basic & Billing Information</h4>
          <form className="form" onSubmit={onFormSubmit}>
            <IonGrid>
              {/* First and Lastname Row */}
              <IonRow className="edit-forms__form__rows name-fields">
                <IonCol>
                  <IonItem className="form-input">
                    <IonLabel className="form-label" position="floating">
                      First Name*
                    </IonLabel>
                    <IonInput
                      type="text"
                      value={firstName}
                      name="firstname"
                      required
                      onIonChange={onInputChange}
                    ></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem className="form-input">
                    <IonLabel className="form-label" position="floating">
                      Last Name*
                    </IonLabel>
                    <IonInput
                      type="text"
                      value={lastName}
                      name="surname"
                      required
                      onIonChange={onInputChange}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              {/* Company Name Row */}
              <IonRow className="edit-forms__form__rows">
                <IonCol>
                  <IonItem className="form-input">
                    <IonLabel className="form-label" position="floating">
                      Company Name*
                    </IonLabel>
                    <IonInput
                      type="text"
                      value={companyName}
                      name="companyName"
                      required
                      onIonChange={onInputChange}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              {/* Address Row */}
              <IonRow className="edit-forms__form__rows">
                <IonCol>
                  <IonItem className="form-input">
                    <IonLabel className="form-label" position="floating">
                      Address*
                    </IonLabel>
                    <IonInput
                      type="text"
                      value={userAddress}
                      name="address"
                      required
                      onIonChange={onInputChange}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              {/* City Row */}
              <IonRow className="edit-forms__form__rows">
                <IonCol>
                  <IonItem className="form-input">
                    <IonLabel className="form-label" position="floating">
                      City*
                    </IonLabel>
                    <IonInput
                      type="text"
                      value={userCity}
                      name="city"
                      required
                      onIonChange={onInputChange}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              {/* State/Province Row */}
              <IonRow className="edit-forms__form__rows">
                <IonCol>
                  <IonItem className="form-input">
                    <IonLabel className="form-label" position="floating">
                      State/Province*
                    </IonLabel>
                    <IonInput
                      type="text"
                      value={userProvice}
                      name="province"
                      required
                      onIonChange={onInputChange}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              {/* Zip/Postal Code Row */}
              <IonRow className="edit-forms__form__rows">
                <IonCol>
                  <IonItem className="form-input">
                    <IonLabel className="form-label" position="floating">
                      Zip/Postal Code*
                    </IonLabel>
                    <IonInput
                      type="text"
                      value={zipCode}
                      name="zipCode"
                      required
                      onIonChange={onInputChange}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              {/* Country Row */}
              <IonRow className="edit-forms__form__rows">
                <IonCol>
                  <IonItem className="form-input">
                    <IonLabel className="form-label" position="floating">
                      Country*
                    </IonLabel>
                    <IonSelect
                      value={countryId}
                      name="country"
                      interface="popover"
                      onIonChange={onInputChange}
                      className="edit-forms__form-select"
                    >
                      {countriesOption()}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              {/* Phone Number Row */}
              <IonRow className="edit-forms__form__rows">
                <IonCol>
                  <IonItem className="form-input">
                    <IonLabel className="form-label" position="floating">
                      Phone Number*
                    </IonLabel>
                    <IonInput
                      type="tel"
                      value={userPhone}
                      name="phone"
                      required
                      onIonChange={onInputChange}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              {/* Submit buttons */}
              <IonRow>
                <IonCol className="edit-forms__form-buttons">
                  <IonButton
                    type="button"
                    className="edit-forms__form-back"
                    onClick={goBack}
                  >
                    Back
                  </IonButton>
                  <IonButton
                    type="submit"
                    className="edit-forms__form-submit"
                    color="#FDAB40"
                  >
                    Submit
                  </IonButton>
                </IonCol>
              </IonRow>
              {/* Error and succesful messages */}
              <div className="edit-forms__messages">
                {isError ? (
                  <>
                    <p className="edit-forms__messages__error">
                      Something went wrong
                    </p>
                    <p className="edit-forms__messages__error">
                      {errorMessage}
                    </p>
                  </>
                ) : null}
              </div>
            </IonGrid>
          </form>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default EditBasicInfo;
