// React Core
import { useContext, useState } from 'react';

// Global State
import BlendCreatorContext from '../../context/BlendCreator/blendCreatorContext';

// Ionic Components
import {
  IonAlert,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonTextarea,
} from '@ionic/react';

// Styling
import './ChooseBlendName.css';

interface Props {
  setBlendingStep: (x: number) => void;
}

const ChooseBlendName: React.FC<Props> = ({ setBlendingStep }) => {
  const [showAlert1, setShowAlert1] = useState<boolean>(false);
  const { blend_name, blend_description, setBlendName, setBlendDescription } =
    useContext(BlendCreatorContext);

  const onNextBlendStepHandler = () => {
    if (blend_name.trim() === '' || blend_description.trim() === '') {
      setShowAlert1(true);
      return;
    }

    setBlendingStep(4);
  };

  const onBackBlendStepHandler = () => {
    setBlendingStep(2);
  };

  return (
    <div className="chooseBlendName">
      <p className="blendingBoard__text">Name your blend</p>
      <p className="blendingBoard__subtext">
        What would you like to call your coffee blend? Describe it a little.
      </p>

      <div className="chooseBlendName__maincontainer">
        <IonItem>
          <IonLabel position="stacked">Blend Name</IonLabel>
          <IonInput
            value={blend_name}
            onIonChange={(e) => setBlendName(e.detail.value)}
            placeholder="Enter Input"
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Describe your blend</IonLabel>
          <IonTextarea
            value={blend_description}
            onIonChange={(e) => setBlendDescription(e.detail.value)}
            placeholder="placeholder"
          ></IonTextarea>
        </IonItem>
      </div>

      <div className="chooseBlendName__actionBtns">
        <div className="chooseBlendName__continueBtns">
          <IonButton
            onClick={onBackBlendStepHandler}
            className="chooseBlendName__nextBtn secondary"
          >
            Back
          </IonButton>
          <IonButton
            onClick={onNextBlendStepHandler}
            className="chooseBlendName__nextBtn primary"
          >
            Next
          </IonButton>
        </div>
      </div>

      <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        cssClass="my-custom-class"
        header={'Alert'}
        message={
          'You need to provide both a name and description for your blend'
        }
        buttons={['OK']}
      />
    </div>
  );
};

export default ChooseBlendName;
