import './HomePageHeader.css';

type Word = {
  word: string;
  color: string;
};

interface Props {
  words: [Word, Word];
  line: boolean;
}

const HomePageHeader: React.FC<Props> = ({ words, line }) => {
  return (
    <div className={`homepageheader ${line && 'bottom-line'}`}>
      {words.map((w, i) => (
        <h2
          className={`homepageheader__title-${i + 1}`}
          key={w.word}
          style={{ color: w.color }}
        >
          {w.word}
        </h2>
      ))}
    </div>
  );
};

export default HomePageHeader;
