import { IonContent, IonPage } from '@ionic/react';
import './Home.css';

/* Components Import */
import { Header } from '../components/Layout/Header';
import { HomeSlider } from '../components/Home/Slider';
import { Intro } from '../components/Home/Intro';
// import ProductSliderN from '../components/Products/ProductSliderN';
import ProductSlider from '../components/Products/ProductSlider';
import { CreateOwn } from '../components/Home/CreateOwn';
import { Testimonials } from '../components/Home/Testimonial';
import { Footer } from '../components/Layout/Footer';
import { Helmet } from 'react-helmet';

const Home: React.FC = () => {
  return (
    <IonPage>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home Page</title>
      </Helmet>
      <IonContent>
        <Header />

        <HomeSlider />
        <Intro />
        <ProductSlider />
        <CreateOwn />
        <Testimonials />

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
