/**
 *
 * @param {number} grindId
 * @returns string
 */
export const getGrindDisplayName = (grindId) => {
  switch (grindId) {
    case 1:
      return 'General';
    case 2:
      return 'Filter';
    case 3:
      return 'Whole Bean';
    case 4:
      return 'Espresso';
    case 5:
      return 'Cafetière';
    default:
      return '';
  }
};

/**
 *
 * @param {number} roastId
 * @returns string
 */
export const getRoastDisplayName = (roastId) => {
  switch (roastId) {
    case 1:
      return 'Light Medium';
    case 2:
      return 'Medium';
    case 3:
      return 'Light Italian';
    case 4:
      return 'Connoisseur';
    case 5:
      return 'Dark Italian';
    case 6:
      return 'Continental';
    default:
      return '';
  }
};

/**
 *
 * @param {number} bagSizeId
 * @returns string
 */
export const getBagSizeDisplayName = (bagSizeId) => {
  switch (bagSizeId) {
    case 1:
      return '50 grams';
    case 2:
      return '125 grams';
    case 3:
      return '250 grams';
    case 4:
      return '454 grams';
    case 5:
      return '500 grams';
    case 6:
      return '908 grams';
    case 7:
      return '1 kilogram';
    case 8:
      return '60 grams';
    case 9:
      return '160 grams';
    case 10:
      return '150 grams';
    default:
      return '';
  }
};
