import React from "react";
import "./MyProfile.css"; 
import BasicInformation from "./BasicInformation/BasicInformation";
import BillingInformation from "./BillingInformation/BillingInformation";
import ShippingInformation from "./ShippingInformation/ShippingInformation";
import SecurityInformation from "./SecurityInformation/SecurityInformation";

const UserProfile: React.FC = () => {

  return (
    <div className="my-profile">
      <BasicInformation />
      <BillingInformation />
      <ShippingInformation />
      <SecurityInformation />
    </div>
  );
};

export default UserProfile;