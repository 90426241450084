import React from 'react';
import { IonSlides, IonSlide, IonButton } from '@ionic/react';

import './Slider.css';
import HomePageHeader from '../SectionHeaders/HomePageHeader/HomePageHeader';

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.
const slideOpts = {
  initialSlide: 0,
  speed: 1500,
  autoplay: true,
  loop: true,
};

const slides = [
  {
    id: 1,
    title: 'Create your own',
    description: 'Unique Blend',
    btnText: 'Start Blending',
    btnUrl: '/blend',
  },
  {
    id: 2,
    title: 'Shop our',
    description: 'Blends Now',
    btnText: 'Shop Now',
    btnUrl: '/shop',
  },
  {
    id: 3,
    title: 'Become a Barista',
    description: 'Distributor today',
    btnText: 'Sign Up',
    btnUrl: '/signup',
  },
];

export const HomeSlider: React.FC = () => {


  return (
  <IonSlides className="homeSlider" pager={true} options={slideOpts}>
    {slides.map((slide, index) => (
      <IonSlide key={index} className={`sliderImage-${slide.id.toString()}`}>
        <div className="ctaWrapper shadowOverlay">
          <HomePageHeader
            words={[
              { word: slide.title, color: '#CFB79F' },
              { word: slide.description, color: '#EBEAE8' },
            ]}
            line={true}
          />

          <IonButton
            className="primary homeSlider__ctaButton"

            href={slide.btnUrl}
          >
            {slide.btnText}
          </IonButton>
        </div>
      </IonSlide>
    ))}
  </IonSlides>
);
}
