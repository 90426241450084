// Core React
import { useEffect, useContext } from 'react';

// Axios
import axios from 'axios';
import config from '../../config';

// Global State
import userProfileContext from '../../context/UserProfile/userProfileContext';
import checkoutContext from '../../context/Checkout/checkoutContext';

// Ionic Components
import {
  IonButton,
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';

// Styling
import './CheckoutBilling.css';

interface Props {
  setCheckoutStep: (x: number) => void;
}

const CheckoutBilling: React.FC<Props> = ({ setCheckoutStep }) => {
  const authToken = localStorage.getItem('blendlyAuthToken');

  // Global State
  const {
    billingInfo,
    setBillingInfo,
    updateBillingInfo,
    shippingIsSame,
    setShippingIsSame,
    setShippingInfo,
    setCheckoutError,
    countriesData,
    guestEmail,
    setGuestEmail,
  } = useContext(checkoutContext);
  const { userProfileData, addGuestUserData } = useContext(userProfileContext);

  useEffect(() => {
    if (!billingInfo) {
      if (userProfileData) {
        let tempObjOne = {
          first_name: userProfileData.first_name,
          last_name: userProfileData.last_name,
          company_name: userProfileData.company_name,
          address: userProfileData.address,
          country_id: userProfileData.country_id,
          city: userProfileData.city,
          province: userProfileData.province,
          zip_code: userProfileData.zip_code,
          phone: userProfileData.phone,
        };

        setBillingInfo(tempObjOne);
      } else {
        let tempObjTwo = {
          first_name: '',
          last_name: '',
          phone: '',
          company_name: '',
          address: '',
          country_id: '',
          city: '',
          province: '',
          zip_code: '',
        };

        setBillingInfo(tempObjTwo);
      }
    }

    //eslint-disable-next-line
  }, [userProfileData, billingInfo]);

  const goToShippingHandler = async () => {
    let {
      first_name,
      last_name,
      company_name,
      phone,
      address,
      country_id,
      province,
      zip_code,
      city,
    } = billingInfo;

    // Quick Validation
    if (
      !first_name ||
      !last_name ||
      !company_name ||
      !phone ||
      !address ||
      !country_id ||
      !province ||
      !zip_code ||
      !city
    ) {
      setCheckoutError('Please fill in all fields');
      return;
    }

    // If the shipping should be the same
    if (shippingIsSame) {
      let tempShipObj = {
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        company_name: company_name,
        address: address,
        country_id: '', // this should not be set for the shipping form
        province: province,
        zip_code: zip_code,
        city: city,
      };

      // Set Global State
      setShippingInfo(tempShipObj);
    } else {
      // Default Empty Shipping Info
      setShippingInfo();
    }

    // Check if not authToken
    if (!authToken) {
      // Quick Validate
      if (!guestEmail) {
        return setCheckoutError('Please provide guest email');
      }

      try {
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        // Create Guest
        let guestObj = {
          email: guestEmail,
          isBaristaDistributorReferral: null,
          isFacebookCustomer: null,
          isSalesManagerReferral: null,
          referralCode: null,
        };

        // Guest Data??
        const { data } = await axios.post(
          `${config.apiURL}/customers/create-guest`,
          guestObj,
          axiosConfig
        );

        addGuestUserData(data);

        // Move on to the next Shipping Info Step
        setCheckoutStep(3);
      } catch (error: any) {
        setCheckoutError(error.response.data.message);
      }
    } else {
      setCheckoutStep(3);
    }
  };

  return (
    <div className="checkoutBilling">
      <h6 className="checkoutBilling__title">Billing Info</h6>
      <form className="checkoutBilling__form">
        {!authToken && (
          <IonItem className="form-input">
            <IonLabel position="floating" className="form-label">
              Email Address
            </IonLabel>
            <IonInput
              value={guestEmail}
              onIonChange={(e: any) => setGuestEmail(e.detail.value)}
            ></IonInput>
          </IonItem>
        )}

        <div className="checkoutBilling__line" />

        <div className="checkoutBilling__flexContainer">
          <div className="checkoutBilling__leftForm">
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                First Name
              </IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.first_name : ''}
                onIonChange={(e: any) =>
                  updateBillingInfo({
                    key: e.target.name,
                    value: e.detail.value,
                  })
                }
                placeholder="First name"
                name="first_name"
              ></IonInput>
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                Last Name
              </IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.last_name : ''}
                onIonChange={(e: any) =>
                  updateBillingInfo({
                    key: e.target.name,
                    value: e.detail.value,
                  })
                }
                placeholder="Last name"
                name="last_name"
              ></IonInput>
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                Phone Number
              </IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.phone : ''}
                onIonChange={(e: any) =>
                  updateBillingInfo({
                    key: e.target.name,
                    value: e.detail.value,
                  })
                }
                placeholder="Phone number"
                name="phone"
              ></IonInput>
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                Company Name <small>(optional)</small>
              </IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.company_name : ''}
                onIonChange={(e: any) =>
                  updateBillingInfo({
                    key: e.target.name,
                    value: e.detail.value,
                  })
                }
                placeholder="Company name"
                name="company_name"
              ></IonInput>
            </IonItem>
          </div>
          <div className="checkoutBilling__rightForm">
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                Address
              </IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.address : ''}
                onIonChange={(e: any) =>
                  updateBillingInfo({
                    key: e.target.name,
                    value: e.detail.value,
                  })
                }
                placeholder="address"
                name="address"
              ></IonInput>
            </IonItem>
            <IonItem className="form-input">
              <IonLabel
                className="form-label"
                position={
                  billingInfo && billingInfo.country_id ? 'stacked' : 'floating'
                }
              >
                Country
              </IonLabel>
              <IonSelect
                className="edit-forms__form-select"
                value={billingInfo?.country_id}
                onIonChange={(e: any) =>
                  updateBillingInfo({
                    key: 'country_id',
                    value: e.detail.value,
                  })
                }
              >
                {countriesData?.map((country: any) => (
                  <IonSelectOption key={country.id} value={country.id}>
                    {country.display_name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                City
              </IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.city : ''}
                onIonChange={(e: any) =>
                  updateBillingInfo({
                    key: e.target.name,
                    value: e.detail.value,
                  })
                }
                placeholder="City"
                name="city"
              ></IonInput>
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                State/Province
              </IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.province : ''}
                onIonChange={(e: any) =>
                  updateBillingInfo({
                    key: e.target.name,
                    value: e.detail.value,
                  })
                }
                placeholder="State/Province"
                name="province"
              ></IonInput>
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                ZIP/Postal Code
              </IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.zip_code : ''}
                onIonChange={(e: any) =>
                  updateBillingInfo({
                    key: e.target.name,
                    value: e.detail.value,
                  })
                }
                placeholder="ZIP/Postal code"
                name="zip_code"
              ></IonInput>
            </IonItem>
          </div>
        </div>
      </form>

      <div className="checkoutBilling__actionBtns">
        <IonItem>
          <IonLabel>Shipping same as above</IonLabel>
          <IonCheckbox
            onIonChange={(e: any) => setShippingIsSame(e.detail.checked)}
            checked={shippingIsSame}
            slot="start"
          />
        </IonItem>

        <IonButton onClick={goToShippingHandler} className="warning">
          Continue
        </IonButton>
      </div>
    </div>
  );
};

export default CheckoutBilling;
