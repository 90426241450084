// Core React
import { useEffect, useContext } from 'react';

// Global State
import CuppingNotesContext from '../../../context/CuppingNotes/cuppingNotesContext';

// Ionic Components
import {
  IonIcon,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
} from '@ionic/react';

// Custom Components
import CuppingNotesCard from './CuppingNotesCard/CuppingNotesCard';

// Ionic Icons
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';

// React Paginated Module
import ReactPaginate from 'react-paginate';

// Styling & Assets
import './SearchAndListSection.css';

const SearchAndListSection: React.FC = () => {
  const cuppingNotesContext = useContext(CuppingNotesContext);
  const {
    cuppingNotes,
    setSelectedNote,
    selectedNote,
    currentPage,
    setCurrentPage,
    notes,
    setNotes,
    notesPerPage,
    searchValue,
    setSearchValue,
  } = cuppingNotesContext;

  const indexOfLastNote = currentPage * notesPerPage;
  const indexOfFirstNote = indexOfLastNote - notesPerPage;
  const currentNotes = Array.from(notes).slice(
    indexOfFirstNote,
    indexOfLastNote
  );

  const pageCount = Math.ceil(notes.length / notesPerPage);

  const searchNotes = (str: string) => {
    setCurrentPage(1);
    const map1 = new Map();

    let filter1 = cuppingNotes.filter((note: any) => {
      if (note.ctaps_id.includes(str)) return note;
      return null;
    });

    let filter2 = cuppingNotes.filter((note: any) => {
      if (note.name.toLowerCase().includes(str.toLowerCase())) return note;
      return null;
    });

    const allFilters = [...filter1, ...filter2];

    allFilters.forEach((filtered: any) => {
      map1.set(filtered.ctaps_id, filtered);
    });

    let searchedValue: any[] = [];
    map1.forEach((el) => searchedValue.push(el));

    return searchedValue;
  };

  useEffect(() => {
    if (searchValue) {
      setNotes(searchNotes(searchValue));
    } else {
      setNotes(cuppingNotes);
    }

    // eslint-disable-next-line
  }, [searchValue, cuppingNotes]);

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected + 1);
  };

  const handleNoteChange = (e: any) => {
    setSelectedNote(e.detail.value);
  };

  return (
    <div className="searchlistSection">
      <IonSearchbar
        value={searchValue}
        onIonChange={(e) => setSearchValue(e.detail.value!)}
      ></IonSearchbar>

      <IonList>
        <IonRadioGroup
          allowEmptySelection
          value={selectedNote}
          onIonChange={handleNoteChange}
        >
          {currentNotes.map((el: any) => (
            <IonItem key={el.ctaps_id}>
              <IonRadio
                slot="start"
                value={el}
                style={{
                  '--border-radius': '0%',
                  '--inner-border-radius': '0%',
                }}
              />
              <CuppingNotesCard notedata={el} />
            </IonItem>
          ))}
        </IonRadioGroup>
      </IonList>

      <ReactPaginate
        forcePage={currentPage - 1}
        nextLabel={<IonIcon icon={chevronForwardOutline} />}
        previousLabel={<IonIcon icon={chevronBackOutline} />}
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        activeClassName={'active'}
        containerClassName={'searchlistSection__pagination'}
        onPageChange={handlePageClick}
      />
    </div>
  );
};

export default SearchAndListSection;
