// Core React
import { useContext } from 'react';

// Ionic Components
import { IonButton, IonIcon } from '@ionic/react';
import { trashBinOutline } from 'ionicons/icons';

// Custom Components
import BagQuantityAdjuster from '../BagQuantityAdjuster/BagQuantityAdjuster';

// Assets
import BlendCardIcon from '../../assets/icons/blend_card_icon.svg';
import currencyFormatter from '../../helpers/currencyFormatter';

// Styling
import './CartItemCard.css';
import UserCartContext from '../../context/UserCart/userCartContext';

//helper functions
import {
  getGrindDisplayName,
  getBagSizeDisplayName,
} from '../../helpers/getDisplayNames';

interface Props {
  cartItem: any;
}

const CartItemCard: React.FC<Props> = ({ cartItem }) => {
  const { removeFromCart } = useContext(UserCartContext);

  return (
    <tr key={cartItem.id} className="cart__tablerow cartItem__item">
      <td className="cart__Img">
        <img src={BlendCardIcon} alt="blend card icon" />
      </td>
      <td className="cart__Desc">
        <p>{cartItem.name}</p>
        <div>
          <p>
            {getGrindDisplayName(cartItem.grind_id)},{' '}
            {getBagSizeDisplayName(cartItem.bag_size_id)}
          </p>
        </div>
      </td>
      <td className="cart__PricePerBag">
        <p>{currencyFormatter(cartItem.unit_price)}</p>
      </td>
      <td className="cart__Qty">
        <BagQuantityAdjuster cartItem={cartItem} />
      </td>
      <td className="cart__SubTotal">
        <p>{currencyFormatter(cartItem.subtotal)}</p>
      </td>
      <td className="cart__VolDis">
        <p>{currencyFormatter(-cartItem.volume_discount)}</p>
      </td>
      <td className="cart__Total">
        <p>{currencyFormatter(cartItem.total_price)}</p>
      </td>
      <td className="cart__RemoveBtn">
        <IonButton
          size="small"
          color="medium"
          onClick={() => removeFromCart(cartItem.id)}
        >
          <span>Remove</span>
          <IonIcon slot="end" icon={trashBinOutline} />
        </IonButton>
      </td>
    </tr>
  );
};

export default CartItemCard;
