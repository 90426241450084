import { IonButton, IonIcon } from '@ionic/react';
import './CheckoutProcessStepper.css';
import { chevronBackOutline } from 'ionicons/icons';

interface Props {
  checkoutStep: number;
  setCheckoutStep: (x: any) => void;
}

const CheckoutProcessStepper: React.FC<Props> = ({
  checkoutStep,
  setCheckoutStep,
}) => {
  return (
    <>
      {checkoutStep > 1 && (
        <IonButton
          fill="clear"
          onClick={() => setCheckoutStep((prev: any) => prev - 1)}
        >
          <IonIcon slot="start" icon={chevronBackOutline} />
          Back
        </IonButton>
      )}
      <div className="checkoutProcessStepper">
        <h6 className="checkoutProcessStepper__title">Checkout Process</h6>

        <div className="checkoutProcessStepper__container">
          <div className="checkoutProcessStepper__line">
            <div
              style={{ width: `${((checkoutStep - 1) / 4) * 100}%` }}
              className="checkoutProcessStepper__progressLine"
            ></div>
          </div>

          <div className="checkoutProcessStepper__stepMarkers">
            <div
              className={`${
                checkoutStep === 1
                  ? 'active'
                  : checkoutStep > 1
                  ? 'completed'
                  : ''
              } checkoutProcessStepper__marker`}
            >
              <p className="checkoutProcessStepper__cricle"></p>
              <p className="checkoutProcessStepper__stepText">Order Summary</p>
            </div>
            <div
              className={`${
                checkoutStep === 2
                  ? 'active'
                  : checkoutStep > 2
                  ? 'completed'
                  : ''
              } checkoutProcessStepper__marker`}
            >
              <p className="checkoutProcessStepper__cricle"></p>
              <p className="checkoutProcessStepper__stepText">Billing</p>
            </div>
            <div
              className={`${
                checkoutStep === 3
                  ? 'active'
                  : checkoutStep > 3
                  ? 'completed'
                  : ''
              } checkoutProcessStepper__marker`}
            >
              <p className="checkoutProcessStepper__cricle"></p>
              <p className="checkoutProcessStepper__stepText">Shipping</p>
            </div>
            <div
              className={`${
                checkoutStep === 4
                  ? 'active'
                  : checkoutStep > 4
                  ? 'completed'
                  : ''
              } checkoutProcessStepper__marker`}
            >
              <p className="checkoutProcessStepper__cricle"></p>
              <p className="checkoutProcessStepper__stepText">Payment</p>
            </div>
            <div
              className={`${
                checkoutStep === 5
                  ? 'active'
                  : checkoutStep > 5
                  ? 'completed'
                  : ''
              } checkoutProcessStepper__marker`}
            >
              <p className="checkoutProcessStepper__cricle"></p>
              <p className="checkoutProcessStepper__stepText">Complete</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutProcessStepper;
