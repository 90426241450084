import { IonGrid, IonRow, IonCol } from "@ionic/react";
import React, { useContext } from "react";
import "../../MyProfile/MyProfile.css";
import userProfileContext from "../../../../context/UserProfile/userProfileContext";

const BillingInformation: React.FC = () => {
  const { userDetails } = useContext(userProfileContext);
  const { full_name, email, company_name, address, city, zip_code } =
    userDetails;

  return (
    <div className="my-profile">
      <section>
        <div className="my-profile__container">
          <IonGrid>
            <IonRow className="my-profile__header">
              <IonCol size="12" className="section-header">Billing Information</IonCol>
            </IonRow>
          </IonGrid>

          {/* Billing Information */}
          <IonGrid>
            {/* Name Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Name</IonCol>
              <IonCol>{full_name}</IonCol>
              <IonCol></IonCol>
            </IonRow>

            {/* Company Name Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Company Name</IonCol>
              <IonCol>{company_name}</IonCol>
              <IonCol></IonCol>
            </IonRow>

            {/* Email Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Email</IonCol>
              <IonCol>{email}</IonCol>
              <IonCol></IonCol>
            </IonRow>

            {/* Address Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Address</IonCol>
              <IonCol>{address}</IonCol>
              <IonCol></IonCol>
            </IonRow>

            {/* City Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>City</IonCol>
              <IonCol>{city}</IonCol>
              <IonCol></IonCol>
            </IonRow>

            {/* Postal Code Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Postal Code</IonCol>
              <IonCol>{zip_code}</IonCol>
              <IonCol></IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </section>
    </div>
  );
};

export default BillingInformation;
