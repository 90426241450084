import { useReducer } from "react";
import {
  INFORMATION,
  SHIPPING,
  SHIPPING_OPTIONS,
  BLENDS,
  STATUSES,
  LOGS,
  BAG_SIZES,
  BAG_TYPES,
  COUNTRIES,
  PAYMENTS,
  PAYMENT_GATEWAYS,
  BLEND_RECIPES,
  BEANS,
  ROASTS,
  GRINDS,
  BLEND_ID,
  BLEND_DISTRIBUTIONS,
} from "../types";
import orderDetailsContext from "./orderDetailsContext";
import orderDetailsReducer from "./orderDetailsReducer";

const OrderDetailsState = (props) => {
  const initState = {
    orderInfo: {},
    orderShipping: [],
    orderBlends: [],
    orderStatus: [],
    orderLogs: [],
    orderBagSizes: [],
    orderBagTypes: [],
    orderContries: [],
    orderPayments: [],
    orderPaymentGateways: [],
    orderShippingOptions: [],
    orderBlendRecipes: [],
    orderBeans: [],
    orderRoasts: [],
    orderGrinds: [],
    orderBlendDistributions: [],
    orderBlendID: null,
  };

  const [state, dispatch] = useReducer(orderDetailsReducer, initState);

  // Reducer Action Functions
  // -- Add Order Details
  const addOrderDetails = (order) => {
    dispatch({
      type: INFORMATION,
      payload: order,
    });
  };

  // -- Add Shipping Details
  const addShippingDetails = (shipping) => {
    dispatch({
      type: SHIPPING,
      payload: shipping,
    });
  };
  // -- Add Shipping Details
  const addShippingOptions = (shipOptions) => {
    dispatch({
      type: SHIPPING_OPTIONS,
      payload: shipOptions,
    });
  };
  // -- Add Blends Details
  const addBlendDetails = (blends) => {
    dispatch({
      type: BLENDS,
      payload: blends,
    });
  };
  // -- Add Bag Size
  const addBagSize = (size) => {
    dispatch({
      type: BAG_SIZES,
      payload: size,
    });
  };
  // -- Add Bag Types
  const addBagTypes = (types) => {
    dispatch({
      type: BAG_TYPES,
      payload: types,
    });
  };
  // -- Add Logs Details
  const addLogDetails = (logs) => {
    dispatch({
      type: LOGS,
      payload: logs,
    });
  };
  // -- Add Order Statuses
  const addOrderStatuses = (status) => {
    dispatch({
      type: STATUSES,
      payload: status,
    });
  };
  // -- Add Order Countries
  const addOrderCountries = (countries) => {
    dispatch({
      type: COUNTRIES,
      payload: countries,
    });
  };
  // -- Add Order Payments
  const addOrderPayments = (payments) => {
    dispatch({
      type: PAYMENTS,
      payload: payments,
    });
  };
  // -- Add Order Payment Gateways
  const addOrderPaymentGateways = (gateways) => {
    dispatch({
      type: PAYMENT_GATEWAYS,
      payload: gateways,
    });
  };
  // -- Add Blend Recipes
  const addOrderBlendRecipes = (blends) => {
    dispatch({
      type: BLEND_RECIPES,
      payload: blends,
    });
  };
  // -- Add Beans
  const addOrderBeans = (beans) => {
    dispatch({
      type: BEANS,
      payload: beans,
    });
  };
  // -- Add Roasts
  const addOrderRoasts = (roasts) => {
    dispatch({
      type: ROASTS,
      payload: roasts,
    });
  };
  // -- Add Roasts
  const addOrderGrinds = (grinds) => {
    dispatch({
      type: GRINDS,
      payload: grinds,
    });
  };
  // -- Add Blend Id
  const addBlendId = (id) => {
    dispatch({
      type: BLEND_ID,
      payload: id,
    });
  };
  // -- Add Blend Distributions
  const addBlendDistributions = (distributions) => {
    dispatch({
      type: BLEND_DISTRIBUTIONS,
      payload: distributions,
    });
  };
  return (
    <orderDetailsContext.Provider
      value={{
        orderInfo: state.orderInfo,
        orderShipping: state.orderShipping,
        orderShippingOptions: state.orderShippingOptions,
        orderBlends: state.orderBlends,
        orderBagSizes: state.orderBagSizes,
        orderBagTypes: state.orderBagTypes,
        orderStatus: state.orderStatus,
        orderLogs: state.orderLogs,
        orderContries: state.orderContries,
        orderPayments: state.orderPayments,
        orderPaymentGateways: state.orderPaymentGateways,
        orderBlendRecipes: state.orderBlendRecipes,
        orderBeans: state.orderBeans,
        orderRoasts: state.orderRoasts,
        orderGrinds: state.orderGrinds,
        orderBlendID: state.orderBlendID,
        orderBlendDistributions: state.orderBlendDistributions,
        addOrderDetails,
        addShippingDetails,
        addShippingOptions,
        addBlendDetails,
        addBagSize,
        addBagTypes,
        addLogDetails,
        addOrderStatuses,
        addOrderCountries,
        addOrderPayments,
        addOrderPaymentGateways,
        addOrderBlendRecipes,
        addOrderBeans,
        addOrderRoasts,
        addOrderGrinds,
        addBlendId,
        addBlendDistributions,
      }}
    >
      {props.children}
    </orderDetailsContext.Provider>
  );
};

export default OrderDetailsState;
