// Core React
import { useState, useContext } from 'react';

// React Router Dom
import { useHistory } from 'react-router-dom';

// Axios
import axios from 'axios';
import config from '../../config';

// Ionic Components
import { IonAlert, IonButton, IonLoading } from '@ionic/react';

// Global State
import BlendCreatorContext from '../../context/BlendCreator/blendCreatorContext';

// Custom Components
import BlendingBeanCard from './BlendingBeanCard/BlendingBeanCard';

// Styling
import './BlendingBoard.css';
import userCartContext from '../../context/UserCart/userCartContext';

interface Props {
  showModal: (x: boolean) => void;
  setBlendingStep: (x: number) => void;
}

const BlendingBoard: React.FC<Props> = ({ showModal, setBlendingStep }) => {
  const [showAlert1, setShowAlert1] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const {
    blend_recipes,
    blend_bagSizeId,
    blend_bagTypeId,
    blend_grindType,
    blend_roastType,
    blend_bagQuantity,
    blend_name,
    blend_description,
    resetBlendCreator,
  } = useContext(BlendCreatorContext);

  const { addToCart, loading } = useContext(userCartContext);

  const history = useHistory();

  const getTotalBlendPercentage = () => {
    return blend_recipes.reduce(
      (acc: number, bean: any) => acc + bean.percentage,
      0
    );
  };

  const onNextBlendStepHandler = async () => {
    // Check if blend_recipes percantages = 100%
    if (blend_recipes.length > 6) {
      setShowAlert1(true);
      setAlertMessage('A blend cannot contain more than six (6) beans');
      return;
    }

    let blendTotal = blend_recipes.reduce(
      (acc: number, bean: any) => acc + bean.percentage,
      0
    );

    if (blendTotal !== 100) {
      setShowAlert1(true);
      setAlertMessage('Total bean percentage should be 100%');
      return;
    }
    try {
      setValidating(true);

      await axios.post(
        `${config.apiURL}/blend-recipes/validate`,
        {
          blendRecipes: blend_recipes.map((r: any) => ({
            beanId: r.id,
            percentage: r.percentage,
          })),
        },
        { params: { token: localStorage.getItem('blendlyAuthToken') } }
      );

      setBlendingStep(2);
      setValidating(false);
    } catch (error: any) {
      setShowAlert1(true);
      setValidating(false);
      setAlertMessage(error.response.data.message);
    }
  };

  const onGoCartBlendingBoardHandler = async () => {
    if (blend_recipes.length === 0 && !blend_name && !blend_description) {
      history.push('/cart');
      return;
    }

    if (blend_recipes.length === 0 || !blend_name || !blend_description) {
      setShowAlert1(true);
      setAlertMessage('Add a blend to your cart first');
      return;
    }

    let blendRecipes = blend_recipes.map((bean: any) => {
      return {
        beanId: bean.id,
        percentage: bean.percentage,
      };
    });

    let data = {
      bagSizeId: blend_bagSizeId,
      bagTypeId: blend_bagTypeId,
      blendRecipes,
      certificationId: 1,
      description: blend_description,
      name: blend_name,
      grindId: blend_grindType,
      roastId: blend_roastType,
      quantity: blend_bagQuantity,
      lableDesignId: 58,
    };

    await addToCart(data);
    resetBlendCreator();
    history.push('/cart');
  };

  return (
    <div className="blendingBoard">
      <IonLoading isOpen={loading} />
      <p className="blendingBoard__text">Choose your coffee bean(s)</p>
      <p className="blendingBoard__subtext">
        Select coffee bean(s) harvested from around the world or from existing
        coffee blends. Different coffee beans produce various flavours and
        notes. Blend percentage should be 100%.
      </p>

      {/* IF BLEND RECIPES STATE IS EMPTY */}
      {blend_recipes.length === 0 && (
        <p className="blendingBoard__emptyText">
          You haven’t selected any coffee blend/beans yet
        </p>
      )}

      {/* IF BLEND RECIPES STATE IS - NOT - EMPTY */}
      {blend_recipes.length > 0 && (
        <div className="blendingBoard__table-container">
          <table className="blendingBoard__table">
            <thead>
              <tr>
                <th></th>
                <th>CTAPS ID</th>
                <th>Coffee Bean</th>
                <th>Blend Percentage</th>
                <th className="blendingBoard__percentage">
                  {getTotalBlendPercentage()}%
                </th>
                <th className="blendingBoard__delete">Delete</th>
              </tr>
            </thead>

            <tbody>
              {blend_recipes.map((bean: any) => (
                <tr key={bean.id}>
                  <BlendingBeanCard beanData={bean} />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="blendpage__actionBtns">
        <IonButton
          onClick={() => showModal(true)}
          className="blendpage__addCoffeeBtn primary"
        >
          Add Coffee Beans
        </IonButton>

        <div className="blendpage__continueBtns">
          <IonButton
            onClick={onNextBlendStepHandler}
            className="blendpage__nextBtn secondary"
            disabled={validating}
          >
            Next
          </IonButton>
          {/* <IonButton
            onClick={onGoCartBlendingBoardHandler}
            className="blendpage__nextBtn warning"
          >
            Checkout
          </IonButton> */}
        </div>
      </div>

      <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        cssClass="my-custom-class"
        header={'Alert'}
        subHeader={'Invalid Blend'}
        message={alertMessage}
        buttons={['OK']}
      />
    </div>
  );
};

export default BlendingBoard;
