// Core React
import { useState, useReducer } from 'react';

// Context & Reducer
import BlendCreatorContext from './blendCreatorContext';
import blendCreatorReducer from './blendCreatorReducer';

// Action Types Constants
import {
  BLENDCREATOR_DATA_LOADING,
  BLENDCREATOR_DATA_SUCCESS,
  BLENDCREATOR_DATA_FAIL,
  ADD_TO_BLENDRECIPES,
  REMOVE_FROM_BLENDRECIPES,
  ADJUST_BEAN_PERCENTAGE,
  SET_BLEND_ROASTTYPE,
  SET_BLEND_GRINDTYPE,
  SET_BLEND_NAME,
  SET_BLEND_DESCRIPTION,
  BLENDCREATOR_PRICES_LOADING,
  BLENDCREATOR_PRICES_FAIL,
  BLENDCREATOR_PRICES_SUCCESS,
  CLEAR_ALL_BLENDRECIPES,
  SET_BLEND_BAG_SIZE_ID,
  SET_BLEND_BAG_QUANTITY,
  RESET_BLENDCREATOR,
} from '../types';

// CONFIGURED API ROUTES
import config from '../../config';

// 3rd Party
import axios from 'axios';

const BlendCreatorState = ({ children }) => {
  const initialBlendCreatorState = {
    beans: [],
    blend_recipes: [],
    blend_roastType: 1,
    blend_grindType: 1,
    blend_bagSizeId: 7,
    blend_bagTypeId: 1,
    blend_bagQuantity: 1,
    blend_name: '',
    blend_description: '',
    dynamically_priced_blends: null,
    loading: false,
    error: null,
  };

  const [state, dispatch] = useReducer(
    blendCreatorReducer,
    initialBlendCreatorState
  );

  const [blendingStep, setBlendingStep] = useState(1);
  const [searchBeans, setSearchBeans] = useState(state.beans);
  const [beansPerPage, setBeansPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddToCartToast, setShowAddToCartToast] = useState(1);

  const addToBlendRecipes = (beanData) => {
    dispatch({
      type: ADD_TO_BLENDRECIPES,
      payload: beanData,
    });
  };

  const removeFromBlendRecipes = (beanId) => {
    dispatch({
      type: REMOVE_FROM_BLENDRECIPES,
      payload: beanId,
    });
  };

  const resetBlendRecipes = () => {
    dispatch({
      type: CLEAR_ALL_BLENDRECIPES,
    });
  };

  const loadBlendCreatorData = async () => {
    try {
      dispatch({
        type: BLENDCREATOR_DATA_LOADING,
      });

      const { data: beans } = await axios.get(`${config.apiURL}/beans`);

      dispatch({
        type: BLENDCREATOR_DATA_SUCCESS,
        payload: beans,
      });
    } catch (error) {
      dispatch({
        type: BLENDCREATOR_DATA_FAIL,
        payload: error.response,
      });
    }
  };

  const adjustBeanPercentage = (beanId, percentage) => {
    // Check if total is in range [0 - 100]
    const currentMixPercentage = state.blend_recipes.reduce((acc, bean) => {
      if (bean.id !== beanId) {
        return acc + bean.percentage;
      }

      return acc;
    }, 0);

    let totalMixPercentage =
      parseInt(currentMixPercentage) + parseInt(percentage);

    if (totalMixPercentage > 100) {
      return;
    }

    let adjustedBlendRecipes = state.blend_recipes.map((bean) => {
      if (bean.id === beanId) {
        return {
          ...bean,
          percentage: percentage,
        };
      }

      return bean;
    });

    dispatch({
      type: ADJUST_BEAN_PERCENTAGE,
      payload: adjustedBlendRecipes,
    });
  };

  const setBlendRoastType = (value) => {
    dispatch({ type: SET_BLEND_ROASTTYPE, payload: value });
  };

  const setBlendGrindType = (value) => {
    dispatch({ type: SET_BLEND_GRINDTYPE, payload: value });
  };

  const setBlendName = (name) => {
    dispatch({ type: SET_BLEND_NAME, payload: name });
  };

  const setBlendDescription = (desc) => {
    dispatch({ type: SET_BLEND_DESCRIPTION, payload: desc });
  };

  const fetchDynamicallyPricedBlend = async () => {
    dispatch({ type: BLENDCREATOR_PRICES_LOADING });

    let blendRecipes = state.blend_recipes.map((bean) => {
      return {
        beanId: bean.id,
        percentage: bean.percentage,
      };
    });

    let obj = {
      bagSizeId: state.blend_bagSizeId,
      bagTypeId: state.blend_bagTypeId,
      blendId: null,
      blendRecipes,
      certificationId: 1,
      customerId: null,
      description: state.blend_description || 'default',
      grindId: state.blend_grindType,
      labelDesignId: 58,
      name: state.blend_name || 'default',
      quantity: state.blend_bagQuantity,
      roastId: state.blend_roastType,
    };

    try {
      const res = await axios.post(
        `${config.apiURL}/dynamically-priced-blends`,
        obj,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            token: localStorage.getItem('blendlyAuthToken'),
          },
        }
      );

      const { data } = res;
      let pricingData = data;

      dispatch({
        type: BLENDCREATOR_PRICES_SUCCESS,
        payload: pricingData,
      });
    } catch (error) {
      dispatch({
        type: BLENDCREATOR_PRICES_FAIL,
        payload: error.response.data,
      });
    }
  };

  const setBlendBagSizeId = (id) => {
    dispatch({
      type: SET_BLEND_BAG_SIZE_ID,
      payload: id,
    });
  };

  const setBlendBagQuantity = (qty) => {
    dispatch({
      type: SET_BLEND_BAG_QUANTITY,
      payload: qty,
    });
  };

  const resetBlendCreator = () => {
    setBlendingStep(1);
    dispatch({
      type: RESET_BLENDCREATOR,
    });
  };

  return (
    <BlendCreatorContext.Provider
      value={{
        loading: state.loading,
        beans: state.beans,
        blend_recipes: state.blend_recipes,
        blend_roastType: state.blend_roastType,
        blend_grindType: state.blend_grindType,
        blend_name: state.blend_name,
        blend_description: state.blend_description,
        dynamically_priced_blends: state.dynamically_priced_blends,
        blend_bagQuantity: state.blend_bagQuantity,
        blend_bagTypeId: state.blend_bagTypeId,
        blend_bagSizeId: state.blend_bagSizeId,
        blendingStep,
        showAddToCartToast,
        currentPage,
        searchBeans,
        beansPerPage,
        searchValue,
        setBeansPerPage,
        setSearchValue,
        setSearchBeans,
        loadBlendCreatorData,
        addToBlendRecipes,
        removeFromBlendRecipes,
        adjustBeanPercentage,
        setBlendRoastType,
        setBlendGrindType,
        setBlendName,
        setBlendDescription,
        fetchDynamicallyPricedBlend,
        resetBlendRecipes,
        setBlendBagSizeId,
        setBlendBagQuantity,
        setBlendingStep,
        resetBlendCreator,
        setShowAddToCartToast,
        setCurrentPage,
      }}
    >
      {children}
    </BlendCreatorContext.Provider>
  );
};

export default BlendCreatorState;
