import React, { useState, useEffect } from 'react';
import { IonLoading, IonPage, IonContent } from "@ionic/react";
import { useHistory } from "react-router";
import axios from "axios";

//HEADER & FOOTER
import { Footer } from '../../components/Layout/Footer';
import { Header } from '../../components/Layout/Header';

//CSS
import './BlendPriceCheck.css';

//HELPERS
import currencyFormatter from '../../helpers/currencyFormatter';
import config from '../../config';

//DROPDOWN LIST
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

//DATA GRID
import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
  } from '@mui/x-data-grid';
import { textAlign } from '@mui/material/node_modules/@mui/system';

const BlendPriceCheck: React.FC = () => {
    const history = useHistory();

    const blendlyAuthToken = localStorage.getItem('blendlyAuthToken');
    
    //STATE
    const [isLoading, setIsLoading] = useState(false);
    const [beans, setBeans] = useState<any>([]);
    const [packSize, setPackSize] = React.useState('0');
    const [userType, setUserType] = React.useState('0')

    const handlePackSizeChange = (event: SelectChangeEvent) => {
        setPackSize(event.target.value);
    };

    const handleUserTypeChange = (event: SelectChangeEvent) => {
        setUserType(event.target.value)
    };

    //AUTHTOKEN CHECK
    if (!blendlyAuthToken) {
        history.push('/login');
        setIsLoading(false);    
    };
    

    // FETCH DATA
    const getBeansData = async () => {
        setIsLoading(true);
        try {
            const beansData = await axios(`${config.apiURL}/blends/price-check`)
               
                setBeans(beansData.data);
                setIsLoading(false);
        } catch (error) {
            console.error(error);
        };
    };
    useEffect(() => {
        getBeansData();
    }, []);

    //DATAGRID
    function CustomToolbar() {
        return (
          <GridToolbarContainer className="custom-toolbar">
            <GridToolbarColumnsButton style={{ color: "#1f4e46", fontFamily: "Hepta Slab"}}/>
            <GridToolbarFilterButton style={{ color: "#1f4e46", fontFamily: "Hepta Slab"}}/>
            <GridToolbarDensitySelector style={{ color: "#1f4e46", fontFamily: "Hepta Slab"}}/>
            <GridToolbarExport style={{ color: "#1f4e46", fontFamily: "Hepta Slab"}}/>
          </GridToolbarContainer>
        );
      }

    //GRID
    const columns: GridColDef[] = [
        { 
            field: 'blend_id', 
            headerName: 'ID',
            description: 'Blend Id',
            sortable: false,
            headerClassName: 'grid-header',
            minWidth: 90,
            align: 'left',
            valueFormatter: (params: any) => `${params.row.price_check_data.blend_id}`,
        },
        {
            field: 'name', 
            headerName: 'Blend',
            description: 'Name of the blend',
            headerClassName: 'grid-header',
            minWidth: 250,
            sortable: true,
        },
        { 
            field: 'brand', 
            headerName: 'Brand',
            description: 'Name of the Brand',
            headerClassName: 'grid-header',
            minWidth: 200,
            sortable: true,
        },
        { 
            field: 'description', 
            headerName: 'Description',
            description: 'Description of the blend',
            headerClassName: 'grid-header',
            flex: 1,
            minWidth: 450,
            resizable: true,
            sortable: false,
        },
        { 
            field: 'ctaps_id', 
            headerName: 'cTaps',
            description: 'CTAPS ID LINK',
            headerClassName: 'grid-header',
            minWidth: 130,
            align: 'left',
            resizable: true,
            sortable: true,
            renderCell: (cell) => (
                <strong style={{ fontSize: '14px', color: '#' }}>
                  <a href={`/cuppingnotes/${cell.value}`}>
                    {cell.value}
                  </a>
                </strong>
              ),
        },
        { 
            field: 'total_price', 
            headerName: 'Price',
            headerClassName: 'grid-header',
            minWidth: 130,
            align: 'left',
            resizable: true,
            sortable: true,
            valueFormatter: (params: any) => `${currencyFormatter(params.row.price_check_data.data[userType].dynamically_priced_blends[packSize].total_price)}`,          
        },
        {
            field: 'ctaps_details_url',
            headerName: 'cTaps URL',
            headerClassName: 'grid-header',
            minWidth: 350,
            sortable: false,
            valueFormatter: (params: any) => `${params.row.ctaps_details_url}`,
            renderCell: (params) => {
                return (
                    <div>
                        <a href={`${params.row.ctaps_details_url}`}>{params.row.ctaps_details_url}</a>
                    </div>
                );
            },
        },

    ];

    console.log(packSize)
    console.log(beans)
    

    return (
        
        <IonPage>
          <IonContent>
            <Header />
            <div className="container">
              <div className="outer-container">
                  <a href={`${history.goBack}`}>Go Back</a>
              </div>
              <div className="container-dropdown">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="packsize_label" style={{ color: "#1f4e46", fontFamily: "Hepta Slab", fontWeight: "bold"}}>Pack Size</InputLabel>
                    <Select
                        labelId="packsize_label"
                        id="packsize"
                        value={packSize}
                        onChange={handlePackSizeChange}
                        label="Pack Size"
                        style={{ color: "#1f4e46", fontFamily: "Hepta Slab"}}
                    >
                      <MenuItem style={{ color: "#1f4e46", fontFamily: "Hepta Slab", paddingLeft: 5 }} value={0}>250g</MenuItem>
                      <br/>
                      <MenuItem style={{ color: "#1f4e46", fontFamily: "Hepta Slab", paddingLeft: 5 }} value={1}>500g</MenuItem>
                      <br/>
                      <MenuItem style={{ color: "#1f4e46", fontFamily: "Hepta Slab", paddingLeft: 5 }} value={2}>1kg</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="user_type_label" style={{ color: "#1f4e46", fontFamily: "Hepta Slab", fontWeight: "bold"}}>User Type</InputLabel>
                    <Select
                        labelId="user_type_label"
                        id="user_type"
                        value={userType}
                        onChange={handleUserTypeChange}
                        label="User Type"
                        style={{ color: "#1f4e46", fontFamily: "Hepta Slab"}}
                    >
                      <MenuItem style={{ color: "#1f4e46", fontFamily: "Hepta Slab", paddingLeft: 5 }} value={0}>Home</MenuItem>
                      <br/>
                      <MenuItem style={{ color: "#1f4e46", fontFamily: "Hepta Slab", paddingLeft: 5 }} value={1}>Shop</MenuItem>
                      <br/>
                      <MenuItem style={{ color: "#1f4e46", fontFamily: "Hepta Slab", paddingLeft: 5 }} value={2}>Redistributor</MenuItem>
                      <br/>
                      <MenuItem style={{ color: "#1f4e46", fontFamily: "Hepta Slab", paddingLeft: 5 }} value={3}>Corporate</MenuItem>
                    </Select>
                </FormControl>
              </div>

              {!beans.length 
                ? 
                    <IonLoading 
                        isOpen={isLoading}
                        message={'Fetching Beans Data...'}
                        spinner="bubbles"
                        
                        
                    />    
                : 
                <div className="data-grid-container">
                    <DataGrid
                        className="data-grid"
                        style={{ color: '#1f4e46', borderRadius: '15px', borderColor: '#1f4e4645'}}
                        rows={beans}
                        columns={columns}
                        autoHeight
                        checkboxSelection
                        disableSelectionOnClick
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                </div>
              }       
            </div>
            <Footer />
          </IonContent>
        </IonPage>
    );
    
};

export default BlendPriceCheck;
