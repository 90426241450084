// CUPPING NOTES DATA ACTIONS
export const SET_CURRENT_CUPPINGNOTE = "SET_CURRENT_CUPPINGNOTE";
export const CLEAR_CURRENT_CUPPINGNOTE = "CLEAR_CURRENT_CUPPINGNOTE";
export const SET_SELECTED_NOTE = "SET_SELECTED_NOTE";
export const CUPPINGNOTE_DATA_LOADING = "CUPPINGNOTE_DATA_LOADING";
export const CUPPINGNOTE_DATA_SUCCESS = "CUPPINGNOTE_DATA_SUCCESS";
export const CUPPINGNOTE_DATA_FAIL = "CUPPINGNOTE_DATA_FAIL";
export const CUPPINGNOTE_PRICE_DATA_LOADING = "CUPPINGNOTE_PRICE_DATA_LOADING";
export const CUPPINGNOTE_PRICE_DATA_SUCCESS = "CUPPINGNOTE_PRICE_DATA_SUCCESS";
export const CUPPINGNOTE_PRICE_DATA_FAIL = "CUPPINGNOTE_PRICE_DATA_FAIL";
export const TOGGLE_VIEW_SELECTED = "TOGGLE_VIEW_SELECTED";

// USER PROFILE ACTIONS
export const USER_DETAILS = "USER_DETAILS";
export const USER_TYPE = "USER_TYPE";
export const SHIPPING_DETAILS = "SHIPPING_DETAILS";
export const ORDERS = "ORDERS";
export const ORDER_STATUSES = "ORDER_STATUSES";

export const USER_PROFILEDATA_SUCCESS = "USER_PROFILEDATA_SUCCESS";
export const USER_PROFILEDATA_LOADING = "USER_PROFILEDATA_LOADING";
export const USER_PROFILEDATA_FAIL = "USER_PROFILEDATA_FAIL";

export const ADD_GUESTUSER_DATA = "ADD_GUESTUSER_DATA";

// BLEND CREATOR DATA ACTIONS
export const BLENDCREATOR_DATA_LOADING = "BLENDCREATOR_DATA_LOADING";
export const BLENDCREATOR_DATA_SUCCESS = "BLENDCREATOR_DATA_SUCCESS";
export const CLEAR_ALL_BLENDRECIPES = "CLEAR_ALL_BLENDRECIPES";
export const BLENDCREATOR_DATA_FAIL = "BLENDCREATOR_DATA_FAIL";
export const ADD_TO_BLENDRECIPES = "ADD_TO_BLENDRECIPES";
export const REMOVE_FROM_BLENDRECIPES = "REMOVE_FROM_BLENDRECIPES";
export const ADJUST_BEAN_PERCENTAGE = "ADJUST_BEAN_PERCENTAGE";
export const SET_BLEND_GRINDTYPE = "SET_BLEND_GRINDTYPE";
export const SET_BLEND_ROASTTYPE = "SET_BLEND_ROASTTYPE";
export const SET_BLEND_NAME = "SET_BLEND_NAME";
export const SET_BLEND_DESCRIPTION = "SET_BLEND_DESCRIPTION";
export const BLENDCREATOR_PRICES_LOADING = "BLENDCREATOR_PRICES_LOADING";
export const BLENDCREATOR_PRICES_SUCCESS = "BLENDCREATOR_PRICES_SUCCESS";
export const BLENDCREATOR_PRICES_FAIL = "BLENDCREATOR_PRICES_FAIL";
export const SET_BLEND_BAG_SIZE_ID = "SET_BLEND_BAG_SIZE_ID";
export const SET_BLEND_BAG_QUANTITY = "SET_BLEND_BAG_QUANTITY";
export const RESET_BLENDCREATOR = "RESET_BLENDCREATOR";

// CART DATA ACTIONS
export const ADD_TO_USERCART_SUCCESS = "ADD_TO_USERCART_SUCCESS";
export const ADD_TO_USERCART_LOADING = "ADD_TO_USERCART_LOADING";
export const ADD_TO_USERCART_FAIL = "ADD_TO_USERCART_FAIL";

export const USER_CARTDATA_LOADING = "USER_CARTDATA_LOADING";
export const USER_CARTDATA_SUCCESS = "USER_CARTDATA_SUCCESS";
export const USER_CARTDATA_FAIL = "USER_CARTDATA_FAIL";

export const REMOVE_FROM_USERCART_LOADING = "REMOVE_FROM_USERCART_LOADING";
export const REMOVE_FROM_USERCART_SUCCESS = "REMOVE_FROM_USERCART_SUCCESS";
export const REMOVE_FROM_USERCART_FAIL = "REMOVE_FROM_USERCART_FAIL";

// ORDER DETAILS ACTION
export const INFORMATION = "INFORMATION";
export const SHIPPING = "SHIPPING";
export const SHIPPING_OPTIONS = "SHIPPING_OPTIONS";
export const BLENDS = "BLENDS";
export const STATUSES = "STATUSES";
export const LOGS = "LOGS";
export const BAG_SIZES = "BAG_SIZES";
export const BAG_TYPES = "BAG_TYPES";
export const COUNTRIES = "COUNTRIES";
export const PAYMENTS = "PAYMENTS";
export const PAYMENT_GATEWAYS = "PAYMENT_GATEWAYS";
export const BLEND_RECIPES = "BLEND_RECIPES";
export const BEANS = "BEANS";
export const ROASTS = "ROASTS";
export const GRINDS = "GRINDS";
export const BLEND_ID = "BLEND_ID";
export const BLEND_DISTRIBUTIONS = "BLEND_DISTRIBUTIONS";

// Mobile Menu State
export const OPEN_MENU = "OPEN_MENU";
