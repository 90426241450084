import { IonContent, IonPage } from '@ionic/react';
import { Header } from '../../components/Layout/Header';
import { Footer } from '../../components/Layout/Footer';
import { Accordian } from '../../components/Accordian/accordian';
import '../TermsPolicyStyles.css';
import { Helmet } from 'react-helmet';

const PrivacyPolicy: React.FC = () => {
  return (
    <IonPage>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
      </Helmet>
      <IonContent>
        <Header />
        <div className="terms-policy">
          <h1 className="terms-policy__header">Newbeans Privacy policy</h1>
          <p className="terms-policy__lead-text">
            This privacy policy applies between you, the User of this Website
            and Newbeans Ltd, the owner and provider of this Website. Newbeans
            Ltd takes the privacy of your information very seriously. This
            privacy policy applies to our use of any and all Data collected by
            us or provided by you in relation to your use of the Website.
            <b> Please read this privacy policy carefully. </b>
          </p>
          <p className="terms-policy__section-header">
            <strong>Definitions and interpretation</strong>
          </p>
          <p className="terms-policy__section-body">
            1. In this privacy policy, the following definitions are used:
          </p>

          {/* Grid Items */}
          <div className="terms-policy__grid-items">
            {/* <div className="terms-policy__grid-items__item"></div> */}
            <div className="terms-policy__grid-items__item">
              <p className="terms-policy__section-header">
                <strong>Data</strong>
              </p>
              <p className="terms-policy__section-body">
                collectively all information that you submit to Newbeans Ltd via
                the Website. This definition incorporates, where applicable, the
                definitions provided in the Data Protection Act 1998.
              </p>
            </div>
            <div className="terms-policy__grid-items__item">
              <p className="terms-policy__section-header">
                <strong>Cookies</strong>
              </p>
              <p className="terms-policy__section-body">
                a small text file placed on your computer by this Website when
                you visit certain parts of the Website and/or when you use
                certain features of the Website. Details of the cookies used by
                this Website are set out in the clause below ( <b>Cookies</b> ).
              </p>
            </div>
            <div className="terms-policy__grid-items__item">
              <p className="terms-policy__section-header">
                <strong>Newbeans Ltd, or us</strong>
              </p>
              <p className="terms-policy__section-body">
                Newbeans Ltd, a company incorporated in Scotland with registered
                number SC270583 whose registered office is at C/O Accountants
                Plus Upper Floor, Unit 1 Cadzow Park, 82 Muir Street, Hamilton,
                South Lanarkshire, ML3 6BJ.
              </p>
            </div>
            <div className="terms-policy__grid-items__item">
              <p className="terms-policy__section-header">
                <strong>User or you</strong>
              </p>
              <p className="terms-policy__section-body">
                any third party that accesses the Website and is not either (i)
                employed by Newbeans Ltd and acting in the course of their
                employment or (ii) engaged as a consultant or otherwise
                providing services to Newbeans Ltd and accessing the Website in
                connection with the provision of such services.
              </p>
            </div>
            <div className="terms-policy__grid-items__item">
              <p className="terms-policy__section-header">
                <strong>Website</strong>
              </p>
              <p className="terms-policy__section-body">
                the website that you are currently using, blendly.co.uk, and any
                sub-domains of this site unless expressly excluded by their own
                terms and conditions.
              </p>
            </div>
          </div>

          <div className="terms-policy__horizontal-line"></div>

          <p className="terms-policy__section-body">
            2. In this privacy policy, unless the context requires a different
            interpretation:
          </p>
          <ol className="terms-policy__section-list" type="a">
            <li>the singular includes the plural and vice versa;</li>
            <li>
              references to sub-clauses, clauses, schedules or appendices are to
              sub-clauses, clauses, schedules or appendices of this privacy
              policy;
            </li>
            <li>
              a reference to a person includes firms, companies, government
              entities, trusts and partnerships;
            </li>
            <li>
              "including" is understood to mean "including without limitation";
            </li>
            <li>
              reference to any statutory provision includes any modification or
              amendment of it;
            </li>
            <li>
              the headings and sub-headings do not form part of this privacy
              policy.
            </li>
          </ol>
          <br />
          <br />

          {/* Accordian Section */}

          <Accordian title="Scope of this privacy policy">
            <p className="terms-policy__section-body">
              3. This privacy policy applies only to the actions of Newbeans Ltd
              and Users with respect to this Website. It does not extend to any
              websites that can be accessed from this Website including, but not
              limited to, any links we may provide to social media websites.{' '}
            </p>
          </Accordian>
          <Accordian title="Data collected">
            <p className="terms-policy__section-body">
              4. We may collect the following Data, which includes personal
              Data, from you:
            </p>
            <ol className="terms-policy__section-list" type="a">
              <li>Name</li>
              <li>
                {' '}
                Contact Information such as email addresses and telephone
                numbers;
              </li>
              <li>Company Name</li>
              <li>Email</li>
              <li> in each case, in accordance with this privacy policy.</li>
            </ol>
          </Accordian>
          <Accordian title="Our use of Data">
            <p className="terms-policy__section-body">
              {' '}
              5. For purposes of the Data Protection Act 1998, Newbeans Ltd is
              the "data controller".{' '}
            </p>
            <p className="terms-policy__section-body">
              {' '}
              6. We will retain any Data you submit for 12 months.{' '}
            </p>
            <p className="terms-policy__section-body">
              {' '}
              7. Unless we are obliged or permitted by law to do so, and subject
              to any third party disclosures specifically set out in this
              policy, your Data will not be disclosed to third parties. This
              includes our affiliates and / or other companies within our group.{' '}
            </p>
            <p className="terms-policy__section-body">
              {' '}
              8. All personal Data is stored securely in accordance with the
              principles of the Data Protection Act 1998. For more details on
              security see the clause below (<b>Security</b> ).
            </p>
            <p className="terms-policy__section-body">
              {' '}
              9. Any or all of the above Data may be required by us from time to
              time in order to provide you with the best possible service and
              experience when using our Website. Specifically, Data may be used
              by us for the following reasons:{' '}
            </p>

            <ol className="terms-policy__section-list" type="a">
              <li>internal record keeping;</li>
              <li>improvement of our products / services;</li>
              <li>
                {' '}
                transmission by email of promotional materials that may be of
                interest to you;
              </li>
            </ol>

            <p className="terms-policy__section-body">
              in each case, in accordance with this privacy policy.
            </p>
          </Accordian>
          <Accordian title="Third party websites and services">
            <p className="terms-policy__section-body">
              10. Newbeans Ltd may, from time to time, employ the services of
              other parties for dealing with certain processes necessary for the
              operation of the Website. The providers of such services do not
              have access to certain personal Data provided by Users of this
              Website.
            </p>
          </Accordian>
          <Accordian title="Links to other websites">
            <p className="terms-policy__section-body">
              {' '}
              11. This Website may, from time to time, provide links to other
              websites. We have no control over such websites and are not
              responsible for the content of these websites. This privacy policy
              does not extend to your use of such websites. You are advised to
              read the privacy policy or statement of other websites prior to
              using them.
            </p>
          </Accordian>
          <Accordian title="Changes of business ownership and control">
            <p className="terms-policy__section-body">
              12. Newbeans Ltd may, from time to time, expand or reduce our
              business and this may involve the sale and/or the transfer of
              control of all or part of Newbeans Ltd. Data provided by Users
              will, where it is relevant to any part of our business so
              transferred, be transferred along with that part and the new owner
              or newly controlling party will, under the terms of this privacy
              policy, be permitted to use the Data for the purposes for which it
              was originally supplied to us.
            </p>
            <p className="terms-policy__section-body">
              13. We may also disclose Data to a prospective purchaser of our
              business or any part of it.
            </p>
            <p className="terms-policy__section-body">
              14. In the above instances, we will take steps with the aim of
              ensuring your privacy is protected
            </p>
          </Accordian>
          <Accordian title="Controlling use of your Data">
            <p className="terms-policy__section-body">
              15. Wherever you are required to submit Data, you will be given
              options to restrict our use of that Data. This may include the
              following:
            </p>
            <p className="terms-policy__section-body">
              16. use of Data for direct marketing purposes; and
            </p>
            <p className="terms-policy__section-body">
              17. sharing Data with third parties.
            </p>
          </Accordian>
          <Accordian title="Functionality of the Website">
            <p className="terms-policy__section-body">
              {' '}
              18. To use all features and functions available on the Website,
              you may be required to submit certain Data.
            </p>
            <p className="terms-policy__section-body">
              {' '}
              19. You may restrict your internet browser's use of Cookies. For
              more information see the clause below (Cookies).
            </p>
          </Accordian>
          <Accordian title="Accessing your own Data">
            <p className="terms-policy__section-body">
              {' '}
              20. You have the right to ask for a copy of any of your personal
              Data held by Newbeans Ltd (where such Data is held) on payment of
              a small fee, which will not exceed £0.
            </p>
          </Accordian>
          <Accordian title="Security">
            <p className="terms-policy__section-body">
              21. Data security is of great importance to Newbeans Ltd and to
              protect your Data we have put in place suitable physical,
              electronic and managerial procedures to safeguard and secure Data
              collected via this Website.
            </p>
            <p className="terms-policy__section-body">
              22. If password access is required for certain parts of the
              Website, you are responsible for keeping this password
              confidential.
            </p>
            <p className="terms-policy__section-body">
              23. We endeavour to do our best to protect your personal Data.
              However, transmission of information over the internet is not
              entirely secure and is done at your own risk. We cannot ensure the
              security of your Data transmitted to the Website.
            </p>
          </Accordian>
          <Accordian title="Cookies">
            <p className="terms-policy__section-body">
              {' '}
              24. This Website may place and access certain Cookies on your
              computer. Newbeans Ltd uses Cookies to improve your experience of
              using the Website and to improve our range of products. Newbeans
              Ltd has carefully chosen these Cookies and has taken steps to
              ensure that your privacy is protected and respected at all times.
            </p>
            <p className="terms-policy__section-body">
              {' '}
              25. All Cookies used by this Website are used in accordance with
              current UK and EU Cookie Law.
            </p>
            <p className="terms-policy__section-body">
              {' '}
              26. This Website uses cookies to:
            </p>
            <ol className="terms-policy__section-list" type="a">
              <li>Help remember and process the items in the shopping cart.</li>
              <li>Understand and save user’s preferences for future visits.</li>
              <li>
                {' '}
                Compile and aggregate data about site traffic and site
                interactions in order to offer better site experiences and tools
                in the future.
              </li>
            </ol>
            <p className="terms-policy__section-body">
              {' '}
              27. This Website may place the following Cookies:
            </p>
            <ol className="terms-policy__section-list" type="a">
              <li>
                Strictly necessary cookies – These are cookies that are required
                for the operation of our website. They include, for example,
                cookies that enable you to log into secure areas of our website,
                use a shopping cart or make use of e-billing services.
              </li>
              <li>
                Functionality cookies - These are used to recognise you when you
                return to our website. This enables us to personalise our
                content for you, greet you by name and remember your preferences
                (for example, your choice of language or region)
              </li>
            </ol>
            <p className="terms-policy__section-body">
              {' '}
              28. You can choose to enable or disable Cookies in your internet
              browser. By default, most internet browsers accept Cookies but
              this can be changed. For further details, please consult the help
              menu in your internet browser.
            </p>
            <p className="terms-policy__section-body">
              {' '}
              29. If you turn Cookies off, some of the features that make your
              site experience more efficient may not function properly.
            </p>
            <p className="terms-policy__section-body">
              {' '}
              30. You can choose to delete Cookies at any time; however you may
              lose any information that enables you to access the Website more
              quickly and efficiently including, but not limited to,
              personalisation settings.
            </p>
            <p className="terms-policy__section-body">
              31. It is recommended that you ensure that your internet browser
              is up-to-date and that you consult the help and guidance provided
              by the developer of your internet browser if you are unsure about
              adjusting your privacy settings.
            </p>
          </Accordian>
          <Accordian title="General">
            <p className="terms-policy__section-body">
              {' '}
              32. You may not transfer any of your rights under this privacy
              policy to any other person. We may transfer our rights under this
              privacy policy where we reasonably believe your rights will not be
              affected.
            </p>
            <p className="terms-policy__section-body">
              {' '}
              33. If any court or competent authority finds that any provision
              of this privacy policy (or part of any provision) is invalid,
              illegal or unenforceable, that provision or part-provision will,
              to the extent required, be deemed to be deleted, and the validity
              and enforceability of the other provisions of this privacy policy
              will not be affected.
            </p>
            <p className="terms-policy__section-body">
              {' '}
              34. Unless otherwise agreed, no delay, act or omission by a party
              in exercising any right or remedy will be deemed a waiver of that,
              or any other, right or remedy.
            </p>
            <p className="terms-policy__section-body">
              {' '}
              35. This Agreement will be governed by and interpreted according
              to the law of England and Wales. All disputes arising under the
              Agreement will be subject to the exclusive jurisdiction of the
              English and Welsh courts.
            </p>
          </Accordian>
          <Accordian title="Changes to this privacy policy">
            <p className="terms-policy__section-body">
              {' '}
              36. Newbeans Ltd reserves the right to change this privacy policy
              as we may deem necessary from time to time or as may be required
              by law. Any changes will be immediately posted on the Website and
              you are deemed to have accepted the terms of the privacy policy on
              your first use of the Website following the alterations.
            </p>
            <p className="terms-policy__section-body">
              You may contact Newbeans Ltd by email at{' '}
              <a href="admin@blendly.co.uk">admin@blendly.co.uk.</a>
            </p>
          </Accordian>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicy;
