// Core React
import { useState, useEffect, useContext } from 'react';

// Global State
import BlendCreatorContext from '../../context/BlendCreator/blendCreatorContext';

// Ionic Components
import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonItem,
  IonList,
  IonSearchbar,
} from '@ionic/react';

// Ionic Icons
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';

// React Paginate
import ReactPaginate from 'react-paginate';

// Styling & Assets
import './SelectFromBeans.css';
import BeanIcon from '../../assets/icons/bean_icon_light.svg';

interface Props {
  modalToggle: (x: boolean) => void;
}

const SelectFromBeans: React.FC<Props> = ({ modalToggle }) => {
  const {
    beans,
    addToBlendRecipes,
    blend_recipes,
    removeFromBlendRecipes,
    currentPage,
    setCurrentPage,
    searchBeans,
    setSearchBeans,
    beansPerPage,
    searchValue,
    setSearchValue,
  } = useContext(BlendCreatorContext);

  const indexOfLastBean = currentPage * beansPerPage;
  const indexOfFirstBean = indexOfLastBean - beansPerPage;
  const currentBeans = Array.from(searchBeans).slice(
    indexOfFirstBean,
    indexOfLastBean
  );
  const pageCount = Math.ceil(searchBeans.length / beansPerPage);

  const searchNotes = (str: string) => {
    setCurrentPage(1);
    const map1 = new Map();

    let filter1 = beans.filter((note: any) => {
      if (note.ctaps_id.includes(str)) return note;
      return null;
    });

    let filter2 = beans.filter((note: any) => {
      if (note.name.toLowerCase().includes(str.toLowerCase())) return note;
      return null;
    });

    const allFilters = [...filter1, ...filter2];

    allFilters.forEach((filtered: any) => {
      map1.set(filtered.ctaps_id, filtered);
    });

    let searchedValue: any[] = [];
    map1.forEach((el) => searchedValue.push(el));

    return searchedValue;
  };

  useEffect(() => {
    if (searchValue) {
      setSearchBeans(searchNotes(searchValue));
    } else {
      setSearchBeans(beans);
    }

    //eslint-disable-next-line
  }, [searchValue, beans]);

  const handlePageClick = (data: any) => {
    const newCurrentPage = data.selected + 1;
    setCurrentPage(newCurrentPage);
  };

  const handleBeanChange = (e: any, bean: any) => {
    let tempBean = {
      ...bean,
      percentage: 0,
    };

    if (e.detail.checked) {
      addToBlendRecipes(tempBean);
    } else {
      removeFromBlendRecipes(bean.id);
    }
  };

  const checkIfAlreadyPicked = (beanId: number) => {
    const isFound = blend_recipes.find((bean: any) => bean.id === beanId);
    if (isFound) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="selectFromBeans">
      <div className="selectFromBeans__searchContainer">
        <div className="selectFromBeans__left">
          <img src={BeanIcon} alt="bean icon" />
          <span>Add Coffee Beans</span>
        </div>
        <IonSearchbar
          className="selectFromBeans__searchbar"
          value={searchValue}
          onIonChange={(e) => setSearchValue(e.detail.value!)}
        ></IonSearchbar>
      </div>

      <IonList className="selectFromBeans__list">
        {currentBeans.map((bean: any) => (
          <IonItem key={bean.id}>
            <IonCheckbox
              onIonChange={(e) => handleBeanChange(e, bean)}
              checked={checkIfAlreadyPicked(bean.id)}
              slot="start"
              style={{
                '--border-radius': '0%',
                '--inner-border-radius': '0%',
              }}
            />
            <span>{bean.name}</span>
          </IonItem>
        ))}
      </IonList>

      <div className="selectFromBeans__bottom">
        <ReactPaginate
          forcePage={currentPage - 1}
          nextLabel={<IonIcon icon={chevronForwardOutline} />}
          previousLabel={<IonIcon icon={chevronBackOutline} />}
          pageCount={pageCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          activeClassName={'active'}
          containerClassName={'searchlistSection__pagination'}
          onPageChange={handlePageClick}
        />

        <div className="selectFromBeans__actionBtns">
          <IonButton
            onClick={() => modalToggle(false)}
            className="primary selectFromBeans__confirmBtn"
          >
            Confirm Selection
          </IonButton>
        </div>
      </div>
    </div>
  );
};

export default SelectFromBeans;
