// Core React
import { useContext, useState, useEffect } from 'react';

// Ionic Components
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonToast,
} from '@ionic/react';

// Global State
import CuppingNotesContext from '../../../context/CuppingNotes/cuppingNotesContext';
import BlendCreatorContext from '../../../context/BlendCreator/blendCreatorContext';

// Rechart Charting Lib
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Tooltip,
} from 'recharts';

// Custom Components
import DynamicallyPricedBlends from './DynamicallyPricedBlends/DynamicallyPricedBlends';

// Styling
import './GraphingSection.css';
import { useHistory } from 'react-router';
import userCartContext from '../../../context/UserCart/userCartContext';

const GraphingSection: React.FC = () => {
  const history = useHistory();

  const [grind, setGrind] = useState(1);
  const [bagQty, setBagQty] = useState(1);
  const [bagSize, setBagSize] = useState(7);
  const [showAddToCartToast, setShowAddToCartToast] = useState(false);

  const { selectedNote } = useContext(CuppingNotesContext);
  const {
    setBlendName,
    setBlendDescription,
    addToBlendRecipes,
    resetBlendRecipes,
    setBlendGrindType,
    setBlendRoastType,
  } = useContext(BlendCreatorContext);

  const { addToCart, loading } = useContext(userCartContext);

  useEffect(() => {
    setBagQty(1);
  }, [selectedNote]);

  const dataObj = [
    {
      subject: 'Winey',
      score: selectedNote.winey,
    },
    {
      subject: 'Bitter',
      score: selectedNote.bitter,
    },
    {
      subject: 'Burnt',
      score: selectedNote.burnt,
    },
    {
      subject: 'Caramel',
      score: selectedNote.caramel,
    },
    {
      subject: 'Chocolate',
      score: selectedNote.chocolate,
    },
    {
      subject: 'Earthy',
      score: selectedNote.earthy,
    },
    {
      subject: 'Floral',
      score: selectedNote.floral,
    },
    {
      subject: 'Fruity',
      score: selectedNote.fruity,
    },
    {
      subject: 'Grassy',
      score: selectedNote.grassy,
    },
    {
      subject: 'Herby',
      score: selectedNote.herby,
    },
    {
      subject: 'Nutty',
      score: selectedNote.nutty,
    },
    {
      subject: 'Peanuts',
      score: selectedNote.peanuts,
    },
    {
      subject: 'Roasted',
      score: selectedNote.roasted,
    },
    {
      subject: 'Salt',
      score: selectedNote.salt,
    },
    {
      subject: 'Sour',
      score: selectedNote.sour,
    },
    {
      subject: 'Spicy',
      score: selectedNote.spicy,
    },
    {
      subject: 'Sweet',
      score: selectedNote.sweet,
    },
    {
      subject: 'Turpeny',
      score: selectedNote.turpeny,
    },
  ];

  const onBlendThisCoffeeHandler = () => {
    if (selectedNote.blend_recipes) {
      resetBlendRecipes();
      setBlendDescription(selectedNote.description);
      setBlendName(selectedNote.name);
      setBlendGrindType(selectedNote.grind_id);
      setBlendRoastType(selectedNote.roast_id);
      selectedNote.blend_recipes.forEach((beanData: any) => {
        addToBlendRecipes(beanData);
      });
    } else {
      addToBlendRecipes({ ...selectedNote, percentage: 0 });
    }
    history.push('/blend');
  };

  const onAddToCartHandler = async () => {
    let obj: any = {
      bagSizeId: bagSize,
      bagTypeId: 1,
      certificationId: selectedNote.certification_id,
      curstomerId: null,
      description: selectedNote.description,
      grindId: grind,
      labelDesignId: 58,
      name: selectedNote.name,
      quantity: bagQty,
      roastId: selectedNote.roast_id,
    };

    if (selectedNote.blend_recipes) {
      obj.blendRecipes = selectedNote.blend_recipes.map((bean: any) => {
        return {
          beanId: bean.id,
          percentage: bean.percentage,
        };
      });
    } else {
      obj.blendRecipes = [
        {
          beanId: selectedNote.id,
          percentage: 100,
        },
      ];
    }

    await addToCart(obj);
    setShowAddToCartToast(true);
  };

  return (
    <div className="graphingSection">
      <IonCard>
        <IonCardContent>
          <IonCardHeader>
            <IonCardSubtitle>Cupping Note Chart</IonCardSubtitle>
            <IonCardTitle>{selectedNote.name}</IonCardTitle>
          </IonCardHeader>

          <div className="graphingSection__radarAndPricing">
            <RadarChart
              outerRadius={90}
              width={350}
              height={250}
              data={dataObj}
              className="graphingSection__radarChart"
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={30} domain={[1, 10]} />
              <Radar
                className="graphingSection__radarComponent"
                dataKey="score"
              />
              <Tooltip />
            </RadarChart>

            <IonList className="graphingSection__pricinginfo">
              <DynamicallyPricedBlends
                bagSize={bagSize}
                setBagSize={setBagSize}
              />
              <IonItem>
                <IonLabel>Grind</IonLabel>
                <IonSelect
                  placeholder="Select One"
                  value={selectedNote.grind_id}
                  onIonChange={(e) => setGrind(e.detail.value)}
                >
                  <IonSelectOption value={1}>General</IonSelectOption>
                  <IonSelectOption value={2}>Filter</IonSelectOption>
                  <IonSelectOption value={3}>Whole Bean</IonSelectOption>
                  <IonSelectOption value={4}>Espresso</IonSelectOption>
                  <IonSelectOption value={5}>Cafetiêre</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel>How many bags</IonLabel>
                <IonSelect
                  placeholder="Select One"
                  value={bagQty}
                  onIonChange={(e) => setBagQty(e.detail.value)}
                >
                  {[...new Array(112)].map((_, idx) => (
                    <IonSelectOption value={idx + 1} key={idx}>
                      {idx + 1}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonList>
          </div>
        </IonCardContent>
        <div className="graphingSection__actionButtons">
          <IonButton className="primary" onClick={onBlendThisCoffeeHandler}>
            Blend This Coffee
          </IonButton>
          <IonButton
            disabled={loading}
            className="secondary"
            onClick={onAddToCartHandler}
          >
            Add To Cart
          </IonButton>
          <IonButton className="warning" onClick={() => history.push('/cart')}>
            Checkout
          </IonButton>
        </div>
      </IonCard>

      <IonToast
        isOpen={showAddToCartToast}
        onDidDismiss={() => setShowAddToCartToast(false)}
        message="Added Blend To Cart"
        duration={5000}
        color="primary"
      />
    </div>
  );
};

export default GraphingSection;
