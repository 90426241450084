// Core React
import { useEffect, useState } from 'react';

// 3rd party
import axios from 'axios';
import { ProductTile } from './ProductTile';

// Ionic Components
import { IonSlides, IonSlide, IonButton, IonSpinner } from '@ionic/react';

// Custom Components
import HomePageHeader from '../SectionHeaders/HomePageHeader/HomePageHeader';

// Styling
import './ProductSlider.css';
import config from '../../config';

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.
const slideOpts = {
  initialSlide: 0,
  slidesPerView: 1,
  pagination: false,
  breakpoints: {
    // when window width is >= 640px
    700: {
      slidesPerView: 2,
    },
    900: {
      slidesPerView: 3,
    },
  },
};

export interface IItems {
  name: string;
  green_bean_price: string;
  ctaps_id: string;
}

const ProductSlider: React.FC = () => {
  const [items, setItems] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const fetchBeans = async () => {
    setLoading(true);

    try {
      // Create Array of Axios calls with all items
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          token: localStorage.getItem('blendlyAuthToken'),
        },
      };

      const { data } = await axios.get(`${config.apiURL}/beans`, axiosConfig);

      const axiosArray = data.map((item: any) => {
        // Create the data obj for axios
        let obj: any = {
          quantity: 1,
          roastId: item.roast_id,
          certificationId: item.certification_id,
          bagSizeId: 7,
        };

        if (item.blend_recipes) {
          const formattedBlendRecipesArr = item.blend_recipes.map((x: any) => {
            return {
              beanId: x.bean_id,
              percentage: x.percentage,
            };
          });

          obj.blendRecipes = formattedBlendRecipesArr;
        } else {
          obj.blendRecipes = [
            {
              beanId: item.id,
              percentage: 100,
            },
          ];
        }

        return axios.post(
          `${config.apiURL}/dynamically-priced-blends`,
          obj,
          axiosConfig
        );
      });

      const res: any = await axios.all(axiosArray);

      const formatedData = data.map((item: any, idx: number) => {
        return {
          ...item,
          price_per_kilo: res[idx].data.price_per_kilo,
        };
      });

      setItems(formatedData);
      setLoading(false);
    } catch (error: any) {
      console.log(error.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBeans();
  }, []);

  return (
    <div className="productSlider">
      <HomePageHeader
        words={[
          { word: 'A few of', color: '#915A3C' },
          { word: 'Our top sellers', color: '#1F4E46' },
        ]}
        line={true}
      />

      {loading && (
        <div className="ion-text-center ion-padding">
          <IonSpinner name="crescent" />
        </div>
      )}

      <div className="productSlider__container">
        {items && (
          <IonSlides pager={true} options={slideOpts}>
            {items.map((p: IItems, i: React.Key | null | undefined) => (
              <IonSlide key={i}>
                <ProductTile p={p} />
              </IonSlide>
            ))}
          </IonSlides>
        )}
      </div>

      <div className="productSlider__morebtn">
        <IonButton
          className="primary productSlider__seeMoreBtn"
          routerLink="/Shop"
        >
          See more Top Sellers
        </IonButton>
      </div>
    </div>
  );
};

export default ProductSlider;
