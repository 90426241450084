// Core React
import { useEffect, useContext, useState } from 'react';

// GLobal State
import userProfileContext from '../../context/UserProfile/userProfileContext';

// Ionic Components
import {
  IonButton,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonSpinner,
} from '@ionic/react';

// Styling
import './SelectShippingAddress.css';

// API packages
import axios from 'axios';
import config from '../../config';
import checkoutContext from '../../context/Checkout/checkoutContext';

interface Props {
  modalToggle: (x: boolean) => void;
}

const SelectShippingAddress: React.FC<Props> = ({ modalToggle }) => {
  const { shippingAddressess, setShippingAddressess } =
    useContext(userProfileContext);

  const { setShippingInfo } = useContext(checkoutContext);

  const [loadingAddressess, setLoadingAddressess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState<any>(null);

  useEffect(() => {
    if (!shippingAddressess) {
      loadShippingAddressess();
    }

    //eslint-disable-next-line
  }, [shippingAddressess]);

  const loadShippingAddressess = async () => {
    try {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          token: localStorage.getItem('blendlyAuthToken'),
        },
      };

      setLoadingAddressess(true);

      const { data } = await axios.get(
        `${config.apiURL}/shipping-details`,
        axiosConfig
      );

      setShippingAddressess(data);

      setLoadingAddressess(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  const selectShippingAddressHandler = () => {
    if (!selectedShippingAddress) {
      setError('Please select a address first');
      setTimeout(() => {
        setError(null);
      }, 4000);
    }

    setShippingInfo(selectedShippingAddress);
    modalToggle(false);
  };

  return (
    <div className="selectShippingAddress">
      {error && <p className="selectShippingAddress__errorMessage">{error}</p>}

      {loadingAddressess && (
        <IonSpinner
          name="crescent"
          color="dark"
          className="selectShippingAddress__loader"
        />
      )}

      <h6 className="form-title">Choose Shipping Address</h6>

      <IonList className="selectShippingAddress__list">
        <IonRadioGroup
          allowEmptySelection
          value={selectedShippingAddress}
          onIonChange={(e: any) => setSelectedShippingAddress(e.detail.value)}
        >
          {!loadingAddressess &&
            shippingAddressess &&
            shippingAddressess.map((address: any) => (
              <IonItem key={address.id}>
                <IonRadio
                  value={address}
                  slot="start"
                  style={{
                    '--border-radius': '0%',
                    '--inner-border-radius': '0%',
                  }}
                />
                <div className="selectShippingAddress__addressItem">
                  <div className="selectShippingAddress__addressItem-left">
                    <p>{address.first_name}</p>
                    <p>{address.last_name}</p>
                    <p>{address.phone}</p>
                    <p>{address.company_name}</p>
                  </div>
                  <div className="selectShippingAddress__addressItem-right">
                    <p>{address.address}</p>
                    <p>{address.country_id}</p>
                    <p>{address.city}</p>
                    <p>{address.province}</p>
                    <p>{address.zip_code}</p>
                  </div>
                </div>
              </IonItem>
            ))}
        </IonRadioGroup>
      </IonList>

      <div className="selectShippingAddress__confirmBtn">
        <IonButton onClick={() => modalToggle(false)} className="light">
          Cancel
        </IonButton>
        <IonButton onClick={selectShippingAddressHandler} className="primary">
          Confirm Selection
        </IonButton>
      </div>
    </div>
  );
};

export default SelectShippingAddress;
