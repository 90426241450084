import { IonGrid, IonRow, IonCol, IonRouterLink } from "@ionic/react";
import React, { useEffect, useContext } from "react";
import "../../MyProfile/MyProfile.css";
import userProfileContext from "../../../../context/UserProfile/userProfileContext";

const SecurityInformation: React.FC = () => {
  const { userDetails, setActiveTab, activeTab } = useContext(userProfileContext);
  const { email } = userDetails;


  return (
    <div className="my-profile">
      
      <section>
        <div className="my-profile__container">
          <IonGrid>
            <IonRow className="my-profile__header">
              <IonCol className="section-header">Security Information</IonCol>
              {/* <IonCol className="edit-button"><a href =" ">Edit</a></IonCol> */}
            </IonRow>
          </IonGrid>

          {/* Security Information */}
          <IonGrid>
            {/* Email Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Email</IonCol>
              <IonCol>{email}</IonCol>
              <IonCol></IonCol>
            </IonRow>

            {/* Password Reset Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Password</IonCol>
              <IonCol className="change-reset-password">
              <IonCol onClick={ () => setActiveTab(4) }>
                  <span className="pointer"> Change </span>                 
              </IonCol>
                <IonRouterLink routerLink="/passwordreset">
                  <span>Forgot your password?</span>
                </IonRouterLink>
              </IonCol>
              <IonCol></IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </section>
    </div>
  );
};

export default SecurityInformation;
