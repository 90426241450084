import { OPEN_MENU } from "../types";

const mobileMenuReducer = (state, action) => {
  switch (action.type) {
    case OPEN_MENU:
      return {
        ...state,
        openMenu: action.payload,
      };
    default:
      break;
  }
};

export default mobileMenuReducer;
