import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { IonButton, IonInput, IonIcon } from '@ionic/react';
import { search as SearchIcon } from 'ionicons/icons';
import './CreateOwn.css';
import HomePageHeader from '../SectionHeaders/HomePageHeader/HomePageHeader';
import cuppingNotesContext from '../../context/CuppingNotes/cuppingNotesContext';

export const CreateOwn: React.FC = () => {
  const history = useHistory();

  const { searchValue, setSearchValue } = useContext(cuppingNotesContext);

  const handleRoute = () => {
    history.push('/blend');
  };

  const handleHomeRoute = () => {
    history.push('/cuppingnotes');
  };

  return (
    <section className="createOwn">
      <div className="createOwn__bgImg">
        <div className="ctaWrapper shadowOverlay">
          <div className="createOwn__content">
            <HomePageHeader
              words={[
                { word: 'Create Your Own Coffee', color: '#CFB79F' },
                { word: 'From Anywhere', color: '#EBEAE8' },
              ]}
              line={false}
            />

            <div className="createOwn__ctaButtons">
              <IonButton className="primary" onClick={handleHomeRoute}>
                NEW to Coffee Blending
              </IonButton>
              <span className="createOwn__orText">or</span>
              <IonButton className="primary" onClick={handleRoute}>
                {' '}
                Already a Coffee Blender{' '}
              </IonButton>
            </div>

            <hr className="createOwn__linethrough" />

            <p className="createOwn__searchCaption">
              Search for your favourite coffee brand
            </p>

            <div className="createOwn__inputGroup">
              <IonButton
                className="primary createOwn__searchIcon"
                onClick={() => history.push('/cuppingnotes')}
              >
                <IonIcon icon={SearchIcon} />
              </IonButton>
              <IonInput
                className="createOwn__input"
                placeholder="Type in your favourite coffee"
                value={searchValue}
                onIonChange={(e: any) => setSearchValue(e.detail.value)}
              />
            </div>
          </div>
        </div>
      </div>

    <div className="createOwn__howtocreate">
      <HomePageHeader
        words={[
          { word: 'How to create a', color: '#915A3C' },
          { word: 'Coffee blend', color: '#1F4E46' },
        ]}
        line={true}
      />

      <div className="createOwn__stepscontainer">
        <div>
          <div className="createOwn__stepitem">
            <h6>Step 1</h6>
            <p className="bold">
              <a href="/blend" className="bold">Select your beans</a> harvested from the world.
            </p>
            <p>Like a fine wine or an excellent single malt whisky, each blend of coffee has unique, specific qualities in its flavour, aroma, body and acidity.</p>
          </div>

          <div className="createOwn__stepitem">
          <h6>Step 3</h6>
            <p className="bold">
               Select your <a href="/wholesale">Barista plan.</a>
            </p>
            <p>Our Wholesalers packages allow you to manage high volume coffee in the most complex supply chains. Our solutions are tailored to allow 
              you to keep your coffee fresh and secure as well as for you to develop value.</p>
          </div>
        </div>
        <div>
          <div className="createOwn__stepitem">
          <h6>Step 2</h6>
            <p className="bold">
               Select your preferred grind and roast.
            </p>
            <p>In order to help you select the finest blend to suit your specific taste, mood or occasion we have created a unique Coffee Tasting and Profiling System (CTAPS).</p>
          </div>
          <div>
            
          <div className="createOwn__stepitem">
            <h6>Step 4</h6>
            <p className="bold">
              We deliver it right to your doorsteps.
            </p>
            <p>At Blendly, you can purchase the highest quality coffee beans then employ the expertise of the world's finest coffee roasters to produce the best tasting coffee, roasted to order and delivered to you in 24 hours.</p>
          </div>
        </div>
      </div> 
</div>
</div>
    </section>
  );
};
