// Core React
import { useContext } from 'react';

// Global State
import CuppingNotesContext from '../../../../context/CuppingNotes/cuppingNotesContext';

// Ionic Components
import { IonButton, IonButtons, IonIcon } from '@ionic/react';

// Images
import BlendIcon from '../../../../assets/icons/blend_card_icon.svg';
import BeanIcon from '../../../../assets/icons/bean_card_icon.svg';

// Styling
import './CuppingNotesCard.css';
import { useHistory } from 'react-router';
import TwiitterIcon from '../../../../assets/icons/icon-twiiter-share.svg';
import FacebookIcon from '../../../../assets/icons/icon-facebook-share.svg';

import { Share } from '@capacitor/share';

interface Props {
  notedata: any;
}

const CuppingNotesCard: React.FC<Props> = ({ notedata }) => {
  const history = useHistory();

  const { setToggleView, setSelectedNote } = useContext(CuppingNotesContext);

  const handleCuppingNoteSelection = () => {
    setSelectedNote(notedata);
    setToggleView(true);
    history.push(`/cuppingnotes/${notedata.ctaps_id}`);
  };

  const handleShareCuppingNote = async () => {
    await Share.share({
      title: 'See cool stuff',
      text: 'Really awesome thing you need to see right meow',
      url: 'http://ionicframework.com/',
      dialogTitle: 'Share with buddies',
    });
  };

  return (
    <div className="cuppingNoteCard">
      <div className="cuppingNoteCard__image">
        <img
          src={notedata.blend_recipes ? BlendIcon : BeanIcon}
          alt="blend card icon"
        />
      </div>

      <div className="cuppingNoteCard__namegrindroast">
        <p
          className="cuppingNoteCard__name"
          onClick={handleCuppingNoteSelection}
        >
          {notedata.name}
        </p>

        <div>
          <p className="cuppingNoteCard__grind">
            Grind: {notedata.grind_display_name}
          </p>
          <p className="cuppingNoteCard__roast">
            Roast: {notedata.roast_display_name}
          </p>
        </div>
      </div>

      <p className="cuppingNoteCard__beanCount">
        {notedata.blend_recipes
          ? `${notedata.blend_recipes.length} beans`
          : `1 bean`}
      </p>

      <IonButtons>
        <IonButton className="cuppingNoteCard__shareBtn">
          <IonIcon
            icon={TwiitterIcon}
            slot="icon-only"
            onClick={handleShareCuppingNote}
          ></IonIcon>
        </IonButton>
        <IonButton className="cuppingNoteCard__shareBtn">
          <IonIcon icon={FacebookIcon} slot="icon-only"></IonIcon>
        </IonButton>
      </IonButtons>
    </div>
  );
};

export default CuppingNotesCard;
