// Ionic Components
import {
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonCol,
  IonThumbnail,
  IonToggle,
  IonIcon,
  IonLoading,
} from '@ionic/react';

// Core React
import { useState, useEffect, useContext, useRef } from 'react';

// React Router Dom
import { useHistory } from 'react-router';

// Axios modules
import axios from 'axios';
import config from '../../config';

// Custom Components
import { Header } from '../../components/Layout/Header';
import { person } from 'ionicons/icons';
import { Footer } from '../../components/Layout/Footer';
import UserProfileTabs from '../../components/UserProfileTabs/UserProfileTabs';
import userProfileContext from '../../context/UserProfile/userProfileContext';

// Styling & Assets
import './UserProfile.css';
import { Helmet } from 'react-helmet';

interface Params {
  userDetailsVar: any;
  userTypeVar: any;
  isLoadingVar: boolean;
}

const UserProfile: React.FC<Params> = () => {
  const history = useHistory();

  // Get Auth Token
  const blendlyAuthToken = localStorage.getItem('blendlyAuthToken');

  // Initialize State
  const [userDetails, setUserDetails] = useState<any>(null);
  const [userType, setUserType] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Get functions from context
  const {
    addUserDetails,
    addUserType,
    addShippingDetails,
    addOrderDetails,
    addOrderStatuses,
    activeTab,
  } = useContext(userProfileContext);

  useEffect(() => {
    // Redirect if auth token is not found
    if (!blendlyAuthToken) {
      return history.push('/login');
    }

    sendGetRequest();
    //eslint-disable-next-line
  }, [blendlyAuthToken, history]);

  useEffect(() => {
    scrollToTop();
  }, [activeTab]);

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  const sendGetRequest = async () => {
    try {
      // Start loading data
      setIsLoading(true);
      // Get User Info
      const responseUser = await axios.get(
        `${config.apiURL}/authenticate/blendly/user?token=${blendlyAuthToken}`
      );
      // Get User ID
      const userID = responseUser.data.id;
      // Get User Type ID
      const userTypeId = responseUser.data.user_type_id;
      //  Get User Type
      const responseUserType = await axios.get(
        `${config.apiURL}/user-types/${userTypeId}`
      );
      // Get User Shipping Details
      const responseShipping = await axios.get(
        `${config.apiURL}/customers/${userID}/shipping-details?token=${blendlyAuthToken}`
      );
      // Get User Orders
      const responseOrders = await axios.get(
        `${config.apiURL}/customers/${userID}/orders?token=${blendlyAuthToken}`
      );
      // Get Orders Status
      const responseStatus = await axios.get(
        `${config.apiURL}/order-statuses?token=${blendlyAuthToken}`
      );

      setUserDetails(responseUser.data);
      addUserDetails(responseUser.data);
      addUserType(responseUserType.data);
      setUserType(responseUserType.data);
      addShippingDetails(responseShipping.data);
      addOrderDetails(responseOrders.data);
      addOrderStatuses(responseStatus.data);

      // Stop loading data
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      history.push('/');
    }
  };

  return (
    <IonPage>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Profile</title>
      </Helmet>
      <IonContent ref={contentRef}>
        <Header />
        <div className="user-profile">
          {/* User Profile Header */}
          <div className="user-profile__container">
            <IonGrid className="user-profile__header">
              <IonRow>
                <IonCol className="user-profile__header__image">
                  <IonThumbnail>
                    <IonIcon icon={person}></IonIcon>
                  </IonThumbnail>
                </IonCol>
                <IonCol className="user-profile__header__details">
                  <h4 className="profile-heading">Profile</h4>
                  <h4 className="profile-name">
                    {userDetails ? userDetails.full_name : null}
                  </h4>
                  <p>{userDetails ? userDetails.email : null}</p>
                  <p>{userType ? userType.display_name : null}</p>
                  <p>
                    {userDetails ? userDetails.trial_days_remaining : null} days
                    left on trial
                  </p>
                  <p className="profile-user-id">
                    ID {userDetails ? userDetails.id : null}
                  </p>
                </IonCol>
                <IonCol className="user-profile__header__order">
                  <h4 className="profile-heading">Predictive Ordering</h4>
                  <IonToggle />
                  <p className="predictive-description">
                    This will let the Blendly system to automatically book an
                    order for you in times that we detect that you need a fresh
                    coffee supply. Your automatic reorder will be based on your
                    last order and past order patterns. Will be subject for
                    approval and confirmation to you first. Slide to the right
                    to activate.
                  </p>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
        {/* Navigation Tabs */}
        <UserProfileTabs />
        <IonLoading isOpen={isLoading} />
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default UserProfile;
