import { IonCol, IonGrid, IonRow, IonRouterLink } from "@ionic/react";
import React, { useContext } from "react";
import "./BlendInformation.css";
import orderDetailsContext from "../../../../../context/OrderDetails/orderDetailsContext";
import BeansIcons from "../../../../../components/BeansIcons/BeansIcons";

const BlendInformation: React.FC = () => {
  const {
    orderBlendID,
    orderBlends,
    orderRoasts,
    orderGrinds,
    orderBlendRecipes,
    orderBeans,
  } = useContext(orderDetailsContext);

  // Blend Information
  const blendInformation = () => {
    if (orderBlendID) {
      const orderBlendinfo = orderBlends.filter((item: any) => {
        return item.id === orderBlendID;
      });

      const { ctaps_id, description, grind_id, name, roast_id } =
        orderBlendinfo[0];

      return (
        <>
          <IonRow className="blend-information__row">
            <IonCol className="blend-information__row__header">CTAPS ID</IonCol>
            <IonCol>
              <IonRouterLink href={`/cuppingnotes/${ctaps_id}`}>
                {ctaps_id}
              </IonRouterLink>
            </IonCol>
          </IonRow>
          <IonRow className="blend-information__row">
            <IonCol className="blend-information__row__header">Name</IonCol>
            <IonCol>{name}</IonCol>
          </IonRow>
          <IonRow className="blend-information__row">
            <IonCol className="blend-information__row__header">
              Label Description
            </IonCol>
            <IonCol>{description}</IonCol>
          </IonRow>
          <IonRow className="blend-information__row">
            <IonCol className="blend-information__row__header">Grind</IonCol>
            <IonCol>{getGrind(grind_id)}</IonCol>
          </IonRow>
          <IonRow className="blend-information__row">
            <IonCol className="blend-information__row__header">Roast</IonCol>
            <IonCol className="blend-information__row__roasts">
              {getRoasts(roast_id)}
            </IonCol>
          </IonRow>
        </>
      );
    }

    return <></>;
  };

  //   GET Grinds of blend
  const getGrind = (id: any) => {
    const grind = orderGrinds.filter((item: any) => {
      return item.id === id;
    });

    return grind[0].display_name;
  };

  //   GET Roasts of blend
  const getRoasts = (id: any) => {
    const roast = orderRoasts.filter((item: any) => {
      return item.id === id;
    });
    return (
      <>
        <span className="blend-information__row__roasts-icons">
          <BeansIcons roastId={id} />
        </span>
        <span> &#8212; {roast[0].display_name}</span>
      </>
    );
  };

  //   Bean Information
  const beanInformation = () => {
    const blendRecipe = orderBlendRecipes.filter((item: any) => {
      return item.order_blend_id === orderBlendID;
    });

    return (
      <>
        {blendRecipe.map((item: any) => {
          return (
            <IonRow key={item.id} className="bean-information__row">
              <IonCol>{getCtapsId(item.bean_id)}</IonCol>
              <IonCol>{getBeanName(item.bean_id)}</IonCol>
              <IonCol>{item.percentage}%</IonCol>
            </IonRow>
          );
        })}
      </>
    );
  };

  const getCtapsId = (id: any) => {
    const bean = orderBeans.filter((item: any) => {
      return item.id === id;
    });
    return (
      <IonRouterLink href={`/cuppingnotes/${bean[0].ctaps_id}`}>
        {bean[0].ctaps_id}
      </IonRouterLink>
    );
  };

  const getBeanName = (id: any) => {
    const bean = orderBeans.filter((item: any) => {
      return item.id === id;
    });
    return bean[0].name;
  };

  return (
    <div className="blend-information">
      {/* Blend Information */}
      <IonGrid>
        <IonRow className="blend-information__header">Blend information</IonRow>
        <div className="blend-information__container">{blendInformation()}</div>
      </IonGrid>
      {/* Bean Information */}
      <IonGrid>
        <div className="bean-information__container">
          <IonRow className="bean-information__header">Bean information</IonRow>
          {/* Header */}
          <IonRow className="bean-information__row__header">
            <IonCol>CTAPS ID</IonCol>
            <IonCol>Name</IonCol>
            <IonCol>Percentage</IonCol>
          </IonRow>
          {/* Body */}
          {beanInformation()}
        </div>
      </IonGrid>
    </div>
  );
};

export default BlendInformation;
