const configurations = {
  production: {
    apiURL: 'https://api.blendly.co.uk/api',
  },
  development: {
    apiURL: 'https://staging-api.blendly.co.uk/api',
  },
};

export default configurations['development'];
