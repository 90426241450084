import { useEffect, useContext } from "react";
import userProfileContext from "../../context/UserProfile/userProfileContext";
import "./UserProfileTabs.css";
import MyProfile from "../../pages/UserProfile/MyProfile/MyProfile";
import Orders from "../../pages/UserProfile/Orders/Orders";
import TopUp from "../../pages/UserProfile/TopUp/TopUp";
import ChangePasswordPage from "../../pages/UserProfile/MyProfile/SecurityInformation/ChangePasswordPage";

const UserProfileTabs = () => {

  const {activeTab, setActiveTab} = useContext(userProfileContext);

  return (
    
    <div className="user-profile-tabs">
      <div className="user-profile-tabs__container">
        <div className="user-profile-tabs__tabnav">
          <div
            className={`user-profile-tabs__tab ${activeTab === 1 && "active"}`}
            onClick={() => {
              setActiveTab(1);
            }}
          >
            My Profile
          </div>
          <div
            className={`user-profile-tabs__tab ${activeTab === 2 && "active"}`}
            onClick={() => {
              setActiveTab(2);
            }}
          >
            Order History
          </div>
          <div
            className={`user-profile-tabs__tab ${activeTab === 3 && "active"}`}
            onClick={() => {
              setActiveTab(3);
            }}
          >
            Top Up
          </div>
          <div
            className={`user-profile-tabs__tab ${activeTab === 4 && "active"}`}
            onClick={() => {
              setActiveTab(4);
            }}
          >
            Update Password
          </div>
        </div>
      </div>

      {activeTab === 1 && <MyProfile />}
      {activeTab === 2 && <Orders />}
      {activeTab === 3 && <TopUp />}
      {activeTab === 4 && <ChangePasswordPage />}
    </div>
  );
};

export default UserProfileTabs;
