// Core React
import { useContext, useState, useEffect, useRef } from 'react';

// Ionic Component
import {
  IonContent,
  IonIcon,
  IonPage,
  IonSpinner,
  IonToast,
} from '@ionic/react';

// Ionic Icons
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';

// Custom Components
import { Header } from '../../components/Layout/Header';
import { Footer } from '../../components/Layout/Footer';
import Banner from '../../components/Banner/Banner';
import { ProductTile } from '../../components/Products/ProductTile';

// React Helmet
import { Helmet } from 'react-helmet';
import shopContext from '../../context/Shop/shopContext';
import ReactPaginate from 'react-paginate';

// Styling
import './Shop.css';
import axios from 'axios';
import config from '../../config';

const Shop: React.FC = () => {
  const {
    getShopPageData,
    loading,
    products,
    error,
    clearError,
    currentPage,
    productsPerPage,
    setCurrentPage,
  } = useContext(shopContext);

  const [currentProducts, setCurrentProducts] = useState<any>(null);
  const [pricedProducts, setPricedProducts] = useState<any>(null);
  const [pageCount, setPageCount] = useState<any>(0);

  useEffect(() => {
    if (!products) {
      getShopPageData();
    }

    if (products) {
      setPageCount(Math.ceil(products.length / productsPerPage));

      const indexOfLastNote = currentPage * productsPerPage;
      const indexOfFirstNote = indexOfLastNote - productsPerPage;

      let currentProducts = Array.from(products).slice(
        indexOfFirstNote,
        indexOfLastNote
      );

      console.trace(currentProducts);

      setCurrentProducts(currentProducts);
    }

    //eslint-disable-next-line
  }, [currentPage, products]);

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollToPoint(0, 380, 1000);
    }
  };

  useEffect(() => {
    if (currentProducts) {
      calculateDynamicPrices(currentProducts);
    }

    //eslint-disable-next-line
  }, [currentProducts]);

  const calculateDynamicPrices = async (currentProducts: any) => {
    setPricedProducts(currentProducts);

    let finalProducts = [];
    for (let i = 0; i < currentProducts.length; i++) {
      let product = currentProducts[i];

      // Check if already has price fetched on object
      if (product.price_per_kilo) {
        finalProducts.push(product);
        continue;
      }

      // Fetch Dynamically priced blend for 1kg bag of current product
      try {
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            token: localStorage.getItem('blendlyAuthToken'),
          },
        };

        let obj: any = {
          quantity: 1,
          roastId: product.roast_id,
          certificationId: product.certification_id,
          bagSizeId: 7,
        };

        if (product.blend_recipes) {
          const formattedBlendRecipesArr = product.blend_recipes.map(
            (x: any) => {
              return {
                beanId: x.bean_id,
                percentage: x.percentage,
              };
            }
          );

          obj.blendRecipes = formattedBlendRecipesArr;
        } else {
          obj.blendRecipes = [
            {
              beanId: product.id,
              percentage: 100,
            },
          ];
        }

        const response = await axios.post(
          `${config.apiURL}/dynamically-priced-blends`,
          obj,
          axiosConfig
        );

        const dynamicPrice = response.data.total_price;

        currentProducts[i].price_per_kilo = dynamicPrice;
      } catch (error) {
        console.log(error);
      }

      finalProducts.push(currentProducts[i]);
    }

    setPricedProducts(finalProducts);
  };

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected + 1);
  };

  return (
    <IonPage className="shop">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shop</title>
      </Helmet>
      <IonContent ref={contentRef}>
        <Header />

        <IonToast
          isOpen={error}
          onDidDismiss={() => clearError()}
          message={error}
          duration={4000}
          color="danger"
        />

        <Banner
          options={{
            h1: 'Order our',
            h2: 'Products',
            bgImg: 'assets/images/shop-blends.jpg',
            size: 'half',
          }}
        />

        <div className="shop__productsRow">
          {loading && (
            <div className="ion-text-center ion-padding">
              <IonSpinner name="crescent" />
            </div>
          )}

          {!loading && pricedProducts && (
            <div>
              <ReactPaginate
                forcePage={currentPage - 1}
                nextLabel={<IonIcon icon={chevronForwardOutline} />}
                previousLabel={<IonIcon icon={chevronBackOutline} />}
                pageCount={pageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                activeClassName={'active'}
                containerClassName={'shop__pagination'}
                onPageChange={handlePageClick}
              />

              <div className="shop__products-grid">
                {pricedProducts.map((p: any, i: number) => (
                  <div className="shop__product-container" key={i}>
                    <ProductTile p={p} />
                  </div>
                ))}
              </div>

              <ReactPaginate
                forcePage={currentPage - 1}
                nextLabel={<IonIcon icon={chevronForwardOutline} />}
                previousLabel={<IonIcon icon={chevronBackOutline} />}
                pageCount={pageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                activeClassName={'active'}
                containerClassName={'shop__pagination'}
                onPageChange={handlePageClick}
              />
            </div>
          )}
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Shop;
