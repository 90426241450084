import {
  ADD_TO_USERCART_SUCCESS,
  ADD_TO_USERCART_LOADING,
  ADD_TO_USERCART_FAIL,
  USER_CARTDATA_LOADING,
  USER_CARTDATA_SUCCESS,
  USER_CARTDATA_FAIL,
  REMOVE_FROM_USERCART_LOADING,
  REMOVE_FROM_USERCART_SUCCESS,
  REMOVE_FROM_USERCART_FAIL,
} from '../types';

const userCartReducer = (state, action) => {
  switch (action.type) {
    case USER_CARTDATA_LOADING:
    case REMOVE_FROM_USERCART_LOADING:
    case ADD_TO_USERCART_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_CARTDATA_SUCCESS:
    case REMOVE_FROM_USERCART_SUCCESS:
    case ADD_TO_USERCART_SUCCESS:
      return {
        ...state,
        loading: false,
        cart: action.payload.cartItems,
        cartPricingData: action.payload.cartPricingData,
      };
    case USER_CARTDATA_FAIL:
    case REMOVE_FROM_USERCART_FAIL:
    case ADD_TO_USERCART_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userCartReducer;
