import { useState, useReducer } from 'react';
import {
  USER_DETAILS,
  SHIPPING_DETAILS,
  ORDERS,
  ORDER_STATUSES,
  USER_PROFILEDATA_SUCCESS,
  USER_PROFILEDATA_LOADING,
  USER_PROFILEDATA_FAIL,
  USER_TYPE,
  ADD_GUESTUSER_DATA,
} from '../types';
import userProfileContext from './userProfileContext';
import userProfileReducer from './userProfileReducer';
import axios from 'axios';
import config from '../../config';

const UserProfileState = (props) => {
  const initState = {
    userProfileData: null,
    guestUserDetails: null,
    userDetails: [],
    shippingDetails: [],
    orderDetails: [],
    orderStatus: [],
    userType: [],
  };

  const [state, dispatch] = useReducer(userProfileReducer, initState);
  const [activeTab, setActiveTab] = useState(1);
  const [shippingAddressess, setShippingAddressess] = useState(null);

  const isLoggedInHandler = async (blendlyAuthToken) => {
    try {
      // Dispatch Loading to True
      dispatch({
        type: USER_PROFILEDATA_LOADING,
      });

      // 1. Get User Data
      const res = await axios.get(
        `${config.apiURL}/authenticate/blendly/user?token=${blendlyAuthToken}`
      );

      // 2. Dispatch User Data to Reducer
      dispatch({
        type: USER_PROFILEDATA_SUCCESS,
        payload: res.data,
      });

      // 3. Extract User Type Id
      const userTypeId = res.data.user_type_id;

      // 4. Get User Type Data Info
      const resUserType = await axios.get(
        `${config.apiURL}/user-types/${userTypeId}`
      );

      // 5. Dispatch User Type Data to Reducer
      dispatch({
        type: USER_TYPE,
        payload: resUserType.data,
      });

      // Save The Cart ID from User Data in localstorage
      localStorage.setItem('blendlyCartId', res.data.cart_id);
    } catch (error) {
      dispatch({
        type: USER_PROFILEDATA_FAIL,
        payload: error.response,
      });
    }
  };

  const isNotLoggedInHandler = async () => {
    // Extract Card Id From localstorage
    let cartId = localStorage.getItem('blendlyCartId');

    // Check if cart id doesnt exists
    if (!cartId) {
      // Create & Retrieve new cart
      const {
        data: { id },
      } = await axios.post(`${config.apiURL}/carts`);

      // Store new cart id in localstorage
      localStorage.setItem('blendlyCartId', id);
    }
  };

  // Load the currently active client details into state
  const loadLoggedInUser = async () => {
    const blendlyAuthToken = localStorage.getItem('blendlyAuthToken');

    if (blendlyAuthToken) {
      isLoggedInHandler(blendlyAuthToken);
    } else {
      isNotLoggedInHandler();
    }
  };

  //   Reducer Action Functions
  // -- Add User Details
  const addUserDetails = (userData) => {
    dispatch({
      type: USER_DETAILS,
      payload: userData,
    });
  };

  // -- Add User Details
  const addUserType = (type) => {
    dispatch({
      type: USER_TYPE,
      payload: type,
    });
  };
  // -- Add Shipping Details
  const addShippingDetails = (shippingDetails) => {
    dispatch({
      type: SHIPPING_DETAILS,
      payload: shippingDetails,
    });
  };

  // -- Add Shipping Details
  const addOrderDetails = (orderDetails) => {
    dispatch({
      type: ORDERS,
      payload: orderDetails,
    });
  };

  // -- Add Order Statuses
  const addOrderStatuses = (orderStatus) => {
    dispatch({
      type: ORDER_STATUSES,
      payload: orderStatus,
    });
  };

  const addGuestUserData = (guestData) => {
    dispatch({
      type: ADD_GUESTUSER_DATA,
      payload: guestData,
    });
  };

  return (
    <userProfileContext.Provider
      value={{
        userProfileData: state.userProfileData,
        userDetails: state.userDetails,
        userType: state.userType,
        shippingDetails: state.shippingDetails,
        orderDetails: state.orderDetails,
        orderStatus: state.orderStatus,
        guestUserDetails: state.guestUserDetails,
        activeTab,
        shippingAddressess,
        setShippingAddressess,
        setActiveTab,
        addUserDetails,
        addUserType,
        addShippingDetails,
        addOrderDetails,
        addOrderStatuses,
        loadLoggedInUser,
        addGuestUserData,
      }}
    >
      {props.children}
    </userProfileContext.Provider>
  );
};

export default UserProfileState;
