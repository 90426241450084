// Core React
import { useEffect, useContext } from 'react';

// Global State
import UserProfileContext from './context/UserProfile/userProfileContext';
import UserCartContext from './context/UserCart/userCartContext';

// React Router Dom
import { Route, Redirect } from 'react-router-dom';

// Ionic Components
import { IonApp, IonRouterOutlet } from '@ionic/react';

// Ionic Router
import { IonReactRouter } from '@ionic/react-router';

/* Custom Components */
import Home from './pages/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import Shop from './pages/Shop/Shop';
import Blend from './pages/Blend/Blend';
import FAQs from './pages/FAQs/faqs';
import LoginPage from './pages/LoginPage/LoginPage';
import CuppingNotes from './pages/CuppingNotes/CuppingNotes';
import SignUpPage from './pages/SignUp/SignUpPage';
import PasswordResetPage from './pages/PasswordReset/PasswordResetPage';
import UsernameResetPage from './pages/PasswordReset/UsernameResetPage';
import UserProfile from './pages/UserProfile/UserProfile';
import ChangePasswordPage from './pages/UserProfile/MyProfile/SecurityInformation/ChangePasswordPage';
import TasteMatch from './pages/TasteMatch/TasteMatch';
import WholeSale from './pages/WholeSale/WholeSale';
import Cart from './pages/Cart/Cart';
import OrderDetails from './pages/OrderDetails/OrderDetails';
import Checkout from './pages/Checkout/Checkout';
import UserProfileTabs from './components/UserProfileTabs/UserProfileTabs';
import EditBasicInfo from './pages/UserProfile/MyProfile/EditBasicInfo/EditBasicInfo';
import EditShippingInfo from './pages/UserProfile/MyProfile/EditShippingInfo/EditShippingInfo';
import Terms from './pages/Terms/Terms';
import ReturnPolicy from './pages/ReturnPolicy/ReturnPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TopUp from './pages/UserProfile/TopUp/TopUp';
import MobileNavbarMenu from './components/MobileNavbarMenu/MobileNavbarMenu';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Subscription from './pages/Subscription/Subscription';
import BlendPriceCheck from './pages/BlendPriceCheck/BlendPriceCheck';
import { Helmet } from 'react-helmet';
// import Subscription from './pages/Subscription/Subscription';

const App: React.FC = () => {
  const userToken = localStorage.getItem('blendlyAuthToken');
  const { loadLoggedInUser } = useContext(UserProfileContext);
  const { loadUserCart } = useContext(UserCartContext);

  useEffect(() => {
    loadLoggedInUser();
    loadUserCart();

    //eslint-disable-next-line
  }, [userToken]);

  return (
    <IonApp>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blendly App</title>
      </Helmet>
      <IonReactRouter>
        <MobileNavbarMenu />

        <IonRouterOutlet>
          <Route exact path="/" component={Home} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/shop" component={Shop} />
          <Route exact path="/blend" component={Blend} />
          <Route exact path="/faqs" component={FAQs} />
          <Route exact path="/login" component={LoginPage} />
          <Route path="/cuppingnotes/:ctapsId?" component={CuppingNotes} />
          <Route exact path="/signup" component={SignUpPage} />
          <Route exact path="/passwordreset" component={PasswordResetPage} />
          <Route exact path="/usernamereset" component={UsernameResetPage} />
          <Route exact path="/changepassword" component={ChangePasswordPage} />
          <PrivateRoute exact path="/profile" component={UserProfile} />
          <Route exact path="/profile/user/update" component={EditBasicInfo} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/returnpolicy" component={ReturnPolicy} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route
            exact
            path="/profile/shipping-details/update/:shippingId"
            component={EditShippingInfo}
          />
          <Route
            exact
            path="/profile/shipping-details/create"
            component={EditShippingInfo}
          />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/checkout/complete" component={Checkout} />
          <Route exact path="/checkout/incomplete" component={Checkout} />
          <Route
            exact
            path="/profile/orders/:orderId"
            component={OrderDetails}
          />
          <Route exact path="/changepassword" component={ChangePasswordPage} />
          <Route exact path="/profile/topup" component={TopUp} />
          <Route exact path="/userprofiletabs" component={UserProfileTabs} />
          <Route exact path="/tastematch" component={TasteMatch} />
          <Route exact path="/wholesale" component={WholeSale} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/TopUp" component={TopUp} />
          <Route exact path="/blendpricecheck" component={BlendPriceCheck} />
          <Redirect to="/" />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
