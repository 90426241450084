// Core React
import { useContext } from 'react';

// Global State
import UserCartContext from '../../context/UserCart/userCartContext';

// Ionic Components
import { IonPage, IonContent, IonLoading } from '@ionic/react';

// Custom Components
import { Footer } from '../../components/Layout/Footer';
import { Header } from '../../components/Layout/Header';
import CartItemCard from '../../components/CartItemCard/CartItemCard';
import CartSummary from '../../components/CartSummary/CartSummary';
import EmptyCartScreen from '../../components/EmptyCartScreen/EmptyCartScreen';

// Styling && Assets
import './Cart.css';
import { Helmet } from 'react-helmet';

const Cart = () => {
  const { cart, loading } = useContext(UserCartContext);

  return (
    <IonPage>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cart</title>
      </Helmet>
      <IonContent>
        <Header />
        <IonLoading isOpen={loading} />

        <div className="cartpage">
          {!loading && cart.length === 0 ? (
            <EmptyCartScreen />
          ) : (
            <>
              <div className="cart__table-container">
                <table className="cart__table">
                  <thead>
                    <tr className="cart__tablerow">
                      <th className="cart__Img">
                        <span>Cart</span>
                      </th>
                      <th className="cart__Desc">
                        <span>Description</span>
                      </th>
                      <th className="cart__PricePerBag">
                        <span>Price per Bag</span>
                      </th>
                      <th className="cart__Qty">
                        <span>No. Of Bag</span>
                      </th>
                      <th className="cart__SubTotal">
                        <span>Sub Total</span>
                      </th>
                      <th className="cart__VolDis">
                        <span>Volume Discount</span>
                      </th>
                      <th className="cart__Total">
                        <span>Total</span>
                      </th>
                      <th className="cart__RemoveBtn"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map((cartItem: any) => (
                      <CartItemCard key={cartItem.id} cartItem={cartItem} />
                    ))}
                  </tbody>
                </table>
              </div>

              <CartSummary />
            </>
          )}
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Cart;
