import { IonIcon } from '@ionic/react';
import {
  cafeOutline as cafeOutlineIcon,
  cafe as cafeIcon,
} from 'ionicons/icons';

interface RatingProps {
  rate: number;
}

const Rating: React.FC<RatingProps> = (props) => {
  let cups = [];

  for (var i = 0; i < 5; i++) {
    if (i < (props.rate ? props.rate : 0)) {
      cups.push(<IonIcon color="warning" icon={cafeIcon} key={i} />);
    } else {
      cups.push(<IonIcon color="dark" icon={cafeOutlineIcon} key={i} />);
    }
  }

  return <div className="cups">{cups}</div>;
};

export default Rating;
