// Core React
import { useState, useEffect, useContext } from 'react';

// Ionic Components
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonToast,
} from '@ionic/react';

// Custom Components
import { Header } from '../../components/Layout/Header';
import { Footer } from '../../components/Layout/Footer';

// Axios module;s
import axios from 'axios';
import config from '../../config';

// Styling & Assets
import './SignUpPage.css';
import { useHistory } from 'react-router';
import userProfileContext from '../../context/UserProfile/userProfileContext';
import SignUpSuccess from '../SignUpSuccess/SignUpSuccess';
import { Helmet } from 'react-helmet';

const signupbg = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/barista-making-chemex-coffee.png')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

interface Params {
  firstNameVar: string;
  lastNameVar: string;
  emailVar: string;
  companyNameVar: string;
  numberVar: number;
  passwordVar: string;
  retypePasswordVar: string;
}

const SignUpPage: React.FC<Params> = () => {
  const history = useHistory();
  const blendlyAuthToken = localStorage.getItem('blendlyAuthToken');

  // Global State
  const { loadLoggedInUser, loadUserCart } = useContext(userProfileContext);

  // Local State
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [number, setNumber] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');

  const [registerError, setRegisterError] = useState('');
  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  useEffect(() => {
    if (blendlyAuthToken) {
      history.push('/');
    }

    //eslint-disable-next-line
  }, []);

  // Fire on Input change of the form fields
  const onChangeField = (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'companyName':
        setCompanyName(value);
        break;
      case 'number':
        setNumber(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'retypepassword':
        setRetypePassword(value);
        break;
      default:
        break;
    }

    // Remove messages on change input fields
  };

  // Fire on Form Submit
  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    // Quick Validation
    if (
      !firstName ||
      !lastName ||
      !companyName ||
      !email ||
      !number ||
      !password
    ) {
      return setRegisterError('Please fill in all fields');
    }

    if (password !== retypePassword) {
      setPassword('');
      setRetypePassword('');
      return setRegisterError("Passwords doesn't match, please try again");
    }

    try {
      setRegisterLoading(true);

      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      let registerPayload = {
        firstName: firstName,
        lastName: lastName,
        companyName: companyName,
        email: email,
        number: number,
        password: password,
        cartId: localStorage.getItem('blendlyCartId'),
      };

      // Register Customer
      await axios.post(
        `${config.apiURL}/customers/create-as-customer`,
        registerPayload,
        axiosConfig
      );

      let loginPayload = {
        cartId: localStorage.getItem('blendlyCartId'),
        email: email,
        password: password,
      };

      // Login Customer After successful register
      const res = await axios.post(
        `${config.apiURL}/authenticate/blendly`,
        loginPayload,
        axiosConfig
      );

      // Save auth token to localstorage
      localStorage.setItem('blendlyAuthToken', res.data.token);

      // Go to profile
      history.push('/profile');

      // Load All User Data
      loadLoggedInUser();
      loadUserCart();

      //Set register loading and success state
      setRegisterSuccess(true);
      setRegisterLoading(false);
    } catch (error: any) {
      console.log(error.response);
      setRegisterLoading(false);
    }
  };

  return (
    <IonPage className="signup">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register</title>
      </Helmet>
      <IonContent>
        <Header />

        <IonToast
          isOpen={registerError !== ''}
          onDidDismiss={() => setRegisterError('')}
          message={registerError}
          duration={4000}
          color="danger"
        />

        {!registerSuccess && (
          <div className="signup__hero" style={signupbg}>
            <form onSubmit={onFormSubmit} className="signup__form">
              <h2>Sign Up</h2>

              <IonItem>
                <IonLabel position="floating">First name</IonLabel>
                <IonInput
                  type="text"
                  name="firstName"
                  required
                  onIonChange={onChangeField}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Last name</IonLabel>
                <IonInput
                  type="text"
                  name="lastName"
                  required
                  onIonChange={onChangeField}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  type="email"
                  name="email"
                  required
                  onIonChange={onChangeField}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Company Name</IonLabel>
                <IonInput
                  type="text"
                  name="companyName"
                  required
                  onIonChange={onChangeField}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Phone Number</IonLabel>
                <IonInput
                  type="number"
                  name="number"
                  required
                  onIonChange={onChangeField}
                />
              </IonItem>

              <hr className="signup__hr" />

              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput
                  type="password"
                  name="password"
                  required
                  onIonChange={onChangeField}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Retype Password</IonLabel>
                <IonInput
                  type="password"
                  name="retypepassword"
                  required
                  onIonChange={onChangeField}
                />
              </IonItem>

              <IonButton
                type="submit"
                className="warning signup_form_button"
                expand="block"
                disabled={registerLoading}
              >
                {!registerLoading ? 'Continue' : 'Loading...'}
              </IonButton>

              <p className="signup__caption">
                Already have an account?
                <IonRouterLink className="login_link" routerLink="/login">
                  Log in
                </IonRouterLink>
              </p>
            </form>
          </div>
        )}

        {registerSuccess && <SignUpSuccess />}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default SignUpPage;
