import React, { useContext } from "react";
import OrderDetailsTabs from "./OrderDetailsTabs/OrderDetailsTabs";
import "./OrderContainer.css";
import orderDetailsContext from "../../../context/OrderDetails/orderDetailsContext";
import { IonCol, IonRow } from "@ionic/react";
const OrderContainer: React.FC = () => {
  const { orderInfo } = useContext(orderDetailsContext);

  const goBack = () => {
    window.history.back();
  };
  return (
    <div className="user-order__container">
      <IonRow className="user-order__header">
      {/* <IonCol size="2" size-sm className="user-order__back">
          <button onClick={goBack}>&#8249; Back</button>
        </IonCol> */}
        <IonCol size="12">
          <div className="user-order__back">
            <button onClick={goBack}>&#8249; Back</button>
          </div>
          <h2 className="user-order__order-id">Order ID: {orderInfo.id}</h2>
        </IonCol>        
      </IonRow>
      <OrderDetailsTabs />
    </div>
  );
};

export default OrderContainer;
