import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import axios from 'axios';
import CommunityTabs from '../../components/CommunityTabs/CommunityTabs';
import { Footer } from '../../components/Layout/Footer';
import { Header } from '../../components/Layout/Header';
import ProfileHeader from '../../components/ProfileHeader/ProfileHeader';
import config from '../../config';
import orderDetailsContext from '../../context/OrderDetails/orderDetailsContext';
import { Helmet } from 'react-helmet';

const OrderDetails: React.FC = () => {
  const history = useHistory();

  // Check to see if logged in
  const blendlyAuthToken = localStorage.getItem('blendlyAuthToken');

  if (!blendlyAuthToken) {
    history.push('/login');
  }

  // Get Order Id from params
  const { orderId } = useParams<any>();

  // Loading State
  const [isLoading, setIsLoading] = useState(false);

  // Get Reducer Functions
  const {
    addOrderDetails,
    addShippingDetails,
    addShippingOptions,
    addBlendDetails,
    addLogDetails,
    addOrderStatuses,
    addBagSize,
    addBagTypes,
    addOrderCountries,
    addOrderPayments,
    addOrderPaymentGateways,
    addOrderBlendRecipes,
    addOrderBeans,
    addOrderRoasts,
    addOrderGrinds,
    addBlendDistributions,
  } = useContext(orderDetailsContext);

  useEffect(() => {
    fetchOrderData();
  }, []);

  const fetchOrderData = async () => {
    try {
      setIsLoading(true);
      // GET Order Details
      const resOrder = await axios(
        `${config.apiURL}/orders/${orderId}?token=${blendlyAuthToken}`
      );
      // GET Order Statuses
      const resOrderStatuses = await axios(`${config.apiURL}/order-statuses`);
      // GET Order Blends
      const resBlends = await axios(
        `${config.apiURL}/orders/${orderId}/order-blends?token=${blendlyAuthToken}`
      );
      // GET Blend Bag Sizes
      const resBlendBagSize = await axios(`${config.apiURL}/bag-sizes`);
      // GET Blend Bag Type
      const resBlendBagType = await axios(`${config.apiURL}/bag-types`);
      // GET Order Countries
      const resCountry = await axios(`${config.apiURL}/countries`);
      // GET Order Shipping
      const resShipping = await axios(
        `${config.apiURL}/orders/${orderId}/order-shipping-details?token=${blendlyAuthToken}`
      );
      // GET Order Shipping OPtions
      const resShippingOption = await axios(
        `${config.apiURL}/shipping-options`
      );
      // GET Payments
      const resPayments = await axios(
        `${config.apiURL}/orders/${orderId}/payments?token=${blendlyAuthToken}`
      );
      // GET Payment Gateways
      const resPaymentGate = await axios(
        `${config.apiURL}/payment-gateways?token=${blendlyAuthToken}`
      );
      // GET Order Logs
      const resLogs = await axios(
        `${config.apiURL}/orders/${orderId}/order-logs?token=${blendlyAuthToken}`
      );
      // GET Order Blend Recipes
      const resBlendRecipes = await axios(
        `${config.apiURL}/orders/${orderId}/order-blend-recipes?token=${blendlyAuthToken}`
      );
      // GET Order Blend Recipes
      const resBlendDistros = await axios(
        `${config.apiURL}/orders/${orderId}/order-blend-distributions?token=${blendlyAuthToken}`
      );
      // GET Beans
      const resBeans = await axios(
        `${config.apiURL}/beans?token=${blendlyAuthToken}`
      );
      // GET Roasts
      const resRoasts = await axios(`${config.apiURL}/roasts`);
      // GET Grinds
      const resGrinds = await axios(`${config.apiURL}/grinds`);

      addOrderDetails(resOrder.data);
      addOrderStatuses(resOrderStatuses.data);
      addBlendDetails(resBlends.data);
      addBagSize(resBlendBagSize.data);
      addBagTypes(resBlendBagType.data);
      addOrderCountries(resCountry.data);
      addShippingDetails(resShipping.data);
      addShippingOptions(resShippingOption.data);
      addOrderPayments(resPayments.data);
      addOrderPaymentGateways(resPaymentGate.data);
      addOrderBlendRecipes(resBlendRecipes.data);
      addOrderBeans(resBeans.data);
      addOrderRoasts(resRoasts.data);
      addOrderGrinds(resGrinds.data);
      addLogDetails(resLogs.data);
      addBlendDistributions(resBlendDistros.data);
      setIsLoading(false);
    } catch (error) {
      alert(error);
      window.location.href = '/profile';
    }
  };

  return (
    <IonPage className="user-order">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Order Details</title>
      </Helmet>
      <IonContent>
        <Header />
        <ProfileHeader />
        <CommunityTabs />
        <IonLoading isOpen={isLoading} />
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default OrderDetails;
