import {
  SET_CURRENT_CUPPINGNOTE,
  CLEAR_CURRENT_CUPPINGNOTE,
  SET_SELECTED_NOTE,
  CUPPINGNOTE_DATA_FAIL,
  CUPPINGNOTE_DATA_LOADING,
  CUPPINGNOTE_DATA_SUCCESS,
  CUPPINGNOTE_PRICE_DATA_LOADING,
  CUPPINGNOTE_PRICE_DATA_SUCCESS,
  CUPPINGNOTE_PRICE_DATA_FAIL,
} from '../types';

const cuppingNotesReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_CUPPINGNOTE:
      return {
        ...state,
        currentCuppingNote: action.payload,
        loading: false,
        error: null,
      };
    case CLEAR_CURRENT_CUPPINGNOTE:
      return {
        ...state,
        currentCuppingNote: null,
      };
    case SET_SELECTED_NOTE:
      return {
        ...state,
        selectedNote: action.payload,
      };
    case CUPPINGNOTE_DATA_LOADING:
    case CUPPINGNOTE_PRICE_DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CUPPINGNOTE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        cuppingNotes: action.payload,
      };
    case CUPPINGNOTE_PRICE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCuppingNoteBagPrices: action.payload,
      };
    case CUPPINGNOTE_DATA_FAIL:
    case CUPPINGNOTE_PRICE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default cuppingNotesReducer;
