// Core React
import { useState, useContext, useEffect } from 'react';

// Ionic Components
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';

// Styling
import './CheckoutShipping.css';
import checkoutContext from '../../context/Checkout/checkoutContext';
import SelectShippingAddress from '../SelectShippingAddress/SelectShippingAddress';

interface Props {
  setCheckoutStep: (x: number) => void;
}

const CheckoutShipping: React.FC<Props> = ({ setCheckoutStep }) => {
  const [showModal, setShowModal] = useState(false);

  const {
    shippingInfo,
    billingInfo,
    updateShippingInfo,
    shippingOption,
    setShippingOption,
    setCheckoutError,
    countriesData,
    fetchDynamicallyPricedOrderData,
  } = useContext(checkoutContext);

  const goToPaymentHandler = () => {
    if (!shippingInfo) {
      return;
    }

    const {
      first_name,
      last_name,
      phone,
      company_name,
      address,
      country_id,
      city,
      province,
      zip_code,
    } = shippingInfo;

    if (
      !first_name ||
      !last_name ||
      !phone ||
      !company_name ||
      !address ||
      !country_id ||
      !city ||
      !province ||
      !zip_code
    ) {
      setCheckoutError('Please fill in all fields');
      return;
    }

    setCheckoutStep(4);
  };

  useEffect(() => {
    if (!billingInfo) {
      setCheckoutStep(2);
    }

    // This will default the country for the select {value prop} to GB
    if (!shippingInfo.country_id) {
      updateShippingInfo({
        key: 'country_id',
        value: 'GB',
      });
    }

    //eslint-disable-next-line
  }, [billingInfo, setCheckoutStep, shippingInfo.country_id]);

  useEffect(() => {
    fetchDynamicallyPricedOrderData();
    //eslint-disable-next-line
  }, [shippingOption]);

  const toggleShippingAddressSelectHandler = () => {
    setShowModal(true);
  };

  return (
    <div className="checkoutShipping">
      <IonModal
        onDidDismiss={() => setShowModal(false)}
        isOpen={showModal}
        cssClass="blendpage__modal"
      >
        <SelectShippingAddress modalToggle={setShowModal} />
      </IonModal>
      <div className="checkoutShipping__header">
        <h6 className="checkoutShipping__title">Shipping Info</h6>
        {localStorage.getItem('blendlyAuthToken') && (
          <p onClick={toggleShippingAddressSelectHandler}>
            Use existing shipping address
          </p>
        )}
      </div>
      <form className="checkoutShipping__form">
        <div className="checkoutShipping__leftForm">
          <IonItem className="form-input">
            <IonLabel position="floating" className="form-label">
              First Name
            </IonLabel>
            <IonInput
              value={shippingInfo ? shippingInfo.first_name : ''}
              onIonChange={(e: any) =>
                updateShippingInfo({
                  key: e.target.name,
                  value: e.detail.value,
                })
              }
              name="first_name"
              placeholder="First name"
            ></IonInput>
          </IonItem>
          <IonItem className="form-input">
            <IonLabel position="floating" className="form-label">
              Last Name
            </IonLabel>
            <IonInput
              value={shippingInfo ? shippingInfo.last_name : ''}
              onIonChange={(e: any) =>
                updateShippingInfo({
                  key: e.target.name,
                  value: e.detail.value,
                })
              }
              name="last_name"
              placeholder="Last name"
            ></IonInput>
          </IonItem>
          <IonItem className="form-input">
            <IonLabel position="floating" className="form-label">
              Phone Number
            </IonLabel>
            <IonInput
              value={shippingInfo ? shippingInfo.phone : ''}
              onIonChange={(e: any) =>
                updateShippingInfo({
                  key: e.target.name,
                  value: e.detail.value,
                })
              }
              name="phone"
              placeholder="Phone number"
            ></IonInput>
          </IonItem>
          <IonItem className="form-input">
            <IonLabel position="floating" className="form-label">
              Company Name <small>(optional)</small>
            </IonLabel>
            <IonInput
              value={shippingInfo ? shippingInfo.company_name : ''}
              onIonChange={(e: any) =>
                updateShippingInfo({
                  key: e.target.name,
                  value: e.detail.value,
                })
              }
              name="company_name"
              placeholder="Company name"
            ></IonInput>
          </IonItem>
        </div>
        <div className="checkoutShipping__rightForm">
          <IonItem className="form-input">
            <IonLabel position="floating" className="form-label">
              Address
            </IonLabel>
            <IonInput
              value={shippingInfo ? shippingInfo.address : ''}
              onIonChange={(e: any) =>
                updateShippingInfo({
                  key: e.target.name,
                  value: e.detail.value,
                })
              }
              name="address"
              placeholder="address"
            ></IonInput>
          </IonItem>
          <IonItem className="form-input">
            <IonLabel
              className="form-label"
              position={
                billingInfo && billingInfo.country_id ? 'stacked' : 'floating'
              }
            >
              Country
            </IonLabel>
            <IonSelect
              className="edit-forms__form-select"
              value={shippingInfo ? shippingInfo.country_id : 'GB'}
              onIonChange={(e: any) =>
                updateShippingInfo({
                  key: 'country_id',
                  value: e.detail.value,
                })
              }
            >
              {countriesData?.map(
                (country: any) =>
                  country.is_shipping && (
                    <IonSelectOption key={country.id} value={country.id}>
                      {country.display_name}
                    </IonSelectOption>
                  )
              )}
            </IonSelect>
          </IonItem>
          <IonItem className="form-input">
            <IonLabel position="floating" className="form-label">
              City
            </IonLabel>
            <IonInput
              value={shippingInfo ? shippingInfo.city : ''}
              onIonChange={(e: any) =>
                updateShippingInfo({
                  key: e.target.name,
                  value: e.detail.value,
                })
              }
              name="city"
              placeholder="City"
            ></IonInput>
          </IonItem>
          <IonItem className="form-input">
            <IonLabel position="floating" className="form-label">
              State/Province
            </IonLabel>
            <IonInput
              value={shippingInfo ? shippingInfo.province : ''}
              onIonChange={(e: any) =>
                updateShippingInfo({
                  key: e.target.name,
                  value: e.detail.value,
                })
              }
              name="province"
              placeholder="State/Province"
            ></IonInput>
          </IonItem>
          <IonItem className="form-input">
            <IonLabel position="floating" className="form-label">
              ZIP/Postal Code
            </IonLabel>
            <IonInput
              value={shippingInfo ? shippingInfo.zip_code : ''}
              onIonChange={(e: any) =>
                updateShippingInfo({
                  key: e.target.name,
                  value: e.detail.value,
                })
              }
              name="zip_code"
              placeholder="ZIP/Postal code"
            ></IonInput>
          </IonItem>
        </div>

        <div className="checkoutShipping__bottom">
          <IonItem className="form-input">
            <IonLabel position="floating" className="form-label">
              Shipping Options
            </IonLabel>
            <IonSelect
              value={shippingOption}
              placeholder="Select One"
              className="edit-forms__form-select"
              onIonChange={(e: any) => setShippingOption(e.detail.value)}
            >
              <IonSelectOption value={1}>
                First Class (Next Day)
              </IonSelectOption>
              <IonSelectOption value={2}>Second Class (2 Days)</IonSelectOption>
            </IonSelect>
          </IonItem>
        </div>
      </form>

      <div className="checkoutShipping__actionBtns">
        <IonButton onClick={goToPaymentHandler} className="warning">
          Continue
        </IonButton>
      </div>
    </div>
  );
};

export default CheckoutShipping;
