import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonRadio,
  IonRadioGroup,
  IonThumbnail,
  IonItem,
  IonRouterLink,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import "./Blends.css";
import orderDetailsContext from "../../../../context/OrderDetails/orderDetailsContext";
import BlendInformation from "./BlendInformation/BlendInformation";

const Blends: React.FC = () => {
  const { orderBlends, orderBagSizes, orderBagTypes, addBlendId } =
    useContext(orderDetailsContext);

  const [blendId, setBlendId] = useState(orderBlends[0].id);

  useEffect(() => {
    onRadioButtonChange(blendId);
  }, []);

  // GET all Order Blends
  const blends = () => {
    return (
      <>
        {orderBlends.map((item: any) => {
          const {
            id,
            blend_id,
            ctaps_id,
            name,
            order_blend_recipe_count,
            bag_size_id,
            bag_type_id,
            unit_price,
            quantity,
            subtotal,
            volume_discount,
            total_price,
          } = item;

          return (
            <IonRow key={blend_id} className="order-blends__table__body-row">
              {/* Select blend */}
              <IonItem>
                <IonRadio
                  slot="start"
                  value={id}
                  onClick={() => onRadioButtonChange(id)}
                />
              </IonItem>
              {/* Row Icon */}
              <IonCol>
                <IonThumbnail>
                  <IonImg src="/assets/images/blend-icon.png" />
                </IonThumbnail>
              </IonCol>
              {/*  ctaps-id */}
              <IonCol>
                <IonRouterLink
                  href={`/cuppingnotes/${ctaps_id}`}
                  onClick={() => {
                    window.location.href = `/cuppingnotes/${ctaps_id}`;
                  }}
                >
                  {ctaps_id}
                </IonRouterLink>
              </IonCol>
              {/* name */}
              <IonCol>{name}</IonCol>
              {/* No. of Beans | order_blend_recipe_count */}
              <IonCol>{order_blend_recipe_count} beans</IonCol>
              {/* Bag Size */}
              <IonCol>{getBagSize(bag_size_id)}</IonCol>
              {/* Bag Type */}
              {/* <IonCol>{getBagTypes(bag_type_id)}</IonCol> */}
              {/* Price per bag | Unit Price */}
              <IonCol>£{unit_price}</IonCol>
              {/* Qunatity */}
              <IonCol>{quantity}</IonCol>
              {/* Subtotal */}
              <IonCol>£{subtotal}</IonCol>
              {/* Vol. Discount */}
              <IonCol>-£{volume_discount.toFixed(2)}</IonCol>
              {/* Total */}
              <IonCol>£{total_price}</IonCol>
            </IonRow>
          );
        })}
      </>
    );
  };

  // Change info when radio buttons are clicked
  const onRadioButtonChange = (id: any) => {
    addBlendId(id);
    setBlendId(id);
  };

  // GET Bag Size
  const getBagSize = (id: number) => {
    const bagSize = orderBagSizes.filter((item: any) => {
      return item.id === id;
    });
    return (
      <span>
        {bagSize[0].value >= 1000
          ? `${bagSize[0].value / 1000}Kg`
          : `${bagSize[0].value}g`}
      </span>
    );
  };

  // GET Bag Types
  const getBagTypes = (id: number) => {
    const bagTypes = orderBagTypes.filter((item: any) => {
      return item.id === id;
    });
    return <span>{bagTypes[0].display_name}</span>;
  };

  return (
    <div className="order-blends">
      <h4 className="order-blends__header">Details</h4>
      <IonGrid className="order-blends__table">
        <IonRow className="order-blends__table__header-row">
          <IonCol></IonCol>
          <IonCol></IonCol>
          <IonCol>CTAPS ID</IonCol>
          <IonCol>Blend Name</IonCol>
          <IonCol>No. of Beans</IonCol>
          <IonCol>Bag Size</IonCol>
          {/* <IonCol>Bag Type</IonCol> */}
          <IonCol>Price Per Bag</IonCol>
          <IonCol>Quantity</IonCol>
          <IonCol>Subtotal</IonCol>
          <IonCol>Vol. Discount</IonCol>
          <IonCol>Total</IonCol>
        </IonRow>
        <IonRow className="order-blends__table__body">
          <IonRadioGroup value={blendId}>{blends()}</IonRadioGroup>
        </IonRow>
      </IonGrid>
      <BlendInformation />
    </div>
  );
};

export default Blends;
