import {
  USER_DETAILS,
  SHIPPING_DETAILS,
  ORDERS,
  ORDER_STATUSES,
  USER_PROFILEDATA_LOADING,
  USER_PROFILEDATA_SUCCESS,
  USER_PROFILEDATA_FAIL,
  USER_TYPE,
} from "../types";

const userProfileReducer = (state, action) => {
  switch (action.type) {
    case USER_PROFILEDATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_PROFILEDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfileData: action.payload,
      };
    case USER_PROFILEDATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    case SHIPPING_DETAILS:
      return {
        ...state,
        shippingDetails: action.payload,
      };
    case ORDERS:
      return {
        ...state,
        orderDetails: action.payload,
      };
    case ORDER_STATUSES:
      return {
        ...state,
        orderStatus: action.payload,
      };
    default:
      return state;
  }
};

export default userProfileReducer;
