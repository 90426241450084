// Ionic Components
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonButton,
  IonIcon,
} from '@ionic/react';

// Ionicons
import { cart as CartIcon, eye as EyeIcon } from 'ionicons/icons';
import { useContext } from 'react';
import { useHistory } from 'react-router';

// Styling & Assets
import productDemoImage from '../../assets/images/default-product.png';
import userCartContext from '../../context/UserCart/userCartContext';
import currencyFormatter from '../../helpers/currencyFormatter';
import './ProductTile.css';

type Props = {
  p: any;
};

export const ProductTile: React.FC<Props> = ({ p }) => {
  const history = useHistory();

  // Global State
  const { addToCart } = useContext(userCartContext);

  const formattedDescription = (desc: string) => {
    if (desc.length <= 80) {
      return desc;
    }

    let newDescription = desc.substr(0, 80) + '...';
    return newDescription;
  };

  const onBuyNowHandler = async () => {
    let obj: any = {
      bagSizeId: 7, // default to 1kg
      bagTypeId: 1,
      certificationId: p.certification_id,
      curstomerId: null,
      description: p.description,
      grindId: p.grind_id,
      labelDesignId: 58,
      name: p.name,
      quantity: 1,
      roastId: p.roast_id,
    };

    if (p.blend_recipes) {
      obj.blendRecipes = p.blend_recipes.map((bean: any) => {
        return {
          beanId: bean.id,
          percentage: bean.percentage,
        };
      });
    } else {
      obj.blendRecipes = [
        {
          beanId: p.id,
          percentage: 100,
        },
      ];
    }

    addToCart(obj);
    history.push('/cart');
  };

  return (
    <IonCard className="productTile">
      <IonCardHeader>
        <div className="productTitle__cardheader">
          <img src={productDemoImage} alt="product" />
        </div>
      </IonCardHeader>

      <IonCardContent className="productTile__content">
        <div className="productTile__nameweight">
          <h5>{p.name}</h5>
          <span>1kg</span>
        </div>

        <p className="productTile__description">
          {formattedDescription(p.description)}
        </p>

        <div className="productTile__price">
          {p.price_per_kilo ? (
            currencyFormatter(p.price_per_kilo)
          ) : (
            <p className="price__loading__loader"></p>
          )}
        </div>

        <div className="productTile__actionButtons">
          <IonButton
            className="primary productTile__buyNowBtn"
            routerLink={`/cuppingnotes/${p.ctaps_id}`}
          >
            <IonIcon icon={EyeIcon} slot="start" />
            View
          </IonButton>
          <span className="productTile__line"></span>
          <IonButton
            onClick={onBuyNowHandler}
            className="warning productTile__buyNowBtn"
          >
            <IonIcon icon={CartIcon} slot="start" />
            Buy Now
          </IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  );
};
