import { IonButton, IonSpinner } from '@ionic/react';
import { useContext } from 'react';
import userCartContext from '../../context/UserCart/userCartContext';
import OrderSummaryItem from '../../components/OrderSummaryItem/OrderSummaryItem';
import './CheckoutOrderSummary.css';
import currencyFormatter from '../../helpers/currencyFormatter';
import UserImage from '../../assets/images/user-image.png';
import checkoutContext from '../../context/Checkout/checkoutContext';
import userProfileContext from '../../context/UserProfile/userProfileContext';

interface Props {
  setCheckoutStep: (x: number) => void;
}

const CheckoutOrderSummary: React.FC<Props> = ({ setCheckoutStep }) => {
  const { userProfileData } = useContext(userProfileContext);
  const { cart } = useContext(userCartContext);
  const { loading, dynamicallyPricedOrder } = useContext(checkoutContext);

  return (
    <div className="checkoutOrderSummary">
      {!localStorage.getItem('blendlyAuthToken') && !userProfileData && (
        <div className="checkoutOrderSummary__login">
          <h6>Checkout as Guest</h6>
          <img src={UserImage} alt="user profile placeholder" />
          <IonButton className="warning" routerLink="/login?redirect=checkout">
            Log in as member
          </IonButton>
        </div>
      )}

      <div className="checkoutOrderSummary__order">
        <h6>Order Summary</h6>

        <div className="checkoutOrderSummary__orderInfo">
          {loading ? (
            <IonSpinner />
          ) : (
            <>
              <div className="checkoutOrderSummary__orderlist">
                {cart.map((cartItem: any) => (
                  <OrderSummaryItem key={cartItem.id} cartItem={cartItem} />
                ))}
              </div>

              <div className="checkoutOrderSummary__prices">
                <div>
                  <span>Sub Total</span>
                  <span>
                    {currencyFormatter(
                      dynamicallyPricedOrder
                        ? dynamicallyPricedOrder.subtotal
                        : 0
                    )}
                  </span>
                </div>
                <div>
                  <span>Shipping Cost</span>
                  <span>
                    {currencyFormatter(
                      dynamicallyPricedOrder
                        ? dynamicallyPricedOrder.shipping_cost
                        : 0
                    )}
                  </span>
                </div>
                <div>
                  <span>Volume Discount</span>
                  <span>
                    {currencyFormatter(
                      dynamicallyPricedOrder
                        ? dynamicallyPricedOrder.volume_discount
                        : 0
                    )}
                  </span>
                </div>
                <div>
                  <span>Grand Total</span>
                  <span>
                    {currencyFormatter(
                      dynamicallyPricedOrder ? dynamicallyPricedOrder.total : 0
                    )}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="checkoutOrderSummary__continueBtn">
          <IonButton
            disabled={loading}
            onClick={() => setCheckoutStep(2)}
            className="warning"
          >
            Continue
          </IonButton>
        </div>
      </div>
    </div>
  );
};

export default CheckoutOrderSummary;
