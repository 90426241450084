import { IonContent, IonPage, IonRouterLink } from '@ionic/react';
import { Helmet } from 'react-helmet';
import { Footer } from '../../components/Layout/Footer';
import { Header } from '../../components/Layout/Header';
import HomePageHeader from '../../components/SectionHeaders/HomePageHeader/HomePageHeader';
import currencyFormatter from '../../helpers/currencyFormatter';
import '../WholeSale/WholeSale.css';
// import '../Subscription.css';

const Subscription = () => {
  return (
    <IonPage>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Subscriptions</title>
      </Helmet>
      <IonContent>
        <Header />

        <section className="subscription__Hero">
          <div className="shadowOverlay ctaWrapper">
            <HomePageHeader
              words={[
                {
                  word: 'Value added coffee plans',
                  color: '#cfb79f',
                },
                {
                  word: 'For Subscriptions',
                  color: '#fff',
                },
              ]}
              line={false}
            />
          </div>
        </section>

        <section className="wholesale__subscriptionsSection">
          <div className="container">
            <div className="wholesale__brasitacorporate-card">
              <div className="colomn">
                <div className="wholesale__subsBox">
                  <p className="wholesale__subsBox-title">Barista Home</p>
                  <p className="wholesale__subsBox-sm">
                    Manage your home coffee
                  </p>
                  <p className="wholesale__subsBox-price">
                    {currencyFormatter(4.99)} <span>/month</span>
                  </p>
                </div>
                <div>
                  <ul>
                    <li>£4.99 worth of credit to your account every month</li>
                    <li>Share and distribute your coffee blends</li>
                    <li>Select coffee beans from around the world</li>
                    <li>Dedicated Online Support</li>
                    <li>Order Management</li>
                    <li>Predictive Ordering</li>
                    <li>Access to Cupping Notes</li>
                    <li>Online Training</li>
                    <li>Unlimited Coffee Blends</li>
                    <li>Coffee Analytics</li>
                    <li>Custom Grind</li>
                    <li>Custom Roasting Colour</li>
                    <li>Custom Label</li>
                  </ul>
                </div>
                <p className="wholesale__brasitacorporate-cardfooter">
                  <IonRouterLink routerLink="/signup">
                    Start Your 30 Day Trail
                  </IonRouterLink>
                </p>
              </div>
            </div>

            <div className="wholesale__brasitacorporate-card">
              <div className=" colomn ">
                <div className="wholesale__subsBox">
                  <p className="wholesale__subsBox-title">Barista Plus</p>
                  <p className="wholesale__subsBox-sm">
                    Run your own coffee business
                  </p>
                  <p className="wholesale__subsBox-price">
                    {currencyFormatter(19.99)} <span>/month</span>
                  </p>
                </div>
                <div>
                  <ul className=".brasitacorporate-card__ul">
                    <li>£19.99 worth of credit to your account every month</li>
                    <li>Share and distribute your coffee blends</li>
                    <li>Select coffee beans from around the world</li>
                    <li>Dedicated Online Support</li>
                    <li>Order Management</li>
                    <li>Predictive Ordering</li>
                    <li>Volumised Discounts</li>
                    <li>Access to Cupping Notes</li>
                    <li>Online Training</li>
                    <li>Unlimited Coffee Blends</li>
                    <li>Advanced Coffee Analytics</li>
                    <li>More Shipping Options</li>
                    <li>Custom Grind</li>
                    <li>Custom Roasting Colour</li>
                    <li>Custom Label</li>
                    <li>Customer Analytics</li>
                    <li>FREE Community Builder Add-on</li>
                    <li>Centralised Stock Management</li>
                    <li>IOT Integration (Coming Soon)</li>
                  </ul>
                </div>
                <p className="wholesale__brasitacorporate-cardfooter">
                  <IonRouterLink routerLink="/signup">
                    Start Your 30 Day Trail
                  </IonRouterLink>
                </p>
              </div>
            </div>

            <div className="wholesale__brasitacorporate-card">
              <div className="colomn">
                <div className="wholesale__subsBox">
                  <p className="wholesale__subsBox-title">Barista</p>
                  <p className="wholesale__subsBox-sm">
                    Manage your shop customers
                  </p>
                  <p className="wholesale__subsBox-price">
                    {currencyFormatter(12.99)} <span>/month</span>
                  </p>
                </div>
                <div>
                  <ul>
                    <li>£12.99 worth of credit to your account every month</li>
                    <li>Share and distribute your coffee blends</li>
                    <li>Select coffee beans from around the world</li>
                    <li>Dedicated Online Support</li>
                    <li>Order Management</li>
                    <li>Predictive Ordering</li>
                    <li>Volumised Discounts</li>
                    <li>Access to Cupping Notes</li>
                    <li>Online Training</li>
                    <li>Unlimited Coffee Blends</li>
                    <li>Advanced Coffee Analytics</li>
                    <li>Custom Grind</li>
                    <li>Custom Roasting Colour</li>
                    <li>Custom Label</li>
                    <li>3 Months FREE Community Builder Add-on</li>
                  </ul>
                </div>
                <p className="wholesale__brasitacorporate-cardfooter">
                  <IonRouterLink routerLink="/signup">
                    Start Your 30 Day Trail
                  </IonRouterLink>
                </p>
              </div>
            </div>
          </div>

          {/* <div className="wholesale__subsBox">
              <p className="wholesale__subsBox-title">Barista Home</p>
              <p className="wholesale__subsBox-sm">Manage your home coffee</p>
              <p className="wholesale__subsBox-price">
                {currencyFormatter(4.99)} <span>/month</span>
              </p>
              <p className="wholesale__subsBox-trail">Start Your 30Day Trail</p>
            </div>
            <div className="wholesale__subsBox">
              <p className="wholesale__subsBox-title">Barista Home</p>
              <p className="wholesale__subsBox-sm">Manage your home coffee</p>
              <p className="wholesale__subsBox-price">
                {currencyFormatter(4.99)} <span>/month</span>
              </p>
              <p className="wholesale__subsBox-trail">Start Your 30Day Trail</p>
            </div>
            <div className="wholesale__subsBox">
              <p className="wholesale__subsBox-title">Barista Home</p>
              <p className="wholesale__subsBox-sm">Manage your home coffee</p>
              <p className="wholesale__subsBox-price">
                {currencyFormatter(4.99)} <span>/month</span>
              </p>
              <p className="wholesale__subsBox-trail">Start Your 30Day Trail</p>
            </div>
            <div className="wholesale__subsBox">
              <p className="wholesale__subsBox-title">Barista Home</p>
              <p className="wholesale__subsBox-sm">Manage your home coffee</p>
              <p className="wholesale__subsBox-price">
                {currencyFormatter(4.99)} <span>/month</span>
              </p>
              <p className="wholesale__subsBox-trail">Start Your 30Day Trail</p>
            </div> */}
        </section>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Subscription;
