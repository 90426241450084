import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonTextarea,
} from '@ionic/react';
import { Helmet } from 'react-helmet';
import { Footer } from '../../components/Layout/Footer';
import { Header } from '../../components/Layout/Header';
import HomePageHeader from '../../components/SectionHeaders/HomePageHeader/HomePageHeader';
import currencyFormatter from '../../helpers/currencyFormatter';
import './WholeSale.css';

const WholeSale = () => {
  return (
    <IonPage className="wholeSale">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wholesale</title>
      </Helmet>
      <IonContent>
        <Header />

        <section className="wholeSale__hero">
          <div className="shadowOverlay ctaWrapper">
            <HomePageHeader
              words={[
                {
                  word: 'Get your wholesale coffee with us',
                  color: '#CFB79F',
                },
                {
                  word: 'And Save More',
                  color: '#fff',
                },
              ]}
              line={false}
            />
          </div>
        </section>

        <section className="wholesale__bulksection">
          <HomePageHeader
            words={[
              {
                word: 'Bulk  /  Wholesale',
                color: '#915a3c',
              },
              {
                word: 'Coffee',
                color: '#1f4e46',
              },
            ]}
            line={true}
          />

          <div className="wholesale__bulkcoffeecontainer">
            <p>
              Blendly.co.uk offer a suite of solutions for accessing bulk
              purchase of coffee , Our Wholesalers packages allow you to manage
              high volume coffee in the most complex supply chains our solutions
              are tailored to allow you to keep your coffee fresh and secure as
              well as for you develop value, increase distribution and keep your
              inventory to a minimum.
            </p>
            <p>
              Blendly operate a CTAPS system that has a Suite of Tools to allow
              greater flexibility and value by allowing access to Tools
              distribution
            </p>
          </div>

          <div className="wholesale__cardcontainer">
            <p className="wholesale__cardheader">
              Blendly - Tools to manage your Wholesale operations
            </p>

            <div className="wholesale__cardcontent">
              <span>1</span>
              <p>
                Searchable recipes and develop local brands around the skill on
                the barista.{" "}
              </p>
            </div>

            <div className="wholesale__cardcontent">
              <span>2</span>
              <p>
                Income and redistribute resources: Capitalising on the
                experience of the coffee lover and baristas they can enter a
                marketplace enabling their skills and recommendations to build
                income.{" "}
              </p>
            </div>

            <div className="wholesale__cardcontent">
              <span>3</span>
              <p>
                Cupping notes: To better develop your brand and the encourage
                feedback from your network of followers and customers.{" "}
              </p>
            </div>

            <div className="wholesale__cardcontent">
              <span>4</span>
              <p>
                Repository: A central database of brands and recipes for you
                to roast like an expert allowing you to use profiles based on
                the cupping of major brands.{" "}
              </p>
            </div>

            <div className="wholesale__cardcontent">
              <span>5</span>
              <p>
                Sharing of coffee: Share Your blend with friends family and
                customers.
              </p>
            </div>

            <div className="wholesale__cardcontent">
              <span>6</span>
              <p>
                Predictive ordering: Manage your stock fresh.
              </p>
            </div>
          </div>
        </section>

        <section className="wholesale__toolSection">
          <HomePageHeader
            words={[
              {
                word: 'The importance of',
                color: '#915a3c',
              },
              {
                word: 'The tools',
                color: '#1f4e46',
              },
            ]}
            line={true}
          />

          <div className="wholesale__toolTextContainer">
            <p>
              The Tools were created to allow coffee to be integrated product
              and the process and production to sit as a localised managed
              service in Homes , Work Places , and as distributed data product
              for more complex supply chains - as well as the allow people to
              interact in the market place using tools that replicate the
              physical tasks within a production and manufacturing environment
              to access via the Internet.
            </p>
            <p>
              The tools also allow the re sell of your coffee brand as ongoing
              subscription severe not only by allowing you to create a custom
              product , but also to use that custom product to sell of the
              Blendly marketplace to Homes , Baristas , Barista Professional as
              a commissioned managed subscription service with tailored plans
            </p>
          </div>
        </section>

        {/* <section className="wholesale__subscriptionsSection">
          <HomePageHeader
            words={[
              {
                word: "Value added coffee plans",
                color: "#915a3c",
              },
              {
                word: "For Subscriptions",
                color: "#1f4e46",
              },
            ]}
            line={true}
          />

          <div className="wholesale__subsBoxContainer">
            <div className="wholesale__subsBox">
              <p className="wholesale__subsBox-title">Barista Home</p>
              <p className="wholesale__subsBox-sm">Manage your home coffee</p>
              <p className="wholesale__subsBox-price">
                {currencyFormatter(4.99)} <span>/month</span>
              </p>
              <p className="wholesale__subsBox-trail">Start Your 30Day Trail</p>
            </div>
            <div className="wholesale__subsBox">
              <p className="wholesale__subsBox-title">Barista Home</p>
              <p className="wholesale__subsBox-sm">Manage your home coffee</p>
              <p className="wholesale__subsBox-price">
                {currencyFormatter(4.99)} <span>/month</span>
              </p>
              <p className="wholesale__subsBox-trail">Start Your 30Day Trail</p>
            </div>
            <div className="wholesale__subsBox">
              <p className="wholesale__subsBox-title">Barista Home</p>
              <p className="wholesale__subsBox-sm">Manage your home coffee</p>
              <p className="wholesale__subsBox-price">
                {currencyFormatter(4.99)} <span>/month</span>
              </p>
              <p className="wholesale__subsBox-trail">Start Your 30Day Trail</p>
            </div>
            <div className="wholesale__subsBox">
              <p className="wholesale__subsBox-title">Barista Home</p>
              <p className="wholesale__subsBox-sm">Manage your home coffee</p>
              <p className="wholesale__subsBox-price">
                {currencyFormatter(4.99)} <span>/month</span>
              </p>
              <p className="wholesale__subsBox-trail">Start Your 30Day Trail</p>
            </div>
            <div className="wholesale__subsBox">
              <p className="wholesale__subsBox-title">Barista Home</p>
              <p className="wholesale__subsBox-sm">Manage your home coffee</p>
              <p className="wholesale__subsBox-price">
                {currencyFormatter(4.99)} <span>/month</span>
              </p>
              <p className="wholesale__subsBox-trail">Start Your 30Day Trail</p>
            </div>
          </div>
        </section> */}

        <section className="wholesale__greenSection">
          <HomePageHeader
            words={[
              {
                word: 'Blend',
                color: '#915a3c',
              },
              {
                word: 'Our wholesale',
                color: '#fff',
              },
            ]}
            line={true}
          />

          <p className="wholesale__greenSection-text">
            Solutions allow you build value and distribution with online
            merchandising as well as tolls that are designed to allow you to
            create more waue better engagement as well as integration into other
            supply chains{' '}
          </p>
        </section>

        <section className="wholesale__brasitacorporate">
          <HomePageHeader
            words={[
              {
                word: 'Barista corporate',
                color: '#915a3c',
              },
              {
                word: 'Account',
                color: '#1f4e46',
              },
            ]}
            line={true}
          />

          <div className="wholesale__brasitacorporate-text">
            <p>
              Barista Corporate accounts are designed to allow low cost
              distribution of fresher coffee as well as help you build your
              brand and your blend, the Barista Corporate plan also allows you
              to sell Valuaaded coffee subscriptions around your coffee blend -
              allowing access to customer in there home, There work, or even
              local coffee shop.
            </p>
            <p>
              Barista corporate accounts centrally manage these relationships
              with the help of your blendly account these accounts allow you to
              achieve low cost fresher coffee that is managed via our predictive
              ordering service £600 per year
            </p>

            <IonRouterLink href="/signup">
              <IonButton className="warning">Get Started</IonButton>
            </IonRouterLink>
          </div>

          <div className="wholesale__brasitacorporate-card">
            <div className="wholesale__brasitacorporate-cardheader">
              <p className="wholesale__subsBox-title">Barista Corporate</p>
              <p className="wholesale__subsBox-sm">Be the best</p>
              <p className="wholesale__subsBox-price">
                {currencyFormatter(49.99)} <span>/month</span>
              </p>
            </div>

            <div>
              <ul>
                <li>Coffee prices start at £2.</li>
                <li>Best pricing among all the Barista plans</li>
                <li>Volumized Discounts</li>
                <li>Training Notes</li>
                <li>Blend Management</li>
                <li>Technical Support</li>
                <li>Customer Management</li>
                <li>Centralized stock control</li>
                <li>Advanced Analytics</li>
                <li>Free Taste Matching service</li>
                <li>Account Manager</li>
              </ul>
            </div>

            <p className="wholesale__brasitacorporate-cardfooter">
              Start Your 30 Day Trial
            </p>
          </div>
        </section>

        <section className="wholesale__salesmanager">
          <HomePageHeader
            words={[
              {
                word: 'Sales Manager',
                color: '#915a3c',
              },
              {
                word: 'Account',
                color: '#1f4e46',
              },
            ]}
            line={true}
          />

          <div className="wholesale__brasitacorporate-text">
            <p>
              Manage Multiple Barista Distribuitor accounts allow you manage
              Barista Corporate accounts Barista plans Baristas and barista home
              accounts and are designed for regional distribution , The sales
              manager account allows you to manage regions and areas of
              customers as part of a growing regional team customers
              subscriptions
            </p>
            <p>
              Sales manager account also comes with a Supported marketing a lead
              generation plan as well as weekly meetings and account reviews
              that can help build your areas as well as develop your business ,
              The sales manager account includes specific area exclusivity and
              support
            </p>
            <p>Cost - per year £1500</p>

            <IonRouterLink href="/signup">
              <IonButton className="warning">Get Started</IonButton>
            </IonRouterLink>
          </div>
        </section>

        {/* <section className="wholesale__apply">
          <HomePageHeader
            words={[
              {
                word: 'Apply to',
                color: '#915a3c',
              },
              {
                word: 'Wholesale with us',
                color: '#1f4e46',
              },
            ]}
            line={true}
          />

          <form className="wholesale__apply-form form">
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                First Name
              </IonLabel>
              <IonInput />
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                Last Name
              </IonLabel>
              <IonInput />
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                Email
              </IonLabel>
              <IonInput />
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                Phone Number
              </IonLabel>
              <IonInput />
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                Business Name
              </IonLabel>
              <IonInput />
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                Type of Business
              </IonLabel>
              <IonInput />
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                What equipment are you currently using
              </IonLabel>
              <IonTextarea />
            </IonItem>
            <IonItem className="form-input">
              <IonLabel position="floating" className="form-label">
                Additional information
              </IonLabel>
              <IonTextarea />
            </IonItem>

            <IonButton className="warning">Submit</IonButton>
          </form>
        </section> */}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default WholeSale;
