import { useState } from "react";
import "./OrderDetailsTabs.css";
import Information from "../Information/Information";
import Blends from "../Blends/Blends";
import Billing from "../Billing/Billing";
import Shipping from "../Shipping/Shipping";
import Barista from "../Barista/Barista";
import Logs from "../Logs/Logs";

const OrderDetailsTabs = () => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="order-tabs">
      <div className="order-tabs__container">
        <div className="order-tabs__tabnav">
          <div
            className={`order-tabs__tab ${activeTab === 1 && "active"}`}
            onClick={() => {
              setActiveTab(1);
            }}
          >
            INFORMATION
          </div>
          <div
            className={`order-tabs__tab ${activeTab === 2 && "active"}`}
            onClick={() => {
              setActiveTab(2);
            }}
          >
            BLENDS
          </div>
          <div
            className={`order-tabs__tab ${activeTab === 3 && "active"}`}
            onClick={() => {
              setActiveTab(3);
            }}
          >
            BILLING
          </div>
          <div
            className={`order-tabs__tab ${activeTab === 4 && "active"}`}
            onClick={() => {
              setActiveTab(4);
            }}
          >
            SHIPPING
          </div>
          {/* <div
            className={`order-tabs__tab ${activeTab === 5 && "active"}`}
            onClick={() => {
              setActiveTab(5);
            }}
          >
            BARISTA DISTRIBUTOR
          </div> */}
          <div
            className={`order-tabs__tab ${activeTab === 5 && "active"}`}
            onClick={() => {
              setActiveTab(5);
            }}
          >
            LOGS
          </div>
        </div>
      </div>

      {activeTab === 1 && <Information />}
      {activeTab === 2 && <Blends />}
      {activeTab === 3 && <Billing />}
      {activeTab === 4 && <Shipping />}
      {/* {activeTab === 5 && <Barista />} */}
      {activeTab === 5 && <Logs />}
    </div>
  );
};

export default OrderDetailsTabs;
