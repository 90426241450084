import { IonGrid, IonRow, IonCol, IonRouterLink } from "@ionic/react";
import React, { useContext } from "react";
import "../../MyProfile/MyProfile.css";
import userProfileContext from "../../../../context/UserProfile/userProfileContext";

const BasicInformation: React.FC = () => {
  const { userDetails, userType, setActiveTab } = useContext(userProfileContext);
  const { full_name, email, company_name, usable_credit } = userDetails;
  const { display_name } = userType;

  return (
    <div className="my-profile">
      <section>
        <div className="my-profile__container">
          <IonGrid>
            <IonRow className="my-profile__header">
              <IonCol className="section-header">Basic Information</IonCol>
              <IonCol className="edit-button">
                <IonRouterLink href="/profile/user/update">Edit</IonRouterLink>
              </IonCol>
            </IonRow>
          </IonGrid>

          {/* Basic Information */}
          <IonGrid>
            {/* Name Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Name</IonCol>
              <IonCol>{full_name}</IonCol>
              <IonCol></IonCol>
            </IonRow>

            {/* Company Name Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Company Name</IonCol>
              <IonCol>{company_name}</IonCol>
              <IonCol></IonCol>
            </IonRow>

            {/* Email Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Email</IonCol>
              <IonCol>{email}</IonCol>
              <IonCol></IonCol>
            </IonRow>

            {/* Barista Plan Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Barista Plan</IonCol>
              <IonCol>{display_name}</IonCol>
              <IonCol className="yellow">Upgrade now</IonCol>
            </IonRow>

            {/* Credit Row */}
            <IonRow className="my-profile__section-rows">
              <IonCol>Available Credit</IonCol>
              <IonCol>&#163;{usable_credit}</IonCol>
              <IonCol className='pointer yellow' onClick={ () => setActiveTab(3)}>Top up</IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </section>
    </div>
  );
};

export default BasicInformation;
