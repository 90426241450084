import { IonButton, IonIcon } from '@ionic/react';
import React from 'react';
import './CheckoutFailed.css';
import { storefrontOutline } from 'ionicons/icons';

interface Props {
  errorMessage: string | null;
}

const CheckoutFailed: React.FC<Props> = ({ errorMessage }) => {
  return (
    <div className="checkoutComplete">
      <div className="checkoutComplete__icon">
        <IonIcon icon={storefrontOutline} />
      </div>

      <h2 className="checkoutComplete__mainText">{errorMessage}</h2>

      <div className="checkoutComplete__mainCtaBtns">
        <IonButton routerLink="/profile" className="warning">
          Go To Profile
        </IonButton>
        <IonButton routerLink="/" className="warning">
          Go To Homepage
        </IonButton>
      </div>
    </div>
  );
};

export default CheckoutFailed;
