// React Router Dom
import { useHistory } from 'react-router';

// React Helmet
import { Helmet } from 'react-helmet';

// Ionic Components
import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonText,
  IonButton,
  IonIcon,
} from '@ionic/react';

// Custom Components
import HomePageHeader from '../../components/SectionHeaders/HomePageHeader/HomePageHeader';
import { Header } from '../../components/Layout/Header';
import { Footer } from '../../components/Layout/Footer';

// Styling & assets
import './AboutUs.css';
import worldMapImage from '../../assets/images/coffee-origins.png';
import roastingSectionImage from '../../assets/images/about-us-bg-img-1.png';
import { cafe as CafeIcon } from 'ionicons/icons';

const firstBlendBgImg = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/create-first-blend.png')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const AboutUs: React.FC = () => {
  const history = useHistory();

  const handleRoute = () => {
    history.push('/blend');
  };
  return (
    <IonPage className="aboutUs">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
      </Helmet>
      <IonContent>
        <Header />
        <section className="aboutUs__hero">
          <div className="shadowOverlay ctaWrapper">
            <HomePageHeader
              words={[
                {
                  word: 'Welcome to the world of',
                  color: '#CFB79F',
                },
                {
                  word: 'Bespoke coffee',
                  color: '#fff',
                },
              ]}
              line={false}
            />
          </div>

          <span className="about__hero-scroll">
            <span className="about__hero-scrollText">Scroll</span>
          </span>
        </section>

        <section className="aboutUs__section">
          <HomePageHeader
            words={[
              {
                word: 'What is',
                color: '#915A3C',
              },
              {
                word: 'Coffee Blending?',
                color: '#1F4E46',
              },
            ]}
            line={true}
          />
          <IonRow className="aboutUs__whatis">
            <IonCol sizeXs="12" sizeMd="6">
              <IonText>
                Have you found yourself wondering what it would be like to take
                a sip of coffee that you created and blended? A sip of your own
                handcrafted dark roast with just enough of the right flavours to
                create a sinfully tasteful cup of coffee where you are savoring
                every sip? Well, you will be delighted to know that Blendly
                allows you to create your own brands and blends from the comfort
                of your home.
              </IonText>
            </IonCol>
            <IonCol sizeXs="12" sizeMd="6">
              <IonText>
                With taste matching where you can match the taste of any of your
                favourite coffee brands and cupping notes that allows you to use
                a taste chart to see which flavours you want in your blend,
                combined with the technology we offer; you can create and blend
                as many coffees as your heart desires.
              </IonText>
            </IonCol>
          </IonRow>
        </section>

        <section className="about__section-bgImage">
          <section className="aboutUs__section">
            <HomePageHeader
              words={[
                {
                  word: 'How to create a',
                  color: '#915A3C',
                },
                {
                  word: 'Coffee Blend?',
                  color: '#1F4E46',
                },
              ]}
              line={true}
            />
            <IonRow className="aboutUs__whatis">
              <IonCol sizeXs="12" sizeMd="6">
                <h6>Step 1</h6>
                <p className="bold">
                  <a href="/blend" className="bold">
                    Select your beans
                  </a>{' '}
                  harvested from the world.
                </p>
                <p>
                  Like a fine wine or an excellent single malt whisky, each
                  blend of coffee has unique, specific qualities in its flavour,
                  aroma, body and acidity.
                </p>
              </IonCol>
              <IonCol sizeXs="12" sizeMd="6">
                <h6>Step 2</h6>
                <p className="bold">Select your preferred grind and roast.</p>
                <p>
                  In order to help you select the finest blend to suit your
                  specific taste, mood or occasion we have created a unique
                  Coffee Tasting and Profiling System (CTAPS).
                </p>
              </IonCol>
            </IonRow>
            <IonRow className="aboutUs__whatis">
              <IonCol sizeXs="12" sizeMd="6">
                <h6>Step 3</h6>
                <p className="bold">
                  Select your <a href="/wholesale">Barista plan.</a>
                </p>
                <p>
                  Our Wholesalers packages allow you to manage high volume
                  coffee in the most complex supply chains our solutions are
                  tailored to allow you to keep your coffee fresh and secure as
                  well as for you develop value.
                </p>
              </IonCol>
              <IonCol sizeXs="12" sizeMd="6">
                <h6>Step 4</h6>
                <p className="bold">We deliver it right to your doorsteps.</p>
                <p>
                  At Blendly, you can purchase the highest quality coffee beans
                  then employ the expertise of the world's finest coffee
                  roasters to produce the best tasting coffee, roasted to order
                  and delivered to you in 24 hours.
                </p>
              </IonCol>
            </IonRow>
          </section>
        </section>

        <section className="about__coffeeRoasting">
          <div className="about__coffeeRoastingImage">
            <img src={roastingSectionImage} alt="roasting section" />
          </div>

          <IonRow className="about__coffeeRoasting-row aboutUs__whatis">
            <IonCol sizeXs="12" sizeMd="6">
              <h3 className="about__info-title">120</h3>
              <h4 className="about__info-light-subheading">Beans</h4>
              <p className="about__info-light-text">
                We source 120 different beans.
              </p>
            </IonCol>
            <IonCol sizeXs="12" sizeMd="6">
              <h3 className="about__info-title">105</h3>
              <h4 className="about__info-light-subheading">Flavors</h4>
              <p className="about__info-light-text">
                We offer over 100 different flavors you can choose from.
              </p>
            </IonCol>
            <IonCol sizeXs="12" sizeMd="6">
              <h3 className="about__info-title">102</h3>
              <h4 className="about__info-light-subheading">Roasting Styles</h4>
              <p className="about__info-light-text">
                Choose from over 100 roasting styles.
              </p>
            </IonCol>
            <IonCol sizeXs="12" sizeMd="6">
              <h3 className="about__info-title">180</h3>
              <h4 className="about__info-light-subheading">Subscribers</h4>
              <p className="about__info-light-text">
                We have 180+ monthly Barista subscribers.
              </p>
            </IonCol>
          </IonRow>
        </section>

        <section className="about__coffeeOrigins">
          <HomePageHeader
            words={[
              {
                word: 'Coffee',
                color: '#915A3C',
              },
              {
                word: 'Origins',
                color: '#1F4E46',
              },
            ]}
            line={true}
          />

          <IonRow className="about__coffeeOrigins-row aboutUs__whatis">
            <IonCol sizeXs="12" sizeMd="12">
              <p className="textLeft">
                The rise in speciality coffee culture is widely attributed to a
                growing number of artisan roasters and specialist independent
                coffee shops that are reinvigorating the coffee scene and
                leading the way into the fourth wave of coffee culture, bringing
                with them an exciting range of single origin and micro-lot
                coffees that are batch roasted for optimum flavour.
              </p>
            </IonCol>
          </IonRow>

          <div className="about__coffeeOrigins-map">
            <img src={worldMapImage} alt="" />
          </div>
        </section>

        <div className="about__firstBlend" style={firstBlendBgImg}>
          <div className="about__firstBlend-content">
            <div className="about__firstBlend-adcopy">
              <HomePageHeader
                words={[
                  {
                    word: 'Your all set',
                    color: '#CFB79F',
                  },
                  {
                    word: 'Create your first blend',
                    color: '#fff',
                  },
                ]}
                line={false}
              />
            </div>

            <IonButton className="warning" onClick={handleRoute}>
              <IonIcon icon={CafeIcon} slot="start" />
              <span>Create Blend</span>
            </IonButton>
          </div>
        </div>

        {/* <section className="about__section about__section-bgImage">
          <MemberForm />
        </section> */}

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default AboutUs;
