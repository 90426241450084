// Ionic Components
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonRouterLink,
  IonIcon,
} from "@ionic/react";

// Ionic Icons
import {
  logoTwitter,
  logoFacebook,
  logoInstagram,
  logoVimeo,
} from "ionicons/icons";

import TopUp from "../../pages/UserProfile/TopUp/TopUp";
import userProfileContext from "../../context/UserProfile/userProfileContext";
// import { useHistory } from "react-router";
import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import config from "../../config";

// Styling
import "./Footer.css";

export const Footer: React.FC = () => {
  // const history = useHistory();

  // const handleRoute = () =>{
  //   history.push("/profile/TopUp");
  // }

  // Initialize State
  const [emailNewsLetter, setEmailNewsLetter] = useState("");
  const { setActiveTab } = useContext(userProfileContext);

  const renderSocialIcons = () => {
    let socials = [
      { icon: logoTwitter, link: "https://twitter.com/BlendlyCoffee" },
      { icon: logoFacebook, link: "https://www.facebook.com/blendlyuk" },
      { icon: logoInstagram, link: "https://www.instagram.com/blendlycoffee/" },
      { icon: logoVimeo, link: "https://vimeo.com/search?q=BlendlyCoffee" },
    ];

    return socials.map((social) => (
      <li key={social.link}>
        <a href={social.link} target="_blank" rel="noreferrer">
          <IonIcon icon={social.icon} />
        </a>
      </li>
    ));
  };

  const newsLetterInputChange = (e: any) => {
    const { value } = e.target;

    setEmailNewsLetter(value);
  };

  const onNewsLetterSubmit = (e: any) => {
    e.preventDefault();
    axios
      .post(`${config.apiURL}/newsletter-subscribers`, {
        email: emailNewsLetter,
      })
      .then((res) => {
        alert("You're now subscribed to our news and updates");
        setEmailNewsLetter("");
      })
      .catch((err) => alert(err.response.data.message));
  };

  return (
    <IonRow className="footer">
      <div className="footer__content">
        <IonGrid>
          <IonRow>
            <IonCol
              className="footer__column"
              sizeXs="12"
              sizeSm="6"
              sizeMd="6"
              sizeLg="3"
            >
              <h6>Navigation</h6>
              <ul>
                <li>
                  <IonRouterLink routerLink="/">Home</IonRouterLink>
                </li>
                <li>
                  <IonRouterLink routerLink="/aboutus">About Us</IonRouterLink>
                </li>
                <li>
                  <IonRouterLink routerLink="/shop">Shop</IonRouterLink>
                </li>
                <li>
                  <IonRouterLink routerLink="/wholesale">
                    Wholesale
                  </IonRouterLink>
                </li>
                <li>
                  <IonRouterLink routerLink="/blend">Blend</IonRouterLink>
                </li>
                <li>
                  <IonRouterLink routerLink="/tastematch">Brand Search</IonRouterLink>
                </li>
                <li>
                  <IonRouterLink routerLink="/cart">Cart</IonRouterLink>
                </li>
                <li>
                  <IonRouterLink routerLink="/cuppingnotes">
                    Cupping Notes
                  </IonRouterLink>
                </li>
                <li>
                  <IonRouterLink routerLink="/subscription">
                    Subscription
                  </IonRouterLink>
                </li>
                {/* <li>
                  <IonRouterLink routerLink="#">
                    Barista Distributor
                  </IonRouterLink>
                </li> */}
                {/* <li>
                  <IonRouterLink routerLink="#">Coffee Blends</IonRouterLink>
                </li> */}
                <li>
                  <a href="/blog">Blog</a>
                </li>
              </ul>
            </IonCol>

            <IonCol
              className="footer__column"
              sizeXs="12"
              sizeSm="6"
              sizeMd="6"
              sizeLg="3"
            >
              <h6>More Info</h6>
              <ul>
                <li>
                  <IonRouterLink routerLink="/faqs">FAQs</IonRouterLink>
                </li>
                <li>
                  <a
                    href="https://goo.gl/forms/PkG6T5M2rL00vOGz1"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Feedback
                  </a>
                </li>
                <li>
                  <a
                    href="https://goo.gl/forms/7JejwuqUlfZbsM3A3"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Refer-a-friend
                  </a>
                </li>
                <li>
                  <IonRouterLink routerLink="/terms">
                    Terms and Conditions
                  </IonRouterLink>
                </li>
                <li>
                  <IonRouterLink routerLink="/returnpolicy">
                    Return Policy
                  </IonRouterLink>
                </li>
                <li>
                  <IonRouterLink routerLink="/privacypolicy">
                    Privacy Policy
                  </IonRouterLink>
                </li>
                <li>
                  <IonRouterLink
                    className="pointer"
                    routerLink="/profile"
                    onClick={() => setActiveTab(3)}
                  >
                    Top-up
                  </IonRouterLink>
                </li>
                {/* <li>
                  <IonRouterLink routerLink="#">
                    International Coffee Day
                  </IonRouterLink>
                </li> */}
              </ul>
            </IonCol>

            <IonCol
              className="footer__column"
              sizeXs="12"
              sizeSm="6"
              sizeMd="6"
              sizeLg="3"
            >
              <h6>Contact Details</h6>
              <ul>
                <li>
                  <IonRouterLink href="tel:03333441232">
                    0333 344 1232
                  </IonRouterLink>
                </li>
                <li>&nbsp;</li>
                <li>
                  <IonRouterLink href="mailto:sales@blendly.co.uk">
                    sales@blendly.co.uk
                  </IonRouterLink>
                </li>
                <li>&nbsp;</li>
                <li>
                  5 Hay Street Perth,
                  <br />
                  Perthshire and Kinross
                  <br />
                  PH1 5HS
                  <br />
                  United Kingdom
                </li>
              </ul>
            </IonCol>

            <IonCol
              className="footer__column"
              sizeXs="12"
              sizeSm="6"
              sizeMd="6"
              sizeLg="3"
            >
              <h6>Connect With Us</h6>
              <ul className="footer__socialLinks">{renderSocialIcons()}</ul>

              <h6>Newsletter</h6>
              <ul>
                <li>
                  Get all our latest news, offers, new beans, an update on new
                  blends
                </li>
                <li>
                  <div className="footer__newsleter">
                    <form onSubmit={onNewsLetterSubmit}>
                      <IonInput
                        className="footer__newsleter-input"
                        placeholder="Sign up here with your email"
                        type="email"
                        onIonChange={newsLetterInputChange}
                        value={emailNewsLetter}
                        required
                      ></IonInput>
                      <IonButton
                        className="warning footer__newsleter-button"
                        type="submit"
                      >
                        Sign up
                      </IonButton>
                    </form>
                  </div>
                </li>
                <li>
                  <img
                    src="assets/images/footer-paymentprocessors.png"
                    alt="footer payment processors"
                  />
                </li>
              </ul>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonRow>
  );
};
