import { useState } from "react";
import "./CommunityTabs.css";
import OrderContainer from "../../pages/OrderDetails/OrderContainer/OrderContainer";

const CommunityTabs = () => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="community-tabs">
      <div className="community-tabs__container">
        <div className="community-tabs__tabnav">
          {/* <div className="community-tabs__tab-header">
            <span>Community Builder</span>
          </div> */}
          <div
            className={`community-tabs__tab ${activeTab === 1 && "active"}`}
            onClick={() => {
              setActiveTab(1);
            }}
          >
            Order History
          </div>
        </div>
      </div>

      {activeTab === 1 && <OrderContainer />}
    </div>
  );
};

export default CommunityTabs;
