import { useState, useReducer } from 'react';
import CheckoutContext from './checkoutContext';
import checkoutReducer from './checkoutReducer';
import * as actionTypes from './action-types.js';
import axios from 'axios';
import config from '../../config';

const defaultObj = {
  first_name: '',
  last_name: '',
  phone: '',
  company_name: '',
  address: '',
  country_id: '',
  city: '',
  province: '',
  zip_code: '',
};

const CheckoutState = (props) => {
  const initialCheckoutState = {
    dynamicallyPricedOrder: null,
    shippingInfo: null,
    billingInfo: null,
    paymentMethod: 1,
    loading: false,
    error: null,
  };

  const [state, dispatch] = useReducer(checkoutReducer, initialCheckoutState);
  const [countriesData, setCountriesData] = useState(null);
  const [checkoutStep, setCheckoutStep] = useState(1);
  const [shippingIsSame, setShippingIsSame] = useState(false);
  const [shippingOption, setShippingOption] = useState(1);
  const [guestEmail, setGuestEmail] = useState('');

  const fetchDynamicallyPricedOrderData = async () => {
    try {
      dispatch({ type: actionTypes.DYNAMIC_ORDER_PRICES_LOADING });

      let token = localStorage.getItem('blendlyAuthToken') || null;
      let cartID = localStorage.getItem('blendlyCartId') || null;

      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          token: token,
        },
      };

      const { data: cartBlends } = await axios.get(
        `${config.apiURL}/carts/${cartID}/cart-blends`,
        axiosConfig
      );

      let formatedCartBlends = cartBlends.map((el) => {
        return {
          bagSizeId: el.bag_size_id,
          bagTypeId: el.bag_type_id,
          blendId: el.blend_id,
          certificationId: el.certification_id,
          description: el.description,
          grindId: el.grind_id,
          id: el.id,
          labelDesignId: 58,
          name: el.name,
          quantity: el.quantity,
          roastId: el.roast_id,
        };
      });

      const dynamicEndpointArr = cartBlends.map((el) => {
        let stringEndpoint = `${config.apiURL}/cart-blends/${el.id}/cart-blend-recipes`;

        return axios.get(stringEndpoint);
      });

      const allRes = await axios.all(dynamicEndpointArr);

      let tempArr = allRes.map((response) => {
        const formatedEl = response.data.map((el) => {
          return {
            beanId: el.bean_id,
            cartBlendId: el.cart_blend_id,
            id: el.id,
            percentage: el.percentage,
          };
        });

        return formatedEl;
      });

      let formatedAllRes = tempArr.reduce((acc, curr) => [...acc, ...curr], []);

      let bodyObj = {
        asBaristaDistributor: 0,
        cartBlendRecipes: formatedAllRes,
        cartBlends: formatedCartBlends,
        customerId: null,
        discountVoucherCode: null,
        shippingOptionId: shippingOption,
      };

      const res = await axios.post(
        `${config.apiURL}/dynamically-priced-orders`,
        bodyObj,
        axiosConfig
      );

      dispatch({
        type: actionTypes.DYNAMIC_ORDER_PRICES_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: actionTypes.DYNAMIC_ORDER_PRICES_ERROR,
        payload: 'Error Occured',
      });
    }
  };

  const setBillingInfo = (infoObj) => {
    dispatch({
      type: actionTypes.SET_BILLING_INFO,
      payload: infoObj,
    });
  };

  const updateBillingInfo = ({ key, value }) => {
    dispatch({
      type: actionTypes.UPDATE_BILLING_INFO,
      payload: { key, value },
    });
  };

  const setShippingInfo = (infoObj = defaultObj) => {
    dispatch({
      type: actionTypes.SET_SHIPPING_INFO,
      payload: infoObj,
    });
  };

  const updateShippingInfo = ({ key, value }) => {
    dispatch({
      type: actionTypes.UPDATE_SHIPPING_INFO,
      payload: { key, value },
    });
  };

  const setCheckoutError = (msg) => {
    dispatch({
      type: actionTypes.SET_CHECKOUT_ERROR,
      payload: msg,
    });
  };

  const clearError = () => {
    dispatch({
      type: actionTypes.CLEAR_CHECKOUT_ERROR_STATE,
    });
  };

  return (
    <CheckoutContext.Provider
      value={{
        dynamicallyPricedOrder: state.dynamicallyPricedOrder,
        shippingInfo: state.shippingInfo,
        billingInfo: state.billingInfo,
        loading: state.loading,
        error: state.error,
        shippingOption,
        shippingIsSame,
        checkoutStep,
        countriesData,
        guestEmail,
        setGuestEmail,
        fetchDynamicallyPricedOrderData,
        setBillingInfo,
        updateBillingInfo,
        setShippingIsSame,
        setShippingInfo,
        updateShippingInfo,
        setShippingOption,
        setCheckoutStep,
        clearError,
        setCheckoutError,
        setCountriesData,
      }}
    >
      {props.children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutState;
