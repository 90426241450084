import { useEffect, useState, useContext } from 'react';

// 3party
import axios from 'axios';

// Global State
import CuppingNotesContext from '../../../../context/CuppingNotes/cuppingNotesContext';

// Ionic Components
import { IonItem, IonRadio, IonRadioGroup, IonSpinner } from '@ionic/react';

// Custom Functions
import currencyFormatter from '../../../../helpers/currencyFormatter';
import config from '../../../../config';

// Styles
import './DynamicallyPricedBlends.css';

interface Props {
  bagSize: number;
  setBagSize: (x: number) => void;
}

const DynamicallyPricedBlends: React.FC<Props> = ({ bagSize, setBagSize }) => {
  const [prices, setPrices] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const cuppingNotesContext = useContext(CuppingNotesContext);
  const { selectedNote, currentCuppingNote } = cuppingNotesContext;

  useEffect(() => {
    setLoading(true);

    let data = selectedNote || currentCuppingNote;

    let obj: any = {
      quantity: 1,
      roastId: data.roast_id,
      certificationId: data.certification_id,
    };

    if (data.blend_recipes) {
      const formattedBlendRecipesArr = data.blend_recipes.map((x: any) => {
        return {
          beanId: x.bean_id,
          percentage: x.percentage,
        };
      });

      obj.blendRecipes = formattedBlendRecipesArr;
    } else {
      obj.blendRecipes = [
        {
          beanId: data.id,
          percentage: 100,
        },
      ];
    }

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        token: localStorage.getItem('blendlyAuthToken'),
      },
    };

    // Finding Data for 250g bag
    obj.bagSizeId = 3;
    const requestOne = axios.post(
      `${config.apiURL}/dynamically-priced-blends`,
      obj,
      axiosConfig
    );

    obj.bagSizeId = 5;
    const requestTwo = axios.post(
      `${config.apiURL}/dynamically-priced-blends`,
      obj,
      axiosConfig
    );

    obj.bagSizeId = 7;
    const requestThree = axios.post(
      `${config.apiURL}/dynamically-priced-blends`,
      obj,
      axiosConfig
    );

    axios
      .all([requestOne, requestTwo, requestThree])
      .then((res: any) => {
        setPrices(res);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });

    return () => {};
  }, [selectedNote, currentCuppingNote]);

  if (loading) {
    return (
      <div className="dynamicallyPricedBlends__loader">
        <IonSpinner />
      </div>
    );
  }

  if (error) {
    return <p>Error Occured</p>;
  }

  return (
    <div className="dynamicallyPriceBlends">
      <IonRadioGroup
        value={bagSize}
        onIonChange={(e) => setBagSize(e.detail.value)}
      >
        <IonItem className="graphingSection__listitem">
          <div>
            <p>250 grams</p>
            <p>{currencyFormatter(prices && prices[0].data.total_price)}</p>
          </div>
          <IonRadio
            style={{
              '--border-radius': '0%',
              '--inner-border-radius': '0%',
            }}
            slot="start"
            value={3}
          />
        </IonItem>
        <IonItem className="graphingSection__listitem">
          <div>
            <p>500 grams</p>
            <p>{currencyFormatter(prices && prices[1].data.total_price)}</p>
          </div>
          <IonRadio
            style={{
              '--border-radius': '0%',
              '--inner-border-radius': '0%',
            }}
            slot="start"
            value={5}
          />
        </IonItem>
        <IonItem className="graphingSection__listitem">
          <div>
            <p>1 kilograms</p>
            <p>{currencyFormatter(prices && prices[2].data.total_price)}</p>
          </div>
          <IonRadio
            style={{
              '--border-radius': '0%',
              '--inner-border-radius': '0%',
            }}
            slot="start"
            value={7}
          />
        </IonItem>
      </IonRadioGroup>
    </div>
  );
};

export default DynamicallyPricedBlends;
