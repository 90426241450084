import {
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
} from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import userProfileContext from '../../../../context/UserProfile/userProfileContext';
import axios from 'axios';
import './ChangePasswordPage.css';
import config from '../../../../config';
import { useLocation } from 'react-router-dom';
import '../FormStyles.css';

type InputEventType = string | null | undefined | number;

const ChangePasswordPage: React.FC = () => {
  const blendlyAuthToken = localStorage.getItem('blendlyAuthToken');
  const { activeTab } = useContext(userProfileContext);

  if (blendlyAuthToken === null) {
    window.location.href = '/login';
  }

  const [oldPassword, setOldPassword] = useState<InputEventType>('');
  const [newPassword, setNewPassword] = useState<InputEventType>('');
  const [confirmedNewPassword, setConfirmedNewPassword] =
    useState<InputEventType>('');
  const [succesfulMsg, setMessage] = useState<InputEventType>('');
  const [errorMsg, setError] = useState<InputEventType>('');
  const [binaryNumber, setBinary] = useState<InputEventType>();

  const onChangeField = (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case 'oldPassword':
        setOldPassword(value);
        break;
      case 'newPassword':
        setNewPassword(value);
        break;
      case 'confirmPassword':
        setConfirmedNewPassword(value);
        break;
      default:
        break;
    }
  };

  // Form Submit
  const onFormSubmit = (e: any) => {
    e.preventDefault();
    e.target.reset();
    setTimeout(() => {
      setError('');
      setMessage('');
      setBinary(-1);
    }, 4000);

    if (oldPassword !== newPassword) {
      axios({
        method: 'put',
        url: `${config.apiURL}/customers/change-password?token=${blendlyAuthToken}`,
        data: {
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmedNewPassword: confirmedNewPassword,
        },
      })
        .then((res) => {
          //  alert("Password changed!")
          setMessage('Password changed!');
          setBinary(0);
          setError('');
        })
        .catch((err) => {
          //  alert("Something went wrong, Please try again.")
          setError('Something went wrong, Please try again.');
          setBinary(1);
          setMessage('');
        });
    } else {
      // alert("Current password cannot be same as new password");
      setError('Current password cannot be the same as new password.');
      setBinary(1);
      setMessage('');
    }
  };

  return (
    <section className="edit-forms change-password">
      <div className="edit-forms__container my-profile__container">
        <h4 className="form-title left-align">Update your password</h4>
        <form onSubmit={onFormSubmit} className="form no-margin">
          <IonGrid>
            <IonRow className="edit-forms__form__rows">
              <IonCol>
                <IonItem className="form-input">
                  <IonLabel position="floating" className="form-label">
                    Current password*
                  </IonLabel>
                  <IonInput
                    className="edit-forms__form-input"
                    type="password"
                    value={oldPassword}
                    onIonChange={onChangeField}
                    required
                    name="oldPassword"
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow className="edit-forms__form__rows">
              <IonCol>
                <IonItem className="form-input">
                  <IonLabel position="floating" className="form-label">
                    New password*
                  </IonLabel>
                  <IonInput
                    className="edit-forms__form-input"
                    type="password"
                    value={newPassword}
                    onIonChange={onChangeField}
                    required
                    name="newPassword"
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow className="edit-forms__form__rows">
              <IonCol>
                <IonItem className="form-input">
                  <IonLabel position="floating" className="form-label">
                    Confirm password*
                  </IonLabel>
                  <IonInput
                    className="edit-forms__form-input"
                    type="password"
                    value={confirmedNewPassword}
                    onIonChange={onChangeField}
                    required
                    name="confirmPassword"
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="edit-forms__form-buttons">
                <IonButton
                  className="edit-forms__form-submit"
                  type="submit"
                  color="#FDAB40"
                >
                  Update
                </IonButton>
              </IonCol>
            </IonRow>

            {/* Success and Error Messages */}
            <div className="edit-forms__messages">
              {binaryNumber === 0 ? (
                <p className="form-messages__success">{succesfulMsg}</p>
              ) : binaryNumber === 1 ? (
                <p className="form-messages__error">{errorMsg}</p>
              ) : null}
            </div>
          </IonGrid>
        </form>
      </div>
    </section>
  );
};

export default ChangePasswordPage;
