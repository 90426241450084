// Core React
import { useState, useContext, useEffect } from 'react';

// React Router Dom
import { useHistory } from 'react-router-dom';

// Global State
import BlendCreatorContext from '../../context/BlendCreator/blendCreatorContext';

// Ionic Components
import {
  IonAlert,
  IonButton,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';

// Styling
import './ChooseRoast.css';

interface Props {
  setBlendingStep: (x: number) => void;
}

const ChooseRoast: React.FC<Props> = ({ setBlendingStep }) => {
  const history = useHistory();

  const {
    blend_roastType,
    blend_grindType,
    setBlendRoastType,
    setBlendGrindType,
    fetchDynamicallyPricedBlend,
  } = useContext(BlendCreatorContext);

  const [showAlert1, setShowAlert1] = useState<boolean>(false);

  const onNextBlendStepHandler = () => {
    // Check if roast and grind is choosen
    if (blend_roastType.length === 0 || blend_grindType.length === 0) {
      setShowAlert1(true);
      return;
    }
    // Load step 2 view
    setBlendingStep(3);
  };

  const onBackBlendStepHandler = () => {
    // Check if blend_recipes percantages = 100%
    // Load step 2 view
    setBlendingStep(1);
  };

  useEffect(() => {
    fetchDynamicallyPricedBlend();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="chooseRoast">
      <p className="blendingBoard__text">Choose your grind and roast</p>
      <p className="blendingBoard__subtext">
        Grinds differ from unground whole beans to espressos. Roasting brings
        out the aroma and flavour locked inside the green coffee beans. Light
        roasts has the most fruity smell while dark roasts bring out the heavy
        body and bitterness in it.
      </p>

      <div className="chooseRoast__maincontainer">
        <IonItem>
          <IonLabel>Select your roast</IonLabel>
          <IonSelect
            value={blend_roastType}
            placeholder="Select One"
            onIonChange={(e: any) => setBlendRoastType(e.detail.value)}
          >
            <IonSelectOption className="chooseRoast__selectOption" value={1}>
              Light Medium
            </IonSelectOption>
            <IonSelectOption value={2}>Medium</IonSelectOption>
            <IonSelectOption value={3}>Light Italian</IonSelectOption>
            <IonSelectOption value={4}>Connoisseur</IonSelectOption>
            <IonSelectOption value={5}>Dark Italian</IonSelectOption>
            <IonSelectOption value={6}>Continental</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel>Select your grind</IonLabel>
          <IonSelect
            value={blend_grindType}
            placeholder="Select One"
            onIonChange={(e) => setBlendGrindType(e.detail.value)}
          >
            <IonSelectOption value={1}>
              General - <span>Medium, V60, Chemex, Drip, Siphon Brewer</span>
            </IonSelectOption>
            <IonSelectOption value={2}>
              Filter -{' '}
              <span>
                Medium Fine, Moka Pot, AeroPress, Stovepot, Coffee Filter
              </span>
            </IonSelectOption>
            <IonSelectOption value={3}>
              Whole Bean - <span>For grinding freshness</span>
            </IonSelectOption>
            <IonSelectOption value={4}>
              Espresso - <span>Fine, Espresso Machines, AeroPress</span>
            </IonSelectOption>
            <IonSelectOption value={5}>
              Cafetiêre - <span>Coarse, Cold Brew, Clever Dripper</span>
            </IonSelectOption>
          </IonSelect>
        </IonItem>
      </div>

      <div className="chooseRoast__actionBtns">
        <div className="blendpage__continueBtns">
          <IonButton
            onClick={onBackBlendStepHandler}
            className="blendpage__nextBtn secondary"
          >
            Back
          </IonButton>
          <IonButton
            onClick={onNextBlendStepHandler}
            className="blendpage__nextBtn primary"
          >
            Next
          </IonButton>
          {/* <IonButton
            onClick={() => history.push('/cart')}
            className="blendpage__nextBtn warning"
          >
            Checkout
          </IonButton> */}
        </div>
      </div>

      <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        cssClass="my-custom-class"
        header={'Alert'}
        message={'You have to select both roast and grind types'}
        buttons={['OK']}
      />
    </div>
  );
};

export default ChooseRoast;
