// Core React
import { useState, useContext, useEffect } from 'react';

// Global State
import checkoutContext from '../../context/Checkout/checkoutContext';
import userCartContext from '../../context/UserCart/userCartContext';

// Ionic Components
import { IonContent, IonPage, IonSpinner, IonToast } from '@ionic/react';

// React Router Dom
import { useLocation, useHistory } from 'react-router-dom';

// Custom Components
import { Footer } from '../../components/Layout/Footer';
import { Header } from '../../components/Layout/Header';
import CheckoutOrderSummary from '../../components/CheckoutOrderSummary/CheckoutOrderSummary';
import CheckoutBilling from '../../components/CheckoutBilling/CheckoutBilling';
import CheckoutShipping from '../../components/CheckoutShipping/CheckoutShipping';
import CheckoutPayment from '../../components/CheckoutPayment/CheckoutPayment';
import CheckoutComplete from '../../components/CheckoutComplete/CheckoutComplete';
import CheckoutProcessStepper from '../../components/CheckoutProcessStepper/CheckoutProcessStepper';
import CheckoutFailed from '../../components/CheckoutFailed/CheckoutFailed';

// Styling & Assets
import './Checkout.css';
import BlendlyLogo from '../../assets/images/blendly-logo.png';

import config from '../../config';

// Axios
import axios from 'axios';
import { Helmet } from 'react-helmet';

interface PayPal_Pay_Payload {
  paypalPaymentId: string | null;
  paypalToken: string | null;
  paypalPayerId: string | null;
}

const Checkout: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();

  // Global State
  const { loadUserCart } = useContext(userCartContext);
  const {
    fetchDynamicallyPricedOrderData,
    checkoutStep,
    setCheckoutStep,
    error,
    clearError,
    countriesData,
    setCountriesData,
    setCheckoutError,
  } = useContext(checkoutContext);

  // Local State
  const [executePayLoading, setExecutePayLoading] = useState(false);
  const [executePayError, setExecutePayError] = useState<string | null>(null);
  const [completedOrderId, setCompletedOrderId] = useState<any>(null);

  useEffect(() => {
    if (pathname === '/checkout') {
      fetchDynamicallyPricedOrderData();
      loadUserCart();
      setCheckoutStep(1);
    }

    if (pathname === '/checkout/complete') {
      let searchParams = new URLSearchParams(location.search);

      let payload: PayPal_Pay_Payload = {
        paypalPaymentId: searchParams.get('paymentId'),
        paypalToken: searchParams.get('token'),
        paypalPayerId: searchParams.get('PayerID'),
      };

      if (
        payload.paypalPaymentId &&
        payload.paypalPayerId &&
        payload.paypalToken
      ) {
        executePayment(payload);
      } else if (searchParams.get('orderId')) {
        loadUserCart();
        setCheckoutStep(5);
      } else {
        history.push('/');
      }
    }

    if (pathname === '/checkout/incomplete') {
      setCheckoutStep(6);
      setExecutePayError('You Order Placement Failed');
    }

    //eslint-disable-next-line
  }, [pathname, history]);

  useEffect(() => {
    const loadCountriesData = async () => {
      try {
        const { data } = await axios.get(`${config.apiURL}/countries`);

        setCountriesData(
          data.sort((a: any, b: any) =>
            a.display_name > b.display_name ? 1 : -1
          )
        );
      } catch (error: any) {
        console.error(error.response.data);
        setCheckoutError(error.response.data);
      }
    };

    if (!countriesData) {
      loadCountriesData();
    }

    // eslint-disable-next-line
  }, [countriesData]);

  const executePayment = async ({
    paypalPaymentId,
    paypalToken,
    paypalPayerId,
  }: PayPal_Pay_Payload) => {
    if (!paypalPaymentId || !paypalToken || !paypalPayerId) {
      return 'Please provide paymentId, token, payerId';
    }

    try {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          token: localStorage.getItem('blendlyAuthToken'),
        },
      };

      setExecutePayLoading(true);

      const res = await axios.put(
        `${config.apiURL}/orders/paypal-pay`,
        { paypalPaymentId, paypalToken, paypalPayerId },
        axiosConfig
      );

      loadUserCart();
      setCompletedOrderId(res.data.id);
      setExecutePayLoading(false);
      setCheckoutStep(5);
    } catch (error: any) {
      setExecutePayError(error.response.data.message);
      setCheckoutStep(6);
      setExecutePayLoading(false);
    }
  };

  return (
    <IonPage>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Checkout</title>
      </Helmet>
      <IonContent>
        <Header />

        <IonToast
          isOpen={error !== null}
          onDidDismiss={() => clearError()}
          message={error}
          duration={4000}
          color="danger"
        />

        {executePayLoading && (
          <div className="checkout__loadingPaymentIndicator">
            <div>
              <IonSpinner name="crescent" color="dark" />
              <p>We are processing your order</p>
              <img src={BlendlyLogo} alt="" />
            </div>
          </div>
        )}

        {!executePayLoading && checkoutStep < 5 && (
          <div className="checkout__container">
            <h2 className="checkout__title">Checkout</h2>

            <div>
              {checkoutStep < 5 && (
                <CheckoutProcessStepper
                  checkoutStep={checkoutStep}
                  setCheckoutStep={setCheckoutStep}
                />
              )}

              {checkoutStep === 1 && (
                <CheckoutOrderSummary setCheckoutStep={setCheckoutStep} />
              )}
              {checkoutStep === 2 && (
                <CheckoutBilling setCheckoutStep={setCheckoutStep} />
              )}
              {checkoutStep === 3 && (
                <CheckoutShipping setCheckoutStep={setCheckoutStep} />
              )}
              {checkoutStep === 4 && (
                <CheckoutPayment setCheckoutStep={setCheckoutStep} />
              )}
            </div>
          </div>
        )}

        {!executePayLoading && checkoutStep === 5 && (
          <CheckoutComplete
            setCheckoutStep={setCheckoutStep}
            completedOrder={completedOrderId}
          />
        )}

        {!executePayLoading && checkoutStep === 6 && (
          <CheckoutFailed errorMessage={executePayError} />
        )}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Checkout;
