import { useReducer } from 'react';
import UserCartContext from './userCartContext';
import userCartReducer from './userCartReducer';
import {
  ADD_TO_USERCART_SUCCESS,
  ADD_TO_USERCART_LOADING,
  ADD_TO_USERCART_FAIL,
  USER_CARTDATA_LOADING,
  USER_CARTDATA_SUCCESS,
  USER_CARTDATA_FAIL,
  REMOVE_FROM_USERCART_LOADING,
  REMOVE_FROM_USERCART_SUCCESS,
  REMOVE_FROM_USERCART_FAIL,
} from '../types';
import axios from 'axios';
import config from '../../config';

const UserCartState = (props) => {
  const initialUserCartState = {
    cart: [],
    cartPricingData: null,
    loading: false,
    error: null,
  };

  const [state, dispatch] = useReducer(userCartReducer, initialUserCartState);

  const addToCart = async (data) => {
    // Extract CartID and Auth Token from localstorage
    let cartId = localStorage.getItem('blendlyCartId') || '';
    let token = localStorage.getItem('blendlyAuthToken');

    // Dispatch Add to cart Loading
    dispatch({
      type: ADD_TO_USERCART_LOADING,
    });

    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        token: token,
      },
    };

    try {
      // Create a cart blend
      await axios.post(
        `${config.apiURL}/cart-blends`,
        { ...data, cartId: cartId },
        axiosConfig
      );

      // Get the cart pring data
      const res = await axios.get(
        `${config.apiURL}/carts/${cartId}`,
        axiosConfig
      );

      // Get all cart items
      const cartResponse = await axios.get(
        `${config.apiURL}/carts/${cartId}/cart-blends`,
        axiosConfig
      );

      // Dispatch cartItems and cartPricingData to Reducer
      dispatch({
        type: ADD_TO_USERCART_SUCCESS,
        payload: {
          cartItems: cartResponse.data,
          cartPricingData: res.data,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: ADD_TO_USERCART_FAIL,
        payload: error.response,
      });
    }
  };

  const removeFromCart = async (id) => {
    let token = localStorage.getItem('blendlyAuthToken');
    let cartId = localStorage.getItem('blendlyCartId');

    try {
      let axiosConfig = {
        params: {
          token: token,
        },
      };

      dispatch({
        type: REMOVE_FROM_USERCART_LOADING,
      });

      await axios.delete(`${config.apiURL}/cart-blends/${id}`, axiosConfig);

      const res = await axios.get(
        `${config.apiURL}/carts/${cartId}`,
        axiosConfig
      );

      const cartResponse = await axios.get(
        `${config.apiURL}/carts/${cartId}/cart-blends`,
        axiosConfig
      );

      dispatch({
        type: REMOVE_FROM_USERCART_SUCCESS,
        payload: {
          cartItems: cartResponse.data,
          cartPricingData: res.data,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: REMOVE_FROM_USERCART_FAIL,
      });
    }
  };

  const loadUserCart = async () => {
    const token = localStorage.getItem('blendlyAuthToken');
    const cartId = localStorage.getItem('blendlyCartId');

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        token: token,
      },
    };

    dispatch({
      type: USER_CARTDATA_LOADING,
    });

    try {
      const res = await axios.get(
        `${config.apiURL}/carts/${cartId}/cart-blends`,
        axiosConfig
      );

      const cartData = await axios.get(
        `${config.apiURL}/carts/${cartId}`,
        axiosConfig
      );

      dispatch({
        type: USER_CARTDATA_SUCCESS,
        payload: {
          cartItems: res.data,
          cartPricingData: cartData.data,
        },
      });
    } catch (error) {
      dispatch({
        type: USER_CARTDATA_FAIL,
        payload: error.response,
      });
    }
  };

  return (
    <UserCartContext.Provider
      value={{
        cart: state.cart,
        loading: state.loading,
        error: state.error,
        cartPricingData: state.cartPricingData,
        addToCart,
        loadUserCart,
        removeFromCart,
      }}
    >
      {props.children}
    </UserCartContext.Provider>
  );
};

export default UserCartState;
