import { IonButton, IonIcon } from '@ionic/react';
import BlendIcon from '../../assets/icons/blend_card_icon.svg';
import './CheckoutComplete.css';

interface Props {
  setCheckoutStep: (x: number) => void;
  completedOrder: any;
}

const CheckoutComplete: React.FC<Props> = ({
  setCheckoutStep,
  completedOrder,
}) => {
  return (
    <div className="checkoutComplete">
      <div className="checkoutComplete__icon">
        <IonIcon icon={BlendIcon} />
      </div>

      <h2 className="checkoutComplete__mainText">Your Order Has Been Placed</h2>

      <p className="checkoutComplete__smallText">
        Your payment has been completed
      </p>
      <p className="checkoutComplete__smallText">
        Thank you for choosing Blendly
      </p>

      <div className="checkoutComplete__mainCtaBtns">
        <IonButton
          className="warning"
          routerLink={`/profile/orders/${completedOrder}`}
        >
          View Order Again
        </IonButton>
        <IonButton className="warning">Barista Distributor</IonButton>
        <IonButton className="warning">View Our Blog</IonButton>
      </div>
    </div>
  );
};

export default CheckoutComplete;
