import { IonGrid, IonCol, IonRow, IonRouterLink } from "@ionic/react";
import React, { useContext } from "react";
import "./Shipping.css";
import orderDetailsContext from "../../../../context/OrderDetails/orderDetailsContext";

const Shipping: React.FC = () => {
  const {
    orderShipping,
    orderContries,
    orderShippingOptions,
    orderBlendDistributions,
    orderBlends,
  } = useContext(orderDetailsContext);

  const getCountry = (countryId: any) => {
    if (orderContries.length > 0) {
      const country = orderContries.filter((item: any) => {
        return item.id === countryId;
      });

      return country[0].display_name;
    }
    return <></>;
  };

  const getShippingOptions = (optionId: any) => {
    const option = orderShippingOptions.filter((item: any) => {
      return item.id === optionId;
    });

    return option[0].value;
  };

  const getShippingPacks = (id: any) => {
    const blends: any = [];
    const blendDistro = orderBlendDistributions.filter((item: any) => {
      return item.order_shipping_detail_id === id;
    });

    blendDistro.forEach((item: any) => {
      const filteredBlends = orderBlends.filter((el: any) => {
        return el.id === item.order_blend_id;
      });

      blends.push({
        ...filteredBlends[0],
        bagQuantity: item.quantity,
      });
    });

    return (
      <>
        {blends.map((item: any) => {
          const { id, ctaps_id, name, bagQuantity, weight } = item;
          return (
            <p key={id}>
              <IonRouterLink href={`/cuppingnotes/${ctaps_id}`}>
                {`[${ctaps_id}]`}
              </IonRouterLink>
              <span style={{ marginLeft: "5px" }}>{`${name} ${
                weight >= 1000 ? "1 kilogram" : weight + " grams"
              } X ${bagQuantity} bags`}</span>
            </p>
          );
        })}
      </>
    );
  };

  const getShippingDetails = () => {
    if (orderShipping.length > 0) {
      let count = 0;
      return (
        <IonCol>
          {orderShipping.map((item: any) => {
            return (
              <IonRow className="order-shipping__shipping-right" key={item.id}>
                {/* Shipping Details */}
                <IonCol className="order-shipping__shipping-details__container">
                  <p className="order-shipping__shipping-details__header">
                    {`Box ${(count = count + 1)} - ${getShippingOptions(
                      item.shipping_option_id
                    )}`}
                    <p>
                      {item.tracking_number
                        ? `Tracking #${item.tracking_number}`
                        : null}
                    </p>
                  </p>
                  <p>{`${item.first_name} ${item.last_name}`}</p>
                  <p>{item.company_name}</p>
                  <p>{item.address}</p>
                  <p>{`${item.city}, ${item.province} ${item.zip_code}`}</p>
                  <p>{getCountry(item.country_id)}</p>
                  <p>{item.phone}</p>
                </IonCol>
                {/* Packs */}
                <IonCol>
                  <p className="order-shipping__shipping-details__header">
                    Packs
                  </p>
                  {getShippingPacks(item.id)}
                </IonCol>
              </IonRow>
            );
          })}
        </IonCol>
      );
    }
  };
  return (
    <div className="order-shipping">
      <h4 className="order-shipping__header">Details</h4>
      {/* Shipping Details */}
      <IonGrid>
        <IonRow>
          <IonCol className="order-shipping__shipping-header title-text-color">
            Shipping Details
          </IonCol>
        </IonRow>
        <IonRow className="order-shipping__shipping-details">
          <IonCol className="order-shipping__box-count">
            Boxes{`(${orderShipping.length})`}
          </IonCol>
          {getShippingDetails()}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Shipping;
