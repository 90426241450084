import { useState, useReducer } from 'react';
import CuppingNotesContext from './cuppingNotesContext';
import cuppingNotesReducer from './cuppingNotesReducer';
import {
  SET_CURRENT_CUPPINGNOTE,
  CLEAR_CURRENT_CUPPINGNOTE,
  SET_SELECTED_NOTE,
  CUPPINGNOTE_DATA_SUCCESS,
  CUPPINGNOTE_DATA_LOADING,
} from '../types';
import axios from 'axios';
import config from '../../config';

const CuppingNotesState = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [notes, setNotes] = useState([]);
  const [notesPerPage, setNotesPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [toggleView, setToggleView] = useState(false);

  const initialCuppingNotesState = {
    cuppingNotes: null,
    selectedNote: null,
    currentCuppingNote: null,
    currentCuppingNoteBagPrices: null,
    loading: false,
    error: null,
  };

  const [state, dispatch] = useReducer(
    cuppingNotesReducer,
    initialCuppingNotesState
  );

  const setCurrentCuppingNote = (data) => {
    dispatch({
      type: SET_CURRENT_CUPPINGNOTE,
      payload: data,
    });
  };

  const clearCurrentCuppingNote = () => {
    dispatch({ type: CLEAR_CURRENT_CUPPINGNOTE });
  };

  const setSelectedNote = (data) => {
    dispatch({
      type: SET_SELECTED_NOTE,
      payload: data,
    });
  };

  const loadCuppingNotesData = async () => {
    dispatch({ type: CUPPINGNOTE_DATA_LOADING });

    const { beans, blends, grinds, roasts, countries, certifications } =
      await fetchCuppingNotesData();

    let newList = [...beans, ...blends];

    newList.forEach((listItem, idx) => {
      const grindId = listItem.grind_id;
      const roastId = listItem.roast_id;
      const countryId = listItem.country_id;
      const certificationId = listItem.certification_id;

      // Adding Certification Display Name to obj
      const certificationData = certifications.find(
        (cert) => cert.id === certificationId
      );
      newList[idx].certification_display_name = certificationData.display_name;

      const grindData = grinds.find((grind) => grind.id === grindId);
      const roastData = roasts.find((roast) => roast.id === roastId);

      newList[idx].grind_display_name = grindData.display_name;
      newList[idx].roast_display_name = roastData.display_name;

      if (listItem.origins) {
        const org = listItem.origins;

        const newOrigins = org.map((el) =>
          el.display_name ? el.display_name : ''
        );

        newList[idx].originsStrings = newOrigins;
      } else {
        const countryData = countries.find(
          (country) => country.id === countryId
        );

        if (countryData.display_name) {
          newList[idx].originsStrings = [countryData.display_name];
        }
      }

      if (listItem.blend_recipes) {
        const temp = listItem.blend_recipes.map((el) => {
          const beanData = beans.find((bean) => bean.id === el.bean_id);
          return {
            ...el,
            ...beanData,
          };
        });

        newList[idx].blend_recipes = temp;
      }
    });

    dispatch({ type: CUPPINGNOTE_DATA_SUCCESS, payload: newList });
  };

  return (
    <CuppingNotesContext.Provider
      value={{
        cuppingNotes: state.cuppingNotes,
        selectedNote: state.selectedNote,
        currentCuppingNote: state.currentCuppingNote,
        loading: state.loading,
        error: state.error,
        selectedView: state.selectedView,
        currentPage: currentPage,
        notes: notes,
        notesPerPage: notesPerPage,
        searchValue: searchValue,
        toggleView: toggleView,
        loadCuppingNotesData,
        setCurrentCuppingNote,
        clearCurrentCuppingNote,
        setSelectedNote,
        setCurrentPage,
        setNotes,
        setNotesPerPage,
        setSearchValue,
        setToggleView,
      }}
    >
      {props.children}
    </CuppingNotesContext.Provider>
  );
};

async function fetchCuppingNotesData() {
  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      token: localStorage.getItem('blendlyAuthToken'),
    },
  };

  const blends = axios.get(`${config.apiURL}/blends`, axiosConfig);
  const beans = axios.get(`${config.apiURL}/beans`, axiosConfig);
  const grinds = axios.get(`${config.apiURL}/grinds`, axiosConfig);
  const roasts = axios.get(`${config.apiURL}/roasts`, axiosConfig);
  const countries = axios.get(`${config.apiURL}/countries`, axiosConfig);
  const certifications = axios.get(
    `${config.apiURL}/certifications`,
    axiosConfig
  );

  const [
    blendsRes,
    beansRes,
    grindsRes,
    roastsRes,
    countriesRes,
    certificationRes,
  ] = await Promise.all([
    blends,
    beans,
    grinds,
    roasts,
    countries,
    certifications,
  ]);

  const data = {
    blends: blendsRes.data,
    beans: beansRes.data,
    grinds: grindsRes.data,
    roasts: roastsRes.data,
    countries: countriesRes.data,
    certifications: certificationRes.data,
  };

  return data;
}

export default CuppingNotesState;
