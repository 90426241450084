// Core React
// import { useContext } from 'react';

// Global State
// import UserCartContext from '../../context/UserCart/userCartContext';

// Ionic Components
import { IonIcon } from '@ionic/react';

// Ionic Icons
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';

// Styling
import './BagQuantityAdjuster.css';

interface Props {
  cartItem: any;
}

const BagQuantityAdjuster: React.FC<Props> = ({ cartItem }) => {
  // Extract From Global User Context
  // const {} = useContext(UserCartContext);

  return (
    <div className="bagQuantityAdjuster">
      <div className="bagQuantityAdjuster__arrow">
        <IonIcon icon={chevronBackOutline} />
      </div>
      <div className="bagQuantityAdjuster__count">{cartItem.quantity}</div>
      <div className="bagQuantityAdjuster__arrow">
        <IonIcon icon={chevronForwardOutline} />
      </div>
    </div>
  );
};

export default BagQuantityAdjuster;
