import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";
import moment from "moment";
import "./Logs.css";
import orderDetailsContext from "../../../../context/OrderDetails/orderDetailsContext";
import { reverse } from "dns";

const Logs: React.FC = () => {
  const { orderLogs } = useContext(orderDetailsContext);

  const logs = () => {
    return (
      <>
        {orderLogs
          .map((item: any) => {
            return (
              <p key={item.id}>
                <span>
                  {moment(item.created_at).format("MMMM D, YYYY h:mm:ss a")}:
                </span>
                <strong className="order-logs__action">{item.action}</strong>
              </p>
            );
          })
          .reverse()}
      </>
    );
  };
  return (
    <div className="order-logs">
      <h4 className="order-logs__header">Log Details</h4>
      <IonGrid>
        <IonRow>
          <IonCol>{logs()}</IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Logs;
