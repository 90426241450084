import {
  IonButton,
  IonCol,
  IonGrid,
  IonImg,
  IonInput,
  IonLoading,
  IonRow,
  IonThumbnail,
  useIonAlert,
} from "@ionic/react";
import React, { useState } from "react";
import axios from "axios";
import "./TopUp.css";
import config from "../../../config";

const TopUp: React.FC = () => {
  // Initial State
  const barcodeNumInit = null;
  const giftCardCodeInit = null;
  const errorMessageInit = null;
  const successMessageInit: any = null;
  // Initialize State
  const [barcodeNum, setbarcodeNum] = useState(barcodeNumInit);
  const [giftCardCode, setgiftCardCode] = useState(giftCardCodeInit);
  const [errorMessage, seterrorMessage] = useState(errorMessageInit);
  const [successMessage, setSuccessMessage] = useState(successMessageInit);
  const [isLoading, setIsLoading] = useState(false);
  const [present] = useIonAlert();

  // Get Auth Token
  const blendlyAuthToken = window.localStorage.getItem("blendlyAuthToken");

  const onInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "barcode-number":
        setbarcodeNum(value);
        break;
      case "gift-card-number":
        setgiftCardCode(value);
        break;
      default:
        break;
    }

    // Remove Messages on fields change
    seterrorMessage(errorMessageInit);
    setSuccessMessage(successMessageInit);
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .put(
        `${config.apiURL}/gift-card-codes/${giftCardCode}/${barcodeNum}/test?token=${blendlyAuthToken}`
      )
      .then((res) => {
        const { code } = res.data;
        const { amount, gtin } = res.data.gift_card;
        setIsLoading(false);
        confirmTopUp(code, gtin, amount);
      })
      .catch((error) => {
        setIsLoading(false);
        seterrorMessage(error.response.data.message);
      });
  };

  const confirmTopUp = (code: any, gtin: any, amount: any) => {
    present({
      cssClass: "my-css",
      header: "Alert",
      message: `Are you sure you want to top up your account with £${amount}`,
      buttons: [
        "Cancel",
        {
          text: "Yes",
          handler: (d) => {
            setIsLoading(true);
            topUpAccount(code, gtin);
          },
        },
      ],
    });
  };

  const topUpAccount = (code: any, gtin: any) => {
    axios
      .post(`${config.apiURL}/top-ups?token=${blendlyAuthToken}`, {
        code: code,
        gtin: gtin,
      })
      .then((res) => {
        const { amount } = res.data.gift_card_code.gift_card;
        setIsLoading(false);
        setSuccessMessage(
          `Top-up Successful! You account has been topped up with £${amount}`
        );
      })
      .catch((err) => {
        setIsLoading(false);
        seterrorMessage(err.response.data.message);
      });
  };

  return (
    <div className="topup">
      <div className="topup__container">
        <IonGrid>
          <IonRow className="topup__row">
            <IonCol>
              <h3 className="topup__header">Top-up using your gift card</h3>
              <p className="topup__lead-text">
                Add more coffee credits to your account now
              </p>
              {/* Mini Form */}
              <form className="topup__form" onSubmit={onSubmitForm}>
                <IonInput
                  type="text"
                  placeholder="Barcode Number*"
                  required
                  name="barcode-number"
                  onIonChange={onInputChange}
                ></IonInput>
                <IonInput
                  type="text"
                  placeholder="Gift Card Number*"
                  required
                  name="gift-card-number"
                  onIonChange={onInputChange}
                ></IonInput>
                <IonButton type="submit">Top Up</IonButton>
                {/* loader */}
                <IonLoading isOpen={isLoading} />
                {/* Success and Error messages */}
                <div className="topup__form__success-messages">
                  {successMessage ? <p>{successMessage}</p> : null}
                </div>
                <div className="topup__form__error-messages">
                  {errorMessage ? <p>{errorMessage}</p> : null}
                </div>
              </form>
              {/* Chat Function */}
              <div className="topup__chat-text">
                <IonThumbnail>
                  <IonImg
                    src={`${process.env.PUBLIC_URL}/assets/images/open-chat.png`}
                  ></IonImg>
                </IonThumbnail>
                <p>If you need any help, talk with us on the live chat.</p>
              </div>
              <IonButton className="topup__chat-button">Start a Chat</IonButton>
            </IonCol>
            {/* Images on the right */}
            <IonCol>
              <IonRow className="topup__right-images">
                <IonCol>
                  <IonImg
                    src={`${process.env.PUBLIC_URL}/assets/images/front.png`}
                  />
                </IonCol>
                <IonCol>
                  <IonImg
                    src={`${process.env.PUBLIC_URL}/assets/images/guide.png`}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default TopUp;
