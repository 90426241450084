import { IonIcon, IonRouterLink } from '@ionic/react';
import { cart } from 'ionicons/icons';
import './EmptyCartScreen.css';

const EmptyCartScreen = () => {
  return (
    <div className="emptyCartScreen">
      <h2>Empty Cart</h2>
      <IonIcon icon={cart} size="large" />
      <p>Your cart is empty</p>
      <p>You don't have any blends on your cart</p>

      <IonRouterLink
        className="emptyCartScreen__blendNowBtn"
        routerLink="/blend"
      >
        Start blending now
      </IonRouterLink>
    </div>
  );
};

export default EmptyCartScreen;
