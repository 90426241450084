export const DYNAMIC_ORDER_PRICES_LOADING = 'DYNAMIC_ORDER_PRICES_LOADING';
export const DYNAMIC_ORDER_PRICES_SUCCESS = 'DYNAMIC_ORDER_PRICES_SUCCESS';
export const DYNAMIC_ORDER_PRICES_ERROR = 'DYNAMIC_ORDER_PRICES_ERROR';

export const SET_BILLING_INFO = 'SET_BILLING_INFO';
export const UPDATE_BILLING_INFO = 'UPDATE_BILLING_INFO';

export const SET_SHIPPING_INFO = 'SET_SHIPPING_INFO';
export const UPDATE_SHIPPING_INFO = 'UPDATE_SHIPPING_INFO';

export const SET_CHECKOUT_ERROR = 'SET_CHECKOUT_ERROR';
export const CLEAR_CHECKOUT_ERROR_STATE = 'CLEAR_CHECKOUT_ERROR_STATE';
