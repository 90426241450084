import {
  INFORMATION,
  SHIPPING,
  SHIPPING_OPTIONS,
  BLENDS,
  STATUSES,
  LOGS,
  BAG_SIZES,
  BAG_TYPES,
  COUNTRIES,
  PAYMENTS,
  PAYMENT_GATEWAYS,
  BLEND_RECIPES,
  BEANS,
  ROASTS,
  GRINDS,
  BLEND_ID,
  BLEND_DISTRIBUTIONS,
} from "../types";

const orderDetailsReducer = (state, action) => {
  switch (action.type) {
    case INFORMATION:
      return {
        ...state,
        orderInfo: action.payload,
      };
    case SHIPPING:
      return {
        ...state,
        orderShipping: action.payload,
      };
    case SHIPPING_OPTIONS:
      return {
        ...state,
        orderShippingOptions: action.payload,
      };
    case BLENDS:
      return {
        ...state,
        orderBlends: action.payload,
      };
    case BAG_SIZES:
      return {
        ...state,
        orderBagSizes: action.payload,
      };
    case BAG_TYPES:
      return {
        ...state,
        orderBagTypes: action.payload,
      };
    case COUNTRIES:
      return {
        ...state,
        orderContries: action.payload,
      };
    case STATUSES:
      return {
        ...state,
        orderStatus: action.payload,
      };
    case PAYMENTS:
      return {
        ...state,
        orderPayments: action.payload,
      };
    case PAYMENT_GATEWAYS:
      return {
        ...state,
        orderPaymentGateways: action.payload,
      };
    case BLEND_RECIPES:
      return {
        ...state,
        orderBlendRecipes: action.payload,
      };
    case BEANS:
      return {
        ...state,
        orderBeans: action.payload,
      };
    case ROASTS:
      return {
        ...state,
        orderRoasts: action.payload,
      };
    case GRINDS:
      return {
        ...state,
        orderGrinds: action.payload,
      };
    case BLEND_ID:
      return {
        ...state,
        orderBlendID: action.payload,
      };
    case BLEND_DISTRIBUTIONS:
      return {
        ...state,
        orderBlendDistributions: action.payload,
      };
    case LOGS:
      return {
        ...state,
        orderLogs: action.payload,
      };
    default:
      break;
  }
};

export default orderDetailsReducer;
