import HomePageHeader from '../SectionHeaders/HomePageHeader/HomePageHeader';
import TestimonialList from './TestimonialList';
import TestimonialCard from './TestimonialCard';
import './Testimonial.css';
import { IonSlide, IonSlides } from '@ionic/react';

interface Testimonial {
  id: string;
  color: string;
  name: string;
  image: string;
  rating: number;
  description: string;
  position: string;
}

const testimonials: Testimonial[] = [
  {
    id: 't1',
    color: 'primary-tint',
    name: 'Paul Flatts',
    image: '	https://blendly.co.uk/assets/images/testimonials/paul-flatts.jpg',
    rating: 4,
    description:
      'The company delivers a great service -- next day fresh coffee that you mix and blend according to taste and price. The coffee smell was bursting out the package before I opened it and the taste was superb.',
    position: 'div-center',
  },
  {
    id: 't2',
    color: 'secondary-tint',
    name: 'Jason de Jonge',
    image: '	https://blendly.co.uk/assets/images/testimonials/jason-de-jonge.jpg',
    rating: 3,
    description:
      'The Espresso Milano is strong, rich and smooth, a great cup of coffee and perfect in the morning or after a good meal. I would highly recommend it to anyone looking for a distinct taste.',
    position: 'div-left',
  },
  {
    id: 't3',
    color: 'secondary-shade',
    name: 'Marina Smith',
    image: 'https://blendly.co.uk/assets/images/miscellaneous/female.svg',
    rating: 5,
    description:
      'It is a superb network that can easily increase sales and create efficiency as well as retain the traditional values of quality coffee. The CTAPS allows scalable, customizable choice to customers in the blending distribution of coffee products, in a way that is in line with extended customer choice.',
    position: 'div-right',
  },
  {
    id: 't4',
    color: 'primary-shade',
    name: 'Paul Ramsey',
    image: 'https://blendly.co.uk/assets/images/testimonials/paul-ramsey.jpg',
    rating: 4,
    description:
      'I was able to match my current blend and the Blendly service was very good I must say. With little adjustments it could match my blends but my classico especially was very close. Great very impressed.',
    position: 'div-center',
  },
  {
    id: 't5',
    color: 'primary-shade',
    name: 'John Fines',
    image: '	https://blendly.co.uk/assets/images/miscellaneous/male.svg',
    rating: 4,
    description:
      'I started my home barista journey with an initial order from the pre-blended range. Living in Kirkcaldy and being a regular visitor to Cupcake Coffee Box, I decided this would be my first order. It did not disappoint, I chose to take the filter grind as I am a black coffee drinker. Fantastic service and I love the product.',
    position: 'div-center',
  },
];

const slideOpts = {
  initialSlide: 0,
  speed: 400,
};

export const Testimonials: React.FC = () => {
  return (
    <div className="testimonials">
      <HomePageHeader
        words={[
          { word: 'What our', color: '#915A3C' },
          { word: 'Customers say', color: '#1F4E46' },
        ]}
        line={true}
      />

      <div className="testimonials__listDesktop">
        <TestimonialList>
          {testimonials.map((testimonial) => (
            <TestimonialCard
              key={testimonial.id}
              id={parseInt(testimonial.id)}
              thumbnail={testimonial.image}
              name={testimonial.name}
              rating={testimonial.rating}
              content={testimonial.description}
            />
          ))}
        </TestimonialList>
      </div>

      <div className="testimonials__listMobile">
        <IonSlides pager={true} options={slideOpts}>
          {testimonials.map((testimonial, index) => (
            <IonSlide
              key={index}
              className={`sliderImage-${testimonial.id.toString()}`}
            >
              <TestimonialCard
                key={testimonial.id}
                id={parseInt(testimonial.id)}
                thumbnail={testimonial.image}
                name={testimonial.name}
                rating={testimonial.rating}
                content={testimonial.description}
              />
            </IonSlide>
          ))}
        </IonSlides>
      </div>
    </div>
  );
};
