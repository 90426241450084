import React, { useRef } from 'react';
import {
  IonContent,
  IonPage,
  IonButton,
  IonRouterLink,
  IonIcon,
} from '@ionic/react';
import { Header } from '../../components/Layout/Header';
import { Footer } from '../../components/Layout/Footer';
import '../TermsPolicyStyles.css';
import { Accordian } from '../../components/Accordian/accordian';
import { chevronUp } from 'ionicons/icons';
import { Helmet } from 'react-helmet';

const Terms: React.FC = () => {
  // Scroll to top functionality
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  return (
    <IonPage>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms</title>
      </Helmet>
      <IonContent ref={contentRef} scrollEvents={true}>
        <Header />
        <div
          id="top"
          className="terms-policy"
          style={{ paddingBottom: '50px' }}
        >
          <h1 className="terms-policy__header">
            Newbeans Terms &amp; Conditions of Sale
          </h1>
          <p
            className="terms-policy__lead-text"
            style={{ textAlign: 'center', marginBottom: '65px' }}
          >
            This Terms and Conditions consists of two sections. Please refer to
            the relevant section.
          </p>

          {/* SECTION 1 */}
          <p className="terms-policy__section-title">
            SECTION 1. TERMS AND CONDITIONS FOR CUSTOMERS
          </p>

          <div className="terms-policy__grid-items">
            <div>
              <p className="terms-policy__section-body">
                This agreement applies between you, the User of this Website,
                and Newbeans, the owner(s) of this Website. Your agreement to
                comply with and be bound by these Terms and Conditions is deemed
                to occur upon your first use of the Website. If you do not agree
                to be bound by these Terms and Conditions you should stop using
                the Website immediately.
              </p>

              <p className="terms-policy__section-body">
                No part of this Website is intended to constitute a contractual
                offer capable of acceptance. Your order constitutes a
                contractual offer and our acceptance of that offer is deemed to
                occur upon our sending a dispatch email to you indicating that
                your order has been fulfilled and is on its way to you.
              </p>
            </div>

            <div>
              <p className="terms-policy__section-body">
                These Terms of Use explain Newbeans.co.uk (“Newbeans” or “we” or
                “our”) policies governing the use of our services and website at
                blendly.co.uk (the “Site”). Please read these Terms of Use
                carefully before using our Site. By accessing, viewing or using
                our website, you agree to these Terms of Use, which incorporate
                our Privacy Policy. IF YOU DO NOT AGREE TO THESE TERMS OF USE,
                DO NOT USE THE WEBSITE. (SB)
              </p>
            </div>
          </div>

          <p className="terms-policy__section-header">
            1. <b>Definitions and Interpretation</b>
          </p>

          <p className="terms-policy__section-body">
            In this Agreement the following terms shall have the following
            meanings:
          </p>

          <br />

          <Accordian title="Account">
            <p className="terms-policy__section-body">
              means collectively the personal information, Payment Information
              and credentials used by Users to access Paid Content and / or any
              communications System on the Website;
            </p>
          </Accordian>
          <Accordian title="Carrier">
            <p className="terms-policy__section-body">
              means any third party responsible for transporting purchased Goods
              from our Premises to customers;
            </p>
          </Accordian>
          <Accordian title="Content">
            <p className="terms-policy__section-body">
              means any text, graphics, images, audio, video, software, data
              compilations and any other form of information capable of being
              stored in a computer that appears on or forms part of this
              Website;
            </p>
          </Accordian>
          <Accordian title="Goods">
            <p className="terms-policy__section-body">
              means any products that NEWBEANS advertises and / or makes
              available for sale through this Website;
            </p>
          </Accordian>
          <Accordian title="Newbeans">
            <p className="terms-policy__section-body">
              means NEWBEANS, Upper Floor, Unit 1 Cadzow Park, 82 Muir Street,
              Hamilton, ML3 6BJ, Scotland
            </p>
          </Accordian>
          <Accordian title="Service">
            <p className="terms-policy__section-body">
              means collectively any online facilities, tools, services or
              information that NEWBEANS makes available through the Website
              either now or in the future;
            </p>
          </Accordian>
          <Accordian title="Payment Information">
            <p className="terms-policy__section-body">
              means any details required for the purchase of Goods from this
              Website. This includes, but is not limited to, credit / debit card
              numbers, bank account numbers and sort codes;
            </p>
          </Accordian>
          <Accordian title="Purchase Information">
            <p className="terms-policy__section-body">
              means collectively any orders, invoices, dispatch notes, receipts
              or similar that may be in hard copy or electronic form;
            </p>
          </Accordian>
          <Accordian title="Premises">
            <p className="terms-policy__section-body">
              means our place(s) of business located at NEWBEANS, Upper Floor,
              Unit 1 Cadzow Park, 82 Muir Street, Hamilton, ML3 6BJ, Scotland;
            </p>
          </Accordian>
          <Accordian title="System">
            <p className="terms-policy__section-body">
              means any online communications infrastructure that NEWBEANS makes
              available through the Website either now or in the future. This
              includes, but is not limited to, web-based email, message boards,
              live chat facilities and email links;
            </p>
          </Accordian>
          <Accordian title="User / Users">
            <p className="terms-policy__section-body">
              means any third party that accesses the Website and is not
              employed by NEWBEANS and acting in the course of their employment;
              and
            </p>
          </Accordian>
          <Accordian title="Website">
            <p className="terms-policy__section-body">
              means the website that you are currently using (blendly.co.uk) and
              any sub-domains of this site (e.g. subdomain.) unless expressly
              excluded by their own terms and conditions.
            </p>
          </Accordian>

          <br />
          <p className="terms-policy__section-header ">
            2. <b>Age Restrictions</b>
          </p>

          <p className="terms-policy__section-body">
            Persons under the age of 18 should use this Website only with the
            supervision of an Adult. Payment Information must be provided by or
            with the permission of an Adult.
          </p>

          <p className="terms-policy__section-header ">
            3. <b>Business Customers</b>
          </p>

          <p className="terms-policy__section-body">
            These Terms and Conditions DO NOT apply to customers buying Goods in
            the course of business. Please go to Section 2 - Terms and
            Conditions for Business Customers.
          </p>

          <p className="terms-policy__section-header ">
            4. <b>International Customers</b>
          </p>

          <p className="terms-policy__section-body">
            If Goods are being ordered from outside NEWBEANS’ country of
            residence, import duties and taxes maybe incurred once your Goods
            reach their destination. Newbeans is not responsible for these
            charges and we undertake to make no calculations or estimates in
            this regard. If you are buying internationally, you are advised to
            contact your local customs authorities for further details on costs
            and procedures. As the purchaser of the Goods, you will also be the
            importer of record and as such should ensure that your purchase is
            in full compliance with the laws of the country into which the Goods
            are being imported. Please be aware that Goods may be inspected on
            arrival at port for customs purposes and NEWBEANS cannot guarantee
            that the packaging of your Goods will be free of signs of tampering.
          </p>

          <p className="terms-policy__section-header ">
            5. <b>Intellectual Property</b>
          </p>

          <p className="terms-policy__section-body">
            5.1 Subject to the exceptions in Clause 6 of these Terms and
            Conditions, all Content included on the Website, unless uploaded by
            Users, including, but not limited to, text, graphics, logos, icons,
            images, sound clips, video clips, data compilations, page layout,
            underlying code and software is the property of Newbeans, our
            affiliates or other relevant third parties. By continuing to use the
            Website you acknowledge that such material is protected by
            applicable United Kingdom and International intellectual property
            and other laws.
          </p>

          <p className="terms-policy__section-body">
            5.2 Subject to Clause 7 you may not reproduce, copy, distribute,
            store or in any other fashion re-use material from the Website
            unless otherwise indicated on the Website or unless given express
            written permission to do so by Newbeans.
          </p>

          <p className="terms-policy__section-header ">
            6. <b>Third Party Intellectual Property</b>
          </p>

          <p className="terms-policy__section-body">
            6.1 Unless otherwise expressly indicated, all Intellectual Property
            rights including, but not limited to, Copyright and Trademarks, in
            product images and descriptions belong to the NEWBEANS,
            manufacturers or distributors of such products as may be applicable.
          </p>

          <p className="terms-policy__section-body">
            6.2 Subject to Clause 7 you may not reproduce, copy, distribute,
            store or in any other fashion re-use such material unless otherwise
            indicated on the Website or unless given express written permission
            to do so by the relevant manufacturer or supplier.
          </p>

          <p className="terms-policy__section-header ">
            7. <b>Fair Use of Intellectual Property</b>
          </p>

          <p className="terms-policy__section-body">
            Material from the Web Site may be re-used without written permission
            where any of the exceptions detailed in Chapter III of the Copyright
            Designs and Patents Act 1988 apply.
          </p>

          <p className="terms-policy__section-header ">
            8. <b>Links to Other Websites</b>
          </p>

          <p className="terms-policy__section-body">
            This Website may contain links to other sites. Unless expressly
            stated, these sites are not under the control of NEWBEANS or that of
            our affiliates. We assume no responsibility for the content of such
            websites and disclaim liability for any and all forms of loss or
            damage arising out of the use of them. The inclusion of a link to
            another site on this Website does not imply any endorsement of the
            sites themselves or of those in control of them.
          </p>

          <p className="terms-policy__section-header ">
            9. <b>Links to this Website</b>
          </p>

          <p className="terms-policy__section-body">
            Those wishing to place a link to this Website on other sites may do
            so only to the home page of the site blendly.co.uk without prior
            permission. Deep linking (i.e. links to specific pages within the
            site) requires the express permission of NEWBEANS. To find out more
            please contact us by email sales@blendly.co.uk or NEWBEANS, Upper
            Floor, Unit 1 Cadzow Park, 82 Muir Street, Hamilton, ML3 6BJ,
            Scotland
          </p>

          <p className="terms-policy__section-header ">
            10. <b>Use of Communications Facilities</b>
          </p>

          <p className="terms-policy__section-body">
            10.1 When using the enquiry form or any other System on the Website
            you should do so in accordance with the following rules:
          </p>

          <p className="terms-policy__section-body">
            10.1.1 You must not use obscene or vulgar language;
          </p>

          <p className="terms-policy__section-body">
            10.1.2 You must not submit Content that is unlawful or otherwise
            objectionable. This includes, but is not limited to, Content that is
            abusive, threatening, harassing, defamatory, ageist, sexist or
            racist;
          </p>

          <p className="terms-policy__section-body">
            10.1.3 You must not submit Content that is intended to promote or
            incite violence;
          </p>

          <p className="terms-policy__section-body">
            10.1.4 It is advised that submissions are made using the English
            language(s) as we may be unable to respond to enquiries submitted in
            any other languages;
          </p>

          <p className="terms-policy__section-body">
            10.1.5 The means by which you identify yourself must not violate
            these terms of use or any applicable laws;
          </p>

          <p className="terms-policy__section-body">
            10.1.6 You must not impersonate other people, particularly employees
            and representatives of NEWBEANS or our affiliates; and
          </p>

          <p className="terms-policy__section-body">
            10.1.7 You must not use our System for unauthorised
            mass-communication such as “spam” or “junk mail”.
          </p>

          <p className="terms-policy__section-body">
            10.2 You acknowledge that Newbeans reserves the right to monitor any
            and all communications made to us or using our System.
          </p>

          <p className="terms-policy__section-header ">
            11. <b>Accounts</b>
          </p>

          <p className="terms-policy__section-body">
            11.1 In order to purchase Goods on this Website and to use the
            FORUMS/CHAT/MSN OR SIMILAR communication facilities you are required
            to create an Account which will contain certain personal details and
            Payment Information which may vary based upon your use of the
            Website as we may not require payment information until you wish to
            make a purchase. By continuing to use this Website you represent and
            warrant that:
          </p>

          <p className="terms-policy__section-body">
            11.1.1 all information you submit is accurate and truthful;
          </p>

          <p className="terms-policy__section-body">
            11.1.2 you have permission to submit Payment Information where
            permission may be required; and
          </p>

          <p className="terms-policy__section-body">
            11.1.3 you will keep this information accurate and up-to-date.
          </p>

          <p className="terms-policy__section-body">
            11.1.4 your creation of an Account is further affirmation of your
            representation and warranty.
          </p>

          <p className="terms-policy__section-body">
            11.2 It is recommended that you do not share your Account details,
            particularly your username and password. NEWBEANS accepts no
            liability for any losses or damages incurred as a result of your
            Account details being shared by you. If you use a shared computer,
            it is recommended that you do not save your Account details in your
            internet browser.
          </p>

          <p className="terms-policy__section-body">
            11.3 If you have reason to believe that your Account details have
            been obtained by another without consent, you should contact
            Newbeans immediately to suspend your Account and cancel any
            unauthorised purchases that may be pending. Please be aware that
            purchases can only be cancelled until they are dispatched. In the
            event that an unauthorised purchase is dispatched prior to your
            notifying us of the unauthorised nature of the purchase, NEWBEANS
            accepts no liability or responsibility and you should make contact
            with the Carrier detailed in the Purchase Information.
          </p>

          <p className="terms-policy__section-body">
            11.4 When choosing your username you are required to adhere to the
            terms set out above in Clause 10. Any failure to do so could result
            in the suspension and/or deletion of your Account.
          </p>

          <p className="terms-policy__section-header ">
            12. <b>Termination and Cancellation</b>
          </p>

          <p className="terms-policy__section-body">
            12.1 Either Newbeans or you may terminate your Account. If NEWBEANS
            terminates your Account, you will be notified by email and an
            explanation for the termination will be provided. Notwithstanding
            the foregoing, we reserve the right to terminate without giving
            reasons.
          </p>

          <p className="terms-policy__section-body">
            12.2 If NEWBEANS terminates your Account, any current or pending
            purchases on your Account WILL be cancelled and WILL NOT be
            dispatched.
          </p>

          <p className="terms-policy__section-body">
            12.3 NEWBEANS reserves the right to cancel purchases without stating
            reasons, for any reason prior to processing payment and dispatch.
          </p>

          <p className="terms-policy__section-body">
            12.4 If purchases are cancelled for any reason prior to dispatch you
            will be refunded any monies paid in relation to those purchases.
          </p>

          <p className="terms-policy__section-body">
            12.5 If you terminate your Account any non-dispatched purchases will
            be cancelled and you will be refunded any monies paid in relation to
            those purchases.
          </p>

          <p className="terms-policy__section-header ">
            13. <b>Goods, Pricing and Availability</b>
          </p>

          <p className="terms-policy__section-body">
            13.1 Whilst every effort has been made to ensure that all graphical
            representations and descriptions of Goods available from NEWBEANS
            correspond to the actual Goods, Newbeans is not responsible for any
            variations from these descriptions. This does not exclude our
            liability for mistakes due to negligence on our part and refers only
            to variations of the correct Goods, not different Goods altogether.
            Please refer to Clause 15.1 for incorrect Goods.
          </p>

          <p className="terms-policy__section-body">
            13.2 Where appropriate, you may be required to select the required
            specification of the Goods that you are purchasing.
          </p>

          <p className="terms-policy__section-body">
            13.3 NEWBEANS does not represent or warrant that such Goods will be
            available. Stock indications provided on the Website HOWEVER THESE
            MAY NOT TAKE INTO ACCOUNT SALES THAT HAVE TAKEN PLACE DURING YOUR
            VISIT TO THE WEBSITE.
          </p>

          <p className="terms-policy__section-body">
            13.4 All pricing information on the Website is correct at the time
            of going online. NEWBEANS reserves the right to change prices and
            alter or remove any special offers from time to time and as
            necessary. All pricing information is reviewed and updated as often
            as possible.<i>Pricing in this </i>website
            <i> is Dynamic and </i>therefore{' '}
            <i>prices may change with the movement of raw material prices. </i>
          </p>

          <p className="terms-policy__section-body">
            13.5 In the event that prices are changed during the period between
            an order being placed for Goods and NEWBEANS processing that order
            and taking payment, you will be contacted prior to your order being
            processed with details of the new price; Prices may change in light
            of market conditions, and all written quotations are only open for
            acceptance on the day the prices are published. NEWBEANS also
            reserves the right to change pricing and products in light of
            information available at the time and do not warrant or guarantee to
            supply coffee at the published rate, as coffee prices can go up or
            down, at any time all prices quoted and published are indicative
            prices only.
          </p>

          <p className="terms-policy__section-body">
            13.6 All prices on the Website include VAT where it is
            stated;NEWBEANS’ VAT number is 911391055
          </p>

          <p className="terms-policy__section-body">
            13.7 All rebates and special affiliate rates, have to be agreed and
            confirmed by NEWBEANS - all rebate and affiliate income will be paid
            by NEWBEANS with NEWBEANS branded goods and products and paid at a
            par value unless other arrangements are confirmed by NEWBEANS.
            NEWBEANS reserves the right to purchase back any rebates at a rate
            of no more that 0,001p and reserve the right to cancel rebates or
            affiliate income at any time. All rebates must be claimed back
            within 6 months and cannot be carried forward or transferred.
          </p>

          <p className="terms-policy__section-header ">
            14. <b>Delivery</b>
          </p>

          <p className="terms-policy__section-body">
            14.1 Newbeans will notify you by way of email when your goods are to
            be dispatched to you. The message will contain details of estimated
            delivery times in addition to any reasons for a delay in the
            delivery of the Goods purchased by you.
          </p>

          <p className="terms-policy__section-body">
            14.2 If NEWBEANS receives no communication from you, within 1 day of
            delivery, regarding any problems with the Goods, you are deemed to
            have received the Goods in full working order and with no problems.
          </p>

          <p className="terms-policy__section-header ">
            15. <b>Returns Policy</b>
          </p>

          <p className="terms-policy__section-body">
            NEWBEANS aims to always provide high quality Goods that are fault
            free and undamaged. On occasion however, goods may need to be
            returned. Returns are governed by these Terms and Conditions.
          </p>

          <p className="terms-policy__section-body">
            15.1 If you receive Goods which do not match those that you ordered,
            unless accompanied by an explanatory note detailing the changes,
            stating reasons for the changes and setting out your options, you
            should contact us within 1 day to arrange collection and return.
            NEWBEANSis responsible for paying shipment costs. You will be given
            the option to have the Goods replaced with those ordered (if
            available) or to be refunded through the payment method used by you
            when purchasing the Goods. Refunds and replacements will be issued
            upon our receipt of the returned Goods.
          </p>

          <p className="terms-policy__section-body">
            15.2 If any Goods you have purchased have faults when they are
            delivered to you, you should contact NEWBEANS within 5 days to
            arrange collection and return. NEWBEANS is responsible for paying
            shipment costs. Goods must be returned in their original condition
            with all packaging and documentation. Upon receipt of the returned
            Goods, the price of the Goods, as paid by you, will be refunded to
            you through the payment method used by you when purchasing the
            Goods.
          </p>

          <p className="terms-policy__section-body">
            15.3 If any Goods develop faults within their warranty period, you
            are entitled to a repair or replacement under the terms of that
            warranty.
          </p>

          <p className="terms-policy__section-body">
            15.4 If Goods are damaged in transit and the damage is apparent on
            delivery, you should sign the delivery note to the effect that the
            goods have been damaged. In any event, you should report such damage
            to Newbeans within 1 day and arrange collection and return. NEWBEANS
            is responsible for paying shipment costs. Upon receipt of the
            returned Goods, the price of the Goods, as paid by you, will be
            refunded to you through the payment method used by you when
            purchasing the Goods.
          </p>

          <p className="terms-policy__section-body">
            15.5 You have a statutory right to a “cooling off” period. This
            period begins once your order is complete and ends 7 days after the
            Goods have been delivered. If you change your mind about the goods
            within this period, please return them to NEWBEANS within 7 days of
            receipt. Goods can only be returned for this reason if their
            packaging remains unopened and the Goods can be re-sold, as new,
            without any additional work on the part of NEWBEANS. You are
            responsible for paying shipment costs if Goods are returned for this
            reason.
          </p>

          <p className="terms-policy__section-body">
            15.6 If you wish to return Goods to NEWBEANS for any of the above
            reasons, please contact us using the details on blendly.co.uk to
            make the appropriate arrangements.
          </p>

          <p className="terms-policy__section-body">
            15.7 Newbeans reserves the right to exercise discretion with respect
            to any returns under these Terms and Conditions, factors which may
            be taken into account in the exercise of this discretion include,
            but are not limited to:
          </p>

          <p className="terms-policy__section-body">
            15.7.1 Any use or enjoyment that you may have already had out of the
            Goods;
          </p>

          <p className="terms-policy__section-body">
            15.7.2 Any characteristics of the Goods which may cause them to
            deteriorate or expire rapidly;
          </p>

          <p className="terms-policy__section-body">
            15.7.3 The fact that the Goods consist of audio or video recordings
            or computer software and that the packaging has been opened;
          </p>

          <p className="terms-policy__section-body">
            15.7.4 Any discounts that may have formed part of the purchase price
            of the Goods to reflect any lack of quality made known to the
            Customer at the time of purchase.Such discretion to be exercised
            only within the confines of the law.
          </p>

          <p className="terms-policy__section-header ">
            16. <b>Privacy</b>
          </p>

          <p className="terms-policy__section-body">
            Use of the Website is also governed by our{' '}
            <a href="/PrivacyPolicy">privacy policy</a>, which is incorporated
            into these Terms and Conditions by this reference. To view the
            Privacy Policy, please click on the link above.
          </p>

          <p className="terms-policy__section-header ">
            17. <b>Disclaimers</b>
          </p>

          <p className="terms-policy__section-body">
            17.1 NEWBEANS makes no warranty or representation that the Website
            will meet your requirements, that it will be of satisfactory
            quality, that it will be fit for a particular purpose, that it will
            not infringe the rights of third parties, that it will be compatible
            with all systems, that it will be secure and that all information
            provided will be accurate. We make no guarantee of any specific
            results from the use of our Services.
          </p>

          <p className="terms-policy__section-body">
            17.2 No part of this Website is intended to constitute advice and
            the Content of this Website should not be relied upon when making
            any decisions or taking any action of any kind.
          </p>

          <p className="terms-policy__section-body">
            17.3 No part of this Website is intended to constitute a contractual
            offer capable of acceptance.
          </p>

          <p className="terms-policy__section-header ">
            18. <b>Changes to the Service and these Terms and Conditions</b>
          </p>

          <p className="terms-policy__section-body">
            Newbeans reserves the right to change the Website, its Content or
            these Terms and Conditions at any time. You will be bound by any
            changes to the Terms and Conditions from the first time you use the
            Website following the changes. If NEWBEANS is required to make any
            changes to Terms and Conditions relating to sale of Goods by law,
            these changes will apply automatically to any orders currently
            pending in addition to any orders placed by you in the future.
          </p>

          <p className="terms-policy__section-header ">
            19. <b>Availability of the Website</b>
          </p>

          <p className="terms-policy__section-body">
            The Service is provided “as is” and on an “as available” basis. We
            give no warranty that the Service will be free of defects and / or
            faults. To the maximum extent permitted by the law we provide no
            warranties (express or implied) of fitness for a particular purpose,
            accuracy of information, compatibility and satisfactory quality.
          </p>

          <p className="terms-policy__section-body">
            NEWBEANS accepts no liability for any disruption or non-availability
            of the Website resulting from external causes including, but not
            limited to, ISP equipment failure, host equipment failure,
            communications network failure, power failure, natural events, acts
            of war or legal restrictions and censorship.
          </p>

          <p className="terms-policy__section-header ">
            20. <b>Limitation of Liability</b>
          </p>

          <p className="terms-policy__section-body">
            20.1 To the maximum extent permitted by law, NEWBEANS accepts no
            liability for any direct or indirect loss or damage, foreseeable or
            otherwise, including any indirect, consequential, special or
            exemplary damages arising from the use of the Website or any
            information contained therein. Users should be aware that they use
            the Website and its Content at their own risk.
          </p>

          <p className="terms-policy__section-body">
            20.2 Nothing in these Terms and Conditions excludes or restricts
            NEWBEANS’ liability for death or personal injury resulting from any
            negligence or fraud on the part of NEWBEANS.
          </p>

          <p className="terms-policy__section-body">
            20.3 Nothing in these Terms and Conditions excludes or restricts
            NEWBEANS’ liability for any direct or indirect loss or damage
            arising out of the incorrect delivery of Goods or out of reliance on
            incorrect information included on the Website.
          </p>

          <p className="terms-policy__section-body">
            20.4 Whilst every effort has been made to ensure that these Terms
            and Conditions adhere strictly with the relevant provisions of the
            Unfair Contract Terms Act 1977, in the event that any of these terms
            are found to be unlawful, invalid or otherwise unenforceable, that
            term is to be deemed severed from these terms and conditions and
            shall not affect the validity and enforceability of the remaining
            terms and conditions. This term shall apply only within
            jurisdictions where a particular term is illegal.
          </p>

          <p className="terms-policy__section-header ">
            21. <b>No Waiver</b>
          </p>

          <p>
            In the event that any party to these Terms and Conditions fails to
            exercise any right or remedy contained herein, this shall not be
            construed as a waiver of that right or remedy.
          </p>

          <p className="terms-policy__section-header ">
            22. <b>Previous Terms and Conditions</b>
          </p>

          <p className="terms-policy__section-body">
            In the event of any conflict between these Terms and Conditions and
            any prior versions thereof, the provisions of these Terms and
            Conditions shall prevail unless it is expressly stated otherwise.
          </p>

          <p className="terms-policy__section-header ">
            23. <b>Notices</b>
          </p>

          <p className="terms-policy__section-body">
            All notices / communications shall be given to us either by post to
            our Premises (see address above) or by email to sales@blendly.co.uk.
            Such notice will be deemed received 3 days after posting if sent by
            first class post, the day of sending if the email is received in
            full on a business day and on the next business day if the email is
            sent on a weekend or public holiday.
          </p>

          <p className="terms-policy__section-header ">
            24. <b>Law and Jurisdiction</b>
          </p>

          <p className="terms-policy__section-body">
            These Terms and Conditions and the relationship between you and
            Newbeans shall be governed by and construed in accordance with the
            Law of Scotland. NEWBEANS and you agree to submit to the exclusive
            jurisdiction of the Courts of Scotland.
          </p>
        </div>
        {/* SECTION 2 */}
        <div className="terms-policy__section--dark">
          <div className="terms-policy__section-container">
            <p className="terms-policy__section-title">
              <b>SECTION 2. TERMS AND CONDITIONS FOR BUSINESS CUSTOMERS</b>
            </p>
          </div>
        </div>
        <div className="terms-policy" style={{ paddingTop: '0px' }}>
          <div className="terms-policy__grid-items">
            <p className="terms-policy__section-body">
              This agreement applies as between you, the User of this Website or
              Purchaser and NEWBEANS, the owner(s) of this Website. Your
              agreement to comply with and be bound by these Terms and
              Conditions is deemed to occur upon your first use of the Website.
              If you do not agree to be bound by these Terms and Conditions, you
              should stop using the Website immediately.
            </p>

            <p className="terms-policy__section-body">
              No part of this Website is intended to constitute a contractual
              offer capable of acceptance. The Purchaser’s order constitutes a
              contractual offer and our acceptance of that offer is deemed to
              occur upon our sending a dispatch email to the Purchaser
              indicating that the order has been fulfilled and has been
              dispatched.
            </p>
          </div>

          <p className="terms-policy__section-header">
            1. <b>Definitions and Interpretation</b>
          </p>

          <p className="terms-policy__section-body">
            In this Agreement the following terms shall have the following
            meanings:
          </p>

          <br />
          {/* Accordina Section */}
          <Accordian title="Account">
            <p className="terms-policy__section-body">
              means collectively the personal information, Payment Information
              and credentials used by Users to access Paid Content and / or any
              communications System on the Website;
            </p>
          </Accordian>
          <Accordian title="Carrier">
            <p className="terms-policy__section-body">
              means any third party responsible for transporting purchased Goods
              from our Premises to customers;
            </p>
          </Accordian>
          <Accordian title="Content">
            <p className="terms-policy__section-body">
              means any text, graphics, images, audio, video, software, data
              compilations and any other form of information capable of being
              stored in a computer that appears on or forms part of this
              Website;
            </p>
          </Accordian>
          <Accordian title="Goods">
            <p className="terms-policy__section-body">
              means any products that NEWBEANS advertises and / or makes
              available for sale through this Website;
            </p>
          </Accordian>
          <Accordian title="Newbeans">
            <p className="terms-policy__section-body">
              means NEWBEANS, Upper Floor, Unit 1 Cadzow Park, 82 Muir Street,
              Hamilton, ML3 6BJ, Scotland
            </p>
          </Accordian>
          <Accordian title="Service">
            <p className="terms-policy__section-body">
              means collectively any online facilities, tools, services or
              information that NEWBEANS makes available through the Website
              either now or in the future;
            </p>
          </Accordian>
          <Accordian title="Payment Information">
            <p className="terms-policy__section-body">
              means any details required for the purchase of Goods from this
              Website. This includes, but is not limited to, credit / debit card
              numbers, bank account numbers and sort codes;
            </p>
          </Accordian>
          <Accordian title="Purchase Information">
            <p className="terms-policy__section-body">
              means collectively any orders, invoices, dispatch notes, receipts
              or similar that may be in hard copy or electronic form;
            </p>
          </Accordian>
          <Accordian title="Premises">
            <p className="terms-policy__section-body">
              means our place(s) of business located at NEWBEANS, Upper Floor,
              Unit 1 Cadzow Park, 82 Muir Street, Hamilton, ML3 6BJ, Scotland;
            </p>
          </Accordian>
          <Accordian title="System">
            <p className="terms-policy__section-body">
              means any online communications infrastructure that NEWBEANS makes
              available through the Website either now or in the future. This
              includes, but is not limited to, web-based email, message boards,
              live chat facilities and email links;
            </p>
          </Accordian>
          <Accordian title="User / Users">
            <p className="terms-policy__section-body">
              means any third party that accesses the Website and is not
              employed by NEWBEANS and acting in the course of their employment;
              and
            </p>
          </Accordian>
          <Accordian title="Website">
            <p className="terms-policy__section-body">
              means the website that you are currently using (blendly.co.uk) and
              any sub-domains of this site (e.g. subdomain.) unless expressly
              excluded by their own terms and conditions.
            </p>
          </Accordian>

          <br />

          <p className="terms-policy__section-header">
            2. <b>Business Customers</b>
          </p>

          <p className="terms-policy__section-body">
            These Terms and Conditions apply to Business customers only.{' '}
          </p>

          <p className="terms-policy__section-header">
            3. <b>International Customers</b>
          </p>

          <p className="terms-policy__section-body">
            If Goods are being ordered from outside NEWBEANS’ country of
            residence, import duties and taxes may be incurred once the Goods
            reach their destination. NEWBEANS is not responsible for these
            charges and we undertake to make no calculations or estimates in
            this regard. If a Purchaser buying internationally, they are advised
            to contact their local customs authorities for further details on
            costs and procedures. The Purchaser of the Goods will also be the
            importer of record and as such should ensure that the purchase is in
            full compliance with the laws of the country into which the Goods
            are being imported. Please be aware that Goods may be inspected on
            arrival at port for customs purposes and NEWBEANS gives no guarantee
            that the packaging of the Goods will be free of signs of tampering.
          </p>

          <p className="terms-policy__section-header">
            4. <b>Intellectual Property</b>
          </p>

          <p className="terms-policy__section-body">
            4.1 Subject to the exceptions in Clause 5 of these Terms and
            Conditions, all Content included on the Website, unless uploaded by
            Users, including, but not limited to, text, graphics, logos, icons,
            images, sound clips, video clips, data compilations, page layout,
            underlying code and software is the property of NEWBEANS, our
            affiliates or other relevant third parties. By continuing to use the
            Website you acknowledge that such material is protected by
            applicable United Kingdom and International intellectual property
            and other laws. All data uploaded to NMH database, recipes, blends
            remain the property of NEWBEANS and its appointed agent, or
            investor.{' '}
          </p>

          <p className="terms-policy__section-body">
            4.2 Subject to Clause 6 you may not reproduce, copy, distribute,
            store or in any other fashion re-use material from the Website
            unless otherwise indicated on the Website or unless given express
            written permission to do so by NEWBEANS.
          </p>

          <p className="terms-policy__section-header">
            5. <b>Third Party Intellectual Property</b>
          </p>

          <p className="terms-policy__section-body">
            5.1 Unless otherwise expressly indicated, all Intellectual Property
            rights including, but not limited to, Copyright and Trademarks, in
            product images and descriptions belong to the manufacturers or
            distributors of such products as may be applicable.
          </p>

          <p className="terms-policy__section-body">
            5.2 Subject to Clause 6 you may not reproduce, copy, distribute,
            store or in any other fashion re-use such material unless otherwise
            indicated on the Website or unless given express written permission
            to do so by the relevant manufacturer or supplier.
          </p>

          <p className="terms-policy__section-header">
            6. <b>Fair Use of Intellectual Property</b>
          </p>

          <p className="terms-policy__section-body">
            Material from the Website may be re-used without written permission
            where any of the exceptions detailed in Chapter III of the Copyright
            Designs and Patents Act 1988 apply.
          </p>

          <p className="terms-policy__section-header">
            7. <b>Links to Other Websites</b>
          </p>

          <p className="terms-policy__section-body">
            This Website may contain links to other sites. Unless expressly
            stated, these sites are not under the control of NEWBEANS or that of
            our affiliates. We assume no responsibility for the content of such
            websites and disclaim liability for any and all forms of loss or
            damage arising out of the use of them. The inclusion of a link to
            another site on this Website does not imply any endorsement of the
            sites themselves or of those in control of them.
          </p>

          <p className="terms-policy__section-header">
            8. <b>Links to this Website</b>
          </p>

          <p className="terms-policy__section-body">
            Those wishing to place a link to this Website on other sites may do
            so only to the home page of the site{' '}
            <a href="http://blendly.co.uk/">blendly.co.uk</a> without prior
            permission. Deep linking (i.e. links to specific pages within the
            site) requires the express permission of NEWBEANS. To find out more
            please contact us by email at{' '}
            <a href="mailto:sales@blendly.co.uk">sales@blendly.co.uk</a>or to
            the company’s place of work by registered post
          </p>

          <p className="terms-policy__section-header">
            9. <b>Use of Communications Facilities</b>
          </p>

          <p className="terms-policy__section-body">
            9.1 When using the enquiry form or any other System on the Website
            you should do so in accordance with the following rules:
          </p>

          <p className="terms-policy__section-body">
            9.1.1 obscene or vulgar language must not be used;
          </p>

          <p className="terms-policy__section-body">
            9.1.2 Content that is unlawful or otherwise objectionable must not
            be submitted. This includes, but is not limited to, Content that is
            abusive, threatening, harassing, defamatory, ageist, sexist or
            racist;
          </p>

          <p className="terms-policy__section-body">
            9.1.3 Content that is intended to promote or incite violence must
            not be submitted;
          </p>

          <p className="terms-policy__section-body">
            9.1.4 it is advised that submissions are made using the English
            language(s) as we may be unable to respond to enquiries submitted in
            any other languages;
          </p>

          <p className="terms-policy__section-body">
            9.1.5 the means by which Users identify themselves must not violate
            these terms of use or any applicable laws;
          </p>

          <p className="terms-policy__section-body">
            9.1.6 Users must not impersonate other people, particularly
            employees and representatives of NEWBEANS or our affiliates; and
          </p>

          <p className="terms-policy__section-body">
            9.1.7 our System must not be used for unauthorised
            mass-communication such as “spam” or “junk mail”.
          </p>

          <p className="terms-policy__section-body">
            9.2 You acknowledge that NEWBEANS reserves the right to monitor any
            and all communications made to us or using our System.
          </p>

          <p className="terms-policy__section-header">
            10. <b>Accounts</b>
          </p>

          <p className="terms-policy__section-body">
            10.1 In order to purchase Goods on this Website and to use the CHAT
            OR SIMILARfacilities Users are required to create an Account, which
            will contain certain personal details, and Payment Information,
            which may vary, based upon a User’s use of the Website as we may not
            require payment information until a purchase is to be made. By
            continuing to use this Website you represent and warrant that:
          </p>

          <p className="terms-policy__section-body">
            10.1.1 all information you submit is accurate and truthful;
          </p>

          <p className="terms-policy__section-body">
            10.1.2 if you are buying on behalf of your employers, you have the
            authority to submit Payment Information where any such authority is
            required; and
          </p>

          <p className="terms-policy__section-body">
            10.1.3 you will keep this information accurate and up-to-date.
          </p>

          <p className="terms-policy__section-body">
            10.1.4 Your creation of an Account is further affirmation of your
            representation and warranty.
          </p>

          <p className="terms-policy__section-body">
            10.2 It is recommended that you do not share your Account details,
            particularly your username and password. NEWBEANS accepts no
            liability for any losses or damages incurred as a result of your
            Account details being shared by you. If you use a shared computer,
            it is recommended that you do not save your Account details in your
            internet browser.
          </p>

          <p className="terms-policy__section-body">
            10.2 If you have reason to believe that your Account details have
            been obtained by another without consent, you should contact
            NEWBEANS immediately to suspend your Account and cancel any
            unauthorised purchases that may be pending. Please be aware that
            purchases can only be cancelled until they are dispatched. In the
            event that an unauthorised purchase is dispatched prior to your
            notifying us of the unauthorised nature of the purchase, NEWBEANS
            accepts no liability or responsibility and you should make contact
            with the Carrier detailed in the Purchase Information.
          </p>

          <p className="terms-policy__section-body">
            10.3 When choosing a username Users are required to adhere to the
            terms set out above in Clause 9. Any failure to do so could result
            in the suspension and/or deletion of your Account.
          </p>

          <p className="terms-policy__section-header">
            11. <b>Termination and Cancellation</b>
          </p>

          <p className="terms-policy__section-body">
            11.1 Either NEWBEANS or a User may terminate an Account. If NEWBEANS
            terminates your Account, you will be notified by email and an
            explanation for the termination will be provided. Notwithstanding
            the foregoing, we reserve the right to terminate without giving
            reasons.
          </p>

          <p className="terms-policy__section-body">
            11.2 If NEWBEANS terminates an Account, any current or pending
            purchases on your Account WILL be cancelled and WILL NOT be
            dispatched.
          </p>

          <p className="terms-policy__section-body">
            11.3 NEWBEANS reserves the right to cancel purchases without stating
            reasons, for any reason prior to processing payment and dispatch.
          </p>

          <p className="terms-policy__section-body">
            11.4 If purchases are cancelled for any reason prior to dispatch
            Purchasers will be refunded any monies paid in relation to those
            purchases.
          </p>

          <p className="terms-policy__section-body">
            11.5 If a User terminates their Account any non-dispatched purchases
            will be cancelled and a full refund of any monies paid in relation
            to those purchases will be paid through the payment method used when
            the Goods were purchased.
          </p>

          <p className="terms-policy__section-header">
            12. <b>Payment</b>
          </p>

          <p className="terms-policy__section-body">
            12.1 Any and all invoices are due for payment on the date shown on
            the invoice, unless alternative arrangements have been agreed
            between the Purchaser and NEWBEANS.
          </p>

          <p className="terms-policy__section-body">
            12.2 Interest will be charged on a DAILY basis, commercial interest
            at 10% above the base rate of the Bank of England obtaining at the
            time.
          </p>

          <p className="terms-policy__section-header">
            13. <b>Goods, Pricing and Availability</b>
          </p>

          <p className="terms-policy__section-body">
            13.1 Whilst every effort has been made to ensure that all graphical
            representations and descriptions of Goods available from NEWBEANS
            correspond to the actual Goods, NEWBEANS is not responsible for any
            variations from these descriptions. This does not exclude our
            liability for mistakes due to negligence on our part and refers only
            to variations of the correct Goods, not different Goods altogether.
            Please refer to Clause 16.1 for incorrect Goods.
          </p>

          <p className="terms-policy__section-body">
            13.2 Where appropriate, you may be required to select the required
            size, weight, number, and other features of the Goods that you are
            purchasing.
          </p>

          <p className="terms-policy__section-body">
            13.3 NEWBEANS does not represent or warrant that such Goods will be
            available. Stock indications are not provided on the web site
            however these may not take into account sales that have taken place
            during your visit to the website.
          </p>

          <p className="terms-policy__section-body">
            13.4 All pricing information on the Website is correct at the time
            of going online. NEWBEANS reserves the right to change prices and
            alter or remove any special offers from time to time and as
            necessary
          </p>

          <p className="terms-policy__section-body">
            13.5 In the event that prices are changed during the period between
            an order being placed for Goods and NEWBEANS processing that order
            and taking payment, you will be contacted prior to your order being
            processed with details of the new price;
          </p>

          <p className="terms-policy__section-body">
            13.6 All prices on the Website include VAT. Where it is stated,
            NEWBEANS VAT number is 911391055
          </p>

          <p className="terms-policy__section-body">
            13.7 All rebates and special affiliate rates, have to be agreed and
            confirmed by NEWBEANS - all rebate and affiliate income will be paid
            by NEWBEANS with NEWBEANS branded goods and products, and paid at a
            par value unless other arrangements are confirmed by
            NEWBEANS.NEWBEANS reserve the right to purchase back any rebates at
            a rate of no more that 0,001p and reserve the right to cancel
            rebates or affiliate income at any time. All rebates must be claimed
            back within 6 months and cannot be carried forward or transferred.
          </p>

          <p className="terms-policy__section-header">
            14. <b>Property, Risk and Account of Profits</b>
          </p>

          <p className="terms-policy__section-body">
            14.1 Title in Goods will not pass to the Purchaser until the full
            purchase price for those Goods has been received by NEWBEANS. Title
            remains with NEWBEANS until payment is complete.
          </p>

          <p className="terms-policy__section-body">
            14.2 If the Purchaser sells the Goods on to its own customers in any
            form, either as purchased from NEWBEANS or forming a component part
            of a larger Good, and any amount of the purchase price payable to
            NEWBEANS remains outstanding, the Purchaser must account to NEWBEANS
            for the proceeds of such a sale. The Purchaser is then required to
            hold such proceeds on trust for NEWBEANS until payment has been
            received in full by NEWBEANS.
          </p>

          <p className="terms-policy__section-body">
            14.3 NEWBEANS reserves the right to trace all proceeds under the
            principles of Re Hallett’s Estate (1880) 13 Ch D 696. Once the
            payment date has passed, if any sums remain outstanding, NEWBEANS
            has the right to enter the Purchaser’s premises and remove any
            Goods, which by virtue of Clause 14.1 remain the property of
            NEWBEANS.
          </p>

          <p className="terms-policy__section-header">
            15. <b>Delivery</b>
          </p>

          <p className="terms-policy__section-body">
            15.1 NEWBEANS will notify you by way of email when your goods are to
            be dispatched to you. The message will contain details of estimated
            delivery times in addition to any reasons for a delay in the
            delivery of the Goods purchased by you.
          </p>

          <p className="terms-policy__section-body">
            15.2 If NEWBEANS receives no communication from you, within 1 day of
            delivery, regarding any problems with the Goods, you are deemed to
            have received the Goods in full working order and with no problems.
          </p>

          <p className="terms-policy__section-header">
            16. <b>Returns Policy</b>
          </p>

          <p className="terms-policy__section-body">
            NEWBEANS aims to always provide high quality Goods that are fault
            free and undamaged. On occasion however, goods may need to be
            returned. Returns are governed by these Terms and Conditions.
          </p>

          <p className="terms-policy__section-body">
            16.1 If the Purchaser receives Goods which do not match those
            ordered, unless accompanied by an explanatory note detailing the
            changes, stating reasons for the changes and setting out your
            options, the Purchaser should contact us within 5 days to arrange
            collection and return. NEWBEANS is responsible for paying shipment
            costs. The Purchaser will be given the option to have the Goods
            replaced with those ordered (if available) or to be refunded through
            the payment method used when the Goods were purchased. Refunds and
            replacements will be issued upon our receipt of the returned Goods.
          </p>

          <p className="terms-policy__section-body">
            16.2 If any Goods purchased have faults when they are delivered, the
            Purchaser should contact NEWBEANS within 5 days to arrange
            collection and return. NEWBEANS is responsible for paying shipment
            costs. Goods must be returned in their original condition with all
            packaging and documentation. Upon receipt of the returned Goods, the
            price of the Goods, as paid, will be refunded through the payment
            method used when the Goods were purchased.
          </p>

          <p className="terms-policy__section-body">
            16.3 If any Goods develop faults within their warranty period, the
            Purchaser is entitled to a repair or replacement under the terms of
            that warranty.
          </p>

          <p className="terms-policy__section-body">
            16.4 If Goods are damaged in transit and the damage is apparent on
            delivery, the Purchaser should sign the delivery note to the effect
            that the goods have been damaged. In any event, such damage should
            be reported to NEWBEANS within 5 days and arrange collection and
            return. NEWBEANS is responsible for paying shipment costs. Upon
            receipt of the returned Goods, the price of the Goods, as paid, will
            be refunded through the payment method used when the Goods were
            purchased.
          </p>

          <p className="terms-policy__section-body">
            16.5 If the Goods have been dispatched or have reached you, but the
            Purchaser decides that they are no longer required, the Goods can be
            returned to NEWBEANS within 5 days of receipt. Goods can only be
            returned for this reason if their packaging remains unopened and the
            Goods can be re-sold, as new, without any additional work on the
            part of NEWBEANS. The Purchaser is responsible for paying shipment
            costs if Goods are returned for this reason.
          </p>

          <p className="terms-policy__section-body">
            16.6 If the Purchaser wishes to return Goods to NEWBEANS for any of
            the above reasons, please contact us using the details on the footer
            of this page to make the appropriate arrangements.
          </p>

          <p className="terms-policy__section-body">
            16.7 NEWBEANS reserves the right to exercise discretion with respect
            to any returns under these Terms and Conditions, factors which may
            be taken into account in the exercise of this discretion include,
            but are not limited to:
          </p>

          <p className="terms-policy__section-body">
            16.7.1 Any use or enjoyment that you may have already had out of the
            Goods;
          </p>

          <p className="terms-policy__section-body">
            16.7.2 Any characteristics of the Goods which may cause them to
            deteriorate or expire rapidly;
          </p>

          <p className="terms-policy__section-body">
            16.7.3 The fact that the Goods consist of audio or video recordings
            or computer software and that the packaging has been opened;
          </p>

          <p className="terms-policy__section-body">
            16.7.4 Any discounts that may have formed part of the purchase price
            of the Goods to reflect any lack of quality made known to the
            Customer at the time of purchase.
          </p>

          <p className="terms-policy__section-body">
            Such discretion to be exercised only within the confines of the law.
          </p>

          <p className="terms-policy__section-header">
            17. <b>Privacy</b>
          </p>

          <p className="terms-policy__section-body">
            Use of the Website is also governed by our{' '}
            <a href="/PrivacyPolicy">privacy policy</a> which is incorporated
            into these terms and conditions by this reference. To view the
            Privacy Policy, please click on this{' '}
            <a href="/PrivacyPolicy">link</a>.
          </p>

          <p className="terms-policy__section-header">
            18. <b>Disclaimers</b>
          </p>

          <p className="terms-policy__section-body">
            18.1 NEWBEANS makes no warranty or representation that the Website
            will meet your requirements, that it will be of satisfactory
            quality, that it will be fit for a particular purpose, that it will
            not infringe the rights of third parties, that it will be compatible
            with all systems, that it will be secure and that all information
            provided will be accurate. We make no guarantee of any specific
            results from the use of our Services.
          </p>

          <p className="terms-policy__section-body">
            18.2 No part of this Website is intended to constitute advice and
            the Content of this Website should not be relied upon when making
            any decisions or taking any action of any kind.
          </p>

          <p className="terms-policy__section-body">
            18.3 No part of this Website is intended to constitute a contractual
            offer capable of acceptance.
          </p>

          <p className="terms-policy__section-header">
            19. <b>Changes to the Service and these Terms and Conditions</b>
          </p>

          <p className="terms-policy__section-body">
            NEWBEANS reserves the right to change the Website, its Content or
            these Terms and Conditions at any time. Users and Purchasers will be
            bound by any changes to the Terms and Conditions from the first time
            the Website is used by them following the changes. If NEWBEANS is
            required to make any changes to Terms and Conditions relating to
            sale of Goods by law, these changes will apply automatically to any
            orders currently pending in addition to any orders placed by
            Purchasers in the future.
          </p>

          <p className="terms-policy__section-header">
            20. <b>Availability of the Website</b>
          </p>

          <p className="terms-policy__section-body">
            The Service is provided “as is” and on an “as available” basis. We
            give no warranty that the Service will be free of defects and / or
            faults. To the maximum extent permitted by the law we provide no
            warranties (express or implied) of fitness for a particular purpose,
            accuracy of information, compatibility and satisfactory quality.
          </p>

          <p className="terms-policy__section-body">
            NEWBEANS accepts no liability for any disruption or non-availability
            of the Website resulting from external causes including, but not
            limited to, ISP equipment failure, host equipment failure,
            communications network failure, power failure, natural events, acts
            of war or legal restrictions and censorship.
          </p>

          <p className="terms-policy__section-header">
            21. <b>Limitation of Liability</b>
          </p>

          <p className="terms-policy__section-body">
            21.1 To the maximum extent permitted by law, NEWBEANS accepts no
            liability for any direct or indirect loss or damage, foreseeable or
            otherwise, including any indirect, consequential, special or
            exemplary damages arising from the use of the Website or any
            information contained therein. Users should be aware that they use
            the Website and its Content at their own risk.
          </p>

          <p className="terms-policy__section-body">
            21.2 Nothing in these Terms and Conditions excludes or restricts
            NEWBEANS’ liability for death or personal injury resulting from any
            negligence or fraud on the part of NEWBEANS.
          </p>

          <p className="terms-policy__section-body">
            21.3 Nothing in these Terms and Conditions excludes or restricts
            NEWBEANS’ liability for any direct or indirect loss or damage
            arising out of the incorrect delivery of Goods or out of reliance on
            incorrect information included on the Website.
          </p>

          <p className="terms-policy__section-body">
            21.4 Whilst every effort has been made to ensure that these terms
            and conditions adhere strictly with the relevant provisions of the
            Unfair Contract Terms Act 1977, in the event that any of these terms
            are found to be unlawful, invalid or otherwise unenforceable, that
            term is to be deemed severed from these terms and conditions and
            shall not affect the validity and enforceability of the remaining
            terms and conditions. This term shall apply only within
            jurisdictions where a particular term is illegal.
          </p>

          <p className="terms-policy__section-header">
            22. <b>No Waiver</b>
          </p>

          <p className="terms-policy__section-body">
            In the event that any party to these Terms and Conditions fails to
            exercise any right or remedy contained herein, this shall not be
            construed as a waiver of that right or remedy.
          </p>

          <p className="terms-policy__section-header">
            23. <b>Previous Terms and Conditions</b>
          </p>

          <p className="terms-policy__section-body">
            In the event of any conflict between these Terms and Conditions and
            any prior versions thereof, the provisions of these Terms and
            Conditions shall prevail unless it is expressly stated otherwise.
          </p>

          <p className="terms-policy__section-header">
            24. <b>Notices</b>
          </p>

          <p className="terms-policy__section-body">
            All notices / communications shall be given to us either by post to
            our Premises (see address above) or by email to{' '}
            <a href="mailto:>@blendly.co.uk">@blendly.co.uk</a>. Such notice
            will be deemed received 3 days after posting if sent by first class
            post, the day of sending if the email is received in full on a
            business day and on the next business day if the email is sent on a
            weekend or public holiday.
          </p>

          <p className="terms-policy__section-header">
            25. <b>Law and Jurisdiction</b>
          </p>

          <p className="terms-policy__section-body">
            These terms and conditions and the relationship between you and
            NEWBEANS shall be governed by and construed in accordance with the
            Law Scotland and you agree to submit to the exclusive jurisdiction
            of the Courts of Scotland.
          </p>
        </div>
        <div className="terms-policy__button-top">
          <IonButton onClick={() => scrollToTop()}>
            <div>
              <IonIcon icon={chevronUp}></IonIcon>
              <span>Back to top</span>
            </div>
          </IonButton>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Terms;
