import { IonButton, IonIcon, IonLoading, IonRouterLink } from "@ionic/react";
import { eye } from "ionicons/icons";
import React, { useContext, useState } from "react";
import moment from "moment";
import axios from "axios";
import "./Orders.css";
import config from "../../../config";
import userProfileContext from "../../../context/UserProfile/userProfileContext";

const Orders: React.FC = () => {
  // Initialize State
  const [isLoading, setIsLoading] = useState(false);
  // Get Order Details From Context
  const { orderDetails, orderStatus } = useContext(userProfileContext);

  // GET blendly Authentication Token & Cart Id
  const blendlyAuthToken = window.localStorage.getItem("blendlyAuthToken");
  const blendlyCartId = window.localStorage.getItem("blendlyCartId");
  // User Order Details
  const userOrderDetails = () => {
    const statusStyle: any = {
      //   Waiting For Initial Action
      1: { color: "pink" },
      //   Pending
      2: { color: "#000000" },
      //   Processing
      3: { color: "#4c7972" },
      //   Dispatched
      4: { color: "blue" },
      //   Returned
      5: { color: "red" },
      //   Cancelled
      6: { color: "#915a3c" },
    };
    return (
      <>
        {orderDetails
          .map((item: any) => {
            const {
              id,
              order_status_id,
              created_at,
              weight,
              order_blend_count,
              total,
              balance,
            } = item;
            return (
              <tr key={id}>
                <td>{id}</td>
                <td style={statusStyle[`${order_status_id}`]}>
                  {userOrderStatus(order_status_id)}
                </td>
                <td>{moment(created_at).format("YYYY/MM/D")}</td>
                <td>{order_blend_count}</td>
                <td>{weight / 1000}kg</td>
                <td>&#163;{total.toFixed(2)}</td>
                <td>&#163;{balance.toFixed(2)}</td>
                <td className="orders-history__table__body__buttons">
                  <IonRouterLink href={`/profile/orders/${id}`}>
                    <IonButton className="view-button">
                      <IonIcon icon={eye}></IonIcon>
                      View
                    </IonButton>
                  </IonRouterLink>
                  <IonRouterLink onClick={() => getOrderBlends(id)}>
                    <IonButton className="reorder-button">Reorder</IonButton>
                  </IonRouterLink>
                </td>
              </tr>
            );
          })
          .reverse()}
      </>
    );
  };

  const getOrderBlends = async (id: any) => {
    try {
      setIsLoading(true);
      // GET Order Blends
      const resOrderBlends = await axios.get(
        `${config.apiURL}/orders/${id}/order-blends?token=${blendlyAuthToken}`
      );
      // GET Order Blends Recipes
      const resOrderBlendRecipes = await axios.get(
        `${config.apiURL}/orders/${id}/order-blend-recipes?token=${blendlyAuthToken}`
      );

      const orderBlends = resOrderBlends.data;
      const orderBlendRecipes = resOrderBlendRecipes.data;

      reorderBlend(orderBlends, orderBlendRecipes);
    } catch (error) {
      alert(error);
      setIsLoading(false);
    }
  };

  // POST reorder
  const reorderBlend = (orderBlends: any, orderBlendRecipes: any) => {
    let blendArr: any = [];
    let blendRecipesArr: any = [];
    let blendCountArr: any = [];

    orderBlendRecipes.forEach((item: any) => {
      blendRecipesArr.push({
        id: item.id,
        percentage: item.percentage,
        beanId: item.bean_id,
        orderBlendId: item.order_blend_id,
        greenBeanPrice: item.green_bean_price,
        financeCost: item.finance_cost,
        afterFinanceCost: item.after_finance_cost,
        deliveryCost: item.delivery_cost,
        afterDeliveryCost: item.after_delivery_cost,
        roastingLossCost: item.roasting_loss_cost,
        landedCost: item.landed_cost,
        productionCost: item.production_cost,
        roasterProductionCost: item.roaster_production_cost,
        priceInterMediumPerKg: item.price_inter_medium_per_kg,
        costPrice: item.cost_price,
        roasterCostPrice: item.roaster_cost_price,
        profitPercentage: item.profit_percentage,
        profit: item.profit,
        weight: item.weight,
        soloRoastingLossCost: item.solo_roasting_loss_cost,
        soloPriceInterMediumPerKg: item.solo_price_inter_medium_per_kg,
        soloProductionCost: item.solo_production_cost,
        soloRoasterProductionCost: item.solo_roaster_production_cost,
        soloCostPrice: item.solo_cost_price,
        soloRoasterCostPrice: item.solo_roaster_cost_price,
        soloProfit: item.solo_profit,
        salePricePerKilo: item.sale_price_per_kilo,
        salePricePerBag: item.sale_price_per_bag,
        totalPricePerBag: item.total_price_per_bag,
      });
    });

    orderBlends.forEach((item: any) => {
      const {
        id,
        bag_size_id,
        bag_type_id,
        blend_id,
        certification_id,
        description,
        grind_id,
        label_design_id,
        name,
        quantity,
        roast_id,
      } = item;

      const blendRecipes = blendRecipesArr.filter((item: any) => {
        return item.orderBlendId === id;
      });

      blendArr.push({
        bagSizeId: bag_size_id,
        bagTypeId: bag_type_id,
        blendId: blend_id,
        blendRecipes: blendRecipes,
        certificationId: certification_id,
        customerId: null,
        description: description,
        grindId: grind_id,
        labelDesignId: label_design_id,
        name: name,
        quantity: quantity,
        roastId: roast_id,
      });
    });

    blendArr.forEach((el: any) => {
      axios
        .post(`${config.apiURL}/cart-blends?token=${blendlyAuthToken}`, {
          ...el,
          cartId: blendlyCartId,
        })
        .then((res) => {
          blendCountArr.push(res.data);
          if (blendArr.length === blendCountArr.length) {
            setIsLoading(false);
            window.location.href = "/cart";
          }
        })
        .catch((err) => {
          alert(err);
        });
    });
  };

  const userOrderStatus = (order_status_id: any) => {
    const statusItem = orderStatus.filter((item: any) => {
      return item.id === order_status_id;
    });
    return statusItem[0].value;
  };
  return (
    <div className="orders-history">
      <div className="orders-history__container">
      <h4 className="form-title left-align">Order History</h4>
        <table className="orders-history__table">
          <thead>
            <tr className="orders-history__table__header">
              <td>Order ID</td>
              <td>Order Status</td>
              <td>Date Added</td>
              <td>No. of Blends</td>
              <td>Volume</td>
              <td>Total</td>
              <td>Balance</td>
              <td></td>
            </tr>
          </thead>
          <tbody className="orders-history__table__body">
            {userOrderDetails()}
          </tbody>
        </table>
      </div>
      <IonLoading isOpen={isLoading} />
    </div>
  );
};

export default Orders;
