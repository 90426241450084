import {
  IonContent,
  IonPage,
  IonRouterLink,
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
} from "@ionic/react";
import React, { useState } from "react";
import axios from "axios";
import config from "../../config";
import { Header } from "../../components/Layout/Header";
import "./ResetsPageStyles.css";
import { Footer } from "../../components/Layout/Footer";

const heroBackground = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/barista-making-chemex-coffee.png')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

interface Params {
  email: string;
  successMessage: string;
  errorMessage: string;
  binaryNum: number;
}

const PasswordResetPage: React.FC<Params> = ({
  email,
  successMessage,
  errorMessage,
  binaryNum,
}) => {
  const [emailString, setEmail] = useState(email);
  const [succesfulMsg, setMessage] = useState(successMessage);
  const [errorMsg, setError] = useState(errorMessage);
  const [binaryNumber, setBinary] = useState(binaryNum);

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e: any) => {
    e.preventDefault();
    axios
      .put(`${config.apiURL}/customers/initialize-password-reset`, {
        email: emailString,
      })
      .then((res) => {
        if (res.status === 200) {
          setMessage(
            `Instructions has been sent to your email: ${emailString}`
          );
          setBinary(0);
          setError("");
        }
      })
      .catch((err) => {
        setError("Something went wrong, try again later.");
        setBinary(1);
        setMessage("");
      });
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <IonPage className="user-reset">
      <IonContent>
        <Header />

        <div className="user-reset__hero" style={heroBackground}>
          <form className="user-reset__form" onSubmit={onSubmit}>
            <h2>Reset Password</h2>
            <IonItem>
              <IonLabel position="floating">Email</IonLabel>
              <IonInput
                type="email"
                required
                value={emailString}
                onIonChange={onChangeEmail}
              ></IonInput>
            </IonItem>
            <div className="user-reset__buttons">
              <IonButton
                type="button"
                onClick={goBack}
                className="user-reset__buttons__link"
              >
                Back
              </IonButton>
              <IonButton type="submit" className="user-reset__buttons__accept">
                Send
              </IonButton>
            </div>

            {/* Success and Error Messages */}
            <div className="form-messages">
              {binaryNumber === 0 ? (
                <p className="form-messages__success">{succesfulMsg}</p>
              ) : binaryNumber === 1 ? (
                <p className="form-messages__error">{errorMsg}</p>
              ) : null}
            </div>
          </form>
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default PasswordResetPage;
