import { useReducer } from "react";
import { OPEN_MENU } from "../types";
import mobileMenuReducer from "./mobileMenuReducer";
import mobileMenuContext from "./mobileMenuContext";

const MobileMenuState = (props) => {
  const initState = {
    openMenu: false,
  };

  const [state, dispatch] = useReducer(mobileMenuReducer, initState);

  // -- Open Mobile Menu Action
  const openMenuAction = (open) => {
    dispatch({
      type: OPEN_MENU,
      payload: open,
    });
  };

  return (
    <mobileMenuContext.Provider
      value={{
        openMenu: state.openMenu,
        openMenuAction,
      }}
    >
      {props.children}
    </mobileMenuContext.Provider>
  );
};

export default MobileMenuState;
