import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

// Global State
import CuppingNotesContext from '../../context/CuppingNotes/cuppingNotesContext';

// Images
import BeanIcon from '../../assets/icons/bean_card_icon.svg';

// Styling
import './NoteBeansInfo.css';

const NoteBeansInfo = () => {
  const { selectedNote, setToggleView } = useContext(CuppingNotesContext);
  const history = useHistory();

  return (
    <ul className="noteBeansInfo">
      {selectedNote.blend_recipes.map((bean: any) => (
        <li key={bean.id}>
          <img className="noteBeansInfo__icon" src={BeanIcon} alt="bean icon" />

          <span
            onClick={() => {
              setToggleView(false);
              history.push(`/cuppingnotes/${bean.ctaps_id}`);
            }}
            className="noteBeansInfo__ctapsId"
          >
            {bean.ctaps_id}
          </span>

          <span className="noteBeansInfo__name">{bean.name}</span>

          <span className="noteBeansInfo__certification">
            {bean.certification_display_name}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default NoteBeansInfo;
