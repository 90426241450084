import * as actionTypes from './action-types.js';

const checkoutReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.DYNAMIC_ORDER_PRICES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CLEAR_CHECKOUT_ERROR_STATE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.SET_CHECKOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DYNAMIC_ORDER_PRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        dynamicallyPricedOrder: action.payload,
      };
    case actionTypes.DYNAMIC_ORDER_PRICES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.SET_BILLING_INFO:
      return {
        ...state,
        billingInfo: action.payload,
      };
    case actionTypes.SET_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: action.payload,
      };
    case actionTypes.UPDATE_BILLING_INFO:
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          [action.payload.key]: action.payload.value,
        },
      };
    case actionTypes.UPDATE_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: {
          ...state.shippingInfo,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default checkoutReducer;
