import { useReducer, useState } from 'react';
import * as actionTypes from './shopTypes';
import ShopContext from './shopContext';
import shopReducer from './shopReducer';
import axios from 'axios';
import config from '../../config';

const ShopState = (props) => {
  const initState = {
    products: null,
    loading: false,
    error: '',
  };

  const [state, dispatch] = useReducer(shopReducer, initState);

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(9);
  const [currentProducts, setCurrentProducts] = useState([]);

  const getShopPageData = async () => {
    try {
      dispatch({ type: actionTypes.GETTING_PRODUCTS_LOADING });

      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          token: localStorage.getItem('blendlyAuthToken'),
        },
      };

      // Make Call
      const blends = await axios.get(`${config.apiURL}/blends`, axiosConfig);
      const beans = await axios.get(`${config.apiURL}/beans`, axiosConfig);

      // Combine data
      const productsData = [...beans.data, ...blends.data];

      console.log(productsData);

      // Save to state
      dispatch({
        type: actionTypes.GETTING_PRODUCTS_SUCCESS,
        payload: productsData,
      });
    } catch (error) {
      console.log(error);

      dispatch({
        type: actionTypes.GETTING_PRODUCTS_FAIL,
        payload: error.response
          ? error.response.data.message
          : 'Something went wrong',
      });
    }
  };

  const clearError = () => {
    dispatch({ type: actionTypes.CLEAR_ERROR });
  };

  return (
    <ShopContext.Provider
      value={{
        products: state.products,
        loading: state.loading,
        error: state.error,
        currentPage,
        productsPerPage,
        currentProducts,
        setCurrentPage,
        setProductsPerPage,
        setCurrentProducts,
        getShopPageData,
        clearError,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopState;
