import {
  BLENDCREATOR_DATA_LOADING,
  BLENDCREATOR_DATA_SUCCESS,
  BLENDCREATOR_DATA_FAIL,
  ADD_TO_BLENDRECIPES,
  REMOVE_FROM_BLENDRECIPES,
  ADJUST_BEAN_PERCENTAGE,
  SET_BLEND_ROASTTYPE,
  SET_BLEND_GRINDTYPE,
  SET_BLEND_NAME,
  SET_BLEND_DESCRIPTION,
  BLENDCREATOR_PRICES_SUCCESS,
  BLENDCREATOR_PRICES_FAIL,
  BLENDCREATOR_PRICES_LOADING,
  CLEAR_ALL_BLENDRECIPES,
  SET_BLEND_BAG_SIZE_ID,
  SET_BLEND_BAG_QUANTITY,
  RESET_BLENDCREATOR,
} from '../types';

const blendCreatorReducer = (state, action) => {
  switch (action.type) {
    case SET_BLEND_BAG_QUANTITY:
      return {
        ...state,
        blend_bagQuantity: action.payload,
      };
    case SET_BLEND_BAG_SIZE_ID:
      return {
        ...state,
        blend_bagSizeId: action.payload,
      };
    case CLEAR_ALL_BLENDRECIPES:
      return {
        ...state,
        blend_recipes: [],
      };
    case BLENDCREATOR_DATA_LOADING:
    case BLENDCREATOR_PRICES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case BLENDCREATOR_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        beans: action.payload,
      };
    case BLENDCREATOR_DATA_FAIL:
    case BLENDCREATOR_PRICES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_TO_BLENDRECIPES:
      let alreadyExists = state.blend_recipes.find(
        (bean) => +bean.id === +action.payload.id
      );

      if (!alreadyExists) {
        return {
          ...state,
          blend_recipes: [...state.blend_recipes, action.payload],
        };
      }

      return state;
    case REMOVE_FROM_BLENDRECIPES:
      return {
        ...state,
        blend_recipes: state.blend_recipes.filter(
          (bean) => bean.id !== action.payload
        ),
      };
    case ADJUST_BEAN_PERCENTAGE:
      return {
        ...state,
        blend_recipes: action.payload,
      };
    case SET_BLEND_ROASTTYPE:
      return {
        ...state,
        blend_roastType: action.payload,
      };
    case SET_BLEND_GRINDTYPE:
      return {
        ...state,
        blend_grindType: action.payload,
      };
    case SET_BLEND_NAME:
      return {
        ...state,
        blend_name: action.payload,
      };
    case SET_BLEND_DESCRIPTION:
      return {
        ...state,
        blend_description: action.payload,
      };
    case BLENDCREATOR_PRICES_SUCCESS:
      return {
        ...state,
        dynamically_priced_blends: action.payload,
        loading: false,
      };
    case RESET_BLENDCREATOR:
      return {
        ...state,
        blend_recipes: [],
        blend_roastType: 1,
        blend_grindType: 1,
        blend_bagSizeId: 7,
        blend_bagTypeId: 1,
        blend_bagQuantity: 1,
        blend_name: '',
        blend_description: '',
        dynamically_priced_blends: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default blendCreatorReducer;
