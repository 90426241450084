// React Imports
import { useEffect, useContext } from 'react';

// React Router Dom
import { useHistory, useParams } from 'react-router';

// Global State
import CuppingNotesContext from '../../context/CuppingNotes/cuppingNotesContext';
import userCartContext from '../../context/UserCart/userCartContext';

// Ionic Components
import {
  IonButton,
  IonContent,
  IonGrid,
  IonLoading,
  IonPage,
  IonSpinner,
} from '@ionic/react';

// Custom Components
import NoteInfoSection from '../../components/NoteInfoSection/NoteInfoSection';
import SearchAndListSection from './SearchAndListSection/SearchAndListSection';
import GraphingSection from './GraphingSection/GraphingSection';
import Banner from '../../components/Banner/Banner';
import { Footer } from '../../components/Layout/Footer';
import { Header } from '../../components/Layout/Header';

// Styling & Assets
import './CuppingNotes.css';
import BlendlyLogo from '../../assets/images/blendly-logo.png';
import { Helmet } from 'react-helmet';

const CuppingNotes: React.FC = () => {
  const { ctapsId } = useParams<any>();
  const history = useHistory();
  // Accessing Global Cupping Notes Context
  const {
    loading,
    error,
    cuppingNotes,
    selectedNote,
    toggleView,
    setToggleView,
    loadCuppingNotesData,
    setSelectedNote,
  } = useContext(CuppingNotesContext);
  const { loading: cartLoading } = useContext(userCartContext);

  useEffect(() => {
    if (!cuppingNotes) {
      loadCuppingNotesData();
    }

    if (ctapsId && cuppingNotes) {
      let data = cuppingNotes.find((note: any) => {
        return note.ctaps_id === ctapsId;
      });

      if (data) {
        setSelectedNote(data);
        setToggleView(true);
      }
    } else {
      setToggleView(false);
    }

    return () => {
      setToggleView(false);
    };

    // eslint-disable-next-line
  }, [ctapsId, cuppingNotes]);

  // Conditional Banner rendering functions
  const renderChangingBanner = () => {
    return !toggleView ? (
      <Banner
        options={{
          h1: 'Cupping Notes',
          h2: 'Choose from list of cupping notes',
          bgImg: 'assets/images/barista-making-kalita-drip-brew-coffee.png',
          size: 'half',
        }}
      />
    ) : (
      <Banner
        options={{
          h1: selectedNote.name,
          h2: selectedNote.brand,
          size: 'half',
        }}
      />
    );
  };

  const renderCuppingNotesSubtext = () => {
    return (
      !toggleView && (
        <div className="cuppingNotes__subtext">
          <p>Choose from a list of cupping notes made by coffee experts and lovers like yourself</p>
        </div>
      )
    );
  };

  return (
    <IonPage className="cuppingNotes">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Cupping Notes {toggleView ? ` | ${selectedNote.ctaps_id}` : ''}
        </title>
      </Helmet>
      <IonContent>
        <Header />

        <IonLoading isOpen={cartLoading} />

        {renderChangingBanner()}

        <IonGrid className="cuppingNotes__content">
          {renderCuppingNotesSubtext()}

          {loading && (
            <div className="cuppingNotes__loader">
              <IonSpinner />
            </div>
          )}

          {error && <p>{error}</p>}

          {!loading && (
            <div className="cuppingNotes__container">
              <div className="cuppingNotes__searchListSection">
                {toggleView && (
                  <div>
                    <IonButton color="light" onClick={() => history.goBack()}>
                      <span className="bold">‹ Back</span>
                    </IonButton>
                    <NoteInfoSection />
                  </div>
                )}

                {!toggleView && cuppingNotes && <SearchAndListSection />}
              </div>
              <div className="cuppingNotes__graphingSection">
                {!selectedNote && (
                  <div className="graphingSection__chartplaceholder">
                    <div>
                      <img src={BlendlyLogo} alt="blendly logo" />
                      <p className="graphingSection__chartplaceholder-title">
                        Select a coffee
                      </p>
                      <p className="graphingSection__chartplaceholder-subtext">
                        To view the taste chart of your selected coffee
                      </p>
                    </div>
                  </div>
                )}

                {selectedNote && <GraphingSection />}
              </div>
            </div>
          )}
        </IonGrid>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default CuppingNotes;
