import { IonGrid, IonRow, IonCol, IonButton } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import "./Information.css";
import config from "../../../../config";
import orderDetailsContext from "../../../../context/OrderDetails/orderDetailsContext";

const Information: React.FC = () => {
  const { orderInfo, orderStatus, orderBlends, orderBlendRecipes } =
    useContext(orderDetailsContext);

  // Get Tokens
  const blendlyAuthToken = window.localStorage.getItem("blendlyAuthToken");
  const blendlyCartId = window.localStorage.getItem("blendlyCartId");
  // GET order info
  const {
    id,
    created_at,
    full_name,
    shipping_cost,
    subtotal,
    volume_discount,
    total,
    amount_paid,
    balance,
    order_status_id,
  } = orderInfo;

  // GET order status
  const getOrderStatus = () => {
    if (orderStatus.length > 0) {
      const status = orderStatus.filter((item: any) => {
        return item.id === order_status_id;
      });

      return status[0].value;
    }
    return <></>;
  };

  // POST reorder
  const reorder = () => {
    let blendArr: any = [];
    let blendRecipesArr: any = [];
    let blendCountArr: any = [];

    orderBlendRecipes.forEach((item: any) => {
      blendRecipesArr.push({
        id: item.id,
        percentage: item.percentage,
        beanId: item.bean_id,
        orderBlendId: item.order_blend_id,
        greenBeanPrice: item.green_bean_price,
        financeCost: item.finance_cost,
        afterFinanceCost: item.after_finance_cost,
        deliveryCost: item.delivery_cost,
        afterDeliveryCost: item.after_delivery_cost,
        roastingLossCost: item.roasting_loss_cost,
        landedCost: item.landed_cost,
        productionCost: item.production_cost,
        roasterProductionCost: item.roaster_production_cost,
        priceInterMediumPerKg: item.price_inter_medium_per_kg,
        costPrice: item.cost_price,
        roasterCostPrice: item.roaster_cost_price,
        profitPercentage: item.profit_percentage,
        profit: item.profit,
        weight: item.weight,
        soloRoastingLossCost: item.solo_roasting_loss_cost,
        soloPriceInterMediumPerKg: item.solo_price_inter_medium_per_kg,
        soloProductionCost: item.solo_production_cost,
        soloRoasterProductionCost: item.solo_roaster_production_cost,
        soloCostPrice: item.solo_cost_price,
        soloRoasterCostPrice: item.solo_roaster_cost_price,
        soloProfit: item.solo_profit,
        salePricePerKilo: item.sale_price_per_kilo,
        salePricePerBag: item.sale_price_per_bag,
        totalPricePerBag: item.total_price_per_bag,
      });
    });

    orderBlends.forEach((item: any) => {
      const {
        id,
        bag_size_id,
        bag_type_id,
        blend_id,
        certification_id,
        description,
        grind_id,
        label_design_id,
        name,
        quantity,
        roast_id,
      } = item;

      const blendRecipes = blendRecipesArr.filter((item: any) => {
        return item.orderBlendId === id;
      });

      blendArr.push({
        bagSizeId: bag_size_id,
        bagTypeId: bag_type_id,
        blendId: blend_id,
        blendRecipes: blendRecipes,
        certificationId: certification_id,
        customerId: null,
        description: description,
        grindId: grind_id,
        labelDesignId: label_design_id,
        name: name,
        quantity: quantity,
        roastId: roast_id,
      });
    });

    blendArr.forEach((el: any) => {
      axios
        .post(`${config.apiURL}/cart-blends?token=${blendlyAuthToken}`, {
          ...el,
          cartId: blendlyCartId,
        })
        .then((res) => {
          blendCountArr.push(res.data);
          if (blendArr.length === blendCountArr.length) {
            window.location.href = "/cart";
          }
        })
        .catch((err) => {
          alert(err);
        });
    });
  };
  return (
    <div className="order-information">
      <h4 className="order-information__header">Details</h4>
      <div>
        <IonGrid>
          <IonRow className="order-information__row">
            {/* Details */}
            <IonCol>
              <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol className="title-text-color">ID</IonCol>
                <IonCol>{id}</IonCol>
              </IonRow>
              <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol className="title-text-color">Date</IonCol>
                <IonCol>
                  {moment(created_at).format("MMMM D, YYYY h:mm:ss a")}
                </IonCol>
              </IonRow>
              <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol className="title-text-color">Status</IonCol>
                <IonCol>{getOrderStatus()}</IonCol>
              </IonRow>
              <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol className="title-text-color">Customer</IonCol>
                <IonCol>{full_name}</IonCol>
              </IonRow>
              {/* <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol className="title-text-color">Payment Status</IonCol>
                <IonCol>Paid in full</IonCol>
              </IonRow> */}
            </IonCol>
            {/* Totals */}
            <IonCol>
              <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol className="title-text-color">Totals</IonCol>
              </IonRow>
              <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol>Shipping Cost</IonCol>
                <IonCol className="text-align-right">
                  £
                  {Object.keys(orderInfo).length > 0
                    ? shipping_cost.toFixed(2)
                    : null}
                </IonCol>
              </IonRow>
              <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol>Sub Total</IonCol>
                <IonCol className="text-align-right">
                  £
                  {Object.keys(orderInfo).length > 0
                    ? subtotal.toFixed(2)
                    : null}
                </IonCol>
              </IonRow>
              <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol>Volume Discount</IonCol>
                <IonCol className="text-align-right text-color-secondary">
                  -£
                  {Object.keys(orderInfo).length > 0
                    ? volume_discount.toFixed(2)
                    : null}
                </IonCol>
              </IonRow>
              <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol className="text-align-right">Total</IonCol>
                <IonCol className="text-align-right">
                  £{Object.keys(orderInfo).length > 0 ? total.toFixed(2) : null}
                </IonCol>
              </IonRow>
              <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol>Amount Paid</IonCol>
                <IonCol className="text-align-right text-color-secondary">
                  -£
                  {Object.keys(orderInfo).length > 0
                    ? amount_paid.toFixed(2)
                    : null}
                </IonCol>
              </IonRow>
              <IonRow className="order-information__detail-row row-border-bottom">
                <IonCol className="text-align-right">Balance</IonCol>
                <IonCol className="text-align-right text-color-secondary">
                  £
                  {Object.keys(orderInfo).length > 0
                    ? balance.toFixed(2)
                    : null}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow>
            {/* Reorder button */}
            <IonCol className="text-align-right">
              <IonButton
                className="order-information__button"
                onClick={reorder}
              >
                Reorder
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default Information;
