import './OrderSummaryItem.css';
import BlendIcon from '../../assets/icons/blend_card_icon.svg';
import { IonIcon } from '@ionic/react';
import currencyFormatter from '../../helpers/currencyFormatter';
import {
  getBagSizeDisplayName,
  getGrindDisplayName,
} from '../../helpers/getDisplayNames';

interface Props {
  cartItem: any;
}

const OrderSummaryItem: React.FC<Props> = ({ cartItem }) => {
  return (
    <div className="orderSummaryItem">
      <IonIcon className="orderSummaryItem__icon" icon={BlendIcon} />

      <div className="orderSummaryItem__info">
        <p className="orderSummaryItem__name">{cartItem.name}</p>
        <p className="orderSummaryItem__roast">
          <span>{getGrindDisplayName(cartItem.grind_id)}</span>,
          <span>{getBagSizeDisplayName(cartItem.bag_size_id)}</span>
        </p>
        <p className="orderSummaryItem__qty">
          Number of bags: {cartItem.quantity}
        </p>
      </div>

      <span className="orderSummaryItem__price">
        {currencyFormatter(cartItem.subtotal)}
      </span>
    </div>
  );
};

export default OrderSummaryItem;
