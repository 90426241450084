import { IonIcon } from '@ionic/react';
import RoastBeanIcon from '../../assets/icons/roasted_bean_icon.svg';
import UnroastedBeanIcon from '../../assets/icons/unroasted_bean_icon.svg';
import './BeansIcons.css';

interface Props {
  roastId: number;
}

const BeansIcons: React.FC<Props> = ({ roastId }) => {
  let beansIcons;

  switch (roastId) {
    case 1:
      beansIcons = (
        <ul className="beansIcons__list">
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
        </ul>
      );
      break;
    case 2:
      beansIcons = (
        <ul className="beansIcons__list">
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
        </ul>
      );
      break;
    case 3:
      beansIcons = (
        <ul className="beansIcons__list">
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
        </ul>
      );
      break;
    case 4:
      beansIcons = (
        <ul className="beansIcons__list">
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
        </ul>
      );
      break;
    case 5:
      beansIcons = (
        <ul className="beansIcons__list">
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={UnroastedBeanIcon} />
          </li>
        </ul>
      );
      break;
    case 6:
      beansIcons = (
        <ul className="beansIcons__list">
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
          <li className="beansIcons__listItem">
            <IonIcon icon={RoastBeanIcon} />
          </li>
        </ul>
      );
      break;
    default:
      beansIcons = <p>Something went wrong</p>;
      break;
  }

  return beansIcons;
};

export default BeansIcons;
