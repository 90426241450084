// Core React
import { useState, useEffect, useContext } from 'react';

// Global State
import BlendCreatorContext from '../../context/BlendCreator/blendCreatorContext';

// Ionic Components
import { IonContent, IonModal, IonPage, IonToast } from '@ionic/react';

// Custom Components
import Banner from '../../components/Banner/Banner';
import { Footer } from '../../components/Layout/Footer';
import { Header } from '../../components/Layout/Header';
import BlendingBoard from '../../components/BlendingBoard/BlendingBoard';
import SelectFromBeans from '../../components/SelectFromBeans/SelectFromBeans';
import ChooseRoast from '../../components/ChooseRoast/ChooseRoast';
import ChooseBlendName from '../../components/ChooseBlendName/ChooseBlendName';
import CreatedBlendSummary from '../../components/CreatedBlendSummary/CreatedBlendSummary';

// Styling
import './Blend.css';
import { Helmet } from 'react-helmet';

const Blend = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    blendingStep,
    setBlendingStep,
    loadBlendCreatorData,
    showAddToCartToast,
    setShowAddToCartToast,
  } = useContext(BlendCreatorContext);

  useEffect(() => {
    loadBlendCreatorData();

    //eslint-disable-next-line
  }, []);

  return (
    <IonPage className="blendpage">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blend Creator</title>
      </Helmet>
      <IonContent>
        <Header />

        <IonModal
          onDidDismiss={() => setShowModal(false)}
          isOpen={showModal}
          cssClass="blendpage__modal"
        >
          <SelectFromBeans modalToggle={setShowModal} />
        </IonModal>

        <Banner
          options={{
            h1: 'Create your own unique coffee blend using',
            h2: 'Our Blend Creator',
            bgImg: 'assets/images/create-blend.png',
            size: 'half',
          }}
        />

        <div className="blendpage__content">
          <div className="blendpage__stepsBtn">Step {blendingStep} of 4</div>

          {blendingStep === 1 && (
            <BlendingBoard
              showModal={setShowModal}
              setBlendingStep={setBlendingStep}
            />
          )}

          {blendingStep === 2 && (
            <ChooseRoast setBlendingStep={setBlendingStep} />
          )}

          {blendingStep === 3 && (
            <ChooseBlendName setBlendingStep={setBlendingStep} />
          )}

          {blendingStep === 4 && (
            <CreatedBlendSummary setBlendingStep={setBlendingStep} />
          )}
        </div>

        <IonToast
          isOpen={showAddToCartToast}
          onDidDismiss={() => setShowAddToCartToast(false)}
          message="Added Blend To Cart"
          duration={5000}
          color="secondary"
        />

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Blend;
