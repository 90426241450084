import { useContext } from 'react';
import UserCartContext from '../../context/UserCart/userCartContext';
import { useHistory } from 'react-router-dom';
import { IonButton } from '@ionic/react';
import currencyFormatter from '../../helpers/currencyFormatter';
import './CartSummary.css';

const CartSummary = () => {
  const history = useHistory();
  const { cartPricingData } = useContext(UserCartContext);

  return (
    <div className="cartsummary">
      <h4 className="cartsummary-title">Totals</h4>
      <div className="cartsummary-subtotal">
        <span className="cartsummary-text">Sub Total</span>

        <span className="cartsummary-price">
          {cartPricingData && currencyFormatter(cartPricingData.subtotal)}
        </span>
      </div>
      <div className="cartsummary-voldiscount">
        <span className="cartsummary-text">Volume Discount</span>

        <span className="cartsummary-price">
          {cartPricingData &&
            currencyFormatter(-cartPricingData.volume_discount)}
        </span>
      </div>
      <div className="cartsummary-total">
        <span className="cartsummary-text">Total</span>

        <span className="cartsummary-price">
          {cartPricingData && currencyFormatter(cartPricingData.total)}
        </span>
      </div>

      <div className="cartsummary-ctaBtns">
        <IonButton className="secondary" onClick={() => history.goBack()}>
          Back
        </IonButton>
        <IonButton className="warning" routerLink="/checkout">
          Confirm
        </IonButton>
      </div>
    </div>
  );
};

export default CartSummary;
