import Rating from './Rating';
import './TestimonialCard.css';

interface Props {
  thumbnail: string;
  name: string;
  rating: number;
  content: string;
  id: number;
}

const TestimonialCard: React.FC<Props> = ({
  thumbnail,
  name,
  rating,
  content,
}) => {
  return (
    <div className="testimonialcard">
      <div className="testimonialcard__thumbnail">
        <img src={thumbnail} alt={name} />
      </div>

      <div className="testimonialcard__content">
        <div className="testimonialcard__namerating">
          <span>{name}</span>
          <Rating rate={rating} />
        </div>

        <p className="testimonialcard__review">{content}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;
