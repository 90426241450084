import {
  IonGrid,
  IonRow,
  IonCol,
  IonThumbnail,
  IonIcon,
  IonToggle,
} from "@ionic/react";
import { person } from "ionicons/icons";
import React, { useContext } from "react";
import "./ProfileHeader.css";
import userProfileContext from "../../context/UserProfile/userProfileContext";

const ProfileHeader: React.FC = () => {
  const { userProfileData, userType } = useContext(userProfileContext);
  return (
    <div className="profile-header">
      {/* User Profile Header */}
      <div className="profile-header__container">
        <IonGrid className="profile-header__header">
          <IonRow>
            <IonCol className="profile-header__header__image">
              <IonThumbnail>
                <IonIcon icon={person}></IonIcon>
              </IonThumbnail>
            </IonCol>
            <IonCol className="profile-header__header__details">
              <h4 className="profile-heading">Profile</h4>
              <h4 className="profile-name">
                {userProfileData ? userProfileData.full_name : null}
              </h4>
              <p>{userProfileData ? userProfileData.email : null}</p>
              <p>{userType ? userType.display_name : null}</p>
              <p>
                {userProfileData ? userProfileData.trial_days_remaining : null}{" "}
                days left on trial
              </p>
              <p className="profile-user-id">
                ID {userProfileData ? userProfileData.id : null}
              </p>
            </IonCol>
            <IonCol className="profile-header__header__order">
              <h4 className="profile-heading">Predictive Ordering</h4>
              <IonToggle />
              <p className="predictive-description">
                This will let the Blendly system to automatically book an order
                for you in times that we detect that you need a fresh coffee
                supply. Your automatic reorder will be based on your last order
                and past order patterns. Will be subject for approval and
                confirmation to you first. Slide to the right to activate.
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default ProfileHeader;
