// Custom Components
import HomePageHeader from '../SectionHeaders/HomePageHeader/HomePageHeader';

// Styling
import './Intro.css';

export const Intro: React.FC = () => (
  <section className="intro">
    <HomePageHeader
      words={[
        { word: 'Intro to', color: '#915A3C' },
        { word: 'Blendly', color: '#1F4E46' },
      ]}
      line={true}
    />

    <div className="intro__content">
      <div className="intro__content-left">
        <p>Blendly allows sustainable and transparent access to fresh coffee production for the global coffee consumer by giving them transparent 
          access to the components that make up your everyday cup of coffee.</p>
        <p>Blendly's decentralized approach to achieving sustainable commercial coffee roasting allows raw materials purchasing, 
          production, manufacturing, and distribution to be commissioned independently. Adding a layer of flexibility to the current mass production models.</p>
        <p>Blendly builds its production base around smaller more flexible batch sizes that can be supplied directly, 
          reducing economic and environmental impact to end users by removing the need for additional user storage and redistribution costs.</p>
      </div>
      <div className="intro__content-right">
        <p>Blendly gives communities the tools to create a more dynamic and customizable service with products that are fresher and unique, 
          focused on sSustainability through economic innovation and digital integration, allowing coffee consumers greater scope to create, 
          develop, manage and build greater sustainable value.</p>
        <p><span className="homepageheader_intro_span">Blendly - Your coffee fresher, quicker and better.</span></p>
      </div>
    </div>
  </section>
);
