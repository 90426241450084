// Core React
import { useContext } from 'react';

// Global State
import BlendCreatorContext from '../../../context/BlendCreator/blendCreatorContext';

// Ionic Icons
import { trashOutline } from 'ionicons/icons';

// Ionic Components
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonRange,
  IonRouterLink,
} from '@ionic/react';

// Styling & Assets
import './BlendingBeanCard.css';
import BeanIcon from '../../../assets/icons/bean_card_icon.svg';

interface Props {
  beanData: {
    id: number;
    ctaps_id: string;
    name: string;
    percentage: number;
  };
}

const BlendingBeanCard: React.FC<Props> = ({ beanData }) => {
  const { adjustBeanPercentage, removeFromBlendRecipes, blend_recipes } =
    useContext(BlendCreatorContext);

  const getTotalBlendPercentage = () => {
    return blend_recipes.reduce(
      (acc: number, bean: any) => acc + bean.percentage,
      0
    );
  };

  const calculateMaxRange = (percentage: number) => {
    let total = getTotalBlendPercentage();

    return 100 - (total - percentage);
  };

  return (
    <>
      <td className="blendingBeanCard__img">
        <img src={BeanIcon} alt="" />
      </td>
      <td className="blendingBeanCard__ctaps">
        <IonRouterLink routerLink={`/cuppingnotes/${beanData.ctaps_id}`}>
          {beanData.ctaps_id}
        </IonRouterLink>
      </td>
      <td className="blendingBeanCard__bean">
        <span>{beanData.name}</span>
      </td>
      <td className="blendingBeanCard__percentage-slider">
        <IonRange
          style={{ width: `${calculateMaxRange(beanData.percentage)}%` }}
          max={calculateMaxRange(beanData.percentage)}
          className="bledingBeanCard__percentage-mainslider"
          pin={true}
          snaps={true}
          disabled={calculateMaxRange(beanData.percentage) === 0}
          value={beanData.percentage}
          onIonChange={(e) =>
            adjustBeanPercentage(beanData.id, e.detail.value as number)
          }
        />
        <IonRange
          className="bledingBeanCard__percentage-mutedslider"
          pin={true}
          disabled={true}
        />
      </td>
      <td className="blendingBeanCard__percentage-select">
        <p>{beanData.percentage}</p>
      </td>
      <td className="blendingBeanCard__removeBtn">
        <IonButtons>
          <IonButton onClick={() => removeFromBlendRecipes(beanData.id)}>
            <IonIcon icon={trashOutline} slot="icon-only"></IonIcon>
          </IonButton>
        </IonButtons>
      </td>
    </>
  );
};

export default BlendingBeanCard;
