import React, { useState, useEffect } from "react";
import {
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonTextarea,
  IonGrid,
} from "@ionic/react";
import axios from "axios";
import config from "../../config";
import publicIp from "public-ip";

import "./form.css";

type Props = {
  bgImage: string;
};

export const Form: React.FC<Props> = ({ bgImage }) => {
  const [firstname, updateFirstname] = useState("");
  const [surname, updateSurname] = useState("");
  const [email, updateEmail] = useState("");
  const [phone, updatePhone] = useState("");
  const [message, updateMessage] = useState("");
  const [ipAdress, setIpAdress] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [erroMessage, setErrorMessage] = useState("");

  useEffect(() => {
    publicIpV4();
  }, []);

  // Test here
  const publicIpV4 = async () => {
    const ipv4 = await publicIp.v4();
    setIpAdress(ipv4);
  };

  const onInputChange = (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case "firstname":
        updateFirstname(value);
        break;
      case "surname":
        updateSurname(value);
        break;
      case "email":
        updateEmail(value);
        break;
      case "number":
        updatePhone(value);
        break;
      case "message":
        updateMessage(value);
        break;
      default:
        break;
    }

    // Remove Error message on input change
    setIsError(false);
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    setIsSuccess(false);
    axios({
      method: "post",
      url: `${config.apiURL}/questions`,
      data: {
        firstName: firstname,
        lastName: surname,
        email: email,
        phone: phone,
        message: message,
        ip: ipAdress,
      },
    })
      .then((res) => {
        setIsError(false);
        setIsSuccess(true);
        updateFirstname("");
        updateSurname("");
        updateEmail("");
        updatePhone("");
        updateMessage("");
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        setIsError(true);
        setIsSuccess(false);
      });
  };

  return (
    <div
      id="form-section"
      className="form-bg"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <IonRow>
        <IonCol size="6" offset="3">
          <h2 className="lightCTA">My question wasn’t answered</h2>
          <p className="intro-sub">
            Chat with us or drop us a call. We'd be glad to help you further.
          </p>
        </IonCol>
      </IonRow>
      <form className="faq__form" onSubmit={onFormSubmit}>
        <IonGrid>
          {/* First and Lastname Row */}
          <IonRow className="faq__form__rows name-fields">
            <IonCol>
              <IonInput
                type="text"
                value={firstname}
                placeholder="Firstname"
                name="firstname"
                required
                onIonChange={onInputChange}
                className="form-input"
              ></IonInput>
            </IonCol>
            <IonCol>
              <IonInput
                type="text"
                value={surname}
                placeholder="Surname"
                name="surname"
                required
                onIonChange={onInputChange}
                className="form-input"
              ></IonInput>
            </IonCol>
          </IonRow>
          {/* Email Row */}
          <IonRow className="faq__form__rows">
            <IonCol>
              <IonInput
                type="email"
                value={email}
                placeholder="Email"
                name="email"
                required
                onIonChange={onInputChange}
                className="form-input"
              ></IonInput>
            </IonCol>
          </IonRow>
          {/* Phone Number Row */}
          <IonRow className="faq__form__rows">
            <IonCol>
              <IonInput
                type="number"
                value={phone}
                placeholder="Phone Number"
                name="number"
                required
                onIonChange={onInputChange}
                className="form-input"
              ></IonInput>
            </IonCol>
          </IonRow>
          {/* Message Row */}
          <IonRow className="faq__form__rows">
            <IonCol>
              <IonTextarea
                rows={10}
                value={message}
                name="message"
                required
                onIonChange={onInputChange}
                placeholder="Message"
                className="form-input"
              ></IonTextarea>
            </IonCol>
          </IonRow>
          {/* Submit buttons */}
          <IonRow>
            <IonCol>
              <IonButton type="submit" className="form-submit" color="#FDAB40">
                Submit
              </IonButton>
            </IonCol>
          </IonRow>
          {/* Error and succesful messages */}
          <div className="faqs-form__messages">
            {isSuccess ? (
              <p className="faqs-form__messages__success">
                Your question has been submitted. One of our agents will reach
                out to you as soon as we can
              </p>
            ) : null}
            {isError ? (
              <p className="faqs-form__messages__error">{erroMessage}</p>
            ) : null}
          </div>
        </IonGrid>
      </form>
    </div>
  );
};
