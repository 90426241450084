import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";
import moment from "moment";
import "./Billing.css";
import orderDetailsContext from "../../../../context/OrderDetails/orderDetailsContext";

const Billing: React.FC = () => {
  const { orderInfo, orderContries, orderPayments, orderPaymentGateways } =
    useContext(orderDetailsContext);
  const {
    full_name,
    company_name,
    shipping_cost,
    subtotal,
    volume_discount,
    total,
    amount_paid,
    balance,
    phone,
    address,
    city,
    zip_code,
    province,
    country_id,
  } = orderInfo;

  const getCountry = () => {
    if (orderContries.length > 0) {
      const country = orderContries.filter((item: any) => {
        return item.id === country_id;
      });

      return country[0].value;
    }

    return <></>;
  };

  const getPayment = () => {
    if (orderPayments.length > 0) {
      //   £66.00 via <strong>Paypal</strong> on Sep 22, 2021
      const { amount, created_at, payment_gateway_id } = orderPayments[0];
      const paymentGateway = orderPaymentGateways.filter((item: any) => {
        return item.id === payment_gateway_id;
      });
      return `£${amount.toFixed(2)} via ${paymentGateway[0].value} on ${moment(
        created_at
      ).format("MMM D, YYYY")}`;
    } else {
      return "No payments made yet";
    }
  };

  return (
    <div className="order-billing">
      <h4 className="order-billing__header">Details</h4>
      {/* Billing Details */}
      <IonGrid>
        <IonRow>
          <IonCol className="order-billing__billing-header title-text-color">
            Billing Details
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="order-billing__billing-details">
            <p>{full_name}</p>
            <p>{company_name}</p>
            <p>{address}</p>
            <p>{`${city}, ${province} ${zip_code}`}</p>
            <p>{getCountry()}</p>
            <p>{phone}</p>
          </IonCol>
        </IonRow>
      </IonGrid>
      {/* Payments */}
      <IonGrid>
        <IonRow>
          <IonCol className="order-billing__payments-header title-text-color">
            <IonRow>
              <IonCol>Payments</IonCol>
              <IonCol className="order-billing__payments-details">
                {getPayment()}
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol className="order-billing__detail-container">
            <IonRow className="order-billing__detail-row row-border-bottom">
              <IonCol className="title-text-color">Totals</IonCol>
            </IonRow>
            <IonRow className="order-billing__detail-row row-border-bottom">
              <IonCol>Shipping Cost</IonCol>
              <IonCol className="text-align-right">
                £{shipping_cost.toFixed(2)}
              </IonCol>
            </IonRow>
            <IonRow className="order-billing__detail-row row-border-bottom">
              <IonCol>Sub Total</IonCol>
              <IonCol className="text-align-right">
                £{subtotal.toFixed(2)}
              </IonCol>
            </IonRow>
            <IonRow className="order-billing__detail-row row-border-bottom">
              <IonCol>Volume Discount</IonCol>
              <IonCol className="text-align-right text-color-secondary">
                -£{volume_discount.toFixed(2)}
              </IonCol>
            </IonRow>
            <IonRow className="order-billing__detail-row row-border-bottom">
              <IonCol className="text-align-right">Total</IonCol>
              <IonCol className="text-align-right">£{total.toFixed(2)}</IonCol>
            </IonRow>
            <IonRow className="order-billing__detail-row row-border-bottom">
              <IonCol>Amount Paid</IonCol>
              <IonCol className="text-align-right text-color-secondary">
                -£{amount_paid.toFixed(2)}
              </IonCol>
            </IonRow>
            <IonRow className="order-billing__detail-row row-border-bottom">
              <IonCol className="text-align-right">Balance</IonCol>
              <IonCol className="text-align-right text-color-secondary">
                £{balance.toFixed(2)}
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Billing;
