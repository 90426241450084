import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Header } from '../../components/Layout/Header';
import { Footer } from '../../components/Layout/Footer';
import '../TermsPolicyStyles.css';
import { Helmet } from 'react-helmet';

const ReturnPolicy: React.FC = () => {
  return (
    <IonPage>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Return Policy</title>
      </Helmet>
      <IonContent>
        <Header />
        <div className="terms-policy">
          <h1 className="terms-policy__header">Newbeans Returns Policy</h1>
          <p
            className="terms-policy__lead-text"
            style={{ textAlign: 'center' }}
          >
            We know that you will be pleased with your purchases from
            blendly.co.uk. <br /> However there may be occasions when you will
            need to return items to us.
          </p>

          <div className="terms-policy__grid-items">
            <div>
              <p className="terms-policy__section-header">
                <strong>Items Damaged in Transit</strong>
              </p>
              <p className="terms-policy__section-body">
                If any items were damaged in transit we ask that you report it
                to us within 7 working days (please note that this is 48 hours
                for our business customers). If the items are visibly damaged on
                receipt it is best to sign the carrier's delivery note
                accordingly. Items should be returned in their original
                packaging complete with all accessories and documentation. Once
                received back into our warehouse we will issue a replacement or
                full refund to you via your original payment method and
                reimburse your reasonable return carriage costs
              </p>
            </div>

            <div>
              <p className="terms-policy__section-header">
                <strong> Items Faulty on Arrival</strong>
              </p>
              <p className="terms-policy__section-body">
                If your items are faulty on arrival you have 3 calendar days in
                which to inform us of the fault (please note that for our
                business customers this is 2 calendar days). Items should be
                returned in their original packaging complete with all
                accessories and documentation. Once we have verified the fault
                we will issue a replacement or full refund to you via your
                original payment method and reimburse your reasonable return
                carriage costs. We test returned items, and, if a returned item
                is found not to be faulty by our technicians, we will return the
                item to you and in such an instance you will be liable for the
                return carriage.
              </p>
            </div>

            <div>
              <p className="terms-policy__section-header">
                <strong> Items Faulty in Warranty Period</strong>
              </p>
              <p className="terms-policy__section-body">
                If any of your purchases develop a fault and it's more than 28
                calendar days since receipt, then provided your item is within
                its warranty period, you are entitled to a warranty repair. In
                some cases, manufacturers provide a specialist full on-site
                service and/or telephone help facilities for your convenience
                which we recommend you use in order to correct the fault
                quickly. For business customers all warranty repairs after 28
                days of receipt are referred directly to the manufacturer
                (unless otherwise stated)
              </p>
            </div>

            <div>
              <p className="terms-policy__section-header">
                <strong> Open-Box Items (Used)</strong>
              </p>
              <p className="terms-policy__section-body">
                Please note that our open-box (used) stock is sold with 90
                calendar days warranty only and therefore any claims under the
                warranty after 90 days of receipt will not be accepted. Any
                other stock will have the guarantee period noted.
              </p>
            </div>

            <div>
              <p className="terms-policy__section-header">
                <strong>If you change your mind</strong>
              </p>
              <p className="terms-policy__section-body">
                If you have simply changed your mind about any item ordered and
                you wish to return it, then in line with the Distance Selling
                Regulations (DSR) you can do so provided you inform us of your
                decision within 7 days of receipt. The item must not be used and
                must be 'as new' when returned to us. Once you've informed us
                that you wish to return goods under the DSR, you have 7 calendar
                days to do so, at your own expense. Once the item is received at
                NEWBEANS.CO.UK designated returns point, we'll issue a full
                refund for the product to your original payment method. Please
                note this policy has some limitations and does not apply to
                business customers.(The Distance Selling Regulations do not
                apply to @ work customers or our Business customers).
              </p>
            </div>

            <div>
              <p className="terms-policy__section-header">
                <strong>Need to return an item?</strong>
              </p>
              <p>
                You can request a return by emailing your order number to:
                <a href="mailto:sales@blendly.co.uk">sales@blendly.co.uk</a>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default ReturnPolicy;
