// Core React
import { useContext, useEffect, useRef } from "react";

// Ionic Components
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonToolbar,
} from "@ionic/react";

// Ionic Icons
import {
  extensionPuzzleOutline,
  informationCircleOutline,
  cartOutline,
  caretForwardCircleOutline,
  cafeOutline,
  storefrontOutline,
  newspaperOutline,
  closeOutline,
  powerOutline,
} from "ionicons/icons";

// Styling & Assets
import "./MobileNavbarMenu.css";
import BlendlyLogo from "../../assets/images/blendly-logo.png";
import BeanLogo from "../../assets/icons/bean_card_icon.svg";
import mobileMenuContext from "../../context/MobileMenu/mobileMenuContext";

const MobileNavbarMenu = () => {
  const { openMenu, openMenuAction } = useContext(mobileMenuContext);
  const contentRef = useRef<HTMLIonMenuElement | null>(null);

  useEffect(() => {
    if (openMenu) {
      contentRef.current?.open(openMenu);
    }
  }, [openMenu]);

  // Close menu functionality
  const closeMenu = () => {
    contentRef.current?.close();
    openMenuAction(false);
  };

  // Get Authentication Token
  const blendlyAuthToken = localStorage.getItem("blendlyAuthToken");

  // Logout fuctionality on mobile
  const logOut = () => {
    localStorage.removeItem("blendlyAuthToken");
    window.location.href = "/";
  };

  return (
    <IonMenu
      className="mobileMenu"
      side="start"
      content-id="main-content"
      swipeGesture={false}
      onIonDidClose={closeMenu}
      ref={contentRef}
    >
      <IonHeader>
        <IonToolbar className="mobileMenu__toolbar">
          <img height="50" width="50" src={BlendlyLogo} alt="blendly logo" />
          <IonIcon
            onClick={closeMenu}
            icon={closeOutline}
            className="mobileMenu__closeBtn"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList className="mobileMenu__navlist">
          <IonItem routerLink="/aboutus" onClick={closeMenu}>
            <IonIcon icon={informationCircleOutline} slot="start"></IonIcon>
            <IonLabel>About Us</IonLabel>
          </IonItem>
          <IonItem routerLink="/shop" onClick={closeMenu}>
            <IonIcon icon={cartOutline} slot="start"></IonIcon>
            <IonLabel>Shop</IonLabel>
          </IonItem>
          <IonItem routerLink="/wholesale" onClick={closeMenu}>
            <IonIcon icon={storefrontOutline} slot="start"></IonIcon>
            <IonLabel>Wholesale</IonLabel>
          </IonItem>
          <IonItem routerLink="/subscription" onClick={closeMenu}>
              <IonIcon icon={caretForwardCircleOutline} slot="start"></IonIcon>
              <IonLabel>Subscriptions</IonLabel>
            </IonItem>
          {/* {blendlyAuthToken === null ? (
            <IonItem routerLink="/subscription" onClick={closeMenu}>
              <IonIcon icon={caretForwardCircleOutline} slot="start"></IonIcon>
              <IonLabel>Subscriptions</IonLabel>
            </IonItem>
          ) : (
            <IonItem routerLink="/" onClick={closeMenu}>
              <IonIcon icon={caretForwardCircleOutline} slot="start"></IonIcon>
              <IonLabel>Subscriptions</IonLabel>
            </IonItem>
          )} */}
          <IonItem routerLink="/blend" onClick={closeMenu}>
            <IonIcon icon={BeanLogo} slot="start"></IonIcon>
            <IonLabel>Blend</IonLabel>
          </IonItem>
          <IonItem routerLink="/cuppingnotes" onClick={closeMenu}>
            <IonIcon icon={cafeOutline} slot="start"></IonIcon>
            <IonLabel>Cupping Notes</IonLabel>
          </IonItem>
          <IonItem routerLink="/tastematch" onClick={closeMenu}>
            <IonIcon icon={extensionPuzzleOutline} slot="start"></IonIcon>
            <IonLabel>Taste Match</IonLabel>
          </IonItem>
          <IonItem href="/blog" onClick={closeMenu}>
            <IonIcon icon={newspaperOutline} slot="start"></IonIcon>
            <IonLabel>Blog</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        {/* Login and Logout button */}
        {blendlyAuthToken ? (
          <IonItem onClick={logOut} className="mobileMenu__authButtons">
            <IonIcon icon={powerOutline} slot="start"></IonIcon>
            <IonLabel>Logout</IonLabel>
          </IonItem>
        ) : (
          <IonItem
            routerLink="/login"
            onClick={closeMenu}
            className="mobileMenu__authButtons"
          >
            <IonIcon icon={powerOutline} slot="start"></IonIcon>
            <IonLabel>Login</IonLabel>
          </IonItem>
        )}
      </IonFooter>
    </IonMenu>
  );
};

export default MobileNavbarMenu;
