import { IonContent, IonPage } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import { Header } from '../../components/Layout/Header';
import Banner from '../../components/Banner/Banner';
import { Accordian } from '../../components/Accordian/accordian';

import './faqs.css';
import { Form } from '../../components/Form/form';
import { Footer } from '../../components/Layout/Footer';
import { Helmet } from 'react-helmet';

const FAQs: React.FC = () => {
  return (
    <IonPage>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAQ</title>
      </Helmet>
      <IonContent>
        <Header />
        <Banner
          options={{
            h1: 'Frequently',
            h2: 'Asked questions',
            bgImg: 'assets/images/faq-header.png',
            size: 'half',
          }}
        />
        {/* Accordian Section */}
        <div className="accordian__container">
          <Accordian title="How does the Blendly service work?">
            <span>
              <p>
                It's a simple service that allows you to pick what goes in your
                coffee. Blendly understands the systems and production processes
                in the creation of fresh coffee, and we implement these systems
                into our own production and manufacturing plants. Our production
                processes are designed to allow{' '}
                <strong>lots of flexibility and customisation</strong> enabling
                you to create your own flexible product based around a selection
                of green beans.
              </p>
              <p>
                We believe that products whenever possible should be transparent
                to customers to allow greater choice and that these production
                and manufacturing lines should be there to provide you with such
                a choice.
              </p>
            </span>
          </Accordian>
          <Accordian title="What’s the rationale behind Blendly?">
            <span>
              <p>
                Many food products offer great levels of transparency, allowing
                products to change and evolve according to the people, companies
                and customers that use them. We like that approach and feel that
                creating transparent products, knowing the ingredients that go
                into them, and the process used to create them, allows customers
                to create their own unique product.
              </p>
              <p>
                We believe that transparency is the foundation for creating
                great products. By allowing greater transparency, more types of
                coffee can be introduced into people’s homes and customer’s
                businesses. This also enables more coffee lovers to better enjoy
                their coffee experience! Blendly lets you explore the effects
                that different coffee beans can have whether you blend coffee or
                even if you decide to roast single origin coffee separately to
                create your own coffee blend.
              </p>
            </span>
          </Accordian>
          <Accordian title="How do you create transparency in your products?">
            <span>
              <p>
                Transparency is created by breaking up the coffee roasting
                process in three ways (1) purchase of green beans, (2) the
                development of high quality and flexible production, and (3) the
                distribution of coffee products.
              </p>
              <p>
                By allowing customers to make choices in these three areas,
                Blendly increases choice and value as well as removes large
                volumes of coffee from the coffee supply chain, so that it is
                available ‘just in time’ to better increase freshness. This
                allows <strong>fresher</strong>, <strong>quicker</strong>,{' '}
                <strong>better</strong> coffee, but also helps reduce the coffee
                in circulation making it ‘on-demand’.
              </p>
              <p>
                This will allow <strong>fresher</strong>,{' '}
                <strong>quicker</strong>, <strong>better</strong> coffee, but
                also help reduce the coffee in circulation making it an
                on-demand.
              </p>
              <h3>Coffee Certification: Social and Corporate Responsibility</h3>
              <p>
                We believe that choice is important, so we include this when
                considering our company’s corporate and social responsibility.
                Through Blendly, you can create your own corporate and social
                responsibility in every kg you purchase.
              </p>
              <p>
                We use certified coffees as we believe these are important but
                we also believe that local issues have to be considered as well.
                With Blendly we give you the choice to contribute according to
                your own values.
              </p>
              <h3>Using Green Beans</h3>
              <p>
                We use and purchase our own green beans and give you a selection
                to use to create your coffee. We find that most coffees can be
                created from a core of green beans, thus allowing you to create
                almost any product that you can imagine!
              </p>
              <p>
                The beans can also be roasted separately, and if you are looking
                to be totally creative, different roast values allow you to mix
                your own at home, or in your restaurant or coffee shop.
              </p>
              <p>
                We purchase our green beans through distributors that are tasked
                with ethically sourcing and providing the best in quality.
              </p>
              <h3>Where our blends come from</h3>
              <p>
                Our recommendations on our site were as a result of a study with
                one of the UK’s leading specialist roasting plants. We recorded
                coffee blends over time to discover the best way to approach
                what makes great coffee. It appears that what is special is the
                ‘blends’ that people can make themselves.
              </p>
              <p>
                There are no rules when making or roasting coffee it really
                depends on how much you want to experiment! Our blends have also
                been tasted by a variety of coffee people and in many different
                places. This has enabled us to develop systems that allow the
                service to be delivered.
              </p>
              <h3>Blend Recommendations</h3>
              <p>
                The blends on our website are our recommendations from what we
                believe are the best that you would get from any roaster. These
                blends were developed by testing, cupping and interacting with
                many different people. They are also underpinned by over 60
                years of roasting experience; advice from the former head of the
                Speciality Coffee Association of Europe and knowledge from
                others, particularly, roasters in the industry. Our
                recommendations represent a starting point for you to build on
                your experience.
              </p>
              <h3>Roasting Colours</h3>
              <p>
                Roasting colours are based on the colour of the bean after it
                has been roasted in this system. We enable you to roast
                according to 6 colours. Roasting colours allow you to obtain the
                best of the coffee. You can vary them according to your
                requirements. For our smaller artisan coffee products, you can
                choose three separate roast colours to get the best from your
                coffee experience.
              </p>
              <p>
                Roast colours are also important as they allow the flavours to
                be brought out in each coffee bean.
              </p>
            </span>
          </Accordian>
          <Accordian title="What are Cupping Notes?">
            <span>
              <p>
                <a href="/cuppingnotes" target="_blank">
                  Cupping Notes
                </a>{' '}
                are a part of a system called{' '}
                <strong>CTAPS (Coffee Tasting and Profiling System)</strong>.
                However, it's full title stands for
                <strong>
                  {' '}
                  Commodity Trading &amp; Production Manufacturing and
                  Distribution System
                </strong>
                . It was designed to allow the information in each roast to be
                captured based on a set of tasting notes, the roast colour and
                the content of the blend. It is an abbreviated system based on
                the rationale of <strong>coffee tasting and profiling</strong>.
                All our roasters are trained to use it.
              </p>
              <p>
                It originates from the traditional coffee tasting chart that was
                created by the Speciality Coffee Association and provides you
                with a visual representation of the coffee you are using.
              </p>
            </span>
          </Accordian>
          <Accordian title="How much does the coffee costs?">
            <span>
              Your coffee blend prices are based on the individual beans that go
              into each blend. Each of these beans has its own price. These
              coffee beans or green beans have different prices and can
              fluctuate according to the global supply and demand, weather,
              economic factors and its origin.
            </span>
          </Accordian>
          <Accordian title="How much is postage and packaging?">
            <span>
              Postage and packaging is calculated at the checkout step. Our post
              rates vary according to the volume and service you require to get
              your fresh coffee delivered. We can deliver your coffee in so many
              different ways.
            </span>
          </Accordian>
          <Accordian title="How fast do you deliver your coffees?">
            <span>
              We recommend three (3) days, however we are set up for next day
              delivery. If your coffee blend is on predictive ordering and on
              larger batches, this may be two (2) to four (4) days, as the fresh
              coffee needs to be degassed. Our plants are designed for two (2) -
              four (4) day turnaround, but in most cases we can do it in a day!
            </span>
          </Accordian>
          <Accordian title="How do you go about blending your coffees">
            <span>
              <p>
                Our research indicated that most coffee blends are created
                between the roaster and the business customer. If you are a
                large coffee user, our service allows you to create a blend
                based on your taste and your choice. The customer interacts with
                the process, thus giving the coffee drinker choice by creating
                more flavours and tastes.
              </p>
              <h3>Our Blends Data</h3>
              <p>
                Consistency is the basis of great coffee. Our blends data was as
                a result of a study of blends that were sold from coffee
                roasters, including speciality coffee roasting. The data
                indicated that most agreed that these are guidelines of what the
                coffee will taste like based on ideal cupping notes.
              </p>
              <p>
                Cupping coffee is not an exact science, and we encourage you to
                taste and cup your own blends to help create your own perfect
                coffee blend.
              </p>
              <h3>The importance of coffee blends</h3>
              <p>
                All coffees sold are a mix of different coffee beans. Blending
                is important as it not only allows roasters to create products
                that are unique, but manage the green beans that are used to
                create the coffee you drink. For many years blending has been
                the basis of all coffee across the world.
              </p>
              <p>
                Coffee Blending began in 1895. Blending allowed coffee to
                develop its taste and introduce a new innovation. With modern
                production manufacturing, combined with the internet, we, at
                Blendly, can extend this service to all who have a love of
                creating and developing products that are unique to them.
              </p>
            </span>
          </Accordian>
          <Accordian title="What if I don’t like my blend?">
            <span>
              All Blends are good however the satisfaction of creating your own
              is perhaps the best and more satisfying! Most coffees that are
              created are based on a tasting journey. If you are unsure about
              your preference you can use our Cupping Notes system to create
              your own and continue to do so until you get the one blend you
              want and of course share your experience with family, friends and
              colleagues!
            </span>
          </Accordian>
          <Accordian title="Can I add my own personal blend? ">
            <span>
              Yes! We would love you to create your own blends. By doing so you
              will learn about the coffee that creates your blends, gain
              knowledge of the parts of the world it is from and you can share
              your experience with others. You can use the Cupping Notes to test
              your blending skills by comparing other brands and other blends on
              the system.
            </span>
          </Accordian>
          <Accordian title="How do you assure the quality of your coffees?">
            <span>
              <p>
                Blendly production lines are built on a level of quality and
                control as we operate to the highest and most up to date
                environmental and quality standards, such as the{' '}
                <i>Rainforest</i>, <i>Organic</i>, <i>KLBA</i>, <i>Cosha</i>,{' '}
                <i>Speciality</i> and <i>Soil Association</i> standards.
              </p>
              <h3>Plant Inspection</h3>
              <p>
                All our production lines are visited on a regular basis to
                ensure quality control, and we practice the latest practices in
                environmental stewardship.
              </p>
              <h3>Training and development (with Partners)</h3>
              <p>
                All our personnel are trained to handle the beans using the
                highest standards referred to above.{' '}
                <strong>
                  Training is also a large part of the process of producing
                  great coffee and we offer a range of training at our
                  production lines to help better understand the process of
                  coffee blending, and coffee making.
                </strong>
              </p>
            </span>
          </Accordian>
          <Accordian title="How can I share my blend?">
            <span>
              At the outset, the Blendly team recognised that the ability to
              share and compare tastes and blends was a big part of enjoying a
              great cup of coffee. Blendly allows you to create a blend and
              publish it to the platform or social media.
            </span>
          </Accordian>
          <Accordian title="What if I don't want to share my blend?">
            <span>
              Over the years we have been roasting coffee and we respect that
              some of our customers may not want to share their coffee blend. In
              your account you can choose to keep your blend private from the
              Blendly marketplace.
            </span>
          </Accordian>
          <Accordian title="How do you create a coffee blend">
            <span>
              <p>
                All our coffee blends are roasted fresh. To create a coffee
                blend you can start off by{' '}
                <a href="/">looking up your preferred brand</a> or from{' '}
                <a href="/cuppingnotes">
                  selecting one of the many exciting blends made by Blendly and
                  other coffee lovers
                </a>{' '}
                and modify it or you can go straight to the{' '}
                <a href="/blend">Blend Creator</a> and start creating your blend
                yourself. Many companies are already providing the mixes for
                specific coffee blends that you can use. You can experiment and
                create blends until you find the exact match for your palate.
              </p>
            </span>
          </Accordian>
          <Accordian title="Can I use another company's blend mix?">
            <span>
              You can go on any coffee company's website and look at the regions
              their coffee comes from. Using that information you can begin to
              create your own blend. Almost everyone uses the same raw material
              base when creating coffee especially if it's a volumised brand.
            </span>
          </Accordian>
          <Accordian title="Can I use micro lots in my blend?">
            <span>
              You can use micro lots and Cups of Excellence in your blend which
              we also keep in stock. If you’re interested in this we will be
              happy to supply a list.
            </span>
          </Accordian>
          <Accordian title="How can I earn money blending?">
            <span>
              Blendly accounts can be upgraded to allow you to supply coffee to
              your colleagues, friends or coffee shop customers. Every account
              features a Community Builder add-on that lets you manage your own
              customers
            </span>
          </Accordian>
          <Accordian title="How can sharing a blend earn an income?">
            <span>
              <p>
                The{' '}
                <a href="#!/community-builder-purchase">Community Builder</a>{' '}
                accounts are designed to allow you to create value as you build
                your knowledge of the coffee beans and tastes. As your knowledge
                increases you can upgrade your account to access lower cost
                coffee that's available in bigger volumes and create and manage
                coffee supply accounts to homes, offices and workplaces.
              </p>
            </span>
          </Accordian>
          <Accordian title="How does Predictive Ordering work?">
            <span>
              Predictive ordering is an intelligent way to keep your coffee
              fresh. Blendly learns your coffee drinking habits and predicts
              when you might want your next delivery. We believe in keeping our
              supply chain as fresh as possible.
            </span>
          </Accordian>
          <Accordian title="What is a Blendly E-Procurement Consultant?">
            <span>
              A Blendly E-Procurement Consultant is a Blendly Regional Trained
              Coffee Partner that has access to more facilities within the
              Blendly ecosystem and has a responsibility for specific areas or
              specific markets in the distribution of the Blendly brand
            </span>
          </Accordian>
          <Accordian title="Can I add my social media link to a blend?">
            <span>
              Every coffee blend had its own digital fingerprint that makes it
              unique to the person that created it called CTAPS. CTAPS numbers
              and URLs can be shared on social media platforms and can be used
              to promote your skills as a coffee blender.
            </span>
          </Accordian>
          <Accordian title="How can I use the taste matching service?">
            <span>
              <p>
                Over the years we have matched most commercial coffee blends.
                This is done the traditional way via cupping the blend. We have
                over 60 years of cupping and roasting experience and we are
                happy to use this experience to enhance your blending
                experience.
              </p>
              <p>
                To use the <a href="/tastematch">taste matching service</a>,
                just head on to this <a href="/tastematch">link</a> and fill-up
                the form. We'll then get in touch with your taste matched blend
                as soon as possible.
              </p>
            </span>
          </Accordian>
          <Accordian title="How do coffee credits work?">
            <span>
              Coffee credits were developed to allow the coffee blending
              experience to be transmitted digitally and allow you to create
              prepaid vouchers. For affiliates and coffee blenders, they allow
              you to add other products and services and create product bundles
              that can be transmitted in many different ways.
            </span>
          </Accordian>
          <Accordian title="Can I have a credit account?">
            <span>
              All our customers have accounts and over time have to use our
              system for many different uses and ways. We are happy to consider
              credit facilities to help grow your network and your sales.
            </span>
          </Accordian>
          <Accordian title="How can I manage home deliveries for my friends and family?">
            <span>
              Your account allows you to take orders from your friends and
              family and help them manage their account through the Community
              Builder add-on. The add-on allows you to manage the list of people
              you want to share your blends to. It also gives you a referral
              link that invites people to join your community. You generate an
              income everytime they purchase coffee.
            </span>
          </Accordian>
          <Accordian title="Why do I need a referral link?">
            <span>
              A referral link is an invitation for other people to join your
              community. Each coffee blend that you've created contains its own
              unique digital code and also serves as an invitation. A referral
              link allows you to send the value of your blend to other people
              and for that value to be recorded in your account through the
              Community Builder add-on.
            </span>
          </Accordian>
          <Accordian title="Where do our blends come from?">
            <span>
              <p>
                We have roasted coffee for many years for all types of
                customers. Our coffee blends have been developed by top baristas
                that use our service to create and manage their coffee supply.
              </p>
              <p>
                The coffee blends on our the platform are consumed in homes,
                offices, coffee shops and hotels throughout the UK and beyond.
                The development of the platform was a result of a study of
                thousands of cups of coffee consumed by hundreds of thousands of
                people. A lot of time and research has been invested in
                understanding what our customers want and helping them find
                their perfect coffee. In doing so the platform represents years
                and years of drinking their own great coffee.
              </p>
              <p>
                We have recorded coffee blends over time to discover the best
                way to approach what makes great coffee. It appears that what is
                special are the “blends” that people can make themselves.
              </p>
              <p>
                There are no rules when making or roasting coffee. It really
                depends on how much you want to experiment! Our blends have also
                been tasted by a variety of coffee people and in many different
                places. This has enabled us to develop systems that allow the
                service to be delivered.
              </p>
            </span>
          </Accordian>
          <Accordian title="Interested in becoming a Partner?">
            <span>
              <p>
                If you are interested in becoming a coffee partner, developing
                your own blend, we offer specialist training for baristas and
                customers who are looking to grow their business with us. We
                offer help in the creating your own blend as well as providing
                knowledge and a range of skills based around coffee, including:
              </p>
              <p>• Start-up and running a coffee business</p>
              <p>• Economics of coffee – both local and global</p>
              <p>• Selecting beans and understanding tastes and blending</p>
              <p>
                • The Blendly Experience: ecosystem production training on
                improving yields, products and developing multichannel sales
              </p>
              <p>
                • The Blendly Roasting School: understanding the roasting
                process, cupping and blend development
              </p>
              <p>
                • The Blendly for Retail Dvelopment: developing your blend as a
                multi channel product
              </p>
              <p>
                • The Blendly Sales: the customer conversation, developing
                feedback about your blend
              </p>
            </span>
          </Accordian>
          {/* Boiler Plate For Individual Accordian */}
          {/* <Accordian title="Question">
            <span>
              LoremLoremLoremLoremLoremLoremLorem
            </span>
          </Accordian> */}
        </div>

        {/* FAQ Form */}
        <Form bgImage="assets/images/faq-form-banner.png" />

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default FAQs;
