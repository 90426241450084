// Core React
import { useState, useContext, useEffect } from 'react';

// Global State
import userCartContext from '../../context/UserCart/userCartContext';
import userProfileContext from '../../context/UserProfile/userProfileContext';
import checkoutContext from '../../context/Checkout/checkoutContext';
// Ionic Components
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonToast,
} from '@ionic/react';

// Styling & Assets
import './CheckoutPayment.css';
import paymentMethodTwo from '../../assets/images/payment-method-2.png';
// import paymentMethodThree from '../../assets/images/payment-method-3.png';
import paymentMethodOne from '../../assets/images/payment-method-1.png';

// Custom Components & modules
import OrderSummaryItem from '../OrderSummaryItem/OrderSummaryItem';
import currencyFormatter from '../../helpers/currencyFormatter';

// API modules
import axios from 'axios';
import config from '../../config';
import { useHistory } from 'react-router';

interface Props {
  setCheckoutStep: (n: number) => void;
}

const CheckoutPayment: React.FC<Props> = ({ setCheckoutStep }) => {
  const history = useHistory();

  // Global State
  const { cart } = useContext(userCartContext);
  const { userProfileData, guestUserDetails } = useContext(userProfileContext);
  const {
    billingInfo,
    shippingInfo,
    dynamicallyPricedOrder,
    shippingOption,
    guestEmail,
  } = useContext(checkoutContext);

  // Local State
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingPaymentError, setLoadingPaymentError] = useState<string>('');
  const [paymentMethodId, setPaymentMethodId] = useState<number>(1);

  useEffect(() => {
    if (!shippingInfo) {
      setCheckoutStep(3);
    }
  });

  const completePaymentHandler = async () => {
    let token = localStorage.getItem('blendlyAuthToken') || null;
    let cartID = localStorage.getItem('blendlyCartId') || null;

    try {
      // Set Loading for spinner to true
      setLoadingPayment(true);

      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          token: token,
        },
      };

      // Get all the items with the cart id or via the token in params
      const { data: cartBlends } = await axios.get(
        `${config.apiURL}/carts/${cartID}/cart-blends`,
        axiosConfig
      );

      // Formated Items into Array of Objects
      let formatedCartBlends = cartBlends.map((el: any) => {
        return {
          bagSizeId: el.bag_size_id,
          bagTypeId: el.bag_type_id,
          blendId: el.blend_id,
          certificationId: el.certification_id,
          description: el.description,
          grindId: el.grind_id,
          id: el.id,
          labelDesignId: 58,
          name: el.name,
          quantity: el.quantity,
          roastId: el.roast_id,
        };
      });

      // Create Array of Axios Calls to be resolved at once
      const dynamicEndpointArr = cartBlends.map((el: any) => {
        // Get item blend recipes using item id
        let stringEndpoint = `${config.apiURL}/cart-blends/${el.id}/cart-blend-recipes`;

        return axios.get(stringEndpoint);
      });

      // Resolving Array of Axios Calls
      const allRes = await axios.all(dynamicEndpointArr);

      // Adding each items recipes array into tempArr
      let tempArr = allRes.map((response: any) => {
        const formatedEl = response.data.map((el: any) => {
          return {
            beanId: el.bean_id,
            cartBlendId: el.cart_blend_id,
            id: el.id,
            percentage: el.percentage,
          };
        });

        return formatedEl;
      });

      // Spreading out all of the array objects into one single recipes array
      let formatedAllRes = tempArr.reduce((acc, curr) => [...acc, ...curr], []);

      // Build body object for endpoint - POST - https://staging-api.blendly.co.uk/api/orders?token="dsad"
      let obj = {
        asBaristaDistributor: false,

        billingAddress: billingInfo.address,
        billingCity: billingInfo.city,
        billingCompanyName: billingInfo.company_name,
        billingCountryId: billingInfo.country_id,
        billingFirstName: billingInfo.first_name,
        billingLastName: billingInfo.last_name,
        billingPhone: billingInfo.phone,
        billingProvince: billingInfo.province,
        billingZipCode: billingInfo.zip_code,

        cartBlendRecipes: formatedAllRes,
        cartBlends: formatedCartBlends,
        cartId: userProfileData ? userProfileData.cart_id : cartID,
        checkoutMethodId: paymentMethodId,
        customerId: null,

        discountVoucherCode: null,

        guestEmail: guestEmail,

        shippingAddress: shippingInfo.address,
        shippingCity: shippingInfo.city,
        shippingCompanyName: shippingInfo.company_name,
        shippingCountryId: shippingInfo.country_id,
        shippingFirstName: shippingInfo.first_name,
        shippingLastName: shippingInfo.last_name,
        shippingOptionId: shippingOption,
        shippingPhone: shippingInfo.phone,
        shippingProvince: shippingInfo.province,
        shippingZipCode: shippingInfo.zip_code,
      };

      // Create The Order Sending Object
      const res = await axios.post(`${config.apiURL}/orders`, obj, axiosConfig);

      // Check if paypal approval url exists
      if (res.data.paypal_approval_url) {
        // Redirect To Paypal via received link
        window.location.href = res.data.paypal_approval_url;
      } else {
        // Redirect To The /checkout/complete providing order id in params
        history.push(`/checkout/complete?orderId=${res.data.order.id}`);
      }
    } catch (error: any) {
      //Handler Error
      let errorMessage: string = error.response
        ? error.response.data.message
        : 'Error Occurred';
      setLoadingPaymentError(errorMessage);
      setLoadingPayment(false);
    }
  };

  const conditionalThridPaymentMethod = () => {
    if (userProfileData) {
      return (
        userProfileData.usable_balance > 0 && (
          <IonItem>
            <IonLabel>
              <p>Save To Balance</p>
              <p>
                <span>
                  {currencyFormatter(
                    userProfileData.balance_limit -
                      userProfileData.usable_balance
                  )}
                </span>
                +
                <span>
                  {currencyFormatter(
                    dynamicallyPricedOrder ? dynamicallyPricedOrder.total : 0
                  )}
                </span>
                /<span>{currencyFormatter(userProfileData.balance_limit)}</span>
              </p>
            </IonLabel>
            <IonRadio value={2} slot="start" />
          </IonItem>
        )
      );
    } else if (guestUserDetails) {
      return (
        guestUserDetails.usable_balance > 0 && (
          <IonItem>
            <IonLabel>
              <p>Save To Balance</p>
              <p>
                {currencyFormatter(
                  guestUserDetails.balance_limit -
                    guestUserDetails.usable_balance
                )}
                +
                {currencyFormatter(
                  dynamicallyPricedOrder ? dynamicallyPricedOrder.total : 0
                )}
                /{currencyFormatter(guestUserDetails.balance_limit)}
              </p>
            </IonLabel>
            <IonRadio value={2} slot="start" />
          </IonItem>
        )
      );
    }
  };

  return (
    <div className="checkoutPayment">
      <IonToast
        isOpen={loadingPaymentError ? true : false}
        onDidDismiss={() => setLoadingPaymentError('')}
        message={loadingPaymentError}
        duration={5000}
        color="danger"
      />

      <div className="checkoutPayment__topinfo">
        <div className="checkoutPayment__left">
          <h6 className="checkoutPayment__title">Order Summary</h6>

          {cart.map((cartItem: any) => (
            <OrderSummaryItem key={cartItem.id} cartItem={cartItem} />
          ))}

          <div className="checkoutPayment__subtotal">
            <span>Sub Total</span>
            <span>
              {currencyFormatter(
                dynamicallyPricedOrder ? dynamicallyPricedOrder.subtotal : 0
              )}
            </span>
          </div>

          <div className="checkoutPayment__shippingCost">
            <span>Shipping cost</span>
            <span>
              {currencyFormatter(
                dynamicallyPricedOrder
                  ? dynamicallyPricedOrder.shipping_cost
                  : 0
              )}
            </span>
          </div>

          <div className="checkoutPayment__voldiscount">
            <span>Volume Discount</span>
            <span>
              -
              {currencyFormatter(
                dynamicallyPricedOrder
                  ? dynamicallyPricedOrder.volume_discount
                  : 0
              )}
            </span>
          </div>

          <div className="checkoutPayment__total">
            <span>Grand Total</span>
            <span>
              {currencyFormatter(
                dynamicallyPricedOrder ? dynamicallyPricedOrder.total : 0
              )}
            </span>
          </div>
        </div>
        <div className="checkoutPayment__right">
          <h6 className="checkoutPayment__title">Payment Method</h6>

          <IonList>
            <IonRadioGroup
              value={paymentMethodId}
              onIonChange={(e: any) => setPaymentMethodId(e.detail.value)}
            >
              <IonItem>
                <IonLabel>
                  <img src={paymentMethodOne} alt="payment method one" />
                </IonLabel>
                <IonRadio value={1} slot="start" />
              </IonItem>
              <IonItem>
                <IonLabel>
                  <img src={paymentMethodTwo} alt="payment method two" />
                </IonLabel>
                <IonRadio value={3} slot="start" />
              </IonItem>

              {/* Only Show this if user logged in and has token and if usable balance is less than total price*/}
              {conditionalThridPaymentMethod()}
            </IonRadioGroup>
          </IonList>

          <IonButton
            disabled={loadingPayment}
            className="warning"
            expand="full"
            onClick={completePaymentHandler}
          >
            {!loadingPayment ? 'Complete Payment' : 'Loading...'}
          </IonButton>
        </div>
      </div>

      <div className="checkoutPayment__billing">
        <h6 className="checkoutPayment__title">Billing</h6>
        <div className="checkoutPayment__form">
          <div className="checkoutPayment__leftForm">
            <IonItem>
              <IonLabel>First Name</IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.first_name : ''}
                placeholder="First name"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Last Name</IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.last_name : ''}
                placeholder="Last name"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Phone Number</IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.phone : ''}
                placeholder="Phone number"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>
                Company Name <small>(optional)</small>
              </IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.company_name : ''}
                placeholder="Company name"
                readonly
              ></IonInput>
            </IonItem>
          </div>
          <div className="checkoutPayment__rightForm">
            <IonItem>
              <IonLabel>Address</IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.address : ''}
                placeholder="adress line 1"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Country</IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.country_id : ''}
                placeholder="Country"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>City</IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.city : ''}
                placeholder="City"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>State/Province</IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.province : ''}
                placeholder="State/Province"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>ZIP/Postal Code</IonLabel>
              <IonInput
                value={billingInfo ? billingInfo.zip_code : ''}
                placeholder="ZIP/Postal code"
                readonly
              ></IonInput>
            </IonItem>
          </div>
        </div>
      </div>

      <div className="checkoutPayment__shipping">
        <h6 className="checkoutPayment__title">Shipping</h6>
        <div className="checkoutPayment__form">
          <div className="checkoutPayment__leftForm">
            <IonItem>
              <IonLabel>First Name</IonLabel>
              <IonInput
                value={shippingInfo ? shippingInfo.first_name : ''}
                placeholder="First name"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Last Name</IonLabel>
              <IonInput
                value={shippingInfo ? shippingInfo.last_name : ''}
                placeholder="Last name"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>
                Company Name <small>(optional)</small>
              </IonLabel>
              <IonInput
                value={shippingInfo ? shippingInfo.company_name : ''}
                placeholder="Company name"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Address</IonLabel>
              <IonInput
                value={shippingInfo ? shippingInfo.address : ''}
                placeholder="Adress"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Shipping Option</IonLabel>
              <IonInput
                value={shippingOption}
                placeholder="shipping option"
                readonly
              ></IonInput>
            </IonItem>
          </div>
          <div className="checkoutPayment__rightForm">
            <IonItem>
              <IonLabel>Country</IonLabel>
              <IonInput
                value={shippingInfo ? shippingInfo.country_id : ''}
                placeholder="Country"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>City</IonLabel>
              <IonInput
                value={shippingInfo ? shippingInfo.city : ''}
                placeholder="City"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>State/Province</IonLabel>
              <IonInput
                value={shippingInfo ? shippingInfo.province : ''}
                placeholder="State/Province"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>ZIP/Postal Code</IonLabel>
              <IonInput
                value={shippingInfo ? shippingInfo.zip_code : ''}
                placeholder="ZIP/Postal code"
                readonly
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>Phone Number</IonLabel>
              <IonInput
                value={shippingInfo ? shippingInfo.phone : ''}
                placeholder="Phone number"
                readonly
              ></IonInput>
            </IonItem>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPayment;
