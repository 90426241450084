import React, { Children, useState } from "react";
import {
  IonRow,
  IonCol,
  IonText,
  IonGrid,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/react";
import { chevronBack, chevronDown } from "ionicons/icons";

import "./accordian.css";

type Props = {
  title: string;
  children: React.ReactNode;
};

export const Accordian: React.FC<Props> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordian-row">
      <IonRow id="accordian-group" className="accordian">
        <IonCol>
          <IonItem
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={isOpen ? "selected" : ""}
          >
            <IonLabel className="accordian__header">{title}</IonLabel>
            {isOpen ? (
              <IonIcon icon={chevronDown}></IonIcon>
            ) : (
              <IonIcon icon={chevronBack}></IonIcon>
            )}
          </IonItem>
          {isOpen ? <div className="accordian-text">{children}</div> : <></>}
        </IonCol>
      </IonRow>
    </div>
  );
};
