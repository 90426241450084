import React, { useState } from 'react';
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
} from '@ionic/react';
import axios from 'axios';
import './TasteMatch.css';
import Banner from '../../components/Banner/Banner';
import { Footer } from '../../components/Layout/Footer';
import { Header } from '../../components/Layout/Header';
import config from '../../config';
import { Helmet } from 'react-helmet';

const TasteMatch: React.FC = () => {
  // Initialize States
  const [brand, setBrand] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isSuccesful, setSuccessBool] = useState(false);
  const [isError, setErrorBool] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // On Input change
  const onInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'brand':
        setBrand(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      default:
        break;
    }
    // Remove messages on input change
    setErrorBool(false);
    setErrorMessage('');
  };

  // On Submit form
  const onSubmitForm = (e: any) => {
    e.preventDefault();
    setSuccessBool(false);
    axios
      .post(`${config.apiURL}/taste-matching-requests`, {
        brand,
        name,
        email,
      })
      .then((res) => {
        setSuccessBool(true);
        setErrorBool(false);
        setBrand('');
        setEmail('');
        setName('');
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        setSuccessBool(false);
        setErrorBool(true);
      });
  };
  return (
    <IonPage className="tasteMatch">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Taste Match</title>
      </Helmet>
      <IonContent>
        <Header />

        <Banner
          options={{
            h1: 'Taste Match Any',
            h2: 'Coffee Brand.',
            bgImg: 'assets/images/taste-match-header.png',
            size: 'half',
          }}
        />

        <section className="tasteMatch__section">
          <div className="tasteMatch__form-container">
            <div className="tasteMatch__form-header form-title">
              <h4>Request a taste match now or chat with a roaster</h4>
            </div>
            <form className="form tasteMatch__form" onSubmit={onSubmitForm}>
              <IonItem className="form-input">
                <IonLabel position="floating" className="form-label">
                  Brand To Taste Match
                </IonLabel>
                <IonInput
                  type="text"
                  required
                  name="brand"
                  value={brand}
                  onIonChange={onInputChange}
                ></IonInput>
              </IonItem>
              <IonItem className="form-input">
                <IonLabel position="floating" className="form-label">
                  Your Name
                </IonLabel>
                <IonInput
                  type="text"
                  required
                  name="name"
                  value={name}
                  onIonChange={onInputChange}
                ></IonInput>
              </IonItem>
              <IonItem className="form-input">
                <IonLabel position="floating" className="form-label">
                  Your Email
                </IonLabel>
                <IonInput
                  type="email"
                  required
                  name="email"
                  value={email}
                  onIonChange={onInputChange}
                ></IonInput>
              </IonItem>
              <IonButton expand="full" className="warning" type="submit">
                Send my Request
              </IonButton>
            </form>
            {/* Success and Error messages */}
            <div className="tasteMatch__form-messages">
              {isSuccesful === true ? (
                <p className="tasteMatch__form-messages__success">
                  You've successfully sent your request. We'll be in touch
                  shortly.
                </p>
              ) : null}
              {isError ? (
                <p className="tasteMatch__form-messages__errors">
                  {errorMessage}
                </p>
              ) : null}
            </div>
          </div>
          <div className="tasteMatch__chat-box">
            <span>
              <img src="assets/images/message-icon.png" />
              <span>Need assistance?</span>
            </span>
          </div>
        </section>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default TasteMatch;
