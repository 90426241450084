// Core React
import { useContext } from "react";

// Global State
import UserCartContext from "../../context/UserCart/userCartContext";
import UserProfileContext from "../../context/UserProfile/userProfileContext";
import mobileMenuContext from "../../context/MobileMenu/mobileMenuContext";

// Ionic Components
import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonRouterLink,
  IonButton,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";

// Ionic Icons
import { cart as CartIcon, person as PersonIcon } from "ionicons/icons";

// Styling
import "./Header.css";

export const Header: React.FC = () => {
  const { cart } = useContext(UserCartContext);
  const { userProfileData } = useContext(UserProfileContext);
  const { openMenuAction } = useContext(mobileMenuContext);

  // const [userName, setUserName] = useState(null);
  const blendlyAuthToken = localStorage.getItem("blendlyAuthToken");

  // Checking Url if it has /profile in it
  const currentUrl = window.location.href;
  const isUserProfile = currentUrl.includes("/profile");

  const openMenu = () => {
    openMenuAction(true);
  };

  const logOut = () => {
    localStorage.removeItem("blendlyAuthToken");
    localStorage.removeItem("blendlyCartId");
    window.location.href = "/login";
  };

  return (
    <IonHeader className="header">
      <div id="main-content">
        <IonToolbar
          className={`header__toolbar ${
            isUserProfile ? "profile__header" : null
          }`}
        >
          {/* Cart And Profile Icon Section */}
          <ul className="header__cartAuth">
            {blendlyAuthToken === null ? (
              <li>
                <IonRouterLink routerLink="/login">Login</IonRouterLink>
              </li>
            ) : (
              <li>
                <button onClick={logOut}>Logout</button>
              </li>
            )}
            <li>
              <IonButton icon-only routerLink="/cart" fill="clear">
                <IonIcon icon={CartIcon} />
                {cart.length > 0 && (
                  <span className="header__cartCount">{cart.length}</span>
                )}
              </IonButton>
            </li>
            <li>
              <IonButton icon-only routerLink="/profile" fill="clear">
                <IonIcon icon={PersonIcon} />
                {userProfileData && <span>{userProfileData.full_name}</span>}
              </IonButton>
            </li>
          </ul>
          <nav className="header__nav">
            {/*  Navigation Links Left of Logo */}
            <ul className="header__navlist">
              <li>
                <IonRouterLink routerLink="/aboutus">About Us</IonRouterLink>
              </li>
              <li>
                <IonRouterLink routerLink="/shop">Shop</IonRouterLink>
              </li>
              <li>
                <IonRouterLink routerLink="/wholesale">Wholesale</IonRouterLink>
              </li>
              <li>
                <IonRouterLink routerLink="/subscription">
                  Subscriptions
                </IonRouterLink>
              </li>
              {/* {blendlyAuthToken === null ? (
                <li>
                  <IonRouterLink routerLink="/subscription">
                    Subscriptions
                  </IonRouterLink>
                </li>
              ) : (
                <li>
                  <IonRouterLink routerLink="/">Subscriptions</IonRouterLink>
                </li>
              )} */}
            </ul>
            {/*  Navigation Links Right of Logo */}
            <ul className="header__navlist">
              <li>
                <IonRouterLink routerLink="/blend">Blend</IonRouterLink>
              </li>
              <li>
                <IonRouterLink routerLink="/cuppingnotes">
                  Cupping Notes
                </IonRouterLink>
              </li>
              <li>
                <IonRouterLink routerLink="/tastematch">
                  Taste Match
                </IonRouterLink>
              </li>
              <li>
                <IonRouterLink href="/blog">Blog</IonRouterLink>
              </li>
            </ul>
          </nav>
        </IonToolbar>
        {/* Blendy Logo Section */}
        <IonRouterLink routerLink="/" className="header__brandlogo">
          {isUserProfile ? (
            <img
              src="assets/images/blendly-logo-dark.png"
              alt="blendly brand logo"
            />
          ) : (
            <img
              src="assets/images/blendly-logo.png"
              alt="blendly brand logo"
            />
          )}
        </IonRouterLink>

        {/* Mobile Menu Section */}
        <div className="header__mobileMenuIcon">
          <div onClick={openMenu} className="header__mobileMenuIcon__container">
            <div></div>
            <div></div>
            <div></div>
          </div>
          {/* <IonMenuButton autoHide={false}></IonMenuButton> */}
        </div>

        {/* Mobile Menu Icons */}
        <IonButtons className="header__mobileAccountIcons">
          <IonButton
            fill="clear"
            routerLink="/cart"
            className="header__mobileAccountBtn"
          >
            <IonIcon icon={CartIcon} slot="icon-only"></IonIcon>
          </IonButton>
          <IonButton
            fill="clear"
            routerLink="/profile"
            className="header__mobileAccountBtn"
          >
            <IonIcon icon={PersonIcon} slot="icon-only"></IonIcon>
          </IonButton>
        </IonButtons>
      </div>
    </IonHeader>
  );
};
