import {
  IonGrid,
  IonRow,
  IonCol,
  IonRouterLink,
  useIonAlert,
} from "@ionic/react";
import React, { useContext } from "react";
import axios from "axios";
import "../../MyProfile/MyProfile.css";
import config from "../../../../config";
import userProfileContext from "../../../../context/UserProfile/userProfileContext";

const ShippingInformation: React.FC = () => {
  const { shippingDetails } = useContext(userProfileContext);
  const [present] = useIonAlert();
  const blendlyAuthToken = window.localStorage.getItem("blendlyAuthToken");
  let count = 0;

  const deleteShipping = (id: any) => {
    axios({
      method: "delete",
      url: `${config.apiURL}/shipping-details/${id}?token=${blendlyAuthToken}`,
    })
      .then((res) => (window.location.href = "/profile"))
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
  return (
    <div className="my-profile">
      <section className="my-profile__section-dark">
        <div className="my-profile__container section-dark">
          <IonGrid>
            <IonRow className="my-profile__header section-dark-header">
              <IonCol size="10" className="section-header">Shipping Information</IonCol>
              <IonCol size="2" className="edit-button">
                <IonRouterLink href="/profile/shipping-details/create">
                  Add
                </IonRouterLink>
              </IonCol>
            </IonRow>
          </IonGrid>

          {/* Shipping Information */}
          <IonGrid>
            {shippingDetails.map((item: any) => {
              const {
                first_name,
                last_name,
                company_name,
                address,
                city,
                zip_code,
                province,
              } = item;
              return (
                <div key={item.id}>
                  {/* Header Row */}
                  <IonRow className="my-profile__header-container">
                    <IonCol>
                      <h5 className="my-profile__shipping-header">
                        Shipping Address {(count = count + 1)}{" "}
                      </h5>
                    </IonCol>

                    
                    <IonCol className="my-profile__shipping-links">
                      <IonRouterLink
                        href={`/profile/shipping-details/update/${item.id}`}
                      >
                        {/* <ion-icon name="create"></ion-icon> */}
                        Edit
                      </IonRouterLink>
                      <IonRouterLink
                        onClick={() =>
                          present({
                            cssClass: "my-profile__shipping-delete",
                            header: "Alert",
                            message:
                              "Are you sure you want to delete shipping address ?",
                            buttons: [
                              "No",
                              {
                                text: "Yes",
                                handler: () => deleteShipping(item.id),
                              },
                            ],
                          })
                        }
                      >
                        {/* <ion-icon name="trash"></ion-icon> */}
                        Delete
                      </IonRouterLink>
                    </IonCol>
                  </IonRow>

                  {/* Name Row */}
                  <IonRow className="my-profile__section-rows">
                    <IonCol>Name</IonCol>
                    <IonCol>{`${first_name} ${last_name}`}</IonCol>
                    <IonCol></IonCol>
                  </IonRow>

                  {/* Company Name Row */}
                  <IonRow className="my-profile__section-rows">
                    <IonCol>Company Name</IonCol>
                    <IonCol>{company_name}</IonCol>
                    <IonCol></IonCol>
                  </IonRow>

                  {/* Address Row */}
                  <IonRow className="my-profile__section-rows">
                    <IonCol>Address</IonCol>
                    <IonCol>{address}</IonCol>
                    <IonCol></IonCol>
                  </IonRow>

                  {/* City Row */}
                  <IonRow className="my-profile__section-rows">
                    <IonCol>City</IonCol>
                    <IonCol>{city}</IonCol>
                    <IonCol></IonCol>
                  </IonRow>

                  {/* Province Row */}
                  <IonRow className="my-profile__section-rows">
                    <IonCol>Province</IonCol>
                    <IonCol>{province}</IonCol>
                    <IonCol></IonCol>
                  </IonRow>

                  {/* Postal Code Row */}
                  <IonRow className="my-profile__section-rows">
                    <IonCol>Postal Code</IonCol>
                    <IonCol>{zip_code}</IonCol>
                    <IonCol></IonCol>
                  </IonRow>
                </div>
              );
            })}
          </IonGrid>
        </div>
      </section>
    </div>
  );
};

export default ShippingInformation;
