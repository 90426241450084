import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
} from '@ionic/react';
import React from 'react';
import { Header } from '../../components/Layout/Header';
import './ResetsPageStyles.css';
import { Footer } from '../../components/Layout/Footer';
import { Helmet } from 'react-helmet';

const heroBackground = {
  backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/barista-making-chemex-coffee.png')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const UsernameResetPage: React.FC = () => {
  return (
    <IonPage className="user-reset">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Password Reset</title>
      </Helmet>
      <IonContent>
        <Header />

        <div className="user-reset__hero" style={heroBackground}>
          <form className="user-reset__form">
            <h2>Reset Username</h2>
            <IonItem>
              <IonLabel position="floating">Email</IonLabel>
              <IonInput type="email" required></IonInput>
            </IonItem>
            <div className="user-reset__buttons">
              <IonRouterLink routerLink="/login">
                <IonButton type="button" className="user-reset__buttons__link">
                  Back
                </IonButton>
              </IonRouterLink>
              <IonButton type="submit" className="user-reset__buttons__accept">
                Send
              </IonButton>
            </div>
          </form>
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default UsernameResetPage;
