import { useContext, useState } from 'react';

// Global State
import CuppingNotesContext from '../../context/CuppingNotes/cuppingNotesContext';

// Custom Components
import NoteInformationSummary from '../NoteInformationSummary/NoteInformationSummary';
import NoteBeansInfo from '../NoteBeansInfo/NoteBeansInfo';

// Styling
import './NoteInfoSection.css';

const NoteInfoSection = () => {
  const [activeTab, setActiveTab] = useState(1);

  const { selectedNote } = useContext(CuppingNotesContext);

  return (
    <div className="noteInfoSection">
      <div className="noteInfoSection__tabnav">
        <div
          className={`noteInfoSection__tab ${activeTab === 1 && 'active'}`}
          onClick={() => setActiveTab(1)}
        >
          Information
        </div>
        {selectedNote.blend_recipes && (
          <div
            className={`noteInfoSection__tab ${activeTab === 2 && 'active'}`}
            onClick={() => setActiveTab(2)}
          >
            Beans
          </div>
        )}
      </div>

      {activeTab === 1 && <NoteInformationSummary />}

      {activeTab === 2 && <NoteBeansInfo />}
    </div>
  );
};

export default NoteInfoSection;
