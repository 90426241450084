import { IonButton, IonIcon } from '@ionic/react';

// Ionic Icons
import { sendOutline } from 'ionicons/icons';

// Styling & Assets
import './SignUpSuccess.css';

const SignUpSuccess = () => {
  return (
    <div className="signupsuccess">
      <h1 className="signupsuccess__title">Welcome To Blendly</h1>
      <h3 className="signupsuccess__subtitle">
        You've successfully created your account
      </h3>

      <div className="signupsuccess__text">
        <p>We sent a verification link to your email</p>
        <p>Please verify it to enjoy all the features of Blendly</p>
      </div>

      <IonButton fill="clear" className="signupsuccess__resendBtn">
        Resend verification email
        <IonIcon icon={sendOutline} slot="end" />
      </IonButton>

      <p>You're already logged in</p>

      <IonButton className="warning">Start Blending Now!</IonButton>
    </div>
  );
};

export default SignUpSuccess;
